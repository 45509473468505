import React from 'react';
import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import {
  ArrayField,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  EditView,
  EditButton,
  FileField,
  FileInput,
  FormTab,
  FunctionField,
  NumberInput,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  ShowButton,
  TabbedForm,
  TextField,
  TextInput,
  Pagination,
  useEditController,
  EditContextProvider,
  useRecordContext,
} from 'react-admin';

import SelectInput from '../input/SelectInput';
import NullableBooleanInput from '../input/NullableBooleanInput';
import SelectArrayInput from '../input/SelectArrayInput';

import UserTitle from './UserTitle';
import TakeSparButton from './TakeSparButton';
import TakeTrapetsButton from './TakeTrapetsButton';
import AddBankAccount from './AddBankAccount';
import VerifyBankAccount from './VerifyBankAccount';

import AccountStatusChip from '../accounts/AccountStatusChip';
import MarkAMLRisk from '../riskReport/MarkAMLRisk';
import { UcInfoFormTab } from '../uc';
import CreditSafeDatagrid from '../creditSafeChecks/CreditSafeDatagrid';

import SendReminderSmsButton from '../utils/SendReminderSmsButton';
import SendFreeTextSmsButton from '../utils/SendFreeTextSmsButton';
import AssignedAdmin from '../utils/AssignedAdmin';
import CreateRelatedButton from '../utils/CreateRelatedButton';
import DocumentsField from '../utils/DocumentsField';
import CategorisationTab from '../utils/CategorisationTab';
import PhoneNumberInput from '../utils/PhoneNumberInput';
import validateBankDetailsFactory from '../utils/validateBankDetails';
import PageTitle from '../utils/PageTitle';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import MoneyField from '../utils/MoneyField';
import HolderAccountList from '../utils/HolderAccountList';
import AnnualTaxReportDownloadButton from '../utils/AnnualTaxReportDownloadButton';

import { email } from '../../utils/validationErrors';
import useGetAdminList from '../../utils/useGetAdminList';
import { useConstantContext } from '../utils/ConstantsContext';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const getRecord = (props) => props.record || {};

const SendTextFields = () => {
  const record = useRecordContext();

  return (
    <Box display="flex" alignItems="center">
      <SendFreeTextSmsButton userId={record.id} />
      <SendReminderSmsButton userId={record.id} />
    </Box>
  );
};

const UserEdit = () => {
  const { choices, constants } = useConstantContext();
  const {
    bankAccountTypes,
    banks,
    maritalStatuses,
    children,
    allOccupations,
    reasonsForLoan,
    amlRiskLevels,
    userFlags,
    applicationSteps,
    decisionStatuses,
    countries,
  } = choices;
  const validateBankDetails = validateBankDetailsFactory(constants.bankAccountTypes);

  const controllerProps = useEditController({
    mutationMode: 'pessimistic',
    redirect: false,
  });

  const { data: adminList, error: adminListError, isLoading: adminListLoading } = useGetAdminList();

  return (
    <EditContextProvider value={controllerProps}>
      <EditView title={<UserTitle />}>
        <TabbedForm validate={validateBankDetails}>
          <FormTab label="User details">
            <PageTitle title="User | Edit" />
            <MarkAMLRisk />
            <TextInput readOnly source="id" />
            <SimpleShowLabeled>
              <SocialSecurityNumberField source="socialSecurityNumber" />
            </SimpleShowLabeled>
            <TextInput source="firstName" label="First name" />
            <TextInput source="lastName" label="Last name" />
            <TextInput source="email" type="email" validate={[email]} />
            <PhoneNumberInput source="phone" label="Phone" />
            <SendTextFields />
            <NullableBooleanInput source="isNotPEP" label="Is not PEP" />
            <SelectArrayInput
              source="citizenships"
              label="Citizenships"
              choices={countries}
            />
            <TextInput source="streetAddress" label="Street address" />
            <TextInput source="postcode" />
            <TextInput source="town" />
            <SimpleShowLabeled>
              <ArrayField source="bankDetails.bankAccounts" label="Bank accounts">
                <Datagrid bulkActionButtons={null}>
                  <TextField source="id" />
                  <SelectField
                    source="bankAccountType"
                    label="Bank&nbsp;account&nbsp;type"
                    choices={bankAccountTypes}
                  />
                  <SelectField
                    source="bank"
                    label="Bank"
                    choices={banks}
                  />
                  <TextField source="accountNumber" label="Account&nbsp;number" />
                  <TextField source="clearingNumber" label="Clearing&nbsp;number" />
                  <BooleanField source="isManuallyVerified" label="Is&nbsp;manually&nbsp;verified" />
                  <BooleanField source="isOwnershipVerified" label="Is&nbsp;ownership&nbsp;verified" />
                  <BooleanField source="isValidForPayin" label="Valid&nbsp;payin" />
                  <BooleanField source="isValidForPayout" label="Valid&nbsp;payout" />
                  <VerifyBankAccount />
                  <ArrayField source="signatures">
                    <Datagrid bulkActionButtons={null}>
                      <DateField source="date" />
                      <BooleanField source="isRevoked" label="is&nbsp;Revoked" />
                    </Datagrid>
                  </ArrayField>
                  <ArrayField source="autogiroMandates">
                    <Datagrid bulkActionButtons={null}>
                      <DateField source="date" />
                      <TextField source="bankgiroNumber" label="bankgiro&nbsp;Number" />
                      <BooleanField source="toCancel" label="to&nbsp;Cancel" />
                    </Datagrid>
                  </ArrayField>
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
            <AddBankAccount />
            <SimpleShowLabeled>
              <BooleanField source="active" />
            </SimpleShowLabeled>
            <BooleanInput source="extensionIsAllowed" label="Is allowed to extend" />
            <NumberInput source="economicDetails.monthlyIncome" label="Monthly income" />
            <NumberInput source="economicDetails.monthlyHousing" label="Monthly housing" />
            <NumberInput source="economicDetails.monthlyLoans" label="Monthly loans" />
            <SelectInput
              source="maritalStatus"
              label="Marital Status"
              choices={maritalStatuses}
            />
            <SelectInput
              source="economicDetails.kids"
              label="Number of kids"
              choices={children}
            />
            <SelectInput
              source="economicDetails.occupation"
              label="Occupation"
              choices={allOccupations}
            />
            <SelectInput
              source="reasonForLoan"
              label="Reason for loan"
              choices={reasonsForLoan}
            />
            <SimpleShowLabeled>
              <SelectField
                source="amlRiskLevel"
                label="AML Risk Level"
                choices={amlRiskLevels}
              />
            </SimpleShowLabeled>
            <SelectArrayInput
              label="Flags"
              source="flags"
              choices={userFlags}
            />
            <SimpleShowLabeled>
              <DateField source="createdAt" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <TextField source="trackingData.utmSource" label="UTM Source" />
            </SimpleShowLabeled>
            <AnnualTaxReportDownloadButton />
            <DocumentsField source="id" model="user" />
            <FileInput source="uploadedDocuments" label="Upload documents" multiple>
              <FileField source="src" title="title" />
            </FileInput>
          </FormTab>
          <FormTab label="Accounts">
            <Box width="100%">
              <HolderAccountList>
                <ReferenceField label="Public id" source="id" reference="account">
                  <TextField source="publicId" />
                </ReferenceField>
                <TextField source="product" />
                <TextField source="accountType" />
                <MoneyField source="remainingCapitalAmount" align="right" />
                <MoneyField source="todaySolvingAmount" align="right" />
                <NumberField source="currentTerms.nominalRate" label="Nominal rate" locales="sv" options={{ maximumFractionDigits: 2 }} />
                <DateField source="createdAt" />
                <NumberField label="Estimated remaining payments" source="estimatedRepaymentTime" />
                <FunctionField label="Status" render={(record) => <AccountStatusChip status={record.status} passedInvoicesPayed={record.passedInvoicesPayed} />} />
                <TextField source="ocrNumber" />
              </HolderAccountList>
            </Box>
          </FormTab>
          <FormTab label="applications">
            <ReferenceManyField
              label="Applications"
              reference="application"
              target="applicants._id"
              fullWidth
              pagination={<Pagination />}
            >
              <Box width="100%">
                <Datagrid bulkActionButtons={null}>
                  <TextField source="id" />
                  <DateField source="createdAt" />
                  <TextField source="trackingData.utmSource" label="UTM Source" />
                  <TextField source="accountType" />
                  <BooleanField source="rejectedByCustomer" />
                  <SelectField
                    source="applicationStep"
                    choices={applicationSteps}
                  />
                  <SelectField
                    source="currentDecisionStatus"
                    choices={decisionStatuses}
                  />
                  <TextField source="loanTerms.nominalRate" label="Nominal rate" />
                  <AssignedAdmin
                    loading={adminListLoading}
                    error={adminListError}
                    adminList={adminList}
                    source="admin"
                    label="Assigned to"
                    resource="application"
                    minimal
                  />
                  <EditButton />
                </Datagrid>
              </Box>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Comments">
            <ReferenceManyField
              label="Comments"
              reference="comment"
              target="user"
              fullWidth
              pagination={<Pagination />}
            >
              <Datagrid bulkActionButtons={null}>
                <DateField source="createdAt" label="Date" showTime />
                <TextField source="message" />
                <ReferenceField source="author" reference="admins">
                  <TextField source="firstName" />
                </ReferenceField>
              </Datagrid>
            </ReferenceManyField>
            <CreateRelatedButton target="user" relatedPath="/comment">Add Comment</CreateRelatedButton>
          </FormTab>
          <FormTab label="Categorisation">
            {getRecord(controllerProps).id
              && <CategorisationTab userId={getRecord(controllerProps).id} />}
          </FormTab>
          <FormTab label="Spar">
            <TakeSparButton />
            <ReferenceManyField
              label="Spar checks"
              reference="sparChecks"
              target="user"
              fullWidth
              pagination={<Pagination />}
            >
              <Datagrid bulkActionButtons={null}>
                <TextField source="id" />
                <DateField source="createdAt" label="Date" />
                <TextField source="latestNames.firstName" label="First Name" />
                <TextField source="latestNames.middleName" label="Middle Name" />
                <TextField source="latestNames.lastName" label="Last Name" />
                <TextField source="registeredAddress.streetAddress" label="Street Address" />
                <TextField source="registeredAddress.postcode" label="Post Code" />
                <TextField source="registeredAddress.town" label="Town" />
                <DateField source="latestDetails.deceasedAt" label="Deceased At" />
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Trapets">
            <TakeTrapetsButton />
            <ReferenceManyField
              label="Trapets checks"
              reference="trapetsChecks"
              target="user"
              pagination={<Pagination />}
            >
              <Datagrid bulkActionButtons={null}>
                <TextField source="id" />
                <DateField source="createdAt" label="Date" />
                <ArrayField source="possibleMatches">
                  <Datagrid bulkActionButtons={null}>
                    <TextField source="name" />
                    <TextField source="listType" />
                    <TextField source="comment" />
                    <DateField source="birthDate" />
                    <ArrayField source="externalUrls">
                      <Datagrid bulkActionButtons={null}>
                        <FunctionField render={(record) => <a target="_blank" href={record} rel="noreferrer">{record}</a>} />
                      </Datagrid>
                    </ArrayField>
                  </Datagrid>
                </ArrayField>
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <UcInfoFormTab />
          <FormTab label="Credit Safe">
            <ReferenceManyField
              label="Credit Safe"
              reference="creditSafeChecks"
              target="user"
              fullWidth
              pagination={<Pagination />}
            >
              <CreditSafeDatagrid />
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Risk Reports">
            <ReferenceManyField
              label="Risk reports"
              reference="riskReport"
              target="accountHolder._id"
              fullWidth
              pagination={<Pagination />}
            >
              <Datagrid bulkActionButtons={null}>
                <TextField source="id" />
                <DateField source="createdAt" />
                <ReferenceField label="User" source="accountHolder._id" reference="user">
                  <SocialSecurityNumberField source="socialSecurityNumber" />
                </ReferenceField>
                <TextField source="rule" />
                <TextField source="status" />
                <FunctionField label="Reason" render={(record) => <Tooltip title={record.reason} placement="top"><Info /></Tooltip>} />
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Held Payments">
            <ReferenceManyField
              label="Held Payments"
              reference="heldPayments"
              target="accountHolders._id"
              pagination={<Pagination />}
            >
              <Datagrid bulkActionButtons={null}>
                <TextField source="id" />
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </EditView>
    </EditContextProvider>
  );
};

export default UserEdit;
