import React from 'react';
import {
  List,
  TextField,
  TextInput,
  EditButton,
  BooleanField,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import HeadedDatagrid from '../utils/HeadedDatagrid';

const partnerFilters = [
  <TextInput source="_id" label="Partner ID" alwaysOn />,
  <TextInput source="name" alwaysOn />,
  <TextInput source="utmSource" alwaysOn />,
];

const PartnerList = () => (
  <List filters={partnerFilters}>
    <HeadedDatagrid head={(<PageTitle title="Partner | List" />)}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="username" />
      <TextField source="utmSource" />
      <BooleanField source="isActive" />
      <EditButton />
    </HeadedDatagrid>
  </List>
);

export default PartnerList;
