/* eslint-disable react/prop-types */
import React from 'react';

import BalanceReport from './BalanceReport';

import PageTitle from '../utils/PageTitle';

const Reports = () => (
  <>
    <PageTitle title="Reports" />
    <BalanceReport />
  </>
);

export default Reports;
