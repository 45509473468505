import React, { useCallback, useState } from 'react';
import {
  useRecordContext,
  DateField,
  NumberField,
  required,
  TextInput,
  useNotify,
  RecordContextProvider,
} from 'react-admin';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ModalButton from './ModalButton';
import TransactionTypeField from './TransactionTypeField';
import useCustomQuery from '../../hooks/useCustomQuery';
import useCustomRpc from '../../hooks/useCustomRpc';

const spacing = {
  marginBottom: '0.5rem',
  marginTop: '0.5rem',
};

const validateRequired = [required()];

const ZeroAccountButton = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const [comment, setComment] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);

  const { data = {} } = useCustomQuery({
    httpMethod: 'GET',
    path: `account/${record.id}/debt-zeroing`,
    errorMessage: 'Error fetching solving transactions',
    enabled: isOpen && shouldFetch,
  });
  const { transactions } = data;

  const { mutate: zeroAccount } = useCustomRpc({
    httpMethod: 'POST',
    path: `account/${record.id}/debt-zeroing`,
    errorMessage: 'Failed to zero account',
    successMessage: 'Account successfully zeroed',
    shouldRefresh: true,
  });

  const onChange = useCallback((e) => {
    e.preventDefault();
    setComment(e.target.value);
  }, [setComment]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClick = useCallback(() => {
    if (record.todaySolvingAmount <= 0) return false;

    if (!comment) {
      notify('Comment is mandatory', { type: 'error' });
      return false;
    }

    zeroAccount({ transactions, comment });
    setShouldFetch(false);
    return true;
  }, [record.todaySolvingAmount, comment, zeroAccount, transactions, notify]);

  return (
    <ModalButton
      variant="contained"
      size="small"
      onOpen={onOpen}
      buttonWrapperStyle={spacing}
      onClick={onClick}
      title="Zero Account"
      loading={!!transactions}
      disabled={(transactions && transactions.length === 0) || record.todaySolvingAmount <= 0}
    >
      {
        transactions && transactions.length > 0
          ? (
            <Box display="flex" flexDirection="column">
              <Typography>
                Those are the transactions that are going to be added to the account:
              </Typography>
              <List dense={false}>
                {
                  transactions.map((transaction) => (
                    <ListItem key={transaction._id} style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                      <RecordContextProvider value={transaction}>
                        <TransactionTypeField source="type" />
                        <DateField source="date" />
                        <NumberField source="amount" options={{ style: 'currency', currency: 'SEK' }} />
                      </RecordContextProvider>
                    </ListItem>
                  ))
                }
              </List>
              <Typography>Are you sure to continue? Please, leave a comment below:</Typography>
              <TextInput
                alwaysOn
                label="Comment"
                source="comment"
                onChange={onChange}
                validate={validateRequired}
              />
            </Box>
          )
          : <Typography>Nothing you can do.</Typography>
      }
    </ModalButton>
  );
};

export default ZeroAccountButton;
