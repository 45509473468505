import React, { useCallback, useState } from 'react';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';
import useCustomRpc from '../../hooks/useCustomRpc';

const styles = {
  inputLine: {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    margin: '2rem',
  },
  container: {
    float: 'right',
  },
};

const UploadPartnerLogoButton = () => {
  const record = useRecordContext();
  const { id } = record;

  const { mutate: sendPartnerLogo } = useCustomRpc({
    httpMethod: 'POST',
    path: `partner/${id}/upload`,
    mimeType: 'formData',
    shouldRefresh: true,
  });

  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileError, setUploadedFileError] = useState('');
  const onChangeUploadedFile = useCallback(
    (e) => {
      setUploadedFileError('');
      setUploadedFile(e.target.files[0]);
    },
    [setUploadedFile, setUploadedFileError],
  );

  const onSubmitUpload = useCallback(
    () => {
      setUploadedFileError('');
      if (!uploadedFile) {
        setUploadedFileError('Please upload a logo');
        return false;
      }
      const formDataFields = [uploadedFile].map((file) => ({
        fieldName: file.name,
        value: file,
      }));
      sendPartnerLogo(formDataFields);
      return true;
    },
    [sendPartnerLogo, uploadedFile],
  );

  const onClose = () => {
    setUploadedFileError('');
    setUploadedFile(null);
  };

  return (
    <div style={styles.container}>
      <ModalButton
        title="Upload Partner Logo"
        onClick={onSubmitUpload}
        onClose={onClose}
      >
        <div style={styles.inputLine}>
          <input
            type="file"
            id="file"
            name="file"
            accept="image/*"
            onChange={onChangeUploadedFile}
          />
        </div>
        <Typography color="error" align="center">{uploadedFileError}</Typography>
      </ModalButton>
    </div>
  );
};

export default UploadPartnerLogoButton;
