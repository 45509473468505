import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ColoredButton from './ColoredButton';

import useCustomRpc from '../../hooks/useCustomRpc';
import onSuccessGetDocument from '../../utils/onSuccessGetDocument';

const DocumentLink = ({
  product,
  type,
  utmSource,
}) => {
  const { mutate: getPublicDocument } = useCustomRpc({
    httpMethod: 'GET',
    path: `publicDocuments/${product}/${type}?utmSource=${utmSource}`,
    errorMessage: 'Could not retrieve file',
    onSuccess: onSuccessGetDocument,
  });
  const onClick = useCallback((e) => {
    e.preventDefault();
    getPublicDocument();
  }, [getPublicDocument]);

  return (
    <ColoredButton
      onClick={onClick}
      background="DodgerBlue"
      color="white"
    >
      Download {type}
    </ColoredButton>
  );
};

DocumentLink.propTypes = {
  product: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  utmSource: PropTypes.string.isRequired,
};

export default DocumentLink;
