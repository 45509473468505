import React, { useContext, useMemo } from 'react';
import { useAuthState } from 'react-admin';
import PropTypes from 'prop-types';
import useCustomQuery from '../../hooks/useCustomQuery';

const ConstantContext = React.createContext();

const choiceNormalizer = (array) => array.map((i) => ({ id: i.value, name: i.label }));

export const ConstantContextProvider = ({ children }) => {
  const { isLoading, authenticated } = useAuthState();
  const isFullyAuthenticated = !isLoading && authenticated;

  const {
    data: constantsData,
    isSuccess: constantsIsSuccess,
  } = useCustomQuery({
    httpMethod: 'GET',
    path: 'constants',
    key: 'constants',
    enabled: isFullyAuthenticated,
  });

  const {
    data: choicesData,
    isSuccess: choicesIsSuccess,
  } = useCustomQuery({
    httpMethod: 'GET',
    path: 'choices',
    key: 'choices',
    enabled: isFullyAuthenticated,
  });

  const constants = useMemo(() => constantsData ?? {}, [constantsData]);
  const choices = useMemo(() => {
    const data = choicesData?.choices ?? {};
    const normalizedData = Object.keys(data)
      .reduce((acc, key) => ({ ...acc, [key]: choiceNormalizer(data[key]) }), {});
    return normalizedData;
  }, [choicesData?.choices]);
  const accountFeatures = useMemo(
    () => choicesData?.accountFeatures ?? {},
    [choicesData?.accountFeatures],
  );

  const contextValue = useMemo(() => ({
    constants,
    choices,
    accountFeatures,
  }), [constants, choices, accountFeatures]);

  if (isFullyAuthenticated && (!constantsIsSuccess || !choicesIsSuccess)) return null;

  return (
    <ConstantContext.Provider value={contextValue}>
      {children}
    </ConstantContext.Provider>
  );
};

ConstantContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useConstantContext = () => {
  const context = useContext(ConstantContext);
  return context;
};
