import moment from 'moment';
import validators from './validators';

const { optional } = validators.generics;

export const accountNumber = (value) => !optional(validators.accountNumber, value) && 'This is not a valid account number';
export const approvedTerms = (value) => !optional(validators.termsApproved, value) && 'This is not a valid value';
export const canBeProfiled = (value) => !optional(validators.canBeProfiled, value) && 'This is not a valid value';
export const clearingNumber = (value) => !optional(validators.clearingNumber, value) && 'This is not a valid clearing number';
export const email = (value) => !optional(validators.email, value) && 'This is not a valid email address';
export const isBorrowingForOwnUse = (value) => !optional(validators.isBorrowingForOwnUse, value) && 'This is not a valid value';
export const isNotPEP = (value) => !optional(validators.isNotPEP, value) && 'This is not a valid value';
export const monthlyHousing = (value) => !optional(validators.monthlyHousing, value) && 'This is not a valid number';
export const monthlyIncome = (value) => !optional(validators.monthlyIncome, value) && 'This is not a valid number';
export const monthlyLoans = (value) => !optional(validators.monthlyLoans, value) && 'This is not a valid number';
export const ocrNumber = (value) => !optional(validators.ocrNumber, value) && 'This is not a valid ocrNumber, it must be between 2 and 25 digits long';
export const phone = (value) => !optional(validators.phone, value) && 'This is not a valid phone number';
export const socialSecurityNumber = (value) => !optional(validators.socialSecurityNumber, value) && 'The social security number is invalid';
export const organisationNumber = (value) => !optional(validators.organisationNumber, value) && 'The organisation number is invalid';
export const adminRoles = (value) => (!value || !value.includes('ROLE_ADMIN')) && 'Role admin is required for admin users';
export const futureDate = (value) => (!moment(value).isValid() || moment().isAfter(value)) && 'Due date has to be defined and in the future';
export const fullAccountNumber = (value) => !validators.fullAccountNumber(value) && 'The account number must be 6 or 8 digits long without hiphens or space';
export const utmSource = (value) => !validators.utmSource(value) && 'The utm source must be at least 3 chars long and the special chars that are allowed are ( _ and - )';
export const hexColor = (value) => !optional(validators.generics.isHexColor, value) && 'This is not a valid hex color. It should have a similar format as #123abc';
