import React from 'react';
import {
  Datagrid,
  TextField,
  DateField,
  ShowButton,
} from 'react-admin';

import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';

const CreditSafeDatagrid = () => (
  <Datagrid bulkActionButtons={null}>
    <TextField source="id" />
    <DateField source="createdAt" />
    <SocialSecurityNumberField source="data.socialSecurityNumber" label="Social Security Number" />
    <TextField source="data.firstName" label="First Name" />
    <TextField source="data.lastName" label="Last Name" />
    <TextField source="data.scoring" label="Scoring" />
    <ShowButton />
  </Datagrid>
);

export default CreditSafeDatagrid;
