import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/fp/get';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';

import { organisationNumberFormatter } from '../../utils/formatters';

const OrganisationNumberField = ({
  source,
}) => {
  const record = useRecordContext();
  return (
    <Typography noWrap>{organisationNumberFormatter(get(source, record))}</Typography>
  );
};

OrganisationNumberField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  source: PropTypes.string.isRequired,
};

OrganisationNumberField.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

export default OrganisationNumberField;
