import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import SelectInput from '../input/SelectInput';
import validateBankDetailsFactory from '../utils/validateBankDetails';
import { useConstantContext } from '../utils/ConstantsContext';

const validateStock = [required()];

const LenderCreate = () => {
  const { choices, constants } = useConstantContext();
  const { bankAccountTypes } = choices;
  const validateBankDetails = validateBankDetailsFactory(constants.bankAccountTypes);
  return (
    <Create>
      <SimpleForm validate={validateBankDetails}>
        <TextInput
          source="name"
          validate={validateStock}
        />
        <SelectInput
          source="bankAccountType"
          choices={bankAccountTypes}
          validate={validateStock}
        />
        <TextInput
          source="accountNumber"
          validate={validateStock}
        />
        <TextInput
          source="clearingNumber"
        />
      </SimpleForm>
    </Create>
  );
};

export default LenderCreate;
