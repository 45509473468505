import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

const styles = {
  wrapperStyle: {
    margin: '1rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      width: 'auto',
      marginRight: '1rem',
    },
  },
};

const ActionBar = ({ classes, children, ...rest }) => (
  <div className={classes.wrapperStyle}>
    {React.Children.map(children, (child) => React.cloneElement(child, rest))}
  </div>
);

ActionBar.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(ActionBar);
