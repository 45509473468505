import React, { useCallback, useState } from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';

import { numberFormat, accountNumberFormat } from '../../utils/formatters';
import validators from '../../utils/validators';
import useCustomQuery from '../../hooks/useCustomQuery';
import useCustomRpc from '../../hooks/useCustomRpc';

const PaperContent = ({ transactions, error, loading }) => {
  if (loading) return <Typography align="center">Loading...</Typography>;
  if (error) {
    return (
      <Typography
        align="center"
        color="error"
      >
        {error}
      </Typography>
    );
  }
  if (transactions && transactions.length > 0) {
    const warning = 'If ocrNumber is red it is not respecting the standard. Please double-check it. If bank details are red there is a mismatch between the values in the user and in the application.';
    return (
      <>
        <Typography align="center" color="error" variant="caption">{warning}</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Amount</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>OCR Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => {
              const destinationText = (
                transaction.paymentDetails && transaction.paymentDetails.lender)
                || transaction.type;
              const amountText = numberFormat(-transaction.amount);
              const bankAccountDetailsText = (
                transaction.paymentDetails && accountNumberFormat(
                  transaction.paymentDetails.bankAccountType,
                  transaction.paymentDetails.accountNumber,
                )) || '';
              const ocrNumberText = (transaction.paymentDetails && transaction.paymentDetails.ocrNumber) || '';
              return (
                <TableRow key={`${transaction.type}-${transaction.amount}`}>
                  <TableCell>{destinationText.toUpperCase()}</TableCell>
                  <TableCell>{amountText}</TableCell>
                  <TableCell>{bankAccountDetailsText}</TableCell>
                  <TableCell>
                    <Typography color={ocrNumberText && validators.ocrNumber(ocrNumberText, { withLengthCheck: true, withCheckDigit: true }) ? 'textPrimary' : 'textSecondary'}>
                      {ocrNumberText}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  }
  return <Typography align="center">Nothing to payout</Typography>;
};

PaperContent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      paymentDetails: PropTypes.shape({
        lender: PropTypes.string,
        accountNumber: PropTypes.string.isRequired,
        bankAccountType: PropTypes.string.isRequired,
        ocrNumber: PropTypes.number,
      }),
    }),
  ),
};

PaperContent.defaultProps = {
  loading: false,
  error: '',
  transactions: [],
};

const PayoutButton = () => {
  const record = useRecordContext();
  const {
    id,
    payout,
    refinancing,
  } = record;

  const [isOpen, setIsOpen] = useState(false);

  const {
    data: transactionsData,
    isLoading,
    error,
  } = useCustomQuery({
    httpMethod: 'GET',
    path: `application/payout/${id}/transactions`,
    key: `application/payout/${id}/transactions`,
    errorMessage: 'Error fetching the application transactions',
    enabled: isOpen,
  });

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const notify = useNotify();

  const { mutate: manualPayout } = useCustomRpc({
    httpMethod: 'PUT',
    path: `application/payout/${id}`,
    errorMessage: 'Failed to paid out Account',
    shouldRefresh: true,
    onSuccess: (data) => {
      if (data.result) notify(data.result, { type: 'info' });
    },
  });

  const handleClick = useCallback(() => {
    manualPayout({ payout, refinancing });
    return true;
  }, [manualPayout, payout, refinancing]);

  const transactions = transactionsData?.transactions;
  const errorTransactions = transactionsData?.error;

  return (
    <ModalButton
      title="Confirm Payout"
      onClick={handleClick}
      onOpen={handleOpen}
    >
      <Typography>Please review the list of all the payouts for this application</Typography>
      <Paper style={{ padding: '2rem 1rem', margin: '1rem' }}>
        <PaperContent
          transactions={transactions}
          error={error ?? errorTransactions}
          loading={isLoading}
        />
      </Paper>
    </ModalButton>
  );
};

export default PayoutButton;
