import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import OpenInNew from '@mui/icons-material/OpenInNew';

import Typography from '@mui/material/Typography';
import ColoredButton from '../../utils/ColoredButton';
import DeleteKalp from '../../utils/DeleteKalp';

import useCustomRpc from '../../../hooks/useCustomRpc';
import onSuccessGetDocument from '../../../utils/onSuccessGetDocument';

const styles = {
  actionContainer: {
    padding: '1rem 0',
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      margin: '0 1rem',
    },
  },
  link: { color: '#3f51b5' },
};

const Kalp = ({
  action,
  applicationId,
  classes,
  isArchived,
  kalpUrl,
  isApplicationProcessed,
}) => {
  const { mutate: createKalp } = useCustomRpc({
    httpMethod: 'POST',
    path: `application/${applicationId}/kalp`,
    successMessage: 'KALP sheet created successfully',
    error: 'Could not create KALP sheet',
    shouldRefresh: true,
  });

  const { mutate: getKalpDocument } = useCustomRpc({
    httpMethod: 'GET',
    path: `application/${applicationId}/kalp/download`,
    error: 'Could not retrieve file',
    onSuccess: onSuccessGetDocument,
  });

  const onCreate = useCallback((e) => {
    e.preventDefault();
    createKalp();
  }, [createKalp]);

  const onDownload = useCallback((e) => {
    e.preventDefault();
    getKalpDocument();
  }, [getKalpDocument]);

  if (kalpUrl) {
    return (
      <>
        <div className={classes.actionContainer}>
          {action}
          <DeleteKalp applicationId={applicationId} />
          <a href={kalpUrl} aria-label="open" target="_blank" rel="noopener noreferrer" className={classes.link}><OpenInNew /></a>
        </div>
        <iframe height="800" width="100%" src={`${kalpUrl}?rm=minimal`} title="kalp-sheet" frameBorder="0" />
      </>
    );
  }

  if (isApplicationProcessed && !isArchived) {
    return (
      <div className={classes.actionContainer}>
        {action}
        <Typography variant="subtitle1">No kalp created for that application</Typography>
      </div>
    );
  }

  return (
    <div className={classes.actionContainer}>
      {action}
      <ColoredButton
        color="white"
        background="#3f51b5"
        onClick={isArchived ? onDownload : onCreate}
      >
        {isArchived ? 'Download' : 'Create Kalp'}
      </ColoredButton>
    </div>
  );
};

Kalp.propTypes = {
  action: PropTypes.node,
  applicationId: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    actionContainer: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  isArchived: PropTypes.bool,
  kalpUrl: PropTypes.string,
  isApplicationProcessed: PropTypes.bool,
};

Kalp.defaultProps = {
  kalpUrl: '',
  action: null,
  isArchived: null,
  isApplicationProcessed: false,
};

export default withStyles(styles)(Kalp);
