import React from 'react';
import {
  FormTab,
  ReferenceManyField,
  Pagination,
} from 'react-admin';

import TakeUcButton from './TakeUcButton';
import UcInfoFieldsPreview from './UcInfoFieldsPreview';

const UcInfoFormTab = ({ ...props }) => (
  <FormTab label="UC" {...props}>
    <TakeUcButton />
    <ReferenceManyField
      label="Uc checks"
      reference="ucChecks"
      target="user"
      fullWidth
      pagination={<Pagination />}
    >
      <UcInfoFieldsPreview />
    </ReferenceManyField>
  </FormTab>
);

export default UcInfoFormTab;
