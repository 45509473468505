export const isAnnuityInvoicingSelector = (accountFeatures) => (accountType) => accountFeatures
  .fixedAnnuityInvoicing.includes(accountType) || accountFeatures
  .variableAnnuityInvoicing.includes(accountType);
export const isInterestInvoicingSelector = (accountFeatures) => (accountType) => accountFeatures
  .interestInvoicing.includes(accountType);
export const isInIndirectInvoiceSelector = (accountFeatures) => (accountType) => accountFeatures
  .indirectInvoice.includes(accountType);
export const isMtpInvoicingSelector = (accountFeatures) => (accountType) => accountFeatures
  .mtpInvoicing.includes(accountType);
export const isManualPayoutSelector = (accountFeatures) => (accountType) => accountFeatures
  .manualPayout.includes(accountType);
