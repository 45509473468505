import React from 'react';
import {
  List,
  TextField,
  ReferenceField,
  ShowButton,
  TextInput,
  DateField,
  FunctionField,
} from 'react-admin';

import SelectInput from '../input/SelectInput';
import PageTitle from '../utils/PageTitle';
import HeadedDatagrid from '../utils/HeadedDatagrid';
import AltField from '../utils/AltField';

import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import { useConstantContext } from '../utils/ConstantsContext';

const isUserBuilder = (holderTypes) => (record) => (
  record.accountHolders[0].holderType === holderTypes.USER
);

const heldPaymentFilters = [
  <TextInput label="Held payments ID" source="_id" alwaysOn />,
  <TextInput label="User ID" source="accountHolders._id" alwaysOn />,
  <TextInput label="EAR ID" source="extraAmortisationRequests._id" alwaysOn />,
  <TextInput label="Transaction ID" source="transactions._id" alwaysOn />,
  <SelectInput
    label="Status"
    source="custom_status"
    choices={[
      { id: 'pending', name: 'Pending' },
      { id: 'resolved', name: 'Resolved' },
    ]}
    alwaysOn
  />,
];

const HeldPaymentList = () => {
  const { constants } = useConstantContext();
  const { holderTypes } = constants;
  const isUser = isUserBuilder(holderTypes);

  return (
    <List filterDefaultValues={{ custom_status: 'pending' }} filters={heldPaymentFilters}>
      <HeadedDatagrid
        head={(<PageTitle title="Held Payments | List" />)}
      >
        <TextField source="id" />
        <AltField
          label="Identifier"
          source="accountHolders[0]._id"
          predicate={(record) => isUser(record)}
          primary={(
            <ReferenceField reference="user" source="id">
              <SocialSecurityNumberField source="socialSecurityNumber" />
            </ReferenceField>
          )}
          secondary={(
            <ReferenceField reference="organisation" source="id">
              <OrganisationNumberField source="organisationNumber" />
            </ReferenceField>
          )}
        />
        <AltField
          label="Account holder name"
          source="accountHolders[0]._id"
          predicate={(record) => isUser(record)}
          primary={(
            <ReferenceField reference="user" source="id" link={false}>
              <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
            </ReferenceField>
          )}
          secondary={(
            <ReferenceField reference="organisation" source="id" link={false}>
              <TextField source="name" />
            </ReferenceField>
          )}
        />
        <TextField source="currentlyHeldPaymentsCount" />
        <TextField source="currentlyHeldPaymentsAmount" />
        <DateField source="lastPayinDate" label="Latest payment" />
        <TextField source="lastPayinAmount" label="Latest payment Amount" />
        <ShowButton />
      </HeadedDatagrid>
    </List>
  );
};

export default HeldPaymentList;
