import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ModalButton from './ModalButton';

import validators from '../../utils/validators';

import useInputStatus from '../hooks/useInputStatus';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const RevertTransaction = ({ accountId }) => {
  const record = useRecordContext();

  const {
    _id: transactionId,
    amount,
    feeType,
    revertedBy,
  } = record ?? {};

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus();

  const { mutate: revertTransaction } = useCustomRpc({
    httpMethod: 'PUT',
    path: `account/${accountId}/transactions/${transactionId}`,
    successMessage: 'Transaction reverted',
    errorMessage: 'Failed to revert transaction',
    shouldRefresh: true,
  });

  const onClick = useCallback(() => {
    if (!isDefined(comment)) {
      setCommentError('Please add a comment');
      return false;
    }

    const data = { comment };
    revertTransaction(data);
    return true;
  }, [comment, revertTransaction, setCommentError]);

  const onClose = useCallback(
    () => setComment(''),
    [setComment],
  );

  if (!record || !accountId) return null;
  if (!feeType) return null;
  if (revertedBy) return null;
  if (amount > 0) return null;

  return (
    <ModalButton
      title="Revert"
      onClick={onClick}
      onClose={onClose}
    >
      <Typography align="center" variant="h6">Revert the selected transaction</Typography>
      <TextField
        label="Leave a comment"
        multiline
        maxRows="4"
        value={comment}
        onChange={onChangeComment}
        error={!!commentError}
        helperText={commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

RevertTransaction.propTypes = {
  accountId: PropTypes.string,
};

RevertTransaction.defaultProps = {
  accountId: undefined,
};

export default RevertTransaction;
