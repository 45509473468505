import React from 'react';

import {
  TextInput,
  ReferenceInput,
} from 'react-admin';

import SelectInput from '../input/SelectInput';
import NullableBooleanInput from '../input/NullableBooleanInput';

import { useConstantContext } from '../utils/ConstantsContext';

const useSignupFilters = () => {
  const { choices } = useConstantContext();
  const { applicationMainSteps, accountTypes } = choices;
  return [
    <TextInput label="Application ID" source="_id" alwaysOn />,
    <TextInput label="Broker reference ID" source="brokerReferenceId" alwaysOn />,
    <TextInput label="Applicant phone" source="applicants.phone" alwaysOn />,
    <TextInput label="Applicant email" source="applicants.email" alwaysOn />,
    <TextInput label="User ID" source="applicants._id" />,
    <SelectInput
      alwaysOn
      label="UTM Medium"
      source="custom_utmMedium"
      choices={[
        { id: 'broker', name: 'Broker' },
        { id: 'other', name: 'Other' },
      ]}
    />,
    <SelectInput
      alwaysOn
      label="Action"
      source="applicationStep"
      choices={applicationMainSteps}
    />,
    <ReferenceInput
      alwaysOn
      label="Assigned to"
      source="admin"
      reference="admins"
    >
      <SelectInput optionText="firstName" />
    </ReferenceInput>,
    <SelectInput
      label="Bank Details"
      source="custom_bankDetails"
      choices={[
        { id: 'true', name: 'Check bank details' },
      ]}
    />,
    <SelectInput
      label="Account Type"
      source="accountType"
      choices={accountTypes}
    />,
    <TextInput label="Public ID" source="publicId" />,
    <NullableBooleanInput label="Waiting for customer?" source="isWaitingForCustomer" />,
  ];
};

export default useSignupFilters;
