import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import TextField from '@mui/material/TextField';

import useInputStatus from '../hooks/useInputStatus';

import ModalButton from './ModalButton';
import SelectField from './SelectField';

import { isBeforeFactory } from '../../utils/signup/compareApplicationSteps';
import validators from '../../utils/validators';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const MarkRejected = () => {
  const record = useRecordContext();
  const {
    id,
    currentDecisionStatus,
    applicationStep,
    rejectedByCustomer,
  } = record;

  const { constants, choices } = useConstantContext();
  const {
    reasonsForReject,
    decisionStatuses,
    applicationSteps,
    orderedApplicationSteps,
  } = constants;
  const { reasonsForReject: reasonsForRejectChoices } = choices;

  const { mutate: markRejected } = useCustomRpc({
    httpMethod: 'PUT',
    path: `application/markRejected/${id}`,
    successMessage: 'Application updated',
    errorMessage: 'Failed to update application',
    shouldRefresh: true,
  });

  const isBefore = isBeforeFactory(orderedApplicationSteps);

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus();

  const [
    reason,
    setReason,
    onChangeReason,
    reasonError,
    setReasonError,
  ] = useInputStatus();

  const onClick = useCallback(() => {
    if (!isDefined(reason)) {
      setReasonError('Please select a reason');
      return false;
    }
    if (reason === reasonsForReject.OTHER && !isDefined(comment)) {
      setCommentError('A comment is required');
      return false;
    }
    const data = {
      message: comment,
      reason,
    };
    markRejected(data);
    return true;
  }, [reason, reasonsForReject.OTHER, comment, markRejected, setReasonError, setCommentError]);

  const onClose = useCallback(() => {
    setComment('');
    setCommentError('');
    setReason('');
    setReasonError('');
  }, [setComment, setCommentError, setReason, setReasonError]);

  const validStep = isBefore(applicationStep, applicationSteps.PAYED_OUT);
  const validDecision = currentDecisionStatus !== decisionStatuses.DENY;
  const canBeRejected = validStep && validDecision && !rejectedByCustomer;

  if (!canBeRejected) return null;
  return (
    <ModalButton
      title="Rejected By Customer"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <SelectField
        label="Reason"
        value={reason}
        choices={reasonsForRejectChoices}
        onChange={onChangeReason}
        error={reasonError}
      />
      <TextField
        label="Leave a comment"
        multiline
        maxRows="2"
        value={comment}
        onChange={onChangeComment}
        error={!!commentError}
        helperText={commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

export default MarkRejected;
