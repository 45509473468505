import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
  AdminContext,
  AdminUI,
  Resource,
  defaultI18nProvider,
  CustomRoutes,
} from 'react-admin';
import * as Sentry from '@sentry/react';

import Layout from './components/layout/Layout';
import { Dashboard } from './components/dashboard';
import {
  UserList,
  UserEdit,
  UserCreate,
  UserIcon,
} from './components/users';
import { ExtensionList, ExtensionIcon } from './components/extensions';
import { SignupList, SignupIcon } from './components/signups';
import { ApplicationList, ApplicationCreate, ApplicationEdit } from './components/applications';
import {
  BlackListList,
  BlackListEdit,
  BlackListCreate,
  BlackListIcon,
} from './components/blackList';
import { RiskReportList, RiskReportShow, RiskReportIcon } from './components/riskReport';
import {
  AdminList,
  AdminEdit,
  AdminCreate,
  AdminIcon,
} from './components/admins';
import {
  PartnerList,
  PartnerEdit,
  PartnerCreate,
  PartnerIcon,
} from './components/partners';
import {
  TodoList,
  TodoEdit,
  TodoCreate,
  TodoIcon,
} from './components/todos';
import {
  AccountList,
  AccountEdit,
  AccountIcon,
} from './components/accounts';
import { LenderList, LenderCreate, LenderIcon } from './components/lenders';
import {
  OrganisationCreate,
  OrganisationList,
  OrganisationEdit,
  OrganisationIcon,
} from './components/organisations';
import {
  UnidentifiedPaymentList,
  UnidentifiedPaymentIcon,
  UnidentifiedPaymentEdit,
} from './components/unidentifiedPayments';
import {
  RejectedPayoutList,
  RejectedPayoutShow,
  RejectedPayoutIcon,
} from './components/rejectedPayouts';
import {
  HeldPaymentList,
  HeldPaymentShow,
  HeldPaymentIcon,
} from './components/heldPayments';
import {
  BrokerList,
  BrokerEdit,
  BrokerIcon,
} from './components/brokers';
import { SparCheckShow } from './components/sparCheck';
import { TrapetsCompanyCheckShow } from './components/trapetsCompanyCheck';
import { UcCheckShow, UcCompanyCheckShow } from './components/uc';
import { CommentCreate } from './components/comments';
import CreditSafeCheckShow from './components/creditSafeChecks/CreditSafeCheckShow';
import Login from './components/login/Login';
import { ConstantContextProvider } from './components/utils/ConstantsContext';
import authClient from './authClient';

import routes from './routes';

import config from '../config';

import loginBackground from './assets/img/login-background.png';
import dataProvider from './dataProvider';
import store from './utils/store';

Sentry.init({
  dsn: config.sentryDsn,
  release: config.sentryRelease,
  integrations: [],
  environment: config.sentryEnvironment,
});

const superAdminWrapper = (Component, permissions) => (permissions?.includes('ROLE_SUPER_ADMIN') ? Component : null);

const CustomLogin = () => <Login backgroundImage={loginBackground} />;

render(
  <BrowserRouter>
    <AdminContext
      dataProvider={dataProvider}
      authProvider={authClient}
      store={store}
      i18nProvider={defaultI18nProvider}
    >
      <ConstantContextProvider>
        <AdminUI
          loginPage={CustomLogin}
          dashboard={Dashboard}
          layout={Layout}
          title="Moank Admin"
          requireAuth
        >
          {(permissions) => [
            <Resource
              name="todo"
              list={TodoList}
              edit={TodoEdit}
              create={TodoCreate}
              icon={TodoIcon}
              options={{ label: 'Todos' }}
            />,
            <Resource
              name="application/pendingDecision"
              list={SignupList}
              icon={SignupIcon}
              options={{ label: 'Signups' }}
            />,
            <Resource
              name="application/pendingDecisionFactoring"
              list={SignupList}
              icon={SignupIcon}
              options={{ label: 'Factoring Signups' }}
            />,
            <Resource
              name="application/pendingDecisionInsurance"
              list={SignupList}
              icon={SignupIcon}
              options={{ label: 'Insurance Signups' }}
            />,
            <Resource
              name="user"
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
              icon={UserIcon}
              options={{ label: 'Users' }}
            />,
            <Resource
              name="account/extension"
              list={ExtensionList}
              icon={ExtensionIcon}
              options={{ label: 'Extensions' }}
            />,
            <Resource
              name="application"
              list={ApplicationList}
              edit={ApplicationEdit}
              create={ApplicationCreate}
              options={{ label: 'Applications' }}
            />,
            <Resource
              name="account"
              list={AccountList}
              edit={AccountEdit}
              icon={AccountIcon}
              options={{ label: 'Accounts' }}
            />,
            <Resource
              name="organisation"
              list={OrganisationList}
              edit={OrganisationEdit}
              create={OrganisationCreate}
              icon={OrganisationIcon}
              options={{ label: 'Organisations' }}
            />,
            <Resource
              name="lender"
              list={LenderList}
              icon={LenderIcon}
              create={superAdminWrapper(LenderCreate, permissions)}
              options={{ label: 'Lenders' }}
            />,
            <Resource
              name="admins"
              list={superAdminWrapper(AdminList, permissions)}
              edit={superAdminWrapper(AdminEdit, permissions)}
              create={superAdminWrapper(AdminCreate, permissions)}
              icon={AdminIcon}
              options={{ label: 'Admins' }}
            />,
            <Resource
              name="partner"
              list={superAdminWrapper(PartnerList, permissions)}
              edit={superAdminWrapper(PartnerEdit, permissions)}
              create={superAdminWrapper(PartnerCreate, permissions)}
              icon={PartnerIcon}
              options={{ label: 'Partners' }}
            />,
            <Resource
              name="blacklist"
              list={BlackListList}
              edit={BlackListEdit}
              create={BlackListCreate}
              icon={BlackListIcon}
              options={{ label: 'Black List' }}
            />,
            <Resource
              name="riskReport"
              list={RiskReportList}
              show={RiskReportShow}
              icon={RiskReportIcon}
              options={{ label: 'Risk Reports' }}
            />,
            <Resource
              name="comment"
              create={CommentCreate}
            />,
            <Resource
              name="sparChecks"
              show={SparCheckShow}
            />,
            <Resource
              name="trapetsCompanyChecks"
              show={TrapetsCompanyCheckShow}
            />,
            <Resource name="trapetsChecks" />,
            <Resource name="bankAccount" />,
            <Resource
              name="ucChecks"
              show={UcCheckShow}
            />,
            <Resource
              name="ucCompanyChecks"
              show={UcCompanyCheckShow}
            />,
            <Resource name="creditSafeChecks" show={CreditSafeCheckShow} />,
            <Resource
              name="unidentifiedPayments"
              list={superAdminWrapper(UnidentifiedPaymentList, permissions)}
              edit={UnidentifiedPaymentEdit}
              icon={UnidentifiedPaymentIcon}
              options={{ label: 'Unidentified Payments' }}
            />,
            <Resource
              name="rejectedPayouts"
              list={superAdminWrapper(RejectedPayoutList, permissions)}
              show={RejectedPayoutShow}
              icon={RejectedPayoutIcon}
              options={{ label: 'Rejected Payouts' }}
            />,
            <Resource
              name="heldPayments"
              list={HeldPaymentList}
              show={HeldPaymentShow}
              icon={HeldPaymentIcon}
              options={{ label: 'Held Payments' }}
            />,
            <Resource
              name="broker"
              list={superAdminWrapper(BrokerList, permissions)}
              edit={BrokerEdit}
              options={{ label: 'Brokers' }}
              icon={BrokerIcon}
            />,
            <CustomRoutes>
              {routes}
            </CustomRoutes>,
          ]}
        </AdminUI>
      </ConstantContextProvider>
    </AdminContext>
  </BrowserRouter>,
  window.document.getElementById('app'),
);
