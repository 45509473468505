import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// This is a workaround to grab fill-available width in the component
const getFillAvailableBasedOnBrowser = () => {
  const { userAgent } = global.navigator;
  if (userAgent.indexOf('Firefox') > -1) {
    return '-moz-available';
  }
  // This handles WebKit browsers (Chrome, Safari, Opera, Edge)
  if (userAgent.indexOf('Chrome') > -1) {
    return '-webkit-fill-available';
  }

  return 'fill-available';
};

const FieldsGroup = ({
  name,
  children,
  width,
  fillAvailable,
  allowOverflow,
  column,
  withBorder,
  actions,
  variant,
  ...rest
}) => {
  const childrenArray = React.Children.toArray(children);
  const hasHeader = (!!actions || !!name);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const expectedColumn = isTablet ? 1 : column;

  const chunk = Math.ceil(childrenArray.length / expectedColumn);

  const paperStyleWidth = fillAvailable ? getFillAvailableBasedOnBrowser() : 'calc(100% - 1.5rem)';

  const paperStyle = {
    padding: withBorder ? '0.75rem' : '',
    margin: withBorder ? '0.75rem' : '',
    ...(hasHeader ? { marginTop: '1rem' } : {}),
    width: paperStyleWidth,
    border: withBorder ? undefined : 'none',
    boxSizing: 'border-box',
  };

  const boxStyle = {
    margin: hasHeader ? '0.5rem 0' : '',
    width: '100%',
  };

  const shouldOverflow = allowOverflow ? 'auto' : 'hidden';

  return (
    <Box display="flex" width={width}>
      <Paper style={paperStyle} variant="outlined">
        {name && <Typography variant="overline">{name}</Typography>}
        {React.Children.toArray(actions)
          .map((el) => React.cloneElement(el, { ...el.props, ...rest }))}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
          style={boxStyle}
        >
          {Array.from({ length: expectedColumn }, (_el, index) => (
            <Box
              display="flex"
              flexDirection="column"
              style={{
                width: `calc(100% / ${expectedColumn})`,
                minWidth: '260px',
                overflow: shouldOverflow,
              }}
              key={`box-${index}`}
            >
              {React.Children.map(
                childrenArray.slice(chunk * index, chunk * (index + 1)),
                (el) => {
                  if (!el) return null;
                  return cloneElement(el, { ...el.props, ...rest });
                },
              )}
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

FieldsGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.string,
  allowOverflow: PropTypes.bool,
  fillAvailable: PropTypes.bool,
  column: PropTypes.number,
  withBorder: PropTypes.bool,
  actions: PropTypes.node,
};

FieldsGroup.defaultProps = {
  name: '',
  variant: undefined,
  width: '100%',
  column: 1,
  withBorder: false,
  actions: null,
  allowOverflow: false,
  fillAvailable: false,
};

export default FieldsGroup;
