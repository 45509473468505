import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import useCustomRpc from '../../hooks/useCustomRpc';

const SendUnreachableEmailButton = ({ applicationId }) => {
  const { mutate: sendUnreachableEmail, isLoading } = useCustomRpc({
    httpMethod: 'GET',
    path: `application/${applicationId}/sendUnreachableEmail`,
    successMessage: 'Email sent successfuly',
    errorMessage: 'Email not sent',
  });

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    sendUnreachableEmail();
  }, [sendUnreachableEmail]);

  return (
    <Button
      disabled={isLoading}
      onClick={onSubmit}
      color="primary"
      variant="contained"
    >
      Send unreachable email
    </Button>
  );
};

SendUnreachableEmailButton.propTypes = {
  applicationId: PropTypes.string.isRequired,
};

export default SendUnreachableEmailButton;
