import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import useInputStatus from '../hooks/useInputStatus';

import ModalButton from './ModalButton';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const JustifyTransactionButton = ({
  accountId,
  accountType,
  transactionId,
}) => {
  const { constants } = useConstantContext();
  const {
    accountTypes,
    amlStatuses,
    amlRuleTypes,
  } = constants;
  const record = useRecordContext();

  const { mutate: sendJustifiedTransactionDocument } = useCustomRpc({
    httpMethod: 'POST',
    path: `heldPayments/${accountId}/transactions/${transactionId}/justify`,
    mimeType: 'formData',
    shouldRefresh: true,
  });

  const [file, setFile] = useState(undefined);
  const [fileError, setFileError] = useState('');
  const onChangeFile = useCallback((e) => {
    setFileError('');
    if (e.target.files && e.target.files.length > 0) setFile(e.target.files[0]);
  }, [setFileError, setFile]);

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus('');

  const [rememberPayer, setRememberPayer] = useState(false);
  const onChangeIsTemporaryPayer = useCallback((event) => {
    const { checked } = event.target;
    setRememberPayer(checked);
  }, []);

  const onClick = useCallback(() => {
    setFileError('');
    setCommentError('');

    if (!file) {
      setFileError('A file is required to justify the extra amortisation');
      return false;
    }

    if (!comment) {
      setCommentError('A comment is required to justify the extra amortisation');
      return false;
    }

    const formDataFields = [
      {
        fieldName: 'file',
        value: file,
      },
      {
        fieldName: 'comment',
        value: comment,
      },
      {
        fieldName: 'isTemporaryPayer',
        value: !rememberPayer,
      },
      {
        fieldName: 'riskReportId',
        value: record.id,
      },
    ];
    sendJustifiedTransactionDocument(formDataFields);
    return true;
  }, [setCommentError, file, comment, rememberPayer, record.id, sendJustifiedTransactionDocument]);

  const onClose = useCallback(() => {
    setFile(undefined);
    setFileError('');
    setComment('');
    setCommentError('');
    setRememberPayer(false);
  }, [setComment, setCommentError]);

  if (!accountId || !transactionId || !record.id) return null;
  if (accountType !== accountTypes.HELD_PAYMENTS) return null;

  return (
    <ModalButton
      title="Justify"
      onClick={onClick}
      onClose={onClose}
      disabled={record.status !== amlStatuses.PENDING}
      right
    >
      <input
        style={{ marginTop: '2rem' }}
        type="file"
        id="file"
        name="file"
        accept=".pdf"
        onChange={onChangeFile}
      />
      <Typography color="error" align="center">{fileError}</Typography>
      <TextField
        id="comment"
        label="Comment"
        multiline
        maxRows="5"
        value={comment}
        onChange={onChangeComment}
        margin="normal"
        error={!!commentError}
        helperText={`It will be used as official documentation of the file for the AML process. ${commentError}`}
        fullWidth
      />
      {record.rule === amlRuleTypes.UNEXPECTED_PAYER && (
        <>
          <FormControlLabel
            control={<Switch checked={rememberPayer} onChange={onChangeIsTemporaryPayer} />}
            label="Remember payer for future transactions?"
          />
          <FormHelperText>
            If on, the payer will be whitelisted for all payments received on this account.
            Otherwise, the payer will be whitelisted for this transaction only.
          </FormHelperText>
        </>
      )}
    </ModalButton>
  );
};

JustifyTransactionButton.propTypes = {
  accountId: PropTypes.string,
  transactionId: PropTypes.string,
  accountType: PropTypes.string,
};

JustifyTransactionButton.defaultProps = {
  accountId: undefined,
  accountType: undefined,
  transactionId: undefined,
};

export default JustifyTransactionButton;
