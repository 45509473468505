import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useUnselectAll } from 'react-admin';

import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';
import SelectField from './SelectField';

import validators from '../../utils/validators';
import { numberParser, numberFormat } from '../../utils/formatters';

import useInputStatus from '../hooks/useInputStatus';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined, isPositiveNumber } = validators.generics;

const useStylesWrapper = makeStyles(() => ({
  root: {
    width: 'calc(100% - 4rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '2rem',
    '& > *:not(:last-child)': {
      marginBottom: '1rem',
    },
  },
}));

const RefinanceButton = ({
  selectedAccounts,
  holderId,
}) => {
  const disabled = !(selectedAccounts.length > 0);

  const { constants, choices } = useConstantContext();
  const { accountStatuses } = constants;
  const { activeTypes, utmMediums, utmSources } = choices;

  const unselectAll = useUnselectAll('account');

  const navigate = useNavigate();

  const { mutate: refinanceAccount } = useCustomRpc({
    httpMethod: 'POST',
    path: 'application/refinance',
    successMessage: 'Application Created',
    errorMessage: 'Error Creating Application',
    onSuccess: (data) => {
      if (data.id) navigate(`/application/${data.id}`);
    },
  });

  const [generalError, setGeneralError] = useState('');

  const [
    accountType,
    setAccountType,
    onChangeAccountType,
    accountTypeError,
    setAccountTypeError,
  ] = useInputStatus();

  const [
    utmSource,
    setUtmSource,
    onChangeUtmSource,
    utmSourceError,
    setUtmSourceError,
  ] = useInputStatus('upsell');

  const [
    utmMedium,
    setUtmMedium,
    onChangeUtmMedium,
  ] = useInputStatus();

  const [
    payout,
    setPayout,
    onChangePayout,
    payoutError,
    setPayoutError,
  ] = useInputStatus(0, numberParser);

  const accountIds = selectedAccounts.map((account) => account.id);
  const invalidAccounts = selectedAccounts.reduce((result, account) => {
    if (account.todaySolvingAmount <= 0) result.push(`Invalid amount for account ${account.publicId}`);
    if (account.status !== accountStatuses.ACTIVE) result.push(`Invalid status for account ${account.publicId}`);
    return result;
  }, []);

  const notValid = invalidAccounts.length > 0;
  const onClick = useCallback(() => {
    if (notValid) {
      setGeneralError('Invalid selected account(s)');
      return false;
    }
    setPayoutError('');
    setGeneralError('');
    setAccountTypeError('');
    setUtmSourceError('');
    if (!isDefined(accountType)) {
      setAccountTypeError('Please select a account type');
      return false;
    }
    if (!isDefined(utmSource)) {
      setUtmSourceError('Please select a UTM Source');
      return false;
    }
    if (!isPositiveNumber(payout)) {
      setPayoutError('Please add a valid payout');
      return false;
    }

    const data = {
      holderId,
      accountIds,
      payout,
      accountType,
      utmSource,
      utmMedium,
    };
    refinanceAccount(data);
    unselectAll();
    return true;
  }, [notValid, setPayoutError, setAccountTypeError, setUtmSourceError, accountType, utmSource,
    payout, holderId, accountIds, utmMedium, refinanceAccount, unselectAll]);

  const onClose = useCallback(() => {
    setPayout(0);
    setAccountType('');
    setUtmSource('');
    setUtmMedium('');
    unselectAll();
  }, [setPayout, setAccountType, setUtmSource, setUtmMedium, unselectAll]);

  const wrapperClasses = useStylesWrapper();

  return (
    <ModalButton
      title="Refinance Account"
      onClick={onClick}
      onClose={onClose}
      disabled={disabled}
      right
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Public id</TableCell>
            <TableCell>Solving amount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedAccounts.map((account) => (
            <TableRow key={account.publicId}>
              <TableCell>{account.publicId}</TableCell>
              <TableCell>{numberFormat(account.todaySolvingAmount)}</TableCell>
              <TableCell>{account.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {invalidAccounts.map((error) => <Typography color="error" align="center" variant="body2" key={error}>{error}</Typography>)}
      <div className={wrapperClasses.root}>
        <TextField
          type="number"
          label="Select the additional payout"
          onChange={onChangePayout}
          value={payout}
          error={!!payoutError}
          helperText={payoutError}
          margin="normal"
          fullWidth
        />
        <SelectField
          label="Application account type"
          onChange={onChangeAccountType}
          value={accountType}
          choices={activeTypes}
          error={accountTypeError}
        />
        <SelectField
          label="Application UTM Source"
          onChange={onChangeUtmSource}
          value={utmSource}
          error={utmSourceError}
          choices={utmSources}
        />
        <SelectField
          label="Application UTM Medium"
          onChange={onChangeUtmMedium}
          value={utmMedium}
          choices={utmMediums}
        />
        {generalError && <Typography color="error" align="center" variant="body2">{generalError}</Typography>}
      </div>
    </ModalButton>
  );
};

RefinanceButton.propTypes = {
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    publicId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    todaySolvingAmount: PropTypes.number.isRequired,
  })).isRequired,
  holderId: PropTypes.string.isRequired,
};

export default RefinanceButton;
