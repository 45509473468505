import React from 'react';

import {
  List,
  TextField,
  DateField,
  EditButton,
  TextInput,
} from 'react-admin';

import SelectInput from '../input/SelectInput';
import PageTitle from '../utils/PageTitle';
import HeadedDatagrid from '../utils/HeadedDatagrid';

const unidentifiedPaymentsFilters = [
  <TextInput alwaysOn label="Unidentified payments ID" source="_id" />,
  <TextInput alwaysOn label="User ID" source="accountHolders._id" />,
  <TextInput alwaysOn label="Transaction ID" source="transactions._id" />,
  <SelectInput
    alwaysOn
    label="Status"
    source="custom_status"
    choices={[
      { id: 'resolved', name: 'Resolved' },
      { id: 'unresolved', name: 'Unresolved' },
    ]}
  />,
];

const UnidentifiedPaymentList = () => (
  <List
    filters={unidentifiedPaymentsFilters}
    filterDefaultValues={{ custom_status: 'unresolved' }}
  >
    <HeadedDatagrid
      head={(<PageTitle title="Unidentified Payments | List" />)}
    >
      <DateField source="createdAt" />
      <TextField source="transactions.0.amount" label="Amount" />
      <TextField source="transactions.0.payinDetails.bankgiroNumber" label="Bankgiro Number" />
      <TextField source="transactions.0.payinDetails.reference" label="OCR Number" />
      <TextField source="transactions.0.payinDetails.informationText" label="Extra information text" />
      <EditButton />
    </HeadedDatagrid>
  </List>
);

export default UnidentifiedPaymentList;
