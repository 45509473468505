import useCustomRpc from './useCustomRpc';
import config from '../../config';
import onSuccessGetDocument from '../utils/onSuccessGetDocument';

const useDownloadDocument = ({
  path,
  errorMessage,
  shouldRefresh,
  shouldUnselectAll,
}) => {
  const customRpc = useCustomRpc({
    errorMessage,
    path: 'authorization/signRequest',
    httpMethod: 'POST',
    shouldRefresh,
    shouldUnselectAll,
    onSuccess: onSuccessGetDocument,
  });

  const extendMutate = async (data, options) => customRpc.mutateAsync({
    ...data,
    url: `${config.apiBase}/${path}`,
  }, options);
  return {
    ...customRpc,
    mutate: extendMutate,
    mutateAsync: extendMutate,
  };
};

export default useDownloadDocument;
