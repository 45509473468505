import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

const ColoredButton = ({
  color,
  background,
  variant,
  size,
  children,
  onClick,
  disabled,
}) => {
  const style = {
    color,
    background,
  };
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      size={size}
      variant={variant}
      style={!disabled ? style : {}}
    >
      {children}
    </Button>
  );
};

ColoredButton.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ColoredButton.defaultProps = {
  background: '#ddd',
  color: 'black',
  disabled: false,
  variant: 'contained',
  size: 'small',
};

export default ColoredButton;
