// Staging config
module.exports = {
  apiBase: 'https://staging.api.moank.se/api',
  apiKey: '914c1f240d851d967f42eb124ee673e71a9131d3',
  chartIds: {
    PORTFOLIO_VOLUME: 'aca2635a-3e0d-4344-afad-e0cad013616d',
    TOTAL_VOLUME: '0b24c15c-ca88-4509-bc3a-6567ccd464f8',
    VOLUME_SIGNUP: 'edf15818-053d-4cfc-b875-0f30798eac46',

    PAYMENT_FILE_LOANS: 'd11098ca-9fbe-48ca-9365-8675fcb16d5a',
    PAYMENT_FILE_UNIDENTIFIED: 'dc10597c-29a6-47ec-ad71-d62542d4af5f',
    VOLUME_PLEDGEABLE: '31f6345e-496f-4b62-9349-4348bb0fb2c7',

    NEW_VOLUME_TODAY: '9b1a47e2-5b2d-49b9-b597-e4f282745b9d',
    NEW_VOLUME_MONTH: '8fdb84aa-6115-4996-ab70-b4e86de26bda',
    NEW_INSURANCE_TODAY: '2bd4c734-df8a-4398-a2fa-891f0e50e724',
    NEW_INSURANCE_MONTH: '73afc899-9bdc-4086-8dfe-7e714978566e',

    SIGNUP_TODAY: '2d715125-7f99-451f-be25-471b15ccb0e2',
    SIGNUP_HOUR: '2d715125-7f99-451f-be25-471b15ccb0e2',

    MANUAL_COMMENTS_TOTAL_TODAY: '880e17ee-5a30-4917-a307-0de99aa4507d',
    MANUAL_COMMENTS_TOTAL_HOUR: '4f185a08-5fea-45ea-94f0-448cc73eef0f',

    QUEUE_MANAGEMENT_TODAY: '0d2b9b94-fe6d-47e3-a99e-cd66bab13f45',
    QUEUE_MANAGEMENT_HOUR: '63428a60-8a20-4811-900a-31affbf753d9',
  },
  sentryEnvironment: 'staging',
};
