import React from 'react';
import PropTypes from 'prop-types';
import { Datagrid, DatagridBody } from 'react-admin';

const HeadedDatagrid = ({ head, ...props }) => (
  <>
    {head}
    <Datagrid bulkActionButtons={null} {...props} body={<DatagridBody />} />
  </>
);

HeadedDatagrid.propTypes = {
  head: PropTypes.node.isRequired,
};

export default HeadedDatagrid;
