import React from 'react';
import {
  List,
  TextField,
  EditButton,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import HeadedDatagrid from '../utils/HeadedDatagrid';

const AdminList = () => (
  <List>
    <HeadedDatagrid head={(<PageTitle title="Admin | List" />)}>
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <EditButton />
    </HeadedDatagrid>
  </List>
);

export default AdminList;
