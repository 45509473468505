import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ColoredButton from './ColoredButton';

import useCustomRpc from '../../hooks/useCustomRpc';

const CloneButton = ({
  source,
  resource,
}) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const { mutate: cloneResource } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resource}/clone/${record[source]}`,
    successMessage: 'Resource cloned',
    errorMessage: 'Failed cloning resource',
    shouldRefresh: true,
    onSuccess: (data) => {
      if (data.id) navigate(`/application/${data.id}`);
    },
  });

  const onClick = useCallback((e) => {
    e.preventDefault();
    cloneResource();
  }, [cloneResource]);

  return (
    <ColoredButton
      onClick={onClick}
      background="Blue"
      color="white"
    >
      Clone
    </ColoredButton>
  );
};

CloneButton.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
};

export default CloneButton;
