import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import {
  ReferenceInput,
  TextInput,
  ReferenceField,
  TextField,
  NumberInput,
  required,
} from 'react-admin';

import SelectInput from '../input/SelectInput';
import SelectArrayInput from '../input/SelectArrayInput';
import NullableBooleanInput from '../input/NullableBooleanInput';
import AutocompleteInput from '../input/AutocompleteInput';
import DocumentsField from '../utils/DocumentsField';
import SendUnreachableEmailButton from './SendUnreachableEmailButton';
import PhoneNumberInput from '../utils/PhoneNumberInput';
import SendUnreachableSmsButton from '../utils/SendUnreachableSmsButton';
import SendFreeTextSmsButton from '../utils/SendFreeTextSmsButton';

import DecisionField from './DecisionField';

import cleanNumberSearch from '../../utils/cleanNumberSearch';
import {
  email,
  isNotPEP,
  monthlyHousing,
  monthlyIncome,
  monthlyLoans,
} from '../../utils/validationErrors';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import { useConstantContext } from '../utils/ConstantsContext';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const spacedButtonStyle = {
  margin: '8px 0 4px',
};

const filterToQueryUser = (searchText) => ({ socialSecurityNumber: cleanNumberSearch(searchText) });
const checkSuggestionUser = (filter, choice) => choice.socialSecurityNumber
  .includes(cleanNumberSearch(filter));

const UserFields = ({
  applicationId,
  scopedFormData,
  getSource,
  readOnly,
}) => {
  const { choices } = useConstantContext();
  const { countries } = choices;
  const { user: userId } = scopedFormData;

  return (
    <>
      {applicationId && userId ? (
        <>
          <SimpleShowLabeled label="First Name">
            <ReferenceField
              link={false}
              source={getSource('_id')}
              reference="user"
            >
              <TextField source="firstName" />
            </ReferenceField>
          </SimpleShowLabeled>
          <SimpleShowLabeled label="Last Name">
            <ReferenceField
              link={false}
              source={getSource('_id')}
              reference="user"
            >
              <TextField source="lastName" />
            </ReferenceField>
          </SimpleShowLabeled>
          <SimpleShowLabeled label="Social security number">
            <ReferenceField
              link={false}
              source={getSource('_id')}
              reference="user"
            >
              <SocialSecurityNumberField source="socialSecurityNumber" />
            </ReferenceField>
          </SimpleShowLabeled>
          <SimpleShowLabeled label="User Id">
            <ReferenceField
              source={getSource('_id')}
              reference="user"
            >
              <TextField source="id" />
            </ReferenceField>
          </SimpleShowLabeled>
        </>
      ) : (
        <ReferenceInput
          label="User"
          source={getSource('_id')}
          reference="user"
        >
          <AutocompleteInput
            optionText="socialSecurityNumber"
            matchSuggestion={checkSuggestionUser}
            validate={required()}
            filterToQuery={filterToQueryUser}
            readOnly={readOnly}
          />
        </ReferenceInput>
      )}
      <TextInput
        source={getSource('email')}
        label="Email"
        type="email"
        validate={[
          required('The email is required'),
          email,
        ]}
        readOnly={readOnly}
      />
      <Box display="flex" alignItems="center">
        <PhoneNumberInput
          source={getSource('phone')}
          required
          label="Phone"
          readOnly={readOnly}
        />
        {applicationId && userId && (<SendFreeTextSmsButton userId={scopedFormData._id} />)}
      </Box>
      <DecisionField
        as={NumberInput}
        source={getSource('economicDetails.monthlyIncome')}
        label="Monthly Income"
        validate={monthlyIncome}
        readOnly={readOnly}
      />
      <DecisionField
        as={NumberInput}
        source={getSource('economicDetails.monthlyHousing')}
        label="Monthly Housing"
        validate={monthlyHousing}
        readOnly={readOnly}
      />
      <DecisionField
        as={NumberInput}
        source={getSource('economicDetails.monthlyLoans')}
        label="Monthly Loans"
        validate={monthlyLoans}
        readOnly={readOnly}
      />
      <DecisionField
        as={NumberInput}
        source={getSource('economicDetails.mortgageAmount')}
        label="Mortgage Loans"
        readOnly={readOnly}
      />
      <DecisionField
        as={SelectInput}
        source={getSource('economicDetails.homeType')}
        label="Home Type"
        choices={choices.homeTypes}
        readOnly={readOnly}
      />
      <DecisionField
        as={SelectInput}
        source={getSource('maritalStatus')}
        label="Marital Status"
        choices={choices.maritalStatuses}
        readOnly={readOnly}
      />
      <DecisionField
        as={SelectInput}
        source={getSource('economicDetails.kids')}
        readOnly={readOnly}
        label="Number of kids"
        choices={choices.children}
      />
      <DecisionField
        as={SelectInput}
        source={getSource('economicDetails.occupation')}
        readOnly={readOnly}
        label="Occupation"
        choices={choices.allOccupations}
      />
      <TextInput
        source={getSource('employer.name')}
        label="Employer name"
        readOnly={readOnly}
      />
      <PhoneNumberInput
        source={getSource('employer.phone')}
        label="Employer phone"
        readOnly={readOnly}
      />
      <DecisionField
        as={NullableBooleanInput}
        source={getSource('isNotPEP')}
        label="Is not PEP"
        validate={isNotPEP}
        readOnly={readOnly}
      />
      <SelectArrayInput
        source={getSource('citizenships')}
        label="Citizenships"
        choices={countries}
        readOnly={readOnly}
      />
      {applicationId && userId && (
        <Box sx={spacedButtonStyle}>
          <SendUnreachableEmailButton applicationId={applicationId} />
        </Box>
      )}
      {applicationId && userId && (
        <Box sx={spacedButtonStyle}>
          <SendUnreachableSmsButton
            userId={scopedFormData._id}
            applicationId={applicationId}
          />
        </Box>
      )}
      {applicationId && userId && (
        <ReferenceField
          label="Applicant documents"
          source={getSource('_id')}
          reference="user"
          link={false}
        >
          <DocumentsField source="id" model="user" />
        </ReferenceField>
      )}
    </>
  );
};

UserFields.propTypes = {
  getSource: PropTypes.func.isRequired,
  scopedFormData: PropTypes.shape({
    _id: PropTypes.string,
    user: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
  applicationId: PropTypes.string,
};

UserFields.defaultProps = {
  applicationId: undefined,
  readOnly: false,
  scopedFormData: {},
};

export default UserFields;
