import React from 'react';
import PropTypes from 'prop-types';
import { Layout as RaLayout } from 'react-admin';
import Menu from './Menu';

const Layout = ({ children }) => (
  <RaLayout menu={Menu}>
    {children}
  </RaLayout>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
