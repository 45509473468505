import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';

import validators from '../../utils/validators';

const { isDefined } = validators.generics;

const formattedSource = (path) => path.replace(/\[([0-9]+)\]()/g, '.$1');

const DecisionField = ({
  as: Component,
  children,
  source,
  ...rest
}) => {
  const record = useRecordContext();

  if (!record) return null;
  const defined = isDefined(get(record, source));

  let warning;
  if (record.decisionData && record.decisionData.requiredFields) {
    const { decisionData: { requiredFields } } = record;
    if (requiredFields.includes(formattedSource(source))) warning = !defined;
  }

  return (
    <>
      <Component source={source} {...rest}>{children}</Component>
      {warning && (
        <Typography style={{ marginTop: '-1rem', marginBottom: '1rem' }} variant="body2" color="error">Required field for decision engine</Typography>
      )}
    </>
  );
};

DecisionField.propTypes = {
  as: PropTypes.elementType.isRequired,
  children: PropTypes.node,
  source: PropTypes.string.isRequired,
};

DecisionField.defaultProps = {
  children: null,
};

export default DecisionField;
