import config from '../config';
import store from './utils/store';

const resources = {
  ORGANISATION: 'organisation',
  USER: 'user',
  APPLICATION: 'application',
};

const holderTypes = {
  USER: 'User',
  ORGANISATION: 'Organisation',
};

const uploadDocuments = (baseUrl) => (files, id) => new Promise((resolve, reject) => {
  const formData = new window.FormData();
  files.forEach((file) => {
    formData.append(file.title, file.rawFile);
  });

  const request = new window.XMLHttpRequest();
  request.open('POST', `${baseUrl}/${id}/upload`);
  request.setRequestHeader('X-API-Key', config.apiKey);
  request.setRequestHeader('X-Token', store.getItem('token'));
  request.onload = () => {
    if (request.readyState === request.DONE) {
      if (request.status >= 400) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('File Upload failed');
      } else {
        resolve();
      }
    }
  };
  request.send(formData);
});

const uploadUserDocuments = uploadDocuments(`${config.apiBase}/${resources.USER}`);
const uploadOrganisationDocuments = uploadDocuments(`${config.apiBase}/${resources.ORGANISATION}`);

export default (dataProvider) => ({
  ...dataProvider,
  update: (resource, params) => {
    if (resource === resources.USER) {
      if (params.data.uploadedDocuments && params.data.uploadedDocuments.length) {
        return uploadUserDocuments(params.data.uploadedDocuments, params.id)
          .then(() => dataProvider.update(resource, params));
      }
    }
    if (resource === resources.APPLICATION) {
      const documents = params.data.applicants
        .map(async ({ uploadedDocuments, holderType, _id }) => {
          if (!(uploadedDocuments && uploadedDocuments.length)) return null;
          if (holderType === holderTypes.USER) {
            return uploadUserDocuments(uploadedDocuments, _id);
          }
          if (holderType === holderTypes.ORGANISATION) {
            return uploadOrganisationDocuments(uploadedDocuments, _id);
          }
          return null;
        });

      return Promise.all(documents).then(() => dataProvider.update(resource, params));
    }
    if (resource === resources.ORGANISATION) {
      if (params.data.uploadedDocuments && params.data.uploadedDocuments.length) {
        return uploadOrganisationDocuments(
          params.data.uploadedDocuments,
          params.id,
        ).then(() => dataProvider.update(resource, params));
      }
    }

    return dataProvider.update(resource, params);
  },
});
