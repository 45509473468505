import { useGetList } from 'react-admin';

const useGetAdminList = () => useGetList(
  'admins',
  {
    pagination: { perPage: 100, page: 1 },
    sort: { field: 'firstName', order: 'ASC' },
    filter: {},
  },
);

export default useGetAdminList;
