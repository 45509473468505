import React, { useState, useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import moment from 'moment';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import useCustomRpc from '../../hooks/useCustomRpc';

const styles = {
  buttonClose: {
    fontSize: '0.7rem',
    padding: '0.3rem 0.7rem',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: '1rem 2.5rem 0.5rem 0.5rem',
    float: 'right',
  },
  buttonSubmit: {
    fontSize: '0.7rem',
    padding: '0.3rem 0.7rem',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: '1rem 0.5rem 1rem 1rem',
    float: 'right',
    color: 'white',
    backgroundColor: '#f44336',
  },
  buttonDebtSold: {
    fontSize: '0.7rem',
    padding: '0.3rem 0.7rem',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: '0.5rem',
    float: 'right',
    color: 'white',
    backgroundColor: '#3C7AF4',
  },
  modal: {
    width: '500px',
    height: 'auto',
    padding: '32px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2) 0px 5px 8px 0px rgba(0, 0, 0, 0.14) 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  input: {
    width: 'auto',
    textAlign: 'center',
    margin: '1rem 2rem',
  },
};

const DebtSoldButton = () => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');

  const account = useRecordContext();

  const { mutate: soldAccount, isLoading } = useCustomRpc({
    httpMethod: 'PUT',
    path: `account/${account.id}/sold`,
    successMessage: 'Account marked as sold',
    errorMessage: 'Failed marking account as sold',
    shouldRefresh: true,
  });

  const handleOpenModal = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    setOpen(false);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (!date) {
      setMessage('Invalid date');
      return false;
    }
    if (!amount || amount < 0) {
      setMessage('Invalid amount');
      return false;
    }
    const data = {
      date,
      amount,
    };
    soldAccount(data);
    return true;
  }, [date, amount, soldAccount]);

  const handleChangeDate = useCallback((event) => {
    setMessage('');
    switch (event.target.id) {
      case 'date':
        setDate(event.target.value ? event.target.value : moment().format('YYYY-MM-DD'));
        break;
      case 'amount':
        setAmount(event.target.value);
        break;
      default:
        setDate(moment().format('YYYY-MM-DD'));
        setAmount('');
    }
  }, []);

  if (!account) return null;
  if (account.status !== 'cancelled' || account.debtSold.date) return null;
  return (
    <>
      <Button
        style={styles.buttonDebtSold}
        variant="contained"
        color="inherit"
        onClick={handleOpenModal}
        disabled={isLoading}
      >
        Mark as sold
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleCloseModal}
      >
        <div style={styles.modal}>
          <Typography align="center">Insert when the account was sold and the amount sold:</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                style={styles.input}
                align="center"
                id="date"
                type="date"
                label="Select sale date"
                InputProps={{
                  inputProps: {
                    max: moment().format('YYYY-MM-DD'),
                  },
                }}
                onChange={handleChangeDate}
                value={date}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                style={styles.input}
                align="center"
                id="amount"
                type="number"
                label="Select sale amount"
                onChange={handleChangeDate}
                value={amount}
              />
            </Grid>
          </Grid>
          <Typography color="error" align="center">{message}</Typography>
          <Button
            style={styles.buttonClose}
            variant="contained"
            color="secondary"
            onClick={handleCloseModal}
            disabled={isLoading}
          >
            Close
          </Button>
          <Button
            style={styles.buttonSubmit}
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Mark as Sold
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DebtSoldButton;
