import React, { useState, useCallback } from 'react';
import { useNotify, useRecordContext } from 'react-admin';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';
import { isBeforeFactory } from '../../utils/signup/compareApplicationSteps';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const ManualProcess = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { applicationSteps, orderedApplicationSteps } = constants;
  const isBefore = isBeforeFactory(orderedApplicationSteps);

  const notify = useNotify();

  const { mutate: manualProcess } = useCustomRpc({
    httpMethod: 'PUT',
    path: `application/manualProcess/${record?.id}`,
    errorMessage: 'Failed to manually process application',
    shouldRefresh: true,
    onSuccess: (data) => {
      if (data.result) notify(data.result, { type: 'info' });
    },
  });

  const [silenceDeny, setSilenceDeny] = useState(false);
  const handleSwitchChange = useCallback((e) => {
    e.preventDefault();
    setSilenceDeny(e.target.checked);
  }, []);

  const onSubmit = useCallback(() => {
    manualProcess({ id: record?.id, silenceDeny });
    setSilenceDeny(false);
    return true;
  }, [record?.id, manualProcess, silenceDeny]);

  const resetState = useCallback(() => {
    setSilenceDeny(false);
  }, []);

  const canBeManualProcessed = () => {
    const { applicationStep } = record;
    return isBefore(applicationStep, applicationSteps.PROCESSED);
  };

  if (!canBeManualProcessed()) return null;
  const processStarted = record.events.length !== 0;
  const processButtonLabel = processStarted
    ? 'Continue Process'
    : 'Start Process';
  return (
    <ModalButton
      title={processButtonLabel}
      onClick={onSubmit}
      onClose={resetState}
    >
      <Typography align="center">Do you want to silence the deny email?</Typography>
      <FormControlLabel
        control={(
          <Switch
            checked={silenceDeny}
            onChange={handleSwitchChange}
            value="silenceDeny"
            color="primary"
          />
        )}
        label="Silence deny email"
      />
    </ModalButton>
  );
};

export default ManualProcess;
