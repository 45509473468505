import React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  ShowView,
  SimpleShowLayout,
  TextField,
  BooleanField,
  FunctionField,
  useShowController,
  ShowContextProvider,
} from 'react-admin';

import {
  formatIncompletePhoneNumber,
} from 'libphonenumber-js';

import AddComment from './AddComment';
import DocumentsField from '../utils/DocumentsField';
import RiskReportButtons from './RiskReportButtons';
import RiskReportTitle from './RiskReportTitle';
import DownloadEARButton from './DownloadEARButton';

import MoneyField from '../utils/MoneyField';
import PageTitle from '../utils/PageTitle';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';

import validators from '../../utils/validators';
import { useConstantContext } from '../utils/ConstantsContext';

const { isNonEmptyArray } = validators.generics;

const formatPhoneNumber = (record) => (record.phone ? formatIncompletePhoneNumber(record.phone, 'SE') : '');

const isUserBuilder = (holderTypes) => (record) => (
  record.accountHolder
  && record.accountHolder.holderType === holderTypes.USER);

const getRecord = (props) => props.record || {};
const getAdditionalInfo = (record) => record.additionalInfo || {};

const RiskReportShow = () => {
  const { constants } = useConstantContext();
  const isUser = isUserBuilder(constants.holderTypes);
  const controllerProps = useShowController();
  return (
    <ShowContextProvider value={controllerProps}>
      <ShowView title={<RiskReportTitle />}>
        <SimpleShowLayout>
          <PageTitle title="RiskReport | Show" />
          <RiskReportButtons />
          <TextField source="id" label="Report Id" />
          <TextField source="accountHolder._id" label="Holder Id" />
          {isUser(getRecord(controllerProps))
            ? (
              [
                <ReferenceField key="id" label="Id" source="accountHolder._id" reference="user">
                  <SocialSecurityNumberField source="socialSecurityNumber" />
                </ReferenceField>,
                <ReferenceField key="amlRiskLevel" label="AML Risk Level" link={false} source="accountHolder._id" reference="user">
                  <TextField source="amlRiskLevel" label="User's AML risk level" />
                </ReferenceField>,
                <ReferenceField key="email" label="Email" link={false} source="accountHolder._id" reference="user">
                  <TextField source="email" />
                </ReferenceField>,
                <ReferenceField key="phone" label="Phone" link={false} source="accountHolder._id" reference="user">
                  <FunctionField render={formatPhoneNumber} />
                </ReferenceField>,
              ]
            )
            : (
              [
                <ReferenceField key="id" label="Id" source="accountHolder._id" reference="organisation">
                  <OrganisationNumberField source="organisationNumber" />
                </ReferenceField>,
                <ReferenceField key="amlRiskLevel" label="AML Risk Level" link={false} source="accountHolder._id" reference="organisation">
                  <TextField source="amlRiskLevel" label="User's AML risk level" />
                </ReferenceField>,
                <ReferenceField key="email" label="Email" link={false} source="accountHolder._id" reference="organisation">
                  <TextField source="email" />
                </ReferenceField>,
                <ReferenceField key="phone" label="Phone" link={false} source="accountHolder._id" reference="organisation">
                  <FunctionField render={formatPhoneNumber} />
                </ReferenceField>,
              ]
            )}
          <DateField source="createdAt" />
          <TextField source="rule" />
          <TextField source="reason" />
          <TextField source="status" />
          <DateField source="isClosedAt" />
          <DateField source="reportedAt" />
          <BooleanField source="ignoreReporting" />
          {getAdditionalInfo(getRecord(controllerProps)).category && (
            <TextField source="additionalInfo.category" label="Transactions' category" />
          )}
          {getAdditionalInfo(getRecord(controllerProps)).signatureDate && (
            <TextField source="additionalInfo.signatureDate" label="Signature date" />
          )}
          {isNonEmptyArray(getAdditionalInfo(getRecord(controllerProps)).transactions) && (
            <ArrayField source="additionalInfo.transactions" label="Suspicious transactions">
              <Datagrid bulkActionButtons={null}>
                <DateField source="date" />
                <MoneyField source="amount" />
                <TextField source="accountNumber" />
                <TextField source="bank" />
                <TextField source="description" />
              </Datagrid>
            </ArrayField>
          )}
          <hr />
          <ArrayField source="comments" label="Comments">
            <Datagrid bulkActionButtons={null}>
              <DateField source="createdAt" />
              <TextField source="message" />
              <ReferenceField source="author" reference="admins">
                <TextField source="firstName" />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
          <AddComment />
          <hr style={{ margin: '1rem 0rem' }} />
          <DownloadEARButton />
          {isUser(getRecord(controllerProps))
            ? (
              <ReferenceField link={false} source="accountHolder._id" reference="user">
                <DocumentsField source="documents" model="user" />
              </ReferenceField>
            )
            : (
              <ReferenceField link={false} source="accountHolder._id" reference="organisation">
                <DocumentsField source="documents" model="organisation" />
              </ReferenceField>
            )}
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};

export default RiskReportShow;
