import React from 'react';
import {
  List,
  EditButton,
  DateField,
  TextField,
  TextInput,
  Datagrid,
  BooleanField,
} from 'react-admin';

const brokerFilters = [
  <TextInput label="Utm Source" source="utmSource" alwaysOn />,
  <TextInput label="Name" source="name" alwaysOn />,
];

const BrokerList = () => (
  <List
    title="Brokers"
    exporter={false}
    filters={brokerFilters}
    perPage={25}
  >
    <Datagrid bulkActionButtons={null}>
      <TextField source="utmSource" />
      <TextField source="name" emptyText="-" />
      <BooleanField source="isActive" />
      <DateField source="createdAt" />
      <EditButton />
    </Datagrid>
  </List>
);

export default BrokerList;
