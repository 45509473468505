import React from 'react';

import {
  ArrayField,
  ShowView,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  Datagrid,
  ReferenceField,
  BooleanField,
  useShowController,
  ShowContextProvider,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';

const UcCompanyCheckShow = () => {
  const controllerProps = useShowController();
  return (
    <ShowContextProvider value={controllerProps}>
      <ShowView>
        <SimpleShowLayout>
          <ReferenceField label="Organisation Id" source="organisation" reference="organisation">
            <TextField source="id" />
          </ReferenceField>
          <PageTitle title="UC Company Check | Show" />
          <TextField source="product" />
          <TextField source="score" />
          <DateField source="createdAt" label="Created At" />
          <TextField source="decision" />
          <OrganisationNumberField source="organisationNumber" />
          <TextField source="name" label="name" />
          <TextField source="streetAddress" label="Address" />
          <TextField source="town" label="Town" />
          <TextField source="postcode" label="Post code" />
          <DateField source="registrationDate" label="Register at" />
          <DateField source="deregistrationDate" label="Deregister at" />
          <TextField source="companyStatus" label="Company status" />
          <DateField source="companyStatusDate" label="Company status date" />
          <TextField source="companyLegalForm" label="Company legal form" />
          <TextField source="companyCorporateForm" label="Company corporate form" />
          <ArrayField source="signatories" label="Signatories">
            <Datagrid bulkActionButtons={null}>
              <TextField source="authorisedSignatories" />
              <TextField source="externalAuthorisedSignatories" />
              <SocialSecurityNumberField source="socialSecurityNumber" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="historicalNames" label="HistoricalNames">
            <Datagrid bulkActionButtons={null}>
              <TextField source="previousName" />
              <DateField source="dateOfChange" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="incomeStatements" label="Income statements">
            <Datagrid bulkActionButtons={null}>
              <BooleanField source="isFullYear" />
              <DateField source="fromDate" />
              <DateField source="toDate" />
              <NumberField source="netSales" />
              <NumberField source="changeInventoriesOfProducts" />
              <NumberField source="costOfGoodsSold" />
              <NumberField source="otherOperatingIncome" />
              <NumberField source="totalOperatingIncome" />
              <NumberField source="ownCapitalisedWork" />
              <NumberField source="rawMaterials" />
              <NumberField source="goodsForResale" />
              <NumberField source="otherExternalCosts" />
              <NumberField source="personnelCosts" />
              <NumberField source="depreciation" />
              <NumberField source="grossProfit" />
              <NumberField source="sellingExpenses" />
              <NumberField source="administrativeExpenses" />
              <NumberField source="researchAndDevelopmentCosts" />
              <NumberField source="comparabilityAffectingItems" />
              <NumberField source="otherOperatingExpenses" />
              <NumberField source="totalCostsAndDepreciation" />
              <NumberField source="operatingProfit" />
              <NumberField source="groupStakeProfit" />
              <NumberField source="groupInterestIncome" />
              <NumberField source="externalInterestIncome" />
              <NumberField source="otherFinancialIncome" />
              <NumberField source="totalFinancialIncome" />
              <NumberField source="groupInterestExpenses" />
              <NumberField source="externalInterestExpenses" />
              <NumberField source="otherFinancialExpenses" />
              <NumberField source="totalFinancialExpenses" />
              <NumberField source="comparabilityAffectingFinancialItems" />
              <NumberField source="profitAfterFinancialItems" />
              <NumberField source="extraordinaryIncome" />
              <NumberField source="extraordinaryExpenses" />
              <NumberField source="shareholdersContribution" />
              <NumberField source="profitBeforeAppropriations" />
              <NumberField source="appropriations" />
              <NumberField source="groupContribution" />
              <NumberField source="profitBeforeTax" />
              <NumberField source="tax" />
              <NumberField source="profitSubsidiariesBeforeAquisition" />
              <NumberField source="netProfit" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="balanceSheets" label="Balance Sheets">
            <Datagrid bulkActionButtons={null}>
              <BooleanField source="isFullYear" />
              <DateField source="fromDate" />
              <DateField source="toDate" />
              <NumberField source="capitalisedExpenditureForRAndD" />
              <NumberField source="concessionsPatentsLicenses" />
              <NumberField source="goodwill" />
              <NumberField source="otherIntangibleFixedAssets" />
              <NumberField source="totalIntangibleFixedAssets" />
              <NumberField source="landAndBuildings" />
              <NumberField source="machinery" />
              <NumberField source="equipment" />
              <NumberField source="totalMachineryAndEquipment" />
              <NumberField source="otherTangibleFixedAssets" />
              <NumberField source="totalTangibleFixedAssets" />
              <NumberField source="stakeInGroup" />
              <NumberField source="receivablesFromGroup" />
              <NumberField source="loansToPartnersAndRelatedParties" />
              <NumberField source="otherFinancialFixedAssets" />
              <NumberField source="totalFinancialFixedAssets" />
              <NumberField source="otherFixedAssets" />
              <NumberField source="totalFixedAssets" />
              <NumberField source="workInProgressOnContract" />
              <NumberField source="otherInventories" />
              <NumberField source="totalInventories" />
              <NumberField source="accountsReceivables" />
              <NumberField source="receivablesAtGroup" />
              <NumberField source="otherCurrentReceivables" />
              <NumberField source="totalCurrentReceivables" />
              <NumberField source="totalCurrentInvestments" />
              <NumberField source="totalCashAndBankBalance" />
              <NumberField source="otherCurrentAssets" />
              <NumberField source="totalCurrentAssets" />
              <NumberField source="totalAssets" />
              <NumberField source="shareCapital" />
              <NumberField source="sharePremiumReserve" />
              <NumberField source="revaluationReserve" />
              <NumberField source="otherRestrictedEquity" />
              <NumberField source="retainedEarnings" />
              <NumberField source="netProfitForYear" />
              <NumberField source="totalShareholdersEquity" />
              <NumberField source="totalUntaxedReserves" />
              <NumberField source="minorityInterest" />
              <NumberField source="totalProvision" />
              <NumberField source="bondLoans" />
              <NumberField source="longTermLiabilitiesToCreditInstitutions" />
              <NumberField source="bankOverdraftFacilityUtilised" />
              <NumberField source="longTermLiabilitiesToGroup" />
              <NumberField source="longTermInterestBearingLiabilities" />
              <NumberField source="totalLongTermLiabilities" />
              <NumberField source="currentLiabilitiesToCreditInstitutions" />
              <NumberField source="accountPayable" />
              <NumberField source="currentLiabilitiesToGroup" />
              <NumberField source="otherCurrentLiabilities" />
              <NumberField source="totalCurrentLiabilities" />
              <NumberField source="totalShareholdersEquityAndLiabilities" />
              <NumberField source="subscribedCapitalUnpaid" />
              <NumberField source="groupContribution" />
              <NumberField source="shareholdersContribution" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="errorMessages" label="Error Messages">
            <Datagrid bulkActionButtons={null}>
              <TextField source="code" />
              <TextField source="message" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};

export default UcCompanyCheckShow;
