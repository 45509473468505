import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { useRecordContext } from 'react-admin';

import useCustomRpc from '../../hooks/useCustomRpc';
import onSuccessGetDocument from '../../utils/onSuccessGetDocument';

const DownloadWhitelistedPayerDocumentButton = ({ accountId }) => {
  const record = useRecordContext();
  const whitelistedPayerId = record._id;

  const { mutate: getWhitelistedPayerDocument } = useCustomRpc({
    httpMethod: 'GET',
    path: `account/${accountId}/whitelisted-payer/${whitelistedPayerId}/download`,
    errorMessage: 'Failed to download document',
    onSuccess: onSuccessGetDocument,
  });

  const onClick = useCallback(() => {
    getWhitelistedPayerDocument();
  }, [getWhitelistedPayerDocument]);
  if (!accountId || !whitelistedPayerId) return null;
  return (
    <Button
      title="Download Document"
      color="primary"
      size="small"
      variant="contained"
      onClick={onClick}
    >
      Download Document
    </Button>
  );
};

DownloadWhitelistedPayerDocumentButton.propTypes = {
  accountId: PropTypes.string,
};

DownloadWhitelistedPayerDocumentButton.defaultProps = {
  accountId: undefined,
};

export default DownloadWhitelistedPayerDocumentButton;
