import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';

const chipStyle = {
  marginRight: '5px',
  backgroundColor: '#eb41eb',
};
const AccountUtmSourceChip = (
  { trackingData: { utmSource } },
) => {
  if (!utmSource) {
    return null;
  }
  return <Chip style={chipStyle} label={utmSource} />;
};

AccountUtmSourceChip.propTypes = {
  trackingData: PropTypes.shape({
    utmSource: PropTypes.string,
  }),
};

AccountUtmSourceChip.defaultProps = {
  trackingData: {
    utmSource: undefined,
  },
};

export default AccountUtmSourceChip;
