import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ModalButton from './ModalButton';
import SelectField from './SelectField';

import validators from '../../utils/validators';

import useInputStatus from '../hooks/useInputStatus';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const InsureButton = ({
  model,
}) => {
  const record = useRecordContext();

  const id = record?.id;
  const { choices } = useConstantContext();
  const { utmMediums } = choices;
  const navigate = useNavigate();

  const { mutate: insureAccount } = useCustomRpc({
    httpMethod: 'POST',
    path: 'application/insure',
    successMessage: 'Application created',
    errorMessage: 'Failed to create application',
    onSuccess: (data) => {
      if (data.id) {
        navigate(`/application/${data.id}`);
      }
    },
  });

  const [
    utmMedium,
    setUtmMedium,
    onChangeUtmMedium,
    utmMediumError,
    setUtmMediumError,
  ] = useInputStatus();

  const onClick = useCallback(() => {
    setUtmMediumError('');
    if (!isDefined(utmMedium)) {
      setUtmMediumError('Please select a UTM Medium');
      return false;
    }

    const data = {
      id,
      model,
      utmMedium,
    };
    insureAccount(data);
    return true;
  }, [
    id, utmMedium, model,
    insureAccount, setUtmMediumError,
  ]);

  const onClose = useCallback(() => {
    setUtmMedium('');
    setUtmMediumError('');
  }, [
    setUtmMedium, setUtmMediumError,
  ]);

  if (!record) return null;

  return (
    <ModalButton
      title="Insure Account"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <SelectField
        label="Application UTM Medium"
        onChange={onChangeUtmMedium}
        value={utmMedium}
        error={utmMediumError}
        choices={utmMediums}
      />
    </ModalButton>
  );
};

InsureButton.propTypes = {
  model: PropTypes.string.isRequired,
};

export default InsureButton;
