import React, {
  Fragment,
  useCallback,
  useState,
} from 'react';
import moment from 'moment';

import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import WarningMessage from './WarningMessage';
import ColoredButton from './ColoredButton';

import CategorisationDetails from './categorisation/CategorisationDetails';
import CategorisationChart from './categorisation/CategorisationChart';
import CategorisationModal from './categorisation/CategorisationModal';

import SendBankDataSmsButton from './SendBankDataSmsButton';

import validators from '../../utils/validators';

import { numberFormat } from '../../utils/formatters';
import useCustomQuery from '../../hooks/useCustomQuery';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const tableCellStyles = {
  root: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
};

const StyledTabledCell = withStyles(tableCellStyles)(TableCell);

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    margin: '1rem 0',
  },
  container: {
    margin: '2rem 0',
  },
  accountTable: { width: 'auto' },
};

const initialState = {
  open: false,
  details: {
    negDet: {},
    posDet: {},
    numberOfTransactions: 0,
    endDate: '',
    negSum: 0,
    posSum: 0,
    startDate: '',
  },
};

const mapCategoryToLabel = {
  income: 'Inkomst',
  incomePattern: 'Inkomst mönster',
  allowance: 'Bidrag',
  loans: 'Lån',
  smsLoans: 'SMS Lån',
  paymentLoans: 'Betalning eller lån',
  inkasso: 'Inkasso',
  gambling: 'Casino och betting',
  gamblingPattern: 'Casino och betting mönster',
  rent: 'Hyra',
  rentPattern: 'Hyra mönster',
  cash: 'Kontant',
  abroadPayments: 'Utomlandsbetalningar',
  dailyTransactions: 'Vardagstransaktioner',
  largeUncategorised: 'Stora okategoriserade',
  swish: 'Swish',
  children: 'Barn',
  savings: 'Sparande',
  car: 'Bil',
  student: 'Student',
  unemploymentBenefit: 'Akassa',
  insurance: 'Försäkring',
  transport: 'Transport',
  internalTransactions: 'Interna överföringar',
  internalTransactionsPattern: 'Interna överföringar mönster',
  sum: 'Summa',
};

const Actions = ({
  userId,
  classes,
  userInfo,
  refetchCategorisation,
}) => {
  const onClickHandler = useCallback(() => {
    refetchCategorisation();
  }, [refetchCategorisation]);

  const { hasCompletableReports, updateBankAccountUrl } = userInfo;

  const { mutate: completeBankReport } = useCustomRpc({
    httpMethod: 'PUT',
    path: `bank/completeReport/${userId}`,
    successMessage: 'Bank Report Completed',
    errorMessage: 'Error Completing Bank Report',
    onSuccess: (data) => {
      if (data.success) refetchCategorisation();
    },
  });

  const completeReport = useCallback((e) => {
    e.preventDefault();
    completeBankReport({ userId });
  }, [completeBankReport, userId]);

  return (
    <>
      <div className={classes.row}>
        <Box display="flex" alignItems="center" gap="1rem">
          <SendBankDataSmsButton userId={userId} />
          <Typography variant="body2">Update Bank Account: <a href={updateBankAccountUrl}>{updateBankAccountUrl}</a></Typography>
        </Box>
        <Button
          onClick={onClickHandler}
          variant="contained"
          color="secondary"
          size="small"
        >
          Refresh visualisation
        </Button>
      </div>
      {hasCompletableReports && (
        <WarningMessage>
          <Typography variant="subtitle1" align="center" color="error">This bank report is not complete. It will be automatically completed within 10 minutes</Typography>
          <ColoredButton
            onClick={completeReport}
            background="red"
            color="white"
          >
            Complete Report Now
          </ColoredButton>
        </WarningMessage>
      )}
    </>
  );
};

const CategorisationTab = ({ userId, classes }) => {
  const [open, setOpen] = useState(initialState.open);
  const [details, setDetails] = useState(initialState.details);

  const { data: userInfo, isLoading, refetch } = useCustomQuery({
    httpMethod: 'GET',
    path: `user/${userId}/categorisation`,
    key: `user/${userId}/categorisation`,
    enabled: isDefined(userId),
    errorMessage: (e) => (e.status === 404 ? false : 'Error fetching categorisation data'),
    refetchOnWindowFocus: false,
    retry: (count, error) => {
      if (error.status === 404) return false;
      return count < 3;
    },
  });

  const handleOpenModal = useCallback((row) => (e) => {
    e.preventDefault();
    setOpen(true);
    setDetails(row);
  }, []);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    setOpen(initialState.open);
    setDetails(initialState.details);
  }, []);

  if (isLoading) {
    return (
      <div className={classes.container}>
        <Typography variant="body2">Loading data from engine...</Typography>
      </div>
    );
  }

  if (!userInfo) {
    return (
      <div className={classes.container}>
        <Typography variant="body2">No data available</Typography>
      </div>
    );
  }

  const { bankAccountInfo } = userInfo;
  const categorisations = bankAccountInfo && Object.entries(bankAccountInfo.categorisation);

  return (
    <>
      <Actions
        userId={userId}
        classes={classes}
        userInfo={userInfo}
        refetchCategorisation={refetch}
      />
      {userInfo.insightsVisualisationLink
        ? (
          <Box width="100%" height="700px" marginTop="1rem">
            <iframe
              title="visualisation-iframe"
              src={userInfo.insightsVisualisationLink}
              width="100%"
              height="100%"
              style={{ border: 'unset' }}
            />
          </Box>
        ) : (
          <>
            <div className={classes.row}>
              <Typography variant="h4">Categorisation for user {userId}</Typography>
            </div>
            {bankAccountInfo
          && bankAccountInfo.accountsInfo
          && bankAccountInfo.accountsInfo.length > 0
          && (
            <div className={classes.container}>
              <Typography variant="h5" paragraph>Accounts</Typography>
              <Typography margin="normal" paragraph>Data collection date: {moment(bankAccountInfo.collectedAt).format('YYYY-MM-DD HH:mm')}</Typography>
              <Typography>Total balance: {numberFormat(bankAccountInfo.totalBalance, 2, { unit: 'kr' })}</Typography>
              <Table classes={{ root: classes.accountTable }}>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Account Number</TableCell>
                    <TableCell variant="head">Bank</TableCell>
                    <TableCell variant="head">Balance</TableCell>
                    <TableCell variant="head">Interest</TableCell>
                    <TableCell variant="head">Name</TableCell>
                    <TableCell variant="head">Type</TableCell>
                    <TableCell variant="head">Report Closed At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bankAccountInfo.accountsInfo.map((account) => (
                    <TableRow key={account.accountNumber}>
                      <TableCell>{account.accountNumber}</TableCell>
                      <TableCell>{account.bank}</TableCell>
                      <TableCell>{isDefined(account.balance) ? numberFormat(account.balance, 2, { unit: 'kr' }) : '-'}</TableCell>
                      <TableCell>{account.interest}</TableCell>
                      <TableCell>{account.name}</TableCell>
                      <TableCell>{account.type}</TableCell>
                      <TableCell>{account.closedAt ? moment(account.closedAt).format('YYYY-MM-DD') : ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
            {bankAccountInfo && (
              <div className={classes.container}>
                <Typography variant="h6">Balance Curve</Typography>
                <CategorisationChart data={bankAccountInfo.transactionGraph} />
                <Typography variant="h5">Categorisation</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {categorisations[0][1].map((row) => (
                        <TableCell key={`${row.startDate}-${row.endDate}`} variant="head">
                          {moment(row.startDate).format('YYYY-MM-DD')} to {moment(row.endDate).format('YYYY-MM-DD')}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Categories</TableCell>
                      {categorisations[0][1].map((row) => (
                        <TableCell key={`${row.startDate}-${row.endDate}`} variant="head">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid item xs={4}>Count</Grid>
                            <Grid item xs={4}>Positive</Grid>
                            <Grid item xs={4}>Negative</Grid>
                          </Grid>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categorisations.map(([category, data]) => (
                      <TableRow key={category}>
                        <TableCell variant="body">{mapCategoryToLabel[category] || category}</TableCell>
                        {data.map((row, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Fragment key={index}>
                            {category === 'sum'
                              ? (
                                <TableCell>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                  >
                                    <Grid item xs={4}>{numberFormat(row.numberOfTransactions, 0)}
                                    </Grid>
                                    <Grid item xs={4}>{numberFormat(row.posSum, 0, { thousandSep: '\'' })}</Grid>
                                    <Grid item xs={4}>{numberFormat(row.negSum, 0, { thousandSep: '\'' })}</Grid>
                                  </Grid>
                                </TableCell>
                              )
                              : (
                                <StyledTabledCell
                                  onClick={handleOpenModal(row)}
                              // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                  >
                                    <Grid item xs={4}>{numberFormat(row.numberOfTransactions, 0)}
                                    </Grid>
                                    <Grid item xs={4}>{numberFormat(row.posSum, 0, { thousandSep: '\'' })}</Grid>
                                    <Grid item xs={4}>{numberFormat(row.negSum, 0, { thousandSep: '\'' })}</Grid>
                                  </Grid>
                                </StyledTabledCell>
                              )}
                          </Fragment>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                  <CategorisationModal
                    open={open}
                    onClose={handleCloseModal}
                  >
                    <CategorisationDetails row={details} />
                  </CategorisationModal>
                </Table>
              </div>
            )}
          </>
        )}
    </>
  );
};

CategorisationTab.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    row: PropTypes.string,
    accountTable: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string,
  userInfo: PropTypes.shape({
    bankAccountInfo: PropTypes.shape({
      accountsInfo: PropTypes.arrayOf(PropTypes.shape({})),
      categorisation: PropTypes.shape({}),
      transactionGraph: PropTypes.arrayOf(PropTypes.shape({})),
      isComplete: PropTypes.bool,
    }),
    updateBankAccountUrl: PropTypes.string,
    hasCompletableReports: PropTypes.bool,
  }),
};

CategorisationTab.defaultProps = {
  userId: null,
  userInfo: null,
};

Actions.propTypes = {
  ...CategorisationTab.propTypes,
  refetchCategorisation: PropTypes.func.isRequired,
};

Actions.defaultProps = CategorisationTab.defaultProps;

export default withStyles(styles)(CategorisationTab);
