import React from 'react';
import {
  ReferenceField,
  TextField,
  Datagrid,
  DateField,
  ShowButton,
} from 'react-admin';

const UcCompanyInfoFieldsPreview = () => (
  <Datagrid bulkActionButtons={null}>
    <TextField source="id" />
    <DateField source="createdAt" label="Date" />
    <TextField source="product" />
    <TextField source="score" />
    <ReferenceField label="Organisation number" source="organisation" reference="organisation">
      <TextField source="organisationNumber" />
    </ReferenceField>
    <TextField source="name" label="name" />
    <ShowButton />
  </Datagrid>
);

export default UcCompanyInfoFieldsPreview;
