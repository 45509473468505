import React from 'react';

import {
  List,
  TopToolbar,
  usePermissions,
  FilterButton,
} from 'react-admin';

import useAccountFilters from './useAccountFilters';
import AccountCustomAction from './AccountCustomAction';
import DownloadPledgeeFile from './DownloadPledgeeFile';

import QuickPagination from '../utils/QuickPagination';
import AccountItem from './AccountItem';
import { useConstantContext } from '../utils/ConstantsContext';

const AccountActions = () => {
  const { constants } = useConstantContext();
  const { userRoles } = constants;
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      <FilterButton />
      {permissions && userRoles && permissions.includes(userRoles.ROLE_SUPER_ADMIN)
        ? <DownloadPledgeeFile />
        : null}
    </TopToolbar>
  );
};

const AccountList = () => {
  const { constants } = useConstantContext();
  const { userRoles } = constants;
  const accountFilters = useAccountFilters();
  const { permissions } = usePermissions();

  return (
    <List
      filters={accountFilters}
      actions={
        <AccountActions />
      }
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<QuickPagination />}
    >
      <AccountItem
        isListPage
        bulkActionButtons={
          permissions && permissions.includes(userRoles.ROLE_SUPER_ADMIN)
            ? <AccountCustomAction />
            : null
        }
      />
    </List>
  );
};

export default AccountList;
