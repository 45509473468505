import React from 'react';
import {
  BooleanField,
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  SelectField,
  TextField,
} from 'react-admin';
import PropTypes from 'prop-types';

import useGetAdminList from '../../utils/useGetAdminList';

import AssignedAdmin from '../utils/AssignedAdmin';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import AltField from '../utils/AltField';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import MoneyField from '../utils/MoneyField';
import AccountCategoryChip from '../utils/AccountCategoryChip';
import AutoRefresh from '../utils/AutoRefresh';
import PageTitle from '../utils/PageTitle';
import HeadedDatagrid from '../utils/HeadedDatagrid';
import { useConstantContext } from '../utils/ConstantsContext';

const isUserBuilder = (holderTypes) => (record) => (
  record.applicants[0].holderType === holderTypes.USER);

const ApplicationItem = ({ isListPage }) => {
  const { choices, constants } = useConstantContext();
  const { decisionStatuses, applicationSteps } = choices;
  const isUser = isUserBuilder(constants.holderTypes);

  const { data: adminList, error: adminListError, isLoading: adminListLoading } = useGetAdminList();

  return (
    <HeadedDatagrid
      head={isListPage && (
        <>
          <AutoRefresh />
          <PageTitle title="Application | List" />
        </>
      )}
    >
      <DateField source="lastEventDate" showTime />
      <AltField
        label="Identifier"
        source="applicants[0]._id"
        predicate={(record) => isUser(record)}
        primary={(
          <ReferenceField reference="user" source="id">
            <SocialSecurityNumberField source="socialSecurityNumber" />
          </ReferenceField>
          )}
        secondary={(
          <ReferenceField reference="organisation" source="id">
            <OrganisationNumberField source="organisationNumber" />
          </ReferenceField>
          )}
      />
      <AltField
        label="Name"
        source="applicants[0]._id"
        link={false}
        predicate={(record) => isUser(record)}
        primary={(
          <ReferenceField reference="user" source="id">
            <FunctionField render={(record) => (`${record.firstName || ''} ${record.lastName || ''}`)} />
          </ReferenceField>
          )}
        secondary={(
          <ReferenceField reference="organisation" source="id">
            <TextField source="name" />
          </ReferenceField>
          )}
      />
      <AccountCategoryChip label="Category" />
      <MoneyField label="Amount" source="loanTerms.amount" />
      <TextField source="loanTerms.nominalRate" label="Nominal rate" />
      <BooleanField source="rejectedByCustomer" label="Rejected by Customer" />
      <TextField label="Account Type" source="accountType" />
      <TextField source="trackingData.utmSource" label="UTM Source" />
      <SelectField
        source="applicationStep"
        choices={applicationSteps}
      />
      <SelectField
        source="currentDecisionStatus"
        choices={decisionStatuses}
      />
      <AssignedAdmin
        loading={adminListLoading}
        error={adminListError}
        adminList={adminList}
        source="admin"
        label="Assigned to"
        resource="application"
        minimal
      />
      <EditButton />
    </HeadedDatagrid>
  );
};

ApplicationItem.propTypes = {
  isListPage: PropTypes.bool,
};

ApplicationItem.defaultProps = {
  isListPage: false,
};

export default ApplicationItem;
