/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Grid from '@mui/material/Grid';
import CategorisationTransactions from './CategorisationTransactions';

const styles = {
  table: {
    width: '500px',
    tableLayout: 'fixed',
  },
  descriptionWidth: '50%',
  amountWidth: '35%',
  numberWidth: '15%',
};

const CategorisationDetails = ({ row }) => (
  <Grid
    container
    direction="row"
    justifyContent="space-around"
  >
    <div>
      Positive transactions
      <Table style={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell width={styles.descriptionWidth}>Description</TableCell>
            <TableCell width={styles.amountWidth}>Sum</TableCell>
            <TableCell width={styles.numberWidth}>#</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(row.posDet).map((transactions, index) => (
            <CategorisationTransactions transactions={transactions} key={index} />
          ))}
        </TableBody>
      </Table>
    </div>
    <div>
      Negative transactions
      <Table style={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell width={styles.descriptionWidth}>Description</TableCell>
            <TableCell width={styles.amountWidth}>Sum</TableCell>
            <TableCell width={styles.numberWidth}>#</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(row.negDet).map((transactions, index) => (
            <CategorisationTransactions transactions={transactions} key={index} />
          ))}
        </TableBody>
      </Table>
    </div>
  </Grid>
);

CategorisationDetails.propTypes = {
  row: PropTypes.object.isRequired,
};

export default CategorisationDetails;
