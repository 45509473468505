import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ContentAdd from '@mui/icons-material/Add';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import { useRecordContext } from 'react-admin';

const styles = {
  desktopLink: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
};

const CreateRelatedButton = ({
  source,
  target,
  redirectPath,
  redirectSource,
  relatedPath,
  children,
  className,
  classes = {},
}) => {
  const record = useRecordContext();
  return (
    <Button
      component={Link}
      color="primary"
      to={`${relatedPath}/create?${target}=${record[source]}&redirectPath=/${redirectPath}/${record[redirectSource]}`}
      className={classnames(classes.desktopLink, className)}
    >
      <ContentAdd className={classes.iconPaddingStyle} />
      {children}
    </Button>
  );
};

CreateRelatedButton.propTypes = {
  source: PropTypes.string,
  redirectPath: PropTypes.string,
  redirectSource: PropTypes.string,
  target: PropTypes.string.isRequired,
  relatedPath: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.shape({
    desktopLink: PropTypes.string,
    iconPaddingStyle: PropTypes.string,
  }),
};
CreateRelatedButton.defaultProps = {
  source: 'id',
  redirectPath: 'user',
  redirectSource: 'id',
  classes: {},
  className: undefined,
};

export default withStyles(styles)(CreateRelatedButton);
