import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import useCustomRpc from '../../hooks/useCustomRpc';

const SendSmsButton = ({
  title,
  applicationId,
  userId,
  smsType,
}) => {
  const { mutate: sendSms, isLoading } = useCustomRpc({
    httpMethod: 'POST',
    path: 'user/send-sms',
    successMessage: 'Sms sent successfuly',
    errorMessage: 'Failed to send sms',
  });

  const handleSubmit = useCallback(
    () => sendSms({ applicationId, userId, smsType }),
    [sendSms, applicationId, userId, smsType],
  );

  return (
    <Button
      title={title}
      disabled={isLoading}
      onClick={handleSubmit}
      variant="contained"
      color="secondary"
      size="small"
    >
      {title}
    </Button>
  );
};

SendSmsButton.propTypes = {
  title: PropTypes.string.isRequired,
  applicationId: PropTypes.string,
  userId: PropTypes.string,
  smsType: PropTypes.string.isRequired,
};

SendSmsButton.defaultProps = {
  applicationId: undefined,
  userId: undefined,
};

export default SendSmsButton;
