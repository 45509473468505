import { Labeled } from 'react-admin';
import { styled } from '@mui/material';

const SimpleShowLabeled = styled(Labeled)({
  marginBottom: '1rem',
  '&:last-child': {
    marginBottom: 0,
  },
});

export default SimpleShowLabeled;
