import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';

const styles = {
  alert: { marginRight: '5px', backgroundColor: '#ff0000', color: '#f2f2f2' },
  warning: { marginRight: '5px', backgroundColor: '#e68a00', color: '#f2f2f2' },
  info: { marginRight: '5px', backgroundColor: '#e6e600' },
  good: { marginRight: '5px', backgroundColor: '#2eb82e', color: '#f2f2f2' },
  frozen: { marginRight: '5px', backgroundColor: '#103a87', color: '#f2f2f2' },
  ignore: { marginRight: '5px', backgroundColor: '#cccccc' },
};

const AccountStatusChip = ({ status, passedInvoicesPayed }) => {
  switch (status) {
    case 'cancelled': return <Chip style={styles.info} label="Cancelled" />;
    case 'fraud': return <Chip style={styles.warning} label="Fraud" />;
    case 'sold': return <Chip style={styles.info} label="Sold" />;
    case 'frozen': return <Chip style={styles.frozen} label="Frozen" />;
    default:
      return passedInvoicesPayed
        ? <Chip style={styles.good} label="On time" />
        : <Chip style={styles.alert} label="Late" />;
  }
};

AccountStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  passedInvoicesPayed: PropTypes.bool.isRequired,
};

export default AccountStatusChip;
