import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import get from 'lodash/get';

import { FormControlLabel, FormGroup, Switch } from '@mui/material';

import useCustomRpc from '../../hooks/useCustomRpc';

const WaitingForCustomer = ({ source }) => {
  const record = useRecordContext();
  const { mutate: setWaitingForCustomer } = useCustomRpc({
    httpMethod: 'PATCH',
    path: `application/${record?.id}/waiting-for-customer`,
    successMessage: 'Updated successfully',
    errorMessage: 'Error when updating value',
    shouldRefresh: true,
  });
  const toggleWaitingForCustomer = useCallback(
    (_, value) => {
      setWaitingForCustomer({ isWaitingForCustomer: value });
    },
    [setWaitingForCustomer],
  );
  return (
    <FormGroup>
      <FormControlLabel
        label="Waiting for customer?"
        control={(
          <Switch
            color="secondary"
            checked={get(record, source)}
            onChange={toggleWaitingForCustomer}
          />
        )}
      />
    </FormGroup>
  );
};

WaitingForCustomer.propTypes = {
  source: PropTypes.string.isRequired,
};

export default WaitingForCustomer;
