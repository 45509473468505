import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import OpenInNew from '@mui/icons-material/OpenInNew';

import useCustomRpc from '../../hooks/useCustomRpc';
import onSuccessGetDocument from '../../utils/onSuccessGetDocument';

const OpenInvoice = ({ accountId }) => {
  const record = useRecordContext();

  const {
    _id: invoiceId,
    pdfGeneratedAt,
  } = record ?? {};

  const { mutate: getInvoiceDocument } = useCustomRpc({
    httpMethod: 'GET',
    path: `account/${accountId}/invoices/${invoiceId}/download`,
    errorMessage: 'Error when downloading invoice',
    onSuccess: onSuccessGetDocument,
  });

  const onClick = useCallback(
    () => getInvoiceDocument(),
    [getInvoiceDocument],
  );

  if (!record) return null;
  if (!pdfGeneratedAt) return null;

  return <OpenInNew onClick={onClick} />;
};

OpenInvoice.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default OpenInvoice;
