import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import SelectArrayInput from '../input/SelectArrayInput';
import { adminRoles as adminRolesValidation, email } from '../../utils/validationErrors';
import { useConstantContext } from '../utils/ConstantsContext';

const validateStock = [required()];

const AdminCreate = () => {
  const { choices } = useConstantContext();
  const { adminRoles } = choices;
  return (
    <Create>
      <SimpleForm>
        <TextInput source="firstName" validate={validateStock} />
        <TextInput source="lastName" validate={validateStock} />
        <TextInput source="email" validate={[...validateStock, email]} type="email" />
        <TextInput source="password" validate={validateStock} type="password" />
        <SelectArrayInput label="Roles" source="roles" choices={adminRoles} validate={[adminRolesValidation]} />
      </SimpleForm>
    </Create>
  );
};

export default AdminCreate;
