import React, { useCallback, useMemo } from 'react';

import {
  DateField,
  SimpleForm,
  useEditController,
  EditContextProvider,
  EditView,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import TransactionFields from '../utils/TransactionFields';

import SendPaymentButton from '../utils/SendPaymentButton';
import IdentifyButton from './IdentifyButton';

import useCustomRpc from '../../hooks/useCustomRpc';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const UnidentifiedPaymentEdit = () => {
  const { mutate: repayUnidentifiedPayment } = useCustomRpc({
    httpMethod: 'POST',
    successMessage: 'Payment repaid successfully',
    errorMessage: 'Failed to repay the payment',
    shouldRefresh: true,
  });
  const handleRepay = useCallback((id, paymentDetails) => {
    repayUnidentifiedPayment({
      ...paymentDetails,
      overridePath: `unidentifiedPayments/${id}/repay`,
    });
  }, [repayUnidentifiedPayment]);

  const controllerProps = useEditController({
    redirect: false,
  });

  const { transactions = [] } = controllerProps && controllerProps.record
    ? controllerProps.record : {};
  const isSolved = useMemo(
    () => transactions
      .reduce(
        (balance, { amount }) => balance + amount,
        0,
      ) <= 0,
    [transactions],
  );

  return (
    <EditContextProvider value={controllerProps}>
      <EditView>
        <SimpleForm toolbar={null}>
          {controllerProps.record && !isSolved && (
            <SendPaymentButton dispatchAction={handleRepay} />
          )}
          {controllerProps.record && !isSolved && (
            <IdentifyButton />
          )}
          <PageTitle title="Unidentified payments | Edit" />
          <SimpleShowLabeled>
            <DateField source="createdAt" />
          </SimpleShowLabeled>
          <SimpleShowLabeled>
            <TransactionFields label="transactions" />
          </SimpleShowLabeled>
        </SimpleForm>
      </EditView>
    </EditContextProvider>
  );
};

export default UnidentifiedPaymentEdit;
