import React from 'react';
import {
  Edit,
  TabbedForm,
  TextInput,
  TextField,
  DateField,
  ArrayInput,
  FileInput,
  FileField,
  FormTab,
  FunctionField,
  Datagrid,
  NumberField,
  SelectField,
  SimpleFormIterator,
  BooleanInput,
  ReferenceInput,
  ReferenceField,
  ReferenceManyField,
  Pagination,
  EditButton,
  BooleanField,
  ShowButton,
  useRecordContext,
} from 'react-admin';

import Tooltip from '@mui/material/Tooltip';
import Info from '@mui/icons-material/Info';

import UcCompanyInfoFormTab from '../uc/UcCompanyInfoFormTab';
import AccountStatusChip from '../accounts/AccountStatusChip';
import DocumentsField from '../utils/DocumentsField';
import CreateRelatedButton from '../utils/CreateRelatedButton';
import PhoneNumberInput from '../utils/PhoneNumberInput';
import PageTitle from '../utils/PageTitle';
import AssignedAdmin from '../utils/AssignedAdmin';

import validateBankDetailsFactory from '../utils/validateBankDetails';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import MoneyField from '../utils/MoneyField';
import HolderAccountList from '../utils/HolderAccountList';
import AnnualTaxReportDownloadButton from '../utils/AnnualTaxReportDownloadButton';

import { email } from '../../utils/validationErrors';
import cleanNumberSearch from '../../utils/cleanNumberSearch';
import useGetAdminList from '../../utils/useGetAdminList';

import AMLChip from '../utils/AMLChip';
import { useConstantContext } from '../utils/ConstantsContext';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import SelectInput from '../input/SelectInput';
import AutocompleteInput from '../input/AutocompleteInput';

const OrganisationTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <>
      <span style={{ margin: '0 1rem' }}>Organisation {record?.id}</span>
      <AMLChip amlRiskLevel={record.amlRiskLevel} />
    </>
  );
};

const userRenderer = (choice) => (choice
  ? `${choice.socialSecurityNumber} - ${choice.firstName || ''} ${choice.lastName || ''}`
  : '');

const filterToQueryUser = (searchText) => ({ socialSecurityNumber: cleanNumberSearch(searchText) });
const checkSuggestionUser = (filter, choice) => choice.socialSecurityNumber
  .includes(cleanNumberSearch(filter));

const OrganisationEdit = () => {
  const { choices, constants } = useConstantContext();
  const {
    industries,
    amlRiskLevels,
    banks,
    bankAccountTypes,
    applicationSteps,
    decisionStatuses,
  } = choices || {};
  const validateBankDetails = validateBankDetailsFactory(constants.bankAccountTypes);

  const { data: adminList, error: adminListError, isLoading: adminListLoading } = useGetAdminList();

  return (
    <Edit
      title={<OrganisationTitle />}
      mutationMode="pessimistic"
      redirect={false}
    >
      <TabbedForm validate={validateBankDetails}>
        <FormTab label="Organisation details">
          <PageTitle title="Organisation | Edit" />
          <SimpleShowLabeled>
            <OrganisationNumberField source="organisationNumber" label="Organisation Number" />
          </SimpleShowLabeled>
          <TextInput source="name" label="Name" />
          <TextInput source="corporateCompany" label="Corporate company" />
          <SelectInput source="industry" choices={industries} />
          <TextInput source="email" label="Email" validate={[email]} />
          <PhoneNumberInput source="phone" label="Phone" />
          <TextInput source="streetAddress" label="Street" />
          <TextInput source="postcode" label="Postal code" />
          <TextInput source="town" label="City" />
          <BooleanInput source="hasForeignTaxObligation" />
          <ArrayInput
            source="payoutSummaryReportRecipients"
            label="Payout&nbsp;summary&nbsp;report&nbsp;recipients"
            helperText="Sends out the Payout summary report to the listed email(s) above"
          >
            <SimpleFormIterator>
              <TextInput
                label="Email"
                validate={email}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="bankDetails.bankAccounts">
            <SimpleFormIterator>
              <SelectInput
                source="bankAccountType"
                label="Bank&nbsp;Account&nbsp;Type"
                choices={bankAccountTypes}
              />
              <TextInput
                source="clearingNumber"
                label="Clearing&nbsp;number"
              />
              <TextInput
                source="accountNumber"
                label="Account&nbsp;number"
              />
              <SelectInput
                source="bank"
                label="Bank"
                choices={banks}
              />
              <BooleanInput
                source="isOwnershipVerified"
                label="Is ownership verified"
              />
            </SimpleFormIterator>
          </ArrayInput>
          <ReferenceInput
            label="Representative"
            source="representative.user"
            reference="user"
          >
            <AutocompleteInput
              filterToQuery={filterToQueryUser}
              optionText={userRenderer}
              matchSuggestion={checkSuggestionUser}
            />
          </ReferenceInput>
          <TextInput source="representative.role" label="Representative's role" />
          <ArrayInput source="beneficialOwners" style={{ width: '80%' }}>
            <SimpleFormIterator>
              <ReferenceInput
                source="user"
                reference="user"
                label="Beneficial Owner"
              >
                <AutocompleteInput
                  filterToQuery={filterToQueryUser}
                  optionText={userRenderer}
                  matchSuggestion={checkSuggestionUser}
                />
              </ReferenceInput>
              <TextInput source="role" label="Role" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="signatoriesCombinations" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <ArrayInput source="signatories" label="Signatories">
                <SimpleFormIterator>
                  <ReferenceInput
                    source="user"
                    reference="user"
                    label="Signatory User"
                  >
                    <AutocompleteInput
                      filterToQuery={filterToQueryUser}
                      optionText={userRenderer}
                      matchSuggestion={checkSuggestionUser}
                    />
                  </ReferenceInput>
                  <TextInput source="email" label="Email" validate={[email]} />
                  <PhoneNumberInput source="phone" label="Phone" />
                </SimpleFormIterator>
              </ArrayInput>
              <BooleanInput source="isAdminSign" label="Is Admin Sign" />
            </SimpleFormIterator>
          </ArrayInput>
          <SimpleShowLabeled>
            <SelectField
              source="amlRiskLevel"
              label="AML Risk Level"
              choices={amlRiskLevels}
            />
          </SimpleShowLabeled>
          <AnnualTaxReportDownloadButton />
          <DocumentsField source="id" model="organisation" />
          <FileInput source="uploadedDocuments" label="Upload documents" multiple>
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
        <FormTab label="Accounts">
          <HolderAccountList>
            <TextField source="id" />
            <ReferenceField label="Public id" source="id" reference="account">
              <TextField source="publicId" />
            </ReferenceField>
            <TextField source="product" />
            <TextField source="accountType" />
            <MoneyField source="remainingCapitalAmount" align="right" />
            <MoneyField source="todaySolvingAmount" align="right" />
            <NumberField source="currentTerms.nominalRate" label="Nominal rate" locales="sv" options={{ maximumFractionDigits: 2 }} />
            <DateField source="createdAt" />
            <FunctionField label="Status" render={(record) => <AccountStatusChip status={record.status} passedInvoicesPayed={record.passedInvoicesPayed} />} />
            <TextField source="ocrNumber" />
          </HolderAccountList>
        </FormTab>
        <FormTab label="applications">
          <ReferenceManyField
            label="Applications"
            reference="application"
            target="applicants._id"
            pagination={<Pagination />}
          >
            <Datagrid bulkActionButtons={null}>
              <TextField source="id" />
              <DateField source="createdAt" />
              <TextField source="trackingData.utmSource" label="UTM Source" />
              <TextField source="accountType" />
              <BooleanField source="rejectedByCustomer" />
              <SelectField
                source="applicationStep"
                choices={applicationSteps}
              />
              <SelectField
                source="currentDecisionStatus"
                choices={decisionStatuses}
              />
              <TextField source="loanTerms.nominalRate" label="Nominal rate" />
              <AssignedAdmin
                loading={adminListLoading}
                error={adminListError}
                adminList={adminList}
                source="admin"
                label="Assigned to"
                resource="application"
                minimal
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Risk Reports">
          <ReferenceManyField
            label="Risk reports"
            reference="riskReport"
            target="accountHolder._id"
            fullWidth
            pagination={<Pagination />}
          >
            <Datagrid bulkActionButtons={null}>
              <TextField source="id" />
              <DateField source="createdAt" />
              <ReferenceField label="User" source="accountHolder._id" reference="organisation">
                <OrganisationNumberField source="organisationNumber" label="Organisation Number" />
              </ReferenceField>
              <TextField source="rule" />
              <TextField source="status" />
              <FunctionField label="Reason" render={(record) => <Tooltip title={record.reason} placement="top"><Info /></Tooltip>} />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Comments">
          <ReferenceManyField
            label="Comments"
            reference="comment"
            target="organisation"
            pagination={<Pagination />}
          >
            <Datagrid bulkActionButtons={null}>
              <DateField source="createdAt" label="Date" showTime />
              <TextField source="message" />
              <ReferenceField source="author" reference="admins">
                <TextField source="firstName" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
          <CreateRelatedButton
            target="organisation"
            relatedPath="/comment"
            redirectPath="organisation"
          >
            Add Comment
          </CreateRelatedButton>
        </FormTab>
        <UcCompanyInfoFormTab />
        <FormTab label="Trapets">
          <ReferenceManyField
            label="Trapets company checks"
            reference="trapetsCompanyChecks"
            target="organisation"
            fullWidth
            pagination={<Pagination />}
          >
            <Datagrid bulkActionButtons={null}>
              <TextField source="id" />
              <DateField source="createdAt" label="Date" />
              <FunctionField render={({ services }) => ((services || []).join(', ')).toUpperCase()} label="Services" />
              <ReferenceField label="Organisation number" source="organisation" reference="organisation">
                <TextField source="organisationNumber" />
              </ReferenceField>
              <FunctionField render={({ possibleMatches }) => (possibleMatches || []).length} label="Possible Matches" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default OrganisationEdit;
