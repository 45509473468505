import React, { useCallback } from 'react';
import { useListContext } from 'react-admin';
import Typography from '@mui/material/Typography';

import SelectField from '../utils/SelectField';
import ModalButton from '../utils/ModalButton';
import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

import validators from '../../utils/validators';

import useInputStatus from '../hooks/useInputStatus';

const { isDefined } = validators.generics;

const AccountCustomAction = () => {
  const [
    pledgee,
    setPledgee,
    onChangePledgee,
    pledgeeError,
    setPledgeeError,
  ] = useInputStatus();
  const { choices } = useConstantContext();
  const { pledgees } = choices;

  const { selectedIds } = useListContext();

  const { mutate: pledgedTo } = useCustomRpc({
    httpMethod: 'PUT',
    path: 'account/pledge',
    successMessage: 'Account pledged',
    errorMessage: 'Account failed to be pledged',
    shouldRefresh: true,
    shouldUnselectAll: true,
  });

  const pledge = useCallback(
    () => {
      if (!isDefined(pledgee)) {
        setPledgeeError('Please select a pledgee');
        return false;
      }

      pledgedTo({ accountIds: selectedIds, pledgeeId: pledgee });
      return true;
    },
    [selectedIds, pledgee, setPledgeeError, pledgedTo],
  );

  const onClose = useCallback(() => {
    setPledgee('');
    setPledgeeError('');
  }, [setPledgee, setPledgeeError]);

  return (
    <ModalButton
      title="Pledge to an organisation"
      onClick={pledge}
      onClose={onClose}
    >
      <Typography align="center">Enter the Organisation social security number</Typography>
      <SelectField
        label="Pledgee"
        onChange={onChangePledgee}
        value={pledgee}
        error={pledgeeError}
        choices={pledgees}
      />
    </ModalButton>
  );
};

export default AccountCustomAction;
