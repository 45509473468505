import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';

import useCustomRpc from '../../hooks/useCustomRpc';

const ResetQueuePosition = () => {
  const record = useRecordContext();

  const { mutate: resetQueuePosition, isLoading } = useCustomRpc({
    httpMethod: 'POST',
    path: `application/${record.id}/reset-queue-position`,
    successMessage: 'Updated successfully',
    errorMessage: 'Error when updating value',
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    resetQueuePosition();
  }, [resetQueuePosition]);

  return (
    <Button
      onClick={handleSubmit}
      disabled={!!isLoading}
      variant="contained"
      color="secondary"
      size="small"
    >
      Reset queue position
    </Button>
  );
};

export default ResetQueuePosition;
