import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRefresh } from 'react-admin';

const AutoRefresh = ({ seconds }) => {
  const refresh = useRefresh();
  useEffect(() => {
    const interval = setInterval(
      () => refresh(),
      seconds * 1000,
    );
    return () => clearInterval(interval);
  }, [refresh, seconds]);

  return null;
};

AutoRefresh.propTypes = {
  seconds: PropTypes.number,
};

AutoRefresh.defaultProps = {
  seconds: 60,
};

export default AutoRefresh;
