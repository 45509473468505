import React from 'react';
import {
  List,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin';
import PageTitle from '../utils/PageTitle';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import HeadedDatagrid from '../utils/HeadedDatagrid';

const organisationFilters = [
  <TextInput source="_id" label="Organisation ID" alwaysOn />,
  <TextInput source="name" alwaysOn />,
  <TextInput source="organisationNumber" alwaysOn />,
  <TextInput source="email" alwaysOn />,
];

const OrganisationList = () => (
  <List filters={organisationFilters}>
    <HeadedDatagrid head={(<PageTitle title="Lender | List" />)}>
      <PageTitle title="Organisation | List" />
      <TextField source="id" />
      <TextField source="name" />
      <OrganisationNumberField source="organisationNumber" />
      <EditButton />
    </HeadedDatagrid>
  </List>
);

export default OrganisationList;
