import React from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import SendSmsButton from './SendSmsButton';
import { useConstantContext } from './ConstantsContext';

const SendSignSmsButton = ({
  application,
}) => {
  const applicantRecord = useRecordContext();
  const { constants } = useConstantContext();
  const { smsTypes, applicationSteps } = constants;

  const isSignable = !application.rejectedByCustomer
    && application.currentDecisionStatus === 'grant'
    && application.applicationStep === applicationSteps.AGREEMENT_SENT;

  if (!isSignable) return null;
  return (
    <SendSmsButton
      title="Send&nbsp;sign&nbsp;sms"
      applicationId={application.id}
      userId={applicantRecord.id}
      smsType={smsTypes.SIGN_APPLICATION}
    />
  );
};

SendSignSmsButton.propTypes = {
  application: PropTypes.object.isRequired,
};

export default SendSignSmsButton;
