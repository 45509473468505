import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Typography from '@mui/material/Typography';
import {
  ArrayInput,
  minValue,
  NumberInput,
  required,
  SimpleFormIterator,
  useNotify,
  useRecordContext,
} from 'react-admin';
import ModalButton from '../utils/ModalButton';

import validators from '../../utils/validators';
import useCustomRpc from '../../hooks/useCustomRpc';
import IdInput from '../input/IdInput';

const { isDefined, isStrictlyPositiveNumber } = validators.generics;

const IdentifyButton = () => {
  const record = useRecordContext();

  const { id, transactions } = record;
  const unidentifiedAmount = transactions && transactions.length > 0
    ? transactions[0].amount
    : 0;
  const { getValues } = useFormContext();

  const notify = useNotify();

  const { mutate: identifyUnidentifiedPayment } = useCustomRpc({
    httpMethod: 'POST',
    path: `unidentifiedPayments/${id}/identify`,
    successMessage: 'Payment identified',
    errorMessage: 'Failed to identify the payment',
    shouldRefresh: true,
    onSuccess: (data) => {
      const { notification } = data;
      if (notification) notify(notification, { type: 'warning' });
      else notify('Successfully identified the transaction', { type: 'info' });
    },
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [amountLeftToAssign, setAmountLeftToAssign] = useState(unidentifiedAmount);

  const getAmountAssigned = useCallback(() => {
    const payments = getValues().paymentArray;

    const totalAssigned = payments
      .filter((payment) => payment && isDefined(payment.amount))
      .reduce((res, { amount }) => res + amount, 0);
    return Math.round(totalAssigned * 100, 2) / 100;
  }, [getValues]);

  const updateAmountLeft = useCallback(() => {
    setTimeout(() => {
      const totalAssigned = getAmountAssigned() || 0;
      setAmountLeftToAssign(Math.round((unidentifiedAmount - totalAssigned) * 100, 2) / 100);
    }, 1);
  }, [unidentifiedAmount, getAmountAssigned]);

  const onSubmitIdentify = useCallback(() => {
    const payments = getValues().paymentArray;
    if (!payments || payments.length === 0 || payments.some((payment) => !isDefined(payment))) {
      setErrorMessage('Some elements in the list are not defined');
      return false;
    }
    updateAmountLeft();

    if (payments.some((payment) => !isStrictlyPositiveNumber(payment.amount))) {
      setErrorMessage('Amount cannot be < or = 0.');
      return false;
    }
    if (payments.some((payment) => !payment.accountId)) {
      setErrorMessage('Must fill in all account Ids.');
      return false;
    }

    const totalAssigned = getAmountAssigned();
    if (totalAssigned !== unidentifiedAmount) {
      setErrorMessage('The whole amount must be identified.');
      return false;
    }

    identifyUnidentifiedPayment({ payments });
    setErrorMessage('');
    return true;
  }, [getValues, updateAmountLeft, getAmountAssigned, unidentifiedAmount,
    identifyUnidentifiedPayment]);

  const onClose = useCallback(() => {
    setErrorMessage('');
  }, []);

  return (
    <ModalButton
      title="Identify"
      onClick={onSubmitIdentify}
      onClose={onClose}
      fullWidth
      right
      confirmationMessage="Are you sure all fees that need to be reverted have been reverted?"
    >
      <Typography align="center">
        Total Amount: {unidentifiedAmount}&nbsp;
      </Typography>
      <Typography align="center">
        Left to assign: {amountLeftToAssign}
      </Typography>
      <Typography color="error">{errorMessage}</Typography>
      <ArrayInput
        source="paymentArray"
      >
        <SimpleFormIterator inline>
          <IdInput
            label="Enter the account id"
            source="accountId"
            validate={required('Account id is required.')}
            onChange={updateAmountLeft}
          />
          <NumberInput
            label="Enter the amount"
            source="amount"
            validate={[required('Amount is required'), minValue(1)]}
            onChange={updateAmountLeft}
            defaultValue={amountLeftToAssign}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <Typography color="error">Please make sure you have reverted ALL the fees you need to revert before identifying payment!</Typography>
    </ModalButton>
  );
};

export default IdentifyButton;
