import React from 'react';

import {
  ArrayInput,
  Create,
  FormDataConsumer,
  NumberInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import UserFields from './UserFields';
import OrganisationFields from './OrganisationFields';
import SelectInput from '../input/SelectInput';
import NullableBooleanInput from '../input/NullableBooleanInput';

import {
  canBeProfiled,
  isBorrowingForOwnUse,
} from '../../utils/validationErrors';
import { numberFormat } from '../../utils/formatters';
import getAnnuityAmount from '../../utils/getAnnuityAmount';

import {
  isAnnuityInvoicingSelector,
  isInterestInvoicingSelector,
} from '../../utils/accountFeatures';
import { useConstantContext } from '../utils/ConstantsContext';

const validateForm = (checks) => (values) => {
  if (checks.some((check) => check(values.accountType)) && !values.repaymentTime) {
    return { repaymentTime: 'Repayment time required' };
  }
  return {};
};

const ApplicationCreate = () => {
  const { constants, choices, accountFeatures } = useConstantContext();
  const { holderTypes } = constants;
  const isAnnuityInvoicing = isAnnuityInvoicingSelector(accountFeatures);
  const isInterestInvoicing = isInterestInvoicingSelector(accountFeatures);
  return (
    <Create>
      <SimpleForm validate={validateForm([isAnnuityInvoicing, isInterestInvoicing])}>
        <SelectInput
          source="accountType"
          label="Account Type"
          choices={choices.activeTypes}
          validate={required('AccountType is required')}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <Grid
              container
              style={{ width: '80rem' }}
              spacing={1}
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={2}>
                <NumberInput
                  source="amount"
                  label="Requested amount"
                  validate={required('The amount is required')}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="repaymentTime"
                  label="Repayment Time"
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="nominalRate"
                  label="Expected Nominal Rate"
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="startupFee"
                  label="Expected Startup Fee"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled
                  value={
                    (formData.amount && formData.repaymentTime && formData.nominalRate
                      && `${numberFormat(getAnnuityAmount(
                        formData.amount,
                        formData.repaymentTime,
                        formData.nominalRate,
                        formData.startupFee,
                      ))}`)
                    || ''
                  }
                  label="Annuity Expected"
                />
              </Grid>
            </Grid>
          )}
        </FormDataConsumer>
        <TextInput
          source="trackingData.utmSource"
          label="UTM Source"
          validate={required()}
        />
        <TextInput
          source="trackingData.utmMedium"
          label="UTM Medium"
          validate={required()}
        />
        <ArrayInput
          source="applicants"
          validate={required('You need to add at least 1 applicant')}
        >
          <SimpleFormIterator>
            <SelectInput
              choices={choices.holderTypes}
              defaultValue="User"
              label="Holder Type"
              source="holderType"
            />
            <FormDataConsumer>
              {({
                scopedFormData,
                getSource,
              }) => scopedFormData && (
                (scopedFormData.holderType === holderTypes.USER) ? (
                  <UserFields
                    getSource={getSource}
                  />
                ) : (
                  <OrganisationFields
                    getSource={getSource}
                  />
                )
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>

        <SelectInput
          source="reasonForLoan"
          label="Reason for loan"
          choices={choices.reasonsForLoan}
        />
        <NullableBooleanInput
          source="canBeProfiled"
          label="Accept profiling"
          validate={canBeProfiled}
        />
        <NullableBooleanInput
          source="isBorrowingForOwnUse"
          label="Borrowing for own use"
          validate={isBorrowingForOwnUse}
        />
      </SimpleForm>
    </Create>
  );
};

export default ApplicationCreate;
