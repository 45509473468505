import React from 'react';
import {
  Create,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  TextInput,
  NumberInput,
  required,
} from 'react-admin';

import { utmSource } from '../../utils/validationErrors';
import cleanNumberSearch from '../../utils/cleanNumberSearch';
import { useConstantContext } from '../utils/ConstantsContext';

import SelectInput from '../input/SelectInput';
import AutocompleteInput from '../input/AutocompleteInput';

const validateStock = [required()];

const checkSuggestionOrganisation = (filter, choice) => choice.organisationNumber
  .includes(cleanNumberSearch(filter));
const filterToQueryOrganisation = (searchText) => ({
  organisationNumber: cleanNumberSearch(searchText),
});

const PartnerCreate = () => {
  const { choices } = useConstantContext();
  const { paymentReferenceTypes, integrationTypes } = choices;

  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" validate={validateStock} />
        <TextInput source="username" validate={validateStock} />
        <TextInput source="password" validate={validateStock} type="password" />
        <TextInput source="utmSource" validate={[...validateStock, utmSource]} />
        <BooleanInput source="isPayedThroughOrganisation" />
        <ReferenceInput
          label="Organisation"
          source="organisation"
          reference="organisation"
        >
          <AutocompleteInput
            optionText="name"
            matchSuggestion={checkSuggestionOrganisation}
            filterToQuery={filterToQueryOrganisation}
          />
        </ReferenceInput>
        <SelectInput
          source="paymentReferenceType"
          label="Payment reference type"
          choices={paymentReferenceTypes}
          validate={validateStock}
        />
        <SelectInput
          source="integrationType"
          label="Integration Type"
          choices={integrationTypes}
          validate={validateStock}
        />
        <NumberInput source="skipBankDetailsLimit" validate={validateStock} />
        <BooleanInput source="shouldNotifyNewAppllications" />
        <BooleanInput source="shouldWaitForUseLoanPromise" />
      </SimpleForm>
    </Create>
  );
};

export default PartnerCreate;
