import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

const styles = {
  container: {
    border: '2px solid red',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    '&>*': {
      margin: '0.3rem',
    },
  },
};

const WarningMessage = ({ children, classes }) => (
  <div className={classes.container}>
    <Typography variant="h4" align="center" color="error">Warning</Typography>
    {children}
  </div>
);

WarningMessage.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WarningMessage);
