import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import get from 'lodash/get';

const validStyle = {
  color: 'green',
  fontSize: '1.4rem',
  marginLeft: '1rem',
};

const unvalidStyle = {
  color: 'red',
  fontSize: '0.9rem',
  marginLeft: '1rem',
};

const pendingStyle = {
  color: 'grey',
  fontSize: '1rem',
  marginLeft: '1rem',
};

const CheckField = ({
  source,
  value,
  required,
}) => {
  const record = useRecordContext();
  const defined = get(record, source, value);
  if (defined) return <span style={validStyle}>{'\u2714'}</span>;
  if (required) return <span style={unvalidStyle}>{'\u274C'}</span>;
  return <span style={pendingStyle}>. . .</span>;
};

CheckField.propTypes = {
  source: PropTypes.string,
  value: PropTypes.bool,
  required: PropTypes.bool,
};

CheckField.defaultProps = {
  value: false,
  source: null,
  required: false,
};

export default CheckField;
