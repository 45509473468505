import React from 'react';
import PropTypes from 'prop-types';

import SendSmsButton from './SendSmsButton';
import { useConstantContext } from './ConstantsContext';

const SendBankDataSmsButton = ({
  userId,
}) => {
  const { constants } = useConstantContext();
  const { smsTypes } = constants;
  return (
    <SendSmsButton
      title="Send bank data link sms"
      userId={userId}
      smsType={smsTypes.COLLECT_BANK_DATA}
    />
  );
};

SendBankDataSmsButton.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default SendBankDataSmsButton;
