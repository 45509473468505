import React from 'react';
import {
  FormTab,
  ReferenceManyField,
  Pagination,
} from 'react-admin';

import TakeUcCompanyButton from './TakeUcCompanyButton';
import UcCompanyFieldsPreview from './UcCompanyFieldsInfoPreview';

const UcCompanyInfoFormTab = (props) => (
  <FormTab label="UC" {...props}>
    <TakeUcCompanyButton />
    <ReferenceManyField
      label="Uc company checks"
      reference="ucCompanyChecks"
      target="organisation"
      fullWidth
      pagination={<Pagination />}
    >
      <UcCompanyFieldsPreview />
    </ReferenceManyField>
  </FormTab>
);

export default UcCompanyInfoFormTab;
