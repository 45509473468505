import React from 'react';
import {
  TextInput,
} from 'react-admin';

import SelectInput from '../input/SelectInput';
import { useConstantContext } from '../utils/ConstantsContext';

const useRiskReportFilters = () => {
  const { choices } = useConstantContext();
  const { amlStatuses, amlRuleTypes } = choices;

  return [
    <TextInput label="Riskreport ID" source="_id" alwaysOn />,
    <TextInput label="Admin ID" source="admin" alwaysOn />,
    <TextInput label="User ID" source="accountHolder._id" alwaysOn />,
    <TextInput label="Reason" source="reason" alwaysOn />,
    <SelectInput
      alwaysOn
      label="Rule"
      source="rule"
      choices={amlRuleTypes}
    />,
    <SelectInput
      alwaysOn
      label="Status"
      source="status"
      choices={amlStatuses}
    />,
    <SelectInput
      label="Is Reported"
      source="custom_reportedAt"
      alwaysOn
      choices={[
        { id: true, name: 'True' },
        { id: false, name: 'False' },
      ]}
    />,
    <SelectInput
      label="Active"
      source="custom_userActive"
      alwaysOn
      choices={[
        { id: true, name: 'True' },
        { id: false, name: 'False' },
      ]}
    />,
    <SelectInput
      label="Ignore Reporting"
      source="custom_ignoreReporting"
      alwaysOn
      choices={[
        { id: true, name: 'True' },
        { id: false, name: 'False' },
      ]}
    />,
  ];
};

export default useRiskReportFilters;
