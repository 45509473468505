import React from 'react';

import Typography from '@mui/material/Typography';

import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';

import MoneyField from './MoneyField';
import { useConstantContext } from './ConstantsContext';
import SimpleShowLabeled from './SimpleShowLabeled';

const DrawdownTerms = () => {
  const record = useRecordContext();
  const { accountFeatures } = useConstantContext();
  const { drawdown } = accountFeatures;

  const { accountType, terms } = record;

  if (!drawdown.includes(accountType)) return null;

  return (
    <div style={{ margin: '1rem 0' }}>
      {terms.drawdown.termination.date && (
        <>
          <Typography variant="subtitle1">Drawdown period ended:</Typography>
          <SimpleShowLabeled>
            <DateField source="terms.drawdown.termination.date" label="Date" showTime />
          </SimpleShowLabeled>
          <SimpleShowLabeled>
            <ReferenceField
              label="Admin"
              source="terms.drawdown.termination.adminId"
              reference="admins"
            >
              <TextField source="firstName" />
            </ReferenceField>
          </SimpleShowLabeled>
        </>
      )}
      <Typography variant="body2">Drawdown requests:</Typography>
      <SimpleShowLabeled>
        <ArrayField
          label="Drawdown requests"
          source="terms.drawdown.requests"
        >
          <Datagrid bulkActionButtons={null}>
            <DateField source="date" label="Date" />
            <MoneyField source="amount" />
            <ReferenceField
              label="Admin"
              source="adminId"
              reference="admins"
            >
              <TextField source="firstName" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeled>
      <Typography variant="body2">Max drawdown amount: {terms.amount}</Typography>
    </div>
  );
};

export default DrawdownTerms;
