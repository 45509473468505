import React, { useCallback } from 'react';
import { usePermissions, useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const MFA = () => {
  const { constants } = useConstantContext();
  const { userRoles } = constants;
  const record = useRecordContext() || {};
  const { permissions } = usePermissions();

  const { mutate: resetMFA } = useCustomRpc({
    httpMethod: 'PUT',
    path: `admins/${record.id}/reset-mfa`,
    successMessage: 'Successfully resetted MFA',
    errorMessage: 'Failed resetting MFA',
    shouldRefresh: true,
  });

  const onClick = useCallback(() => {
    resetMFA();
  }, [resetMFA]);

  if (!record
    || !permissions
    || !permissions.includes(userRoles.ROLE_SUPER_ADMIN)) return null;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: '1.5rem' }}>
        <Typography variant="caption">Has MFA</Typography>
        {record.hasMfa
          ? <CheckIcon />
          : <CloseIcon />}
      </div>
      <Button
        title="Reset MFA"
        disabled={!record.hasMfa}
        onClick={onClick}
        variant="contained"
        color="primary"
        size="small"
      >
        Reset MFA
      </Button>
    </div>
  );
};

export default MFA;
