import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import { isBeforeFactory } from '../../utils/signup/compareApplicationSteps';
import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const SendSmsButton = () => {
  const record = useRecordContext();
  const { applicationStep } = record;

  const { constants } = useConstantContext();
  const { applicationSteps, orderedApplicationSteps } = constants;

  const isBefore = isBeforeFactory(orderedApplicationSteps);

  const { mutate: sendSignupText, isLoading } = useCustomRpc({
    httpMethod: 'GET',
    path: `application/sendSignupText/${record.id}`,
    successMessage: 'Sms sent',
    errorMessage: 'Sms sending failed',
  });

  const handleSubmit = useCallback(() => {
    sendSignupText();
  }, [sendSignupText]);

  if (isBefore(applicationStep, applicationSteps.INFO_COLLECTED)
    || applicationStep === applicationSteps.AGREEMENT_SENT) {
    const label = applicationStep === applicationSteps.AGREEMENT_SENT
      ? 'Send sign link sms'
      : 'Send complete link sms';

    return (
      <Button
        title={label}
        disabled={isLoading}
        onClick={handleSubmit}
        variant="contained"
        color="secondary"
        size="small"
      >
        {label}
      </Button>
    );
  }

  return null;
};

export default SendSmsButton;
