import React, { useCallback, useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';

import validators from '../../utils/validators';
import { numberParser } from '../../utils/formatters';

import useInputStatus from '../hooks/useInputStatus';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined, isStrictlyPositiveNumber } = validators.generics;

const PaybackButton = ({ source }) => {
  const account = useRecordContext();
  const { status, debtBreakdown } = account;
  const id = get(account, source);

  const { constants } = useConstantContext();
  const { accountStatuses } = constants;

  const { mutate: payback } = useCustomRpc({
    httpMethod: 'PUT',
    path: `account/${id}/payback`,
    successMessage: 'Payback registered',
    errorMessage: 'Failed to pay back',
    shouldRefresh: true,
  });

  const leftToPay = debtBreakdown.principal + debtBreakdown.interest + debtBreakdown.fee;

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus();

  const [
    amount,
    setAmount,
    onChangeAmount,
    amountError,
    setAmountError,
  ] = useInputStatus(0, numberParser);
  const bankDetailsError = useMemo(() => (account.bankAccountIdPayout
    ? ''
    : 'Missing bank account for payout'), [account.bankAccountIdPayout]);

  const onClick = useCallback(() => {
    if (bankDetailsError) return false;

    if (!isStrictlyPositiveNumber(amount)) {
      setAmountError('Please add a valid amount');
      return false;
    }
    if (!isDefined(comment)) {
      setCommentError('Please add a valid comment');
      return false;
    }
    payback({ amount, comment });
    return true;
  }, [bankDetailsError, amount, comment, payback, setAmountError, setCommentError]);

  const onClose = useCallback(() => {
    setAmount('');
    setComment('');
  }, [setAmount, setComment]);

  if (!account) return null;
  if (
    status !== accountStatuses.ACTIVE
    && (status !== accountStatuses.CANCELLED || leftToPay >= 0)
    && status !== accountStatuses.FRAUD
  ) return null;

  return (
    <ModalButton
      title="Payback"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <Typography color="error">{bankDetailsError}</Typography>
      <TextField
        type="number"
        label="Select the amount"
        onChange={onChangeAmount}
        value={amount}
        error={!!amountError}
        helperText={amountError}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Leave a comment"
        multiline
        maxRows="4"
        value={comment}
        onChange={onChangeComment}
        error={!!commentError}
        helperText={commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

PaybackButton.propTypes = {
  source: PropTypes.string.isRequired,
};

export default PaybackButton;
