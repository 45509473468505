import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import useCustomRpc from '../../hooks/useCustomRpc';
import onSuccessGetDocument from '../../utils/onSuccessGetDocument';

const styles = {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'inherit',
    height: 'inherit',
  },
  downloadButton: {
    margin: '0.5rem',
  },
};

const OpenCustomerAgreement = () => {
  const record = useRecordContext();

  const { mutate: getCustomerAgreement } = useCustomRpc({
    httpMethod: 'GET',
    path: `account/${record?.id}/download`,
    errorMessage: 'Error when downloading agreement',
    onSuccess: onSuccessGetDocument,
  });

  const onClick = useCallback(() => getCustomerAgreement(), [getCustomerAgreement]);

  return (
    <div style={styles.buttonWrapper}>
      <Button
        style={styles.downloadButton}
        size="small"
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        Download Agreement
      </Button>
    </div>
  );
};

export default OpenCustomerAgreement;
