import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';

import { Button } from '@mui/material';

import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const ForceMovePayment = ({ account }) => {
  const { constants } = useConstantContext();
  const record = useRecordContext();
  const { accountTypes, transactionTypes, userRoles: { ROLE_SUPER_ADMIN } } = constants;

  const { mutate: forceMovePayment } = useCustomRpc({
    httpMethod: 'POST',
    path: `heldPayments/${account.id}/transactions/${record.id}/force-move`,
    successMessage: 'Payment successfully moved',
    errorMessage: 'Failed to move payment',
    shouldRefresh: true,
  });

  const onClick = useCallback(() => {
    forceMovePayment();
  }, [forceMovePayment]);
  const { permissions } = usePermissions();

  if (
    !permissions
    || !permissions.includes(ROLE_SUPER_ADMIN)
    || record.type !== transactionTypes.USER_PAYMENT
    || record.revertedBy
    || account.accountType !== accountTypes.HELD_PAYMENTS
  ) return null;

  if (!account.id) return null;
  if (!record.id) return null;

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="secondary"
      size="small"
    >
      Force move
    </Button>
  );
};

ForceMovePayment.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountType: PropTypes.string,
  }),
};
ForceMovePayment.defaultProps = {
  account: {},
};

export default ForceMovePayment;
