import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';
import onSuccessGetDocument from '../../utils/onSuccessGetDocument';

const DownloadEARButton = () => {
  const record = useRecordContext();
  const {
    rule,
    status,
    additionalInfo = {},
  } = record;
  const { accountId, extraAmortisationRequestId } = additionalInfo;

  const { constants } = useConstantContext();
  const { amlRuleTypes, amlStatuses } = constants;

  const { mutate: getEARDocument, isLoading } = useCustomRpc({
    httpMethod: 'GET',
    path: `account/${accountId}/extra-amortisation-request/${extraAmortisationRequestId}/download`,
    errorMessage: 'Could not retrieve file',
    onSuccess: onSuccessGetDocument,
  });

  const handleClick = useCallback(() => {
    getEARDocument();
  }, [getEARDocument]);

  if (
    rule !== amlRuleTypes.EXTRA_AMORTISATION
    || status !== amlStatuses.RESOLVED
    || !accountId
    || !extraAmortisationRequestId
  ) return null;

  return (
    <Button
      onClick={handleClick}
      disabled={isLoading}
      variant="contained"
      color="primary"
      size="small"
    >
      Download EAR Document
    </Button>
  );
};

export default DownloadEARButton;
