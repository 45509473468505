import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import { makeStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';

import SendSmsButton from './SendSmsButton';
import SendEmailButton from './SendEmailButton';

import DigitalAgreementLink from '../utils/DigitalAgreementLink';
import WetAgreementLink from '../utils/WetAgreementLink';
import CheckField from '../utils/CheckField';
import CloneButton from '../utils/CloneButton';
import DocumentLink from '../utils/DocumentLink';
import ManualDecision from '../utils/ManualDecision';
import ManuallySigned from '../utils/ManuallySigned';
import ManualProcess from '../utils/ManualProcess';
import MarkRejected from '../utils/MarkRejected';
import MarkAccepted from '../utils/MarkAccepted';
import PayoutButton from '../utils/PayoutButton';
import SourceField from '../utils/SourceField';
import ReviewDecision from '../utils/ReviewDecision';
import WarningMessage from '../utils/WarningMessage';

import getNextAdminAction from '../../utils/applicationConsole/getNextAdminAction';

import { isSameOrAfterFactory } from '../../utils/signup/compareApplicationSteps';
import { useConstantContext } from '../utils/ConstantsContext';

const useStyles = makeStyles(() => ({
  console: {
    margin: '1rem 0',
    padding: '1rem',
    border: '2px solid #2196f3',
    background: '#efefef',
    width: '100%',
  },
  rightAlign: {
    float: 'right',
    margin: '0 5%',
  },
  interSection: {
    margin: '0 5%',
    width: '90%',
    borderTop: '1px dashed grey',
  },
  styledH4: {
    margin: '0.5rem 0',
  },
  styledSection: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-end',
    '&>*': {
      margin: '0.3rem 0.5rem',
    },
  },
}));

const ApplicationConsole = () => {
  const classes = useStyles();
  const record = useRecordContext();

  const {
    currentDecisionStatus,
    applicationStep,
  } = record;

  const {
    constants: {
      decisionStatuses,
      applicationSteps,
      orderedApplicationSteps,
    },
    choices: {
      applicationMainSteps,
    },
  } = useConstantContext();

  const isDecisionTaken = useCallback(
    () => currentDecisionStatus === decisionStatuses.GRANT,
    [currentDecisionStatus, decisionStatuses.GRANT],
  );

  const isAgreementSent = useCallback(
    () => isSameOrAfterFactory(orderedApplicationSteps)(
      applicationStep,
      applicationSteps.AGREEMENT_SENT,
    ),
    [orderedApplicationSteps, applicationStep, applicationSteps.AGREEMENT_SENT],
  );

  const isAgreementSigned = useCallback(
    () => isSameOrAfterFactory(orderedApplicationSteps)(
      applicationStep,
      applicationSteps.SIGNED,
    ),
    [orderedApplicationSteps, applicationStep, applicationSteps.SIGNED],
  );

  const isPaymentDetailsVerified = useCallback(
    () => isSameOrAfterFactory(orderedApplicationSteps)(
      applicationStep,
      applicationSteps.PAYMENT_DETAILS_VERIFIED,
    ),
    [orderedApplicationSteps, applicationStep, applicationSteps.PAYMENT_DETAILS_VERIFIED],
  );

  const areFinalAMLChecksOK = useCallback(
    () => isSameOrAfterFactory(orderedApplicationSteps)(
      applicationStep,
      applicationSteps.FINAL_AML_CHECKS_OK,
    ),
    [orderedApplicationSteps, applicationStep, applicationSteps.FINAL_AML_CHECKS_OK],
  );

  const isPayedOut = useCallback(
    () => isSameOrAfterFactory(orderedApplicationSteps)(
      applicationStep,
      applicationSteps.PAYED_OUT,
    ),
    [orderedApplicationSteps, applicationStep, applicationSteps.PAYED_OUT],
  );

  const nextAdminAction = useCallback(
    () => getNextAdminAction(applicationMainSteps, applicationStep),
    [applicationMainSteps, applicationStep],
  );
  return (
    <div className={classes.console}>
      <section className={classes.styledSection}>
        <Typography variant="h6">Application Console</Typography>
        {record.pendingProcessing
          && (
          <WarningMessage>
            <Typography variant="subtitle1" align="center" color="error">
              This application has not been processed yet. It will be processed within 10 minutes
            </Typography>
          </WarningMessage>
          )}
        <div className={classes.actions}>
          <MarkAccepted />
          <MarkRejected />
          <CloneButton source="id" resource="application" />
        </div>
      </section>
      <hr />
      <section className={classes.styledSection}>
        <Typography variant="h6">Action to take: {nextAdminAction()}</Typography>
        <div className={classes.actions}>
          <SendSmsButton />
          <SendEmailButton />
          <ManualProcess />
          <ReviewDecision />
          <ManuallySigned />
          {isAgreementSigned() && !isPayedOut() && (
            <PayoutButton />
          )}
          <ManualDecision />
        </div>
      </section>
      {isAgreementSent() && !isPayedOut() && (
        <section className={classes.styledSection}>
          <Typography variant="body2">Download Documents:</Typography>
          <div className={[classes.actions]}>
            <WetAgreementLink id={record.id} accountType={record.accountType} />
            <DigitalAgreementLink id={record.id} accountType={record.accountType} />
            <DocumentLink product={record.product} type="terms" utmSource={record.trackingData.utmSource} />
            <DocumentLink product={record.product} type="sekki" utmSource={record.trackingData.utmSource} />
          </div>
        </section>
      )}
      <hr />
      <Typography variant="h6">Information collected:</Typography>
      <Typography variant="body2" className={classes.styledH4}>
        Application required fields:
        <span className={classes.rightAlign}>
          <SourceField
            source="application"
            required
          />
        </span>
      </Typography>
      <hr className={classes.interSection} />
      <Typography variant="body2" className={classes.styledH4}>
        Bank Report:
        <span className={classes.rightAlign}>
          <SourceField
            source="bank_data"
            required
            perApplicant
          />
        </span>
      </Typography>
      <hr className={classes.interSection} />
      <Typography variant="body2" className={classes.styledH4}>
        Credit Safe Report:
        <span className={classes.rightAlign}>
          <SourceField
            source="credit_safe"
            perApplicant
          />
        </span>
      </Typography>
      <hr className={classes.interSection} />
      <Typography variant="body2" className={classes.styledH4}>
        Preliminary UC Report:
        <span className={classes.rightAlign}>
          <SourceField
            source="preliminary_uc"
            perApplicant
          />
        </span>
      </Typography>
      <hr className={classes.interSection} />
      <Typography variant="body2" className={classes.styledH4}>
        UC Report:
        <span className={classes.rightAlign}>
          <SourceField
            source="uc"
            required
            perApplicant
          />
        </span>
      </Typography>
      <hr className={classes.interSection} />
      <Typography variant="body2" className={classes.styledH4}>
        Trapets Report:
        <span className={classes.rightAlign}>
          <SourceField
            source="trapets"
            required
            perApplicant
          />
        </span>
      </Typography>
      <hr />
      <Typography variant="h6">
        Decision taken:
        <span className={classes.rightAlign}>
          <CheckField value={isDecisionTaken()} required />
        </span>
      </Typography>
      <hr />
      <Typography variant="h6">
        Agreement Sent:
        <span className={classes.rightAlign}>
          <CheckField value={isAgreementSent()} required />
        </span>
      </Typography>
      <hr />
      <Typography variant="h6">
        Agreement Signed:
        <span className={classes.rightAlign}>
          <CheckField value={isAgreementSigned()} required />
        </span>
      </Typography>
      <hr />
      <Typography variant="h6">
        Payment details verified:
        <span className={classes.rightAlign}>
          <CheckField value={isPaymentDetailsVerified()} required />
        </span>
      </Typography>
      <hr />
      <Typography variant="h6">
        Final AML Checks verified:
        <span className={classes.rightAlign}>
          <CheckField value={areFinalAMLChecksOK()} required />
        </span>
      </Typography>
      <hr />
      <Typography variant="h6">
        Payout application:
        <span className={classes.rightAlign}>
          <CheckField value={isPayedOut()} required />
        </span>
      </Typography>
      <hr />
      <Typography variant="body2">
        Public ID of the future account to be used in agreements:
        <span className={classes.rightAlign}> {record.publicId}</span>
      </Typography>
    </div>
  );
};

export default ApplicationConsole;
