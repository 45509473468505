import React from 'react';
import {
  List,
  TextField,
  TextInput,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import HeadedDatagrid from '../utils/HeadedDatagrid';

const lenderFilters = [
  <TextInput alwaysOn label="Lender ID" source="_id" />,
  <TextInput alwaysOn label="Lender Name" source="name" />,
  <TextInput alwaysOn label="Account number" source="accountNumber" />,
];

const LenderList = () => (
  <List filters={lenderFilters} sort={{ field: 'name', order: 'ASC' }}>
    <HeadedDatagrid head={(<PageTitle title="Lender | List" />)}>
      <TextField label="Name" source="name" />
      <TextField label="Account Type" source="bankAccountType" />
      <TextField label="Account Number" source="accountNumber" />
      <TextField label="Clearing Number" source="clearingNumber" />
    </HeadedDatagrid>
  </List>
);

export default LenderList;
