import React from 'react';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';

import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';
import ModalButton from './ModalButton';

const FreezeAccountButton = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { accountStatuses } = constants;

  const { id: accountId, status } = record || {};

  const { mutate: freezeAccount } = useCustomRpc({
    httpMethod: 'PUT',
    path: `account/${accountId}/freeze`,
    successMessage: 'Account frozen',
    errorMessage: 'Failed freezing account',
    shouldRefresh: true,
  });

  const onClick = () => {
    freezeAccount();
    return true;
  };

  if (!accountId
    || status !== accountStatuses.ACTIVE
    || status === accountStatuses.FROZEN
  ) return null;

  return (
    <ModalButton
      title="Freeze Account"
      onClick={onClick}
      right
    >
      <Typography variant="h6" color="error" align="center">WARNING</Typography>
      <Typography variant="body2" align="center">Marking the account as Frozen will pause all ledger functions on it.</Typography>
    </ModalButton>
  );
};

export default FreezeAccountButton;
