import React from 'react';
import {
  List,
  DateField,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin';

import SelectInput from '../input/SelectInput';
import PageTitle from '../utils/PageTitle';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import HeadedDatagrid from '../utils/HeadedDatagrid';
import AutoRefresh from '../utils/AutoRefresh';
import QuickPagination from '../utils/QuickPagination';

const userFilters = [
  <TextInput label="User ID" source="_id" alwaysOn />,
  <TextInput label="Social Security Number" source="socialSecurityNumber" alwaysOn />,
  <TextInput label="First name" source="firstName" alwaysOn />,
  <TextInput label="Last name" source="lastName" alwaysOn />,
  <TextInput label="Email" source="email" alwaysOn />,
  <TextInput label="Phone" source="phone" alwaysOn />,
  <SelectInput
    label="Active"
    source="active"
    alwaysOn
    choices={[
      { id: true, name: 'True' },
      { id: false, name: 'False' },
    ]}
  />,
];

const UserList = () => (
  <List
    filters={userFilters}
    filterDefaultValues={{ active: true }}
    pagination={<QuickPagination />}
  >
    <HeadedDatagrid
      head={(
        <>
          <AutoRefresh />
          <PageTitle title="User | List" />
        </>
      )}
    >
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <SocialSecurityNumberField source="socialSecurityNumber" />
      <DateField source="createdAt" />
      <EditButton />
    </HeadedDatagrid>
  </List>
);

export default UserList;
