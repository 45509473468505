import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Area,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

import CategorisationModal from './CategorisationModal';
import GraphCategorisationDetails from './CategorisationGraphDetails';

import { numberFormat } from '../../../utils/formatters';
import validators from '../../../utils/validators';

const { isInObject } = validators.generics;

const margin = {
  top: 20,
  right: 55,
  bottom: 55,
  left: 5,
};

const cleanedLabel = (value) => value.replace(/info\./, '');

const CategorisationChart = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [pointData, setPointData] = useState({
    positiveTransactions: [],
    negativeTransactions: [],
  });

  const toggleModal = useCallback((e) => {
    if (e && isInObject('activePayload', e)) {
      const [clickData] = e.activePayload;
      const { positiveTransactions, negativeTransactions } = clickData.payload.info;
      setPointData({ positiveTransactions, negativeTransactions });
    }
    setOpen((c) => !c);
  }, []);

  return (
    <div>
      <CategorisationModal
        open={open}
        onClose={toggleModal}
      >
        <GraphCategorisationDetails {...pointData} />
      </CategorisationModal>
      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart
          data={data}
          margin={margin}
          onClick={toggleModal}
        >
          <Legend verticalAlign="top" height={36} formatter={cleanedLabel} />
          <Area type="monotone" dataKey="info.balance" stroke="#8884d8" />
          <Line type="monotone" dataKey="info.negativeSum" stroke="#a83232" dot={false} />
          <Line type="monotone" dataKey="info.positiveSum" stroke="#006400" dot={false} />
          <CartesianGrid
            stroke="#ccc"
            strokeDasharray="5 5"
          />
          <XAxis
            dataKey="date"
            angle={-45}
            textAnchor="end"
            interval={15}
          />
          <YAxis />
          <Tooltip formatter={(value, name) => ([numberFormat(value, 0), cleanedLabel(name)])} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

CategorisationChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CategorisationChart;
