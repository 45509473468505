import React, { useCallback, useState } from 'react';
import { useRecordContext } from 'react-admin';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useCustomRpc from '../../hooks/useCustomRpc';

const styles = {
  buttonCancel: {
    fontSize: '0.7rem',
    padding: '0.3rem 0.7rem',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: '1rem 2.5rem 0.5rem 0.5rem',
    float: 'right',
  },
  buttonSubmit: {
    fontSize: '0.7rem',
    padding: '0.3rem 0.7rem',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: '1rem 0.5rem 1rem 1rem',
    float: 'right',
    color: 'white',
    backgroundColor: '#f44336',
  },
  buttonDisabled: {
    fontSize: '0.7rem',
    padding: '0.3rem 0.7rem',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: '1rem 0.5rem 1rem 1rem',
    float: 'right',
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  buttonMain: {
    fontSize: '0.7rem',
    padding: '0.3rem 0.7rem',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: '0.5rem',
    float: 'right',
    color: 'white',
    backgroundColor: '#f44336',
  },
  modal: {
    width: '500px',
    height: 'auto',
    padding: '32px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2) 0px 5px 8px 0px rgba(0, 0, 0, 0.14) 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  comment: {
    width: '15rem',
    height: '2rem',
    display: 'block',
  },
};

const MarkAsFraud = () => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');

  const account = useRecordContext();

  const { mutate: markFraud, isLoading } = useCustomRpc({
    httpMethod: 'PUT',
    path: `account/${account.id}/markFraud`,
    successMessage: 'Account marked as fraud',
    errorMessage: 'Failed to mark account fraud',
    shouldRefresh: true,
  });

  const handleOpenModal = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    setOpen(false);
    setComment('');
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (comment === '') return;
    markFraud({ comment });
    setComment('');
  }, [comment, markFraud]);

  const handleChange = useCallback((e) => {
    setComment(e.target.value);
  }, []);

  if (!account) return null;
  if (account.status !== 'active') return null;
  return (
    <>
      <Button
        style={styles.buttonMain}
        variant="contained"
        color="inherit"
        onClick={handleOpenModal}
        disabled={isLoading}
      >
        Mark As Fraud
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleCloseModal}
      >
        <div style={styles.modal}>
          <Typography align="center">Mark an account as fraud will freeze the account while waiting for the court decision</Typography>
          <Typography align="center" variant="caption">The account with the fraud status not accumulate interest and not has any more invoices</Typography>
          <TextField
            id="comment"
            label="Comment"
            multiline
            maxRows="5"
            value={comment}
            onChange={handleChange}
            style={styles.comment}
            margin="normal"
          />
          <div>
            <Button
              style={styles.buttonCancel}
              variant="contained"
              color="secondary"
              onClick={handleCloseModal}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              style={(comment === '' ? styles.buttonDisabled : styles.buttonSubmit)}
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoading || (comment === '')}
            >
              Mark As Fraud
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MarkAsFraud;
