import React, { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import {
  useRecordContext,
  usePermissions,
} from 'react-admin';

import ModalButton from './ModalButton';

import useInputStatus from '../hooks/useInputStatus';

import validators from '../../utils/validators';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const {
  required,
  isDefined,
  isStrictlyPositiveNumber,
} = validators.generics;

const ReportLateInterestButton = () => {
  const record = useRecordContext();

  const { constants } = useConstantContext();
  const { accountStatuses, userRoles: { CAPITAL_MANAGER } } = constants;

  const { permissions } = usePermissions();

  const { id, status, accountHolders } = record;

  const { mutate: adjustAccountDebt } = useCustomRpc({
    httpMethod: 'POST',
    path: `account/${id}/report-late-interest`,
    successMessage: 'Late interest reported',
    errorMessage: 'Failed to report late interest',
    shouldRefresh: true,
  });

  const isAccountCancelled = status === accountStatuses.CANCELLED;
  const isActiveOrganisationAccount = status === accountStatuses.ACTIVE && accountHolders[0].holderType === 'Organisation';

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus();

  const [
    amount,
    setAmount,
    onChangeAmount,
    amountError,
    setamountError,
  ] = useInputStatus();

  const onClickAdjustmentTransaction = useCallback(() => {
    if (!required(isStrictlyPositiveNumber, amount)) {
      setamountError('Amount should be a positive number');
      return false;
    }

    if (!isDefined(comment)) {
      setCommentError('You must leave a coment');
      return false;
    }
    const data = { amount, comment };
    adjustAccountDebt(data);
    return true;
  }, [
    comment,
    amount,
    setCommentError,
    setamountError,
    adjustAccountDebt,
  ]);

  const onClose = useCallback(() => {
    setComment(undefined);
    setCommentError(undefined);
    setAmount(undefined);
    setamountError(undefined);
  }, [
    setComment,
    setCommentError,
    setAmount,
    setamountError,
  ]);

  if (!record || !permissions) return null;
  if ((!isAccountCancelled && !isActiveOrganisationAccount)
    || !permissions.includes(CAPITAL_MANAGER)) return null;

  return (
    <ModalButton
      title="Report Late Interest"
      onClick={onClickAdjustmentTransaction}
      onClose={onClose}
      confirmationMessage={`Are you sure you want to report in the acount ${amount} late interest?`}
    >
      <Typography>Add adjustment transaction to account</Typography>
      <TextField
        label="Adjustment Amount"
        source="adjustmentAmount"
        value={amount}
        onChange={onChangeAmount}
        type="number"
        error={!!amountError}
        helperText={amountError}
        margin="normal"
      />
      <TextField
        label="Leave a comment"
        multiline
        maxRows="4"
        value={comment}
        onChange={onChangeComment}
        error={!!commentError}
        helperText={commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

export default ReportLateInterestButton;
