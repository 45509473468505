import moment from 'moment';
import { getJWTPayload } from './jwt';
import store from './store';

import config from '../../config';

const fetchNewToken = (refreshToken) => new Promise((resolve, reject) => {
  const request = new window.XMLHttpRequest();
  request.open('POST', `${config.apiBase}/authorization/refresh-access-token`, true);
  request.setRequestHeader('X-API-Key', config.apiKey);
  request.setRequestHeader('Content-type', 'application/json');
  request.onload = () => {
    if (request.readyState === request.DONE) {
      try {
        if (request.status >= 400) {
          reject(JSON.parse(request.response));
        } else {
          const { data: { token: newAccessToken } } = JSON.parse(request.responseText);
          resolve(newAccessToken);
        }
      } catch (error) {
        reject(error);
      }
    }
  };
  request.send(JSON.stringify({ refreshToken }));
});

const getAccessToken = async () => {
  const token = store.getItem('token');
  const refreshToken = store.getItem('refreshToken');
  if (!token) return undefined;

  const { exp } = getJWTPayload(token);

  const tokenExpiryDate = moment.unix(exp);
  const now = moment().add(1, 'seconds');

  if (tokenExpiryDate.isAfter(now)) return token;
  try {
    const newToken = await fetchNewToken(refreshToken);
    store.setItem('token', newToken);
    return newToken;
  } catch (error) {
    store.removeItem('token');
    store.removeItem('refreshToken');
    return undefined;
  }
};

export default getAccessToken;
