import React, { useCallback, useState } from 'react';
import { useRecordContext } from 'react-admin';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';

import validators from '../../utils/validators';

import useInputStatus from '../hooks/useInputStatus';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const TerminateDrawdownButton = () => {
  const record = useRecordContext();
  const { accountFeatures } = useConstantContext();
  const { drawdown } = accountFeatures;
  const { id, accountType, terms } = record || {};

  const { mutate: terminateDrawdownRequest } = useCustomRpc({
    httpMethod: 'POST',
    path: `account/${id}/terminate-drawdown`,
    mimeType: 'formData',
    shouldRefresh: true,
  });

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus();

  const [file, setFile] = useState(undefined);
  const [fileError, setFileError] = useState('');
  const onChangeFile = useCallback((e) => {
    setFileError('');
    if (e.target.files && e.target.files.length > 0) setFile(e.target.files[0]);
  }, [setFileError, setFile]);

  const onClick = useCallback(
    () => {
      setCommentError('');
      setFileError('');

      if (!file) {
        setFileError('A file is required to justify the extra amortisation');
        return false;
      }

      if (!isDefined(comment)) {
        setCommentError('A comment is required');
        return false;
      }

      const formDataFields = [
        {
          fieldName: 'comment',
          value: comment,
        },
        {
          fieldName: 'file',
          value: file,
        },
      ];

      terminateDrawdownRequest(formDataFields);
      return true;
    },
    [comment, terminateDrawdownRequest, setFileError, setCommentError, file],
  );

  const onClose = useCallback(() => {
    setComment('');
    setCommentError('');
    setFile(undefined);
    setFileError('');
  }, [setComment, setCommentError, setFile, setFileError]);

  if (!drawdown.includes(accountType)) return null;
  if (terms.drawdown && terms.drawdown.termination && terms.drawdown.termination.date) return null;

  return (
    <ModalButton
      title="End Drawdown Period"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <input
        style={{ marginTop: '2rem' }}
        type="file"
        id="file"
        name="file"
        accept=".pdf"
        onChange={onChangeFile}
      />
      <Typography variant="caption" color="error" align="center">{fileError}</Typography>
      <TextField
        label="Leave a comment"
        multiline
        maxRows="4"
        value={comment}
        onChange={onChangeComment}
        error={!!commentError}
        helperText={commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

export default TerminateDrawdownButton;
