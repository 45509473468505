import React from 'react';

import SelectInput from '../input/SelectInput';
import { useConstantContext } from '../utils/ConstantsContext';

const useTodoFilters = () => {
  const { choices } = useConstantContext();
  const { todoStatuses } = choices;
  return [
    <SelectInput
      alwaysOn
      source="status"
      choices={todoStatuses}
    />,
  ];
};

export default useTodoFilters;
