import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecordContext, usePermissions } from 'react-admin';
import { TextField, Typography } from '@mui/material';
import ModalButton from './ModalButton';

import useInputStatus from '../hooks/useInputStatus';

import validators from '../../utils/validators';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const RevertPayinTransaction = ({ accountId }) => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { transactionTypes, userRoles: { CAPITAL_MANAGER } } = constants;
  const { permissions } = usePermissions();

  const {
    _id: transactionId,
    amount,
    type,
    asPayin,
    revertedBy,
  } = record;

  const { mutate: transferTransaction } = useCustomRpc({
    httpMethod: 'POST',
    path: `account/${accountId}/transactions/${transactionId}/transfer`,
    successMessage: 'Transaction transferred',
    errorMessage: 'Failed to transfer transaction',
    shouldRefresh: true,
  });

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus();

  const [
    accountToId,
    setAccountToId,
    onChangeAccountToId,
    accountToIdError,
    setAccountToIdError,
  ] = useInputStatus();

  const onClick = useCallback(() => {
    if (!isDefined(accountToId)) {
      setAccountToIdError('Please add id of account to transfer transaction to');
      return false;
    }

    if (!isDefined(comment)) {
      setCommentError('Please add a comment');
      return false;
    }

    if (accountId === accountToId) {
      setAccountToIdError('Cannot transfer account to the same account it currently is in');
      return false;
    }

    const data = { comment, accountToId };
    transferTransaction(data);
    return true;
  }, [accountToId, comment, accountId, transferTransaction, setAccountToIdError, setCommentError]);

  const onClose = useCallback(() => {
    setComment('');
    setAccountToId('');
    setCommentError('');
    setAccountToIdError('');
  }, [
    setComment,
    setAccountToId,
    setCommentError,
    setAccountToIdError,
  ]);

  if (!record || !accountId || !permissions) return null;

  if ((!type === transactionTypes.USER_PAYMENT
    && !(type === transactionTypes.INTERNAL && asPayin))
    || amount <= 0
    || revertedBy
    || !permissions.includes(CAPITAL_MANAGER)
  ) return null;

  return (
    <ModalButton
      title="Transfer"
      onClick={onClick}
      onClose={onClose}
    >
      <Typography align="center" variant="h6"> Transfer the selected transaction to a different account</Typography>
      <TextField
        label="Receiving Account Id"
        value={accountToId}
        onChange={onChangeAccountToId}
        error={!!accountToIdError}
        helperText={accountToIdError}
        margin="normal"
      />
      <TextField
        label="Leave a comment"
        multiline
        maxRows="4"
        value={comment}
        onChange={onChangeComment}
        error={!!commentError}
        helperText={commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

RevertPayinTransaction.propTypes = {
  accountId: PropTypes.string,
};

RevertPayinTransaction.defaultProps = {
  accountId: undefined,
};

export default RevertPayinTransaction;
