import React from 'react';
import { useGetMany, useRecordContext } from 'react-admin';
import Chip from '@mui/material/Chip';
import AMLChip from '../utils/AMLChip';

import AccountCategoryChip from '../utils/AccountCategoryChip';

import { isSameOrBeforeFactory, isBeforeFactory } from '../../utils/signup/compareApplicationSteps';
import { useConstantContext } from '../utils/ConstantsContext';

const margin = '0 1rem';

const RED = '#cc1212';
const GREEN = '#33cc33';
const YELLOW = '#ffff66';
const ORANGE = '#ff9800';

const getChipPropsBuilder = (
  applicationSteps,
  applicationStepsChoices,
  orderedApplicationSteps,
) => (applicationStep) => {
  const isSameOrBefore = isSameOrBeforeFactory(orderedApplicationSteps);
  const isBefore = isBeforeFactory(orderedApplicationSteps);

  let color;
  if (isBefore(applicationStep, applicationSteps.SUBMITTED)) color = YELLOW;
  else if (isBefore(applicationStep, applicationSteps.AGREEMENT_SENT)) color = ORANGE;
  else if (isSameOrBefore(applicationStep, applicationSteps.PAYED_OUT)) color = GREEN;
  else color = RED;

  const label = (applicationStepsChoices.find(({ id }) => applicationStep === id) || {}).name;
  return { style: { margin, backgroundColor: color }, label };
};

const amlRiskLevelSelector = (applicantUsers) => {
  if (!applicantUsers) return undefined;

  const amlRiskLevel = applicantUsers.reduce((riskLevel, applicant) => {
    if (!applicant.amlRiskLevel) return riskLevel;

    if (!riskLevel) return applicant.amlRiskLevel;

    return Math.max(applicant.amlRiskLevel, riskLevel);
  }, undefined);

  return amlRiskLevel;
};

const ApplicationTitle = () => {
  const { constants, choices } = useConstantContext();
  const application = useRecordContext();
  const getChipProps = getChipPropsBuilder(
    constants.applicationSteps,
    choices.applicationSteps,
    constants.orderedApplicationSteps,
  );
  const { data: applicantUsers } = useGetMany(
    'user',
    { ids: (application?.applicants ?? []).map(({ user }) => user) },
    { enabled: !!application },
  );

  if (!application) return null;

  const chipProps = getChipProps(application.applicationStep);
  const amlRiskLevel = amlRiskLevelSelector(applicantUsers);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <span style={{ margin }}>Application: {application.id}</span>
      <AccountCategoryChip accountType={application.accountType} />
      <Chip {...chipProps} />
      <AMLChip amlRiskLevel={amlRiskLevel} />
    </div>
  );
};

export default ApplicationTitle;
