import React from 'react';
import {
  List,
} from 'react-admin';

import useApplicationFilters from './useApplicationFilters';

import QuickPagination from '../utils/QuickPagination';
import ApplicationItem from './ApplicationItem';

const ApplicationList = () => {
  const filters = useApplicationFilters();
  return (
    <List
      filters={filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<QuickPagination />}
    >
      <ApplicationItem isListPage />
    </List>
  );
};

export default ApplicationList;
