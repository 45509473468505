import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

import { withStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import SelectField from './SelectField';
import ColoredButton from './ColoredButton';

import { isSameOrAfterFactory, isBeforeFactory } from '../../utils/signup/compareApplicationSteps';

import validators from '../../utils/validators';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const styles = {
  buttons: {
    '&>*': {
      marginLeft: '1rem',
    },
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '35rem',
    height: 'auto',
    padding: '2rem',
    boxShadow: '0px 5px 8px 0px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    backgroundColor: 'white',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  input: {
    width: '25rem',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  button: {
    margin: '1rem',
  },
};

const ManualDecision = ({
  classes,
}) => {
  const [open, setOpen] = useState(false);
  const [silenceDeny, setSilenceDeny] = useState(false);
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [decision, setDecision] = useState(null);

  const record = useRecordContext();

  const { constants, choices } = useConstantContext();
  const {
    reasonsForDeny,
    decisionStatuses,
    applicationSteps,
    orderedApplicationSteps,
  } = constants;
  const { reasonsForDeny: reasonsForDenyChoices } = choices;

  const isSameOrAfter = isSameOrAfterFactory(orderedApplicationSteps);
  const isBefore = isBeforeFactory(orderedApplicationSteps);

  const { mutate: sendDecisionUpdate, isLoading } = useCustomRpc({
    httpMethod: 'PUT',
    path: `application/pendingDecision/${record.id}`,
    successMessage: 'Application decision updated',
    errorMessage: 'Failed to update application decision',
    shouldRefresh: true,
  });

  const handleChange = (name) => (event) => {
    event.preventDefault();
    const { checked, value } = event.target;
    if (name === 'reason') setReason(value);
    else if (name === 'message') setMessage(value);
    else if (name === 'silenceDeny') setSilenceDeny(checked);
    else setDecision(value);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setOpen(false);
    setSilenceDeny(false);
    setReason('');
    setMessage('');
    setError('');
    setDecision(null);
  };

  const handleOpenModal = (chosenDecision) => (e) => {
    e.preventDefault();
    setOpen(true);
    setDecision(chosenDecision);
  };

  const updateDecision = () => {
    if (decision === decisionStatuses.DENY) {
      if (!isDefined(reason)) {
        setError('Please select a reason');
        return;
      }
      if (reason === reasonsForDeny.OTHER && !isDefined(message)) {
        setError('Please add a comment');
        return;
      }
    } else if (!isDefined(message)) {
      setError('Please select a comment');
      return;
    }
    const data = {
      decision,
      reason,
      silenceDeny,
      message,
    };
    sendDecisionUpdate(data);
    setOpen(false);
    setSilenceDeny(false);
    setReason('');
    setMessage('');
    setError('');
    setDecision(null);
  };

  const canBeDenied = () => {
    const { applicationStep } = record;
    return isSameOrAfter(applicationStep, applicationSteps.SUBMITTED)
      && isBefore(applicationStep, applicationSteps.PAYED_OUT);
  };

  const canTakeFullDecision = () => {
    const { applicationStep } = record;
    return applicationSteps.PROCESSED === applicationStep;
  };

  const {
    currentDecisionStatus,
    applicationStep,
  } = record;
  if (!canBeDenied(applicationStep)) return null;
  return (
    <div className={classes.buttons}>
      <ColoredButton
        onClick={handleOpenModal(decisionStatuses.DENY)}
        disabled={isLoading || currentDecisionStatus === decisionStatuses.DENY}
        color="white"
        background="red"
      >
        {decisionStatuses.DENY}
      </ColoredButton>
      {canTakeFullDecision(applicationStep) && (
        <>
          <Button
            title="Manually step update"
            onClick={handleOpenModal(decisionStatuses.REFER)}
            disabled={isLoading || currentDecisionStatus === decisionStatuses.REFER}
            variant="contained"
            size="small"
          >
            {decisionStatuses.REFER}
          </Button>
          <Button
            title="Manually step update"
            disabled={isLoading || currentDecisionStatus === decisionStatuses.GRANT}
            onClick={handleOpenModal(decisionStatuses.GRANT)}
            variant="contained"
            color="primary"
            size="small"
          >
            {decisionStatuses.GRANT}
          </Button>
        </>
      )}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleCloseModal}
      >
        <section className={classes.modal}>
          <h3>Overriding current decision {`${currentDecisionStatus}`.toUpperCase()} with {`${decision}`.toUpperCase()}</h3>
          <Typography color="error" align="center">{error}</Typography>
          <TextField
            id="message"
            name="message"
            label="Message"
            multiline
            maxRows="4"
            value={message}
            onChange={handleChange('message')}
            className={classes.input}
          />
          {decision === decisionStatuses.DENY && (
            <>
              <div className={classes.input}>
                <SelectField
                  label="Reason"
                  value={reason}
                  choices={reasonsForDenyChoices}
                  onChange={handleChange('reason')}
                />
              </div>
              <FormControlLabel
                control={(
                  <Switch
                    checked={silenceDeny}
                    onChange={handleChange('silenceDeny')}
                    color="primary"
                  />
                )}
                label="Silence deny email"
              />
            </>
          )}
          <Button
            variant="contained"
            onClick={updateDecision}
            disabled={isLoading}
            color="primary"
            size="small"
            style={styles.button}
          >
            Update Decision
          </Button>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            disabled={isLoading}
            size="small"
          >
            Close
          </Button>
        </section>
      </Modal>
    </div>
  );
};

ManualDecision.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ManualDecision);
