import React from 'react';
import { useRecordContext } from 'react-admin';
import AMLChip from '../utils/AMLChip';

const UserTitle = () => {
  const user = useRecordContext();
  if (!user) return null;
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <span style={{ margin: '0 1rem' }}>User: {user.firstName} {user.lastName}</span>
      <AMLChip amlRiskLevel={user.amlRiskLevel} />
    </div>
  );
};

export default UserTitle;
