const getAnnuityAmount = (amount, repaymentTime, nominalRate, startupFee = 0) => {
  if (!amount || !nominalRate || !repaymentTime) return '';
  const monthlyInterest = (nominalRate / 12) / 100;
  const annuityAmount = monthlyInterest * (
    (amount + (startupFee / (1 + monthlyInterest)))
    / (1 - ((1 + monthlyInterest) ** (-repaymentTime)))
  );
  return Math.ceil(annuityAmount);
};

export default getAnnuityAmount;
