import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Modal from '@mui/material/Modal';

const styles = {
  modal: {
    padding: '32px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2) 0px 5px 8px 0px rgba(0, 0, 0, 0.14) 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    backgroundColor: 'white',
    maxHeight: '70%',
    minWidth: '75%',
    overflow: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const CategorisationModal = ({
  children,
  classes,
  open,
  onClose,
}) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={open}
    onClose={onClose}
  >
    <div className={classes.modal}>
      {children}
    </div>
  </Modal>
);

CategorisationModal.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(CategorisationModal);
