import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';

import useInputStatus from '../hooks/useInputStatus';

import validators from '../../utils/validators';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { generics: { isDefined } } = validators;

const SendInvoiceManuallyButton = ({ accountId }) => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { invoiceTypes } = constants;
  const hasPDFGenerated = isDefined(record.pdfGeneratedAt);

  const { mutate: sendInvoiceManually } = useCustomRpc({
    httpMethod: 'POST',
    path: `account/${accountId}/invoices/${record.id}/send-again`,
    successMessage: 'Successfully sent invoice',
    errorMessage: 'Failed to send invoice',
  });

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus(undefined);

  const onClose = useCallback(() => {
    setComment('');
  }, [setComment]);
  const onClick = useCallback(() => {
    if (!isDefined(comment)) {
      setCommentError('The comment for manually sending an invoice is required.');
      return false;
    }

    sendInvoiceManually({ comment });
    setComment('');
    return true;
  }, [comment, sendInvoiceManually, setComment, setCommentError]);

  if (!isDefined(invoiceTypes)) return null;
  const isOriginalInvoice = record.type === invoiceTypes.INVOICE;
  if (!accountId || !hasPDFGenerated || !isOriginalInvoice) return null;

  return (
    <ModalButton
      title="Send Manually"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <Typography>Send this invoice to the customer by email</Typography>
      <TextField
        type="text"
        label="Manual sending invoice comment"
        onChange={onChangeComment}
        value={comment}
        error={!!commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

SendInvoiceManuallyButton.propTypes = {
  accountId: PropTypes.string,
};

SendInvoiceManuallyButton.defaultProps = {
  accountId: undefined,
};

export default SendInvoiceManuallyButton;
