import React from 'react';
import {
  DateInput,
  DateTimeInput,
  EditView,
  FunctionField,
  TextInput,
  ReferenceField,
  required,
  SimpleForm,
  TextField,
  useEditController,
  EditContextProvider,
} from 'react-admin';

import SelectInput from '../input/SelectInput';

import PageTitle from '../utils/PageTitle';
import { futureDate as futureDateValidation } from '../../utils/validationErrors';
import { useConstantContext } from '../utils/ConstantsContext';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const validateStock = [required()];

const getRecord = (props) => props.record || {};

const TodoEdit = () => {
  const { choices } = useConstantContext();
  const { todoStatuses } = choices;
  const controllerProps = useEditController({
    mutationMode: 'pessimistic',
    redirect: false,
  });
  return (
    <EditContextProvider value={controllerProps}>
      <EditView>
        <SimpleForm>
          <PageTitle title="Todo | Edit" />
          <DateInput source="createdAt" validate={validateStock} readOnly />
          <TextInput multiline source="description" validate={validateStock} />
          <DateTimeInput
            source="dueDate"
            validate={[...validateStock, futureDateValidation]}
            options={{ ampm: 'false', clearable: 'true' }}
          />
          <SelectInput
            source="status"
            choices={todoStatuses}
          />
          <SimpleShowLabeled>
            {getRecord(controllerProps).author ? (
              <ReferenceField label="Author" source="author" reference="admins">
                <TextField source="firstName" />
              </ReferenceField>
            ) : (
              <FunctionField label="Author" render={() => 'System'} />
            )}
          </SimpleShowLabeled>
        </SimpleForm>
      </EditView>
    </EditContextProvider>
  );
};

export default TodoEdit;
