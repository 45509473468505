import React from 'react';
import {
  Create,
  SimpleForm,
  DateTimeInput,
  TextInput,
  required,
} from 'react-admin';

import { futureDate as futureDateValidation } from '../../utils/validationErrors';

const validateStock = [required()];

const CreateAdmin = () => (
  <Create>
    <SimpleForm>
      <TextInput multiline source="description" validate={validateStock} />
      <DateTimeInput
        source="dueDate"
        validate={[...validateStock, futureDateValidation]}
        options={{ ampm: 'false', clearable: 'true' }}
      />
    </SimpleForm>
  </Create>
);

export default CreateAdmin;
