import React from 'react';
import {
  MenuItemLink,
  DashboardMenuItem,
  useResourceDefinitions,
  useAuthState,
  Menu as RaMenu,
  usePermissions,
  useGetResourceLabel,
  useCreatePath,
} from 'react-admin';
import Badge from '@mui/material/Badge';
import ReportIcon from '@mui/icons-material/Assessment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DefaultIcon from '@mui/icons-material/ViewList';
import { useConstantContext } from '../utils/ConstantsContext';
import useCustomQuery from '../../hooks/useCustomQuery';

const Menu = () => {
  const { authenticated, isLoading } = useAuthState();
  const { constants } = useConstantContext();
  const { userRoles } = constants;
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const isFullyAuthenticated = !isLoading && authenticated;

  const { permissions } = usePermissions();

  const { data: notifications } = useCustomQuery({
    httpMethod: 'GET',
    path: 'statistics/notifications',
    key: 'notifications',
    errorMessage: 'Error when fetching notifications',
    refetchInterval: 60000,
    enabled: isFullyAuthenticated,
  });

  return (
    <RaMenu>
      <DashboardMenuItem />
      {Object.values(resources)
        .filter((r) => r.hasList)
        .map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={createPath({ resource: resource.name, type: 'list' })}
            primaryText={getResourceLabel(resource.name, 2)}
            leftIcon={(
              <Badge badgeContent={notifications?.[resource.name]} color="error">
                {resource.icon ? <resource.icon /> : <DefaultIcon />}
              </Badge>
            )}
          />
        ))}
      <MenuItemLink to="/reports" primaryText="Reports" leftIcon={<ReportIcon />} />
      {permissions && permissions.includes(userRoles?.ROLE_SUPER_ADMIN) ? (
        <MenuItemLink
          to="/payment-files"
          primaryText="Payment Files"
          leftIcon={<InsertDriveFileIcon />}
        />
      ) : null}
    </RaMenu>
  );
};

export default Menu;
