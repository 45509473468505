import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';

import useCustomRpc from '../../hooks/useCustomRpc';

const DeleteKalp = ({ applicationId }) => {
  const { mutate: deleteKalp } = useCustomRpc({
    httpMethod: 'DELETE',
    path: `application/${applicationId}/kalp`,
    successMessage: 'Kalp file deleted',
    errorMessage: 'Failed deleting kalp file',
    shouldRefresh: true,
  });

  const onDelete = useCallback(() => {
    deleteKalp();
    return true;
  }, [deleteKalp]);

  return (
    <ModalButton
      title="Delete Kalp"
      onClick={onDelete}
    >
      <Typography align="center" color="error" variant="subtitle1">All data inserted will be lost!</Typography>
      <Typography align="center" color="error" variant="subtitle1">Continue anyway?</Typography>
    </ModalButton>
  );
};

DeleteKalp.propTypes = {
  applicationId: PropTypes.string.isRequired,
};

export default DeleteKalp;
