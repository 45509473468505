import React from 'react';
import moment from 'moment';
import {
  List,
  ReferenceField,
  TextField,
  SelectField,
  FunctionField,
} from 'react-admin';

import GoToIcon from '@mui/icons-material/ExitToApp';
import Chip from '@mui/material/Chip';

import AssignedAdmin from '../utils/AssignedAdmin';
import AltField from '../utils/AltField';
import PageTitle from '../utils/PageTitle';
import OrganisationNumberField from '../utils/OrganisationNumberField';

import useSignupFilters from './useSignupFilters';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import MoneyField from '../utils/MoneyField';
import HeadedDatagrid from '../utils/HeadedDatagrid';
import AutoRefresh from '../utils/AutoRefresh';
import AccountCategoryChip from '../utils/AccountCategoryChip';

import useGetAdminList from '../../utils/useGetAdminList';
import { useConstantContext } from '../utils/ConstantsContext';

const styles = {
  notHandeled: { marginRight: '5px', backgroundColor: '#ff0000', color: '#f2f2f2' },
  handled: { marginRight: '5px', backgroundColor: '#248B21', color: '#f2f2f2' },
};

const Icon = () => <GoToIcon style={{ marginRight: '2rem', marginTop: '0.3rem' }} />;

const isUserBuilder = (holderTypes) => (record) => (
  record.applicants[0].holderType === holderTypes.USER);

const SignupList = () => {
  const { choices, constants } = useConstantContext();
  const { applicationMainSteps } = choices;
  const isUser = isUserBuilder(constants.holderTypes);

  const { data: adminList, error: adminListError, isLoading: adminListLoading } = useGetAdminList();

  const signupFilters = useSignupFilters();

  return (
    <List
      title="Signups list"
      filters={signupFilters}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <HeadedDatagrid
        head={(
          <>
            <AutoRefresh />
            <PageTitle title="Signup | List" />
          </>
        )}
      >
        <FunctionField
          label="Queue timestamp"
          render={(record) => (record.queueTimestamp && moment().isSame(record.queueTimestamp, 'day') ? (
            <Chip style={styles.handled} label={moment(record.queueTimestamp).format('YYYY-MM-DD HH:mm')} />
          ) : (
            <Chip
              style={styles.notHandeled}
              label={record.queueTimestamp
                ? moment(record.queueTimestamp).format('YYYY-MM-DD HH:mm')
                : `NP: ${moment(record.lastEventDate).format('YYYY-MM-DD HH:mm')}`}
            />
          ))}
        />
        <AltField
          label="Identifier"
          source="applicants[0]._id"
          predicate={(record) => isUser(record)}
          primary={(
            <ReferenceField reference="user" source="id">
              <SocialSecurityNumberField source="socialSecurityNumber" />
            </ReferenceField>
          )}
          secondary={(
            <ReferenceField reference="organisation" source="id">
              <OrganisationNumberField source="organisationNumber" />
            </ReferenceField>
          )}
        />
        <AltField
          label="Name"
          source="applicants[0]._id"
          link={false}
          predicate={(record) => isUser(record)}
          primary={(
            <ReferenceField reference="user" source="id">
              <FunctionField render={(record) => (`${record.firstName || ''} ${record.lastName || ''}`)} />
            </ReferenceField>
          )}
          secondary={(
            <ReferenceField reference="organisation" source="id">
              <TextField source="name" />
            </ReferenceField>
          )}
        />
        <MoneyField label="Loan Amount" source="loanTerms.amount" />
        <TextField source="loanTerms.nominalRate" label="Nominal rate" />
        <AccountCategoryChip label="Category" />
        <TextField label="Account Type" source="accountType" />
        <TextField label="UTM Source" source="trackingData.utmSource" />
        <SelectField
          label="Action to take"
          source="applicationStep"
          choices={applicationMainSteps}
        />
        <FunctionField
          label="Has Bank Details"
          render={(record) => {
            if (record.hasBankDetails === true) return 'Yes';
            if (record.hasBankDetails === false) return 'No';
            return '';
          }}
        />
        <AssignedAdmin
          loading={adminListLoading}
          error={adminListError}
          adminList={adminList}
          source="admin"
          label="Assigned to"
          resource="application"
          minimal
        />
        <ReferenceField label="" source="id" reference="application">
          <Icon />
        </ReferenceField>
      </HeadedDatagrid>
    </List>
  );
};

export default SignupList;
