import React from 'react';
import PropTypes from 'prop-types';
import getOr from 'lodash/fp/getOr';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';

import { numberFormat } from '../../utils/formatters';

const MoneyField = ({
  source,
  value,
}) => {
  const record = useRecordContext();
  return (record ? <Typography>{numberFormat(getOr(value, source, record))}</Typography> : null);
};

MoneyField.propTypes = {
  source: PropTypes.string,
  value: PropTypes.any,
};

MoneyField.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
  source: null,
  value: null,
  textAlign: 'right', // eslint-disable-line react/default-props-match-prop-types
};

export default MoneyField;
