import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

import Chip from '@mui/material/Chip';
import { useConstantContext } from './ConstantsContext';

const margin = '0 1rem';
const INSURANCE = '#dddddd';
const LOAN = '#ff9800';
const SAVING = '#97de45';
const INVESTMENT = '#45a6de';
const TRANSACTIONAL = '#dddddd';
const FACTORING = '#ff9800';
const FINANCING = '#F9D342';
const INSTALMENT = '#ff9800';
const LEASING = '#ff9800';

const getChipBuilder = (accountFeatures) => function (accountType) {
  const {
    insuranceCategory,
    investmentCategory,
    loanCategory,
    savingCategory,
    transactionalCategory,
    factoringCategory,
    financingCategory,
    instalmentCategory,
    leasingCategory,
  } = accountFeatures;

  const chipProps = { style: { margin } };

  if (insuranceCategory.includes(accountType)) {
    chipProps.style.backgroundColor = INSURANCE;
    chipProps.label = 'Insurance';
  } else if (loanCategory.includes(accountType)) {
    chipProps.style.backgroundColor = LOAN;
    chipProps.label = 'Loan';
  } else if (savingCategory.includes(accountType)) {
    chipProps.style.backgroundColor = SAVING;
    chipProps.label = 'Saving';
  } else if (investmentCategory.includes(accountType)) {
    chipProps.style.backgroundColor = INVESTMENT;
    chipProps.label = 'Investment';
  } else if (transactionalCategory.includes(accountType)) {
    chipProps.style.backgroundColor = TRANSACTIONAL;
    chipProps.label = 'Transactional';
  } else if (factoringCategory.includes(accountType)) {
    chipProps.style.backgroundColor = FACTORING;
    chipProps.label = 'Factoring';
  } else if (financingCategory.includes(accountType)) {
    chipProps.style.backgroundColor = FINANCING;
    chipProps.label = 'Financing';
  } else if (instalmentCategory.includes(accountType)) {
    chipProps.style.backgroundColor = INSTALMENT;
    chipProps.label = 'Instalment';
  } else if (leasingCategory.includes(accountType)) {
    chipProps.style.backgroundColor = LEASING;
    chipProps.label = 'Leasing';
  } else return null;

  return <Chip {...chipProps} />;
};

const AccountCategoryChip = ({ accountType }) => {
  const { accountFeatures } = useConstantContext();
  const record = useRecordContext();
  const getChip = getChipBuilder(accountFeatures);
  let type;
  if (record.accountType) type = record.accountType;
  else if (accountType) type = accountType;
  else return null;

  return getChip(type);
};

AccountCategoryChip.propTypes = {
  accountType: PropTypes.string,
};

AccountCategoryChip.defaultProps = {
  accountType: '',
};

export default AccountCategoryChip;
