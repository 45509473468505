import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  required,
  useRecordContext,
} from 'react-admin';
import { ssnFormatter, ssnParser } from '../../utils/formatters';
import { socialSecurityNumber } from '../../utils/validationErrors';

import PageTitle from '../utils/PageTitle';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const BlackListTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (<>Blacklisted Item: {record.socialSecurityNumber}</>);
};

const validateStock = [required(), socialSecurityNumber];

const BlackListEdit = () => (
  <Edit title={<BlackListTitle />} mutationMode="pessimistic" redirect={false}>
    <SimpleForm>
      <PageTitle title="BlackList | Edit" />
      <TextInput readOnly source="id" />
      <TextInput source="socialSecurityNumber" validate={validateStock} format={ssnFormatter} parse={ssnParser} />
      <SimpleShowLabeled>
        <DateField source="createdAt" showTime />
      </SimpleShowLabeled>
    </SimpleForm>
  </Edit>
);

export default BlackListEdit;
