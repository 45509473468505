import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import React, { useState, useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import useInputStatus from '../hooks/useInputStatus';
import useCustomRpc from '../../hooks/useCustomRpc';

const styles = {
  ucButton: {
    float: 'right',
    color: '#ffffff',
    backgroundColor: '#6048b8',
  },
  buttonClose: {
    alignSelf: 'center',
    margin: '0.5rem 0',
  },
  buttonSubmit: {
    alignSelf: 'center',
    color: '#ffffff',
    backgroundColor: '#f44336',
    margin: '1rem 0 0.5rem',
  },
  modal: {
    width: '500px',
    padding: '32px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2) 0px 5px 8px 0px rgba(0, 0, 0, 0.14) 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    backgroundColor: '#ffffff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
  },
  ucProduct: {
    width: '15rem',
    alignSelf: 'center',
    margin: '1rem 0 0',
  },
  errorMessage: {
    alignSelf: 'center',
    marginBottom: '0.5rem',
  },
  warning: {
    color: '#f44336',
  },
};

const TakeUcCompanyButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus('');

  const { mutate: takeNewUcCompany, isLoading } = useCustomRpc({
    httpMethod: 'POST',
    path: `organisation/${record.id}/ucCompanyCheck`,
    successMessage: 'UC successfully taken',
    errorMessage: 'Error taking new UC',
    shouldRefresh: true,
  });

  const handleOpenModal = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    setOpen(false);
    setComment('');
    setCommentError('');
  }, [setComment, setCommentError]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (!comment) {
      setCommentError('You have to write a comment');
      return false;
    }

    takeNewUcCompany({ comment });

    setOpen(false);
    setComment('');
    setCommentError('');
    return true;
  }, [comment, takeNewUcCompany, setComment, setCommentError]);

  return (
    <>
      <Button
        style={styles.ucButton}
        variant="contained"
        onClick={handleOpenModal}
        disabled={isLoading}
      >
        Take new UC
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleCloseModal}
      >
        <div style={styles.modal}>
          <Typography style={styles.warning} align="center"><span role="img" aria-label="warning">⚠️</span> Taking a UC this way will not be linked to any broker and will be shown separatly in the Customer&apos;s credit questions</Typography><br />
          <Typography align="left">
            Write a comment to explain why you have to take a new UC
          </Typography>
          <TextField
            id="comment"
            label="Comment"
            multiline
            maxRows="5"
            value={comment}
            onChange={onChangeComment}
            margin="normal"
            disabled={isLoading}
            error={!!commentError}
            helperText={commentError}
          />
          <Button
            style={styles.buttonSubmit}
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Take UC
          </Button>
          <Button
            style={styles.buttonClose}
            variant="contained"
            color="secondary"
            onClick={handleCloseModal}
            disabled={isLoading}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TakeUcCompanyButton;
