import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { useRecordContext } from 'react-admin';

import ModalButton from './ModalButton';

import useCustomRpc from '../../hooks/useCustomRpc';
import onSuccessGetDocument from '../../utils/onSuccessGetDocument';

const styles = {
  documentLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0.5rem 0',
    borderTop: '1px solid gray',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  text: {
    margin: '0 0.5rem',
  },
  icon: {
    margin: '0 1rem',
    color: 'gray',
    cursor: 'pointer',
  },
};

const DocumentsField = ({
  model,
}) => {
  const record = useRecordContext();
  const resourceId = record?._id || record?.id;

  const { mutate: getDocumentForResource } = useCustomRpc({
    httpMethod: 'GET',
    errorMessage: 'Could not retrieve file',
    onSuccess: onSuccessGetDocument,
  });

  const { mutate: deleteDocumentForResource } = useCustomRpc({
    httpMethod: 'DELETE',
    successMessage: 'File removed',
    errorMessage: 'File removing failed',
    shouldRefresh: true,
  });

  const openDocument = (document) => (e) => {
    e.preventDefault();
    const { id, _id } = document;
    getDocumentForResource({
      overridePath: `${model}/${resourceId}/download/${id || _id}`,
    });
  };

  const deleteDocument = (document) => () => {
    const { id, _id } = document;
    deleteDocumentForResource({
      overridePath: `${model}/${resourceId}/remove/${id || _id}`,
    });
    return true;
  };

  return (
    <>
      <h4>Documents</h4>
      {record.documents && record.documents.map((document) => (
        <div
          key={`${document.id}-${document.name}`}
          style={styles.documentLine}
        >
          <ModalButton
            title="Delete"
            onClick={deleteDocument(document)}
          >
            <p style={styles.noWrap}>Do you really want to delete this file?</p>
          </ModalButton>
          <OpenInNew onClick={openDocument(document)} style={styles.icon} />
          <span style={styles.text}>{moment(document.date).format('YYYY-MM-DD HH:mm')}</span>
          <span style={styles.text}>{document.name}</span>
        </div>
      ))}
    </>
  );
};

DocumentsField.propTypes = {
  model: PropTypes.oneOf([
    'user',
    'organisation',
  ]).isRequired,
};

export default DocumentsField;
