import React, { useCallback } from 'react';
import { useListContext } from 'react-admin';

import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import FirstPage from '@mui/icons-material/FirstPage';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

const useSelectStyles = makeStyles({
  root: {
    fontSize: '0.875rem',
  },
});

const useToolbarStyles = makeStyles({
  root: {
    justifyContent: 'flex-end',
  },
});

const QuickPagination = () => {
  const {
    page,
    perPage,
    total,
    setPage,
    setPerPage,
  } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;

  const goToFirstPage = useCallback(() => setPage(1), [setPage]);
  const goToPreviousPage = useCallback(() => setPage(page - 1), [page, setPage]);
  const goToNextPage = useCallback(() => setPage(page + 1), [page, setPage]);
  const updatePerPage = useCallback((e) => setPerPage(e.target.value), [setPerPage]);

  const selectClasses = useSelectStyles();
  const toolbarClasses = useToolbarStyles();

  if (nbPages === 0) return null;

  return (
    <Toolbar variant="dense" classes={toolbarClasses}>
      <Typography variant="body2">Rows per page:</Typography>
      &nbsp;&nbsp;
      <Select
        value={perPage}
        onChange={updatePerPage}
        classes={selectClasses}
        size="small"
      >
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
      </Select>
      <IconButton
        color="primary"
        key="first"
        onClick={goToFirstPage}
        disabled={page === 1}
        size="large"
      >
        <FirstPage />
      </IconButton>
      <Button color="primary" key="prev" onClick={goToPreviousPage} disabled={page === 1}>
        <ChevronLeft /> Prev
      </Button>
      <Button color="primary" key="next" onClick={goToNextPage} disabled={page === nbPages}>
        Next <ChevronRight />
      </Button>
    </Toolbar>
  );
};

export default QuickPagination;
