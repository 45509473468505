import React, { useCallback } from 'react';

import { useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import useDownloadDocument from '../../hooks/useDownloadDocument';

const AnnualTaxReportDownloadButton = () => {
  const record = useRecordContext();

  const { mutate: getAnnualTaxReport } = useDownloadDocument({
    path: `download/user/${record.id}/annual-tax-report`,
    errorMessage: 'Could not retrieve annual tax report',
  });

  const onClick = useCallback(() => {
    getAnnualTaxReport();
  }, [getAnnualTaxReport]);

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={onClick}
    >
      Download annual tax report
    </Button>
  );
};

export default AnnualTaxReportDownloadButton;
