import React from 'react';

import {
  TextField,
  ShowView,
  SimpleShowLayout,
  useShowController,
  ShowContextProvider,
} from 'react-admin';
import AccountHoldersFields from '../accounts/AccountHoldersFields';

import PageTitle from '../utils/PageTitle';
import TransactionFields from '../utils/TransactionFields';

const HeldPaymentShow = () => {
  const controllerProps = useShowController();
  return (
    <ShowContextProvider value={controllerProps}>
      <ShowView>
        <SimpleShowLayout>
          <PageTitle title="Held Payment | Show" />
          <AccountHoldersFields addLabel />
          <TextField source="currentlyHeldPaymentsCount" />
          <TextField source="currentlyHeldPaymentsAmount" />
          <TransactionFields addLabel />
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};

export default HeldPaymentShow;
