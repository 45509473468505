import React from 'react';

import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import {
  List,
  DateField,
  FunctionField,
  ReferenceField,
  ShowButton,
  TextField,
} from 'react-admin';

import useRiskReportFilters from './useRiskReportFilters';

import AltField from '../utils/AltField';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import AssignedAdmin from '../utils/AssignedAdmin';
import PageTitle from '../utils/PageTitle';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import HeadedDatagrid from '../utils/HeadedDatagrid';
import AutoRefresh from '../utils/AutoRefresh';

import useGetAdminList from '../../utils/useGetAdminList';
import { useConstantContext } from '../utils/ConstantsContext';

const isUserBuilder = (holderTypes) => (record) => (
  record.accountHolder.holderType === holderTypes.USER);

const RiskReportList = () => {
  const { constants } = useConstantContext();
  const isUser = isUserBuilder(constants.holderTypes);

  const { data: adminList, error: adminListError, isLoading: adminListLoading } = useGetAdminList();

  const riskReportFilters = useRiskReportFilters();

  return (
    <List
      title="High Risk Reports"
      filters={riskReportFilters}
      filterDefaultValues={{ status: 'pending', custom_userActive: 'true' }}
    >
      <HeadedDatagrid
        head={(
          <>
            <AutoRefresh />
            <PageTitle title="Risk Report | List" />
          </>
        )}
      >
        <DateField source="createdAt" />
        <TextField label="Holder Type" source="accountHolder.holderType" />
        <AltField
          label="Identifier"
          predicate={(record) => isUser(record)}
          primary={(
            <ReferenceField source="accountHolder._id" reference="user">
              <SocialSecurityNumberField source="socialSecurityNumber" />
            </ReferenceField>
          )}
          secondary={(
            <ReferenceField source="accountHolder._id" reference="organisation">
              <OrganisationNumberField source="organisationNumber" />
            </ReferenceField>
          )}
        />
        <TextField source="rule" />
        <TextField source="status" />
        <DateField source="isClosedAt" />
        <DateField source="reportedAt" />
        <FunctionField label="Reason" render={(record) => <Tooltip title={record.reason} placement="top"><Info /></Tooltip>} />
        <ShowButton />
        <AssignedAdmin
          loading={adminListLoading}
          error={adminListError}
          adminList={adminList}
          source="admin"
          label="Assigned to"
          resource="riskReport"
          minimal
        />
      </HeadedDatagrid>
    </List>
  );
};

export default RiskReportList;
