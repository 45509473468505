import React, { useCallback, useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Title } from 'react-admin';

import PageTitle from '../utils/PageTitle';
import ModalButton from '../utils/ModalButton';
import onSuccessGetDocument from '../../utils/onSuccessGetDocument';
import useCustomRpc from '../../hooks/useCustomRpc';

const PaymentFiles = () => {
  const {
    mutate: getPaymentsFileDocument,
    isLoading: isLoadingGetPaymentsFile,
  } = useCustomRpc({
    httpMethod: 'GET',
    error: 'Could not retrieve file',
    onSuccess: onSuccessGetDocument,
  });

  const {
    mutate: sendPaymentFile,
    isLoading: isLoadingSendPaymentFile,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: '/upload/payment-file',
    mimeType: 'formData',
    shouldRefresh: true,
  });

  const loading = isLoadingGetPaymentsFile || isLoadingSendPaymentFile;

  const [files, setFiles] = useState(null);
  const [filesError, setFilesError] = useState('');
  const onChangeFiles = useCallback(
    (e) => {
      setFilesError('');
      setFiles(e.target.files);
    },
    [setFiles, setFilesError],
  );

  const onPaymentsFileClick = useCallback(
    (bangiroCustomerNumber, fileType) => () => getPaymentsFileDocument({
      overridePath: `files/${fileType}/${bangiroCustomerNumber}`,
    }),
    [getPaymentsFileDocument],
  );

  const onUploadPaymentFileClick = useCallback(() => {
    setFilesError('');
    if (!files) {
      setFilesError('Please choose a payment file to upload');
      return false;
    }
    const formDataFields = [...files].map((file) => ({
      fieldName: file.name,
      value: file,
    }));
    sendPaymentFile(formDataFields);
    return true;
  }, [files, setFilesError, sendPaymentFile]);

  const onClose = () => {
    setFiles('');
    setFilesError('');
  };

  return (
    <Card>
      <CardContent>
        <Title title="Payment Files" />
        <PageTitle title="Payment Files" />
        <Typography variant="subtitle1">Download Payment File</Typography>
        <Button
          color="primary"
          onClick={onPaymentsFileClick('556873', 'supplier-payments-order')}
          disabled={loading}
        >
          New Payouts Orders
        </Button>
        <Button
          color="primary"
          onClick={onPaymentsFileClick('556873', 'autogiro-orders')}
          disabled={loading}
        >
          New Autogiro Orders (Swedbank)
        </Button>
        <Button
          color="primary"
          onClick={onPaymentsFileClick('378845', 'autogiro-orders')}
          disabled={loading}
        >
          New Autogiro Orders (Nordea)
        </Button>
        <Button
          color="primary"
          onClick={onPaymentsFileClick('418981', 'autogiro-orders')}
          disabled={loading}
        >
          New Autogiro Orders (Nordiska)
        </Button>
        <Typography variant="subtitle1">Upload Payment File</Typography>
        <ModalButton
          title="Upload payment file"
          onClick={onUploadPaymentFileClick}
          onClose={onClose}
        >
          <Typography align="center">Choose a payment file to upload</Typography>
          <input
            type="file"
            id="file"
            name="file"
            onChange={onChangeFiles}
            multiple
          />
          <Typography color="error" align="center">{filesError}</Typography>
        </ModalButton>
      </CardContent>
    </Card>
  );
};

export default PaymentFiles;
