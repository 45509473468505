import React from 'react';
import PropTypes from 'prop-types';
import {
  Labeled,
  TextField,
  DateField,
  BooleanField,
  useRecordContext,
  RecordContextProvider,
} from 'react-admin';
import moment from 'moment';
import get from 'lodash/fp/get';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import MoneyField from './MoneyField';

import validators from '../../utils/validators';
import { fromCammelCaseToWords } from '../../utils/formatters';

const { isDefined } = validators.generics;

const ObjectFields = ({ source }) => {
  const record = useRecordContext();
  const entries = Object.entries(get(source, record))
    .filter(([, value]) => isDefined(value) && !Array.isArray(value));

  return entries.length > 0 ? (
    <Box sx={{ minWidth: '30rem', border: '2px solid rgba(200,200,200,.5)', padding: '0 1rem' }}>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {entries.map(([key, value]) => {
          let Component = TextField;
          if (typeof value === 'boolean') Component = BooleanField;
          else if (typeof value === 'number') Component = MoneyField;
          else if (moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) Component = DateField;
          return (
            <RecordContextProvider value={get(source, record)} key={key}>
              <Grid item xs={3}>
                <Labeled label={fromCammelCaseToWords(key)}>
                  <Component source={key} />
                </Labeled>
              </Grid>
            </RecordContextProvider>
          );
        })}
      </Grid>
    </Box>
  ) : null;
};

ObjectFields.propTypes = {
  source: PropTypes.string.isRequired,
};

ObjectFields.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  label: '',
};

export default ObjectFields;
