import React, { useState, useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import moment from 'moment';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { numberFormat } from '../../utils/formatters';

import useCustomQuery from '../../hooks/useCustomQuery';

const styles = {
  input: {
    width: 256,
    marginTop: 16,
    marginBottom: 8,
    marginRight: 8,
  },
};

const SolvingAmount = () => {
  const account = useRecordContext();
  const [solvingDate, setSolvingDate] = useState(moment().format('YYYY-MM-DD'));
  const [shouldFetch, setShouldFetch] = useState(false);

  const { data: solvingAmount } = useCustomQuery({
    httpMethod: 'GET',
    path: `account/${account.id}/solvingAmount?solvingDate=${solvingDate}`,
    key: `solvingAmount-${account.id}-${solvingDate}`,
    enabled: shouldFetch,
  });

  const handleChangeDate = useCallback((event) => {
    const date = event.target.value;
    setSolvingDate(date);
    setShouldFetch(true);
  }, []);

  if (!account) return null;
  return (
    <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <TextField
          style={styles.input}
          id="solving-amount"
          label="Solving Amount"
          value={numberFormat(solvingAmount?.solvingAmountAtDate ?? account.todaySolvingAmount)}
        />
        <TextField
          style={styles.input}
          id="solving-date"
          type="date"
          label="Select solving date"
          onChange={handleChangeDate}
          value={solvingDate}
        />
      </div>
      {moment().isAfter(solvingDate, 'day') && (
        <>
          <Typography variant="caption">Keep in mind the payment dates in our system is the date we <b>received</b> the payment.</Typography>
          <Typography variant="caption">Thus select 1 bank day before the payment date if you want to check how much should the customer have paid</Typography>
        </>
      )}
    </div>
  );
};

export default SolvingAmount;
