import React from 'react';
import { useRecordContext } from 'react-admin';

import Chip from '@mui/material/Chip';
import { useConstantContext } from '../utils/ConstantsContext';

const styles = {
  alert: { marginRight: '5px', backgroundColor: '#ff0000', color: '#f2f2f2' },
  info: { marginRight: '5px', backgroundColor: '#e6e600' },
  good: { marginRight: '5px', backgroundColor: '#2eb82e', color: '#f2f2f2' },
};

const RiskReportTitle = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { amlStatuses } = constants;

  if (!record) return null;

  let chipStyle;
  switch (record.status) {
    case amlStatuses.CONFIRMED:
      chipStyle = styles.alert;
      break;
    case amlStatuses.RESOLVED:
      chipStyle = styles.good;
      break;
    default:
      chipStyle = styles.info;
  }

  let reportedChip = null;
  if (record.reportedAt) reportedChip = <Chip style={styles.info} label="reported" />;
  if (record.ignoreReporting) reportedChip = <Chip style={styles.info} label="ignored reporting" />;

  return (
    <span>Risk Report <Chip style={chipStyle} label={record.status} />{reportedChip}</span>
  );
};

export default RiskReportTitle;
