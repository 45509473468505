import React from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import { isBeforeFactory } from '../../utils/signup/compareApplicationSteps';

import SendSmsButton from './SendSmsButton';
import { useConstantContext } from './ConstantsContext';

const SendCompleteSmsButton = ({
  application,
}) => {
  const applicantRecord = useRecordContext();
  const { constants, accountFeatures } = useConstantContext();
  const { smsTypes, applicationSteps, orderedApplicationSteps } = constants;

  const completableApplicationTypes = accountFeatures.completable;
  const isBefore = isBeforeFactory(orderedApplicationSteps);

  const isCompletable = !application.rejectedByCustomer
    && completableApplicationTypes.includes(application.accountType)
    && application.currentDecisionStatus !== 'deny'
    && isBefore(application.applicationStep, applicationSteps.SUBMITTED);
  if (!isCompletable) return null;

  return (
    <SendSmsButton
      title="Send&nbsp;complete&nbsp;link&nbsp;sms"
      applicationId={application.id}
      userId={applicantRecord.id}
      smsType={smsTypes.COMPLETE_APPLICATION}
    />
  );
};

SendCompleteSmsButton.propTypes = {
  application: PropTypes.object.isRequired,
};

export default SendCompleteSmsButton;
