import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import {
  TextInput,
} from 'react-admin';

import ModalButton from './ModalButton';

import useInputStatus from '../hooks/useInputStatus';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const MIN_TEXT_LIMIT = 160;
const TOTAL_TEXT_LIMIT = 1600;
const SMS_COST = 0.055;
const SMS_CURRENCY = '$';

const getTextLimit = (wordCounter) => (wordCounter
  ? `${MIN_TEXT_LIMIT * Math.ceil(wordCounter / MIN_TEXT_LIMIT)} (${Math.ceil(wordCounter / MIN_TEXT_LIMIT)} sms) = ${SMS_COST * Math.ceil(wordCounter / MIN_TEXT_LIMIT)}${SMS_CURRENCY}`
  : `${MIN_TEXT_LIMIT} (1 sms) = ${SMS_COST}${SMS_CURRENCY}`);

const SendFreeTextSmsButton = ({
  userId,
}) => {
  const [
    text,
    setText,
    onChangeText,
    errorText,
    setErrorText,
  ] = useInputStatus();

  const { constants } = useConstantContext();
  const { smsTypes } = constants;

  const { mutate: sendSms } = useCustomRpc({
    httpMethod: 'POST',
    path: 'user/send-sms',
    successMessage: 'Sms sent successfuly',
    errorMessage: 'Failed to send sms',
  });

  const onClick = useCallback(() => {
    setErrorText('');
    if (!text) {
      setErrorText('Cannot send an empty message');
      return false;
    }

    if (text.length > TOTAL_TEXT_LIMIT) {
      setErrorText(`Cannot send too long message (${TOTAL_TEXT_LIMIT} characters max allowed)`);
      return false;
    }

    sendSms({ userId, message: text, smsType: smsTypes.FREE_TEXT });
    setText('');
    return true;
  }, [sendSms, setErrorText, setText, smsTypes.FREE_TEXT, text, userId]);

  const onClose = () => {
    setText('');
    setErrorText('');
  };

  return (
    <ModalButton
      title="Send SMS"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <TextInput
        id="text"
        name="text"
        label="Text"
        multiline
        rows="6"
        rowsMax="10"
        fullWidth
        value={text}
        onChange={onChangeText}
      />
      <div style={{ width: '100%' }}>
        <Typography paragraph align="left">{`${text.length}/${getTextLimit(text.length)}`}</Typography>
      </div>
      <Typography paragraph color="error" variant="body2">
        Please note that the text might fit in more than 1 sms if over 160 characters.
        The customer will not notice it, but the cost to send the message will be higher.
      </Typography>
      <Typography paragraph color="error">{errorText}</Typography>
    </ModalButton>
  );
};

SendFreeTextSmsButton.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default SendFreeTextSmsButton;
