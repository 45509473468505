import React from 'react';

import {
  ArrayField,
  ShowView,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField,
  Datagrid,
  ReferenceField,
  FunctionField,
  useShowController,
  ShowContextProvider,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';

import ObjectFields from '../utils/ObjectFields';

const UcCheckShow = () => {
  const controllerProps = useShowController();
  return (
    <ShowContextProvider value={controllerProps}>
      <ShowView>
        <SimpleShowLayout>
          <ReferenceField label="User Id" source="user" reference="user">
            <TextField source="id" />
          </ReferenceField>
          <PageTitle title="UC Check | Show" />
          <BooleanField source="isComplete" />
          <TextField source="product" />
          <TextField source="score" />
          <DateField source="createdAt" label="Created At" />
          <TextField source="decision" />
          <SocialSecurityNumberField source="socialSecurityNumber" />
          <TextField source="firstName" label="First name" />
          <TextField source="lastName" label="Last name" />
          <TextField source="fullName" label="Full name" />
          <TextField source="streetAddress" label="Address" />
          <TextField source="town" label="Town" />
          <TextField source="postcode" label="Post code" />
          <DateField source="recentlyRegisteredAt" label="Recently register at" />
          <BooleanField source="runsBusiness" label="Run Business" />
          <BooleanField source="passportLost" label="Passport lost" />
          <BooleanField source="protectedAddress" label="Protected address" />
          <BooleanField source="legallyIncompetent" label="Legally incompetent" />
          <BooleanField source="idLost" label="Id lost" />
          <BooleanField source="drivingLicenseLost" label="Driving license lost" />
          <ObjectFields source="previousDebts" label="Previous debt" />
          <ObjectFields source="municipality" label="Municipality" />
          <ObjectFields source="parish" label="Parish" />
          <ObjectFields source="partner" label="Partner" />
          <ObjectFields source="maritalStatus" label="marital Status" />
          <ObjectFields source="debts" label="Debts" />
          <ObjectFields source="bankruptcyApplication" label="Bankruptcy Application" />
          <ObjectFields source="county" label="County" />
          <ObjectFields source="registeredAddress" label="Registered Address" />
          <ObjectFields source="block" label="Block" />
          <ArrayField source="properties" label="Properties">
            <Datagrid bulkActionButtons={null}>
              <TextField source="name" />
              <TextField source="type.description" />
              <TextField source="county" />
              <TextField source="town" />
              <TextField source="parish" />
              <TextField source="landValue" />
              <TextField source="buildingValue" />
              <TextField source="assessedValue" />
              <TextField source="ownedShare" />
              <DateField source="purchasedAt" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="incomes" label="Incomes">
            <Datagrid bulkActionButtons={null}>
              <DateField source="incomeDate" />
              <TextField source="incomeAmount" />
              <TextField source="surplusEmployment" />
              <TextField source="surplusActiveBusiness" />
              <TextField source="surplusPassiveBusiness" />
              <TextField source="surplusCapital" />
              <TextField source="capitalDeficit" />
              <TextField source="assessedIncome" />
              <TextField source="taxOnFoundRetainedBusiness" />
              <TextField source="capitalDeficitPassiveBusiness" />
            </Datagrid>
          </ArrayField>
          <FunctionField label="Total Credit Questions" render={(record) => (record.creditQuestions ? record.creditQuestions.length : '')} />
          <ArrayField source="creditQuestions" label="Credit Questions">
            <Datagrid bulkActionButtons={null}>
              <DateField source="date" />
              <TextField source="name" />
              <TextField source="amount" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="creditEngagements" label="Credit Engagements">
            <Datagrid bulkActionButtons={null}>
              <DateField source="infoDate" />
              <TextField source="noCredits" />
              <TextField source="noCreditIssuers" />
              <TextField source="grantedCredit" />
              <TextField source="usedInstallments" />
              <TextField source="usedAccountCredits" />
              <TextField source="usedBlanco" />
              <TextField source="usedHousingVilla" />
              <TextField source="usedHousingFlat" />
              <TextField source="totUsed" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="nonPaymentRecords" label="Non Payments Records">
            <Datagrid bulkActionButtons={null}>
              <DateField source="date" />
              <TextField source="type" />
              <TextField source="amount" />
              <TextField source="claimer" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="historicalAddresses" label="Historical Addresses">
            <Datagrid bulkActionButtons={null}>
              <DateField source="changedAt" />
              <TextField source="streetAddress" />
              <TextField source="postcode" />
              <TextField source="town" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="errorMessages" label="Error Messages">
            <Datagrid bulkActionButtons={null}>
              <TextField source="code" />
              <TextField source="message" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};

export default UcCheckShow;
