import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

import match from '../../utils/match';

const AltField = ({
  predicate,
  primary,
  secondary,
  ...props
}) => {
  const record = useRecordContext();

  return match()
    .on(() => record && predicate(record), () => React.cloneElement(primary, props))
    .otherwise(() => React.cloneElement(secondary, props));
};

AltField.propTypes = {
  predicate: PropTypes.func.isRequired,
  primary: PropTypes.node.isRequired,
  secondary: PropTypes.node.isRequired,
};

export default AltField;
