import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import TextField from '@mui/material/TextField';

import ModalButton from '../utils/ModalButton';

import validators from '../../utils/validators';

import useInputStatus from '../hooks/useInputStatus';

import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const TakeTrapetsButton = React.memo(() => {
  const record = useRecordContext();
  const id = record?.id;

  const { mutate: takeNewTrapets } = useCustomRpc({
    httpMethod: 'POST',
    path: 'trapetsChecks',
    successMessage: 'Trapets successfuly taken',
    errorMessage: 'Error taking new Trapets',
    shouldRefresh: true,
  });

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus();

  const onClick = useCallback(() => {
    if (!isDefined(comment)) {
      setCommentError('Please add a valid comment');
      return false;
    }
    takeNewTrapets({
      userId: id,
      comment,
    });
    return true;
  }, [comment, takeNewTrapets, id, setCommentError]);

  const onClose = useCallback(() => {
    setComment('');
  }, [setComment]);

  if (!record) return null;

  return (
    <ModalButton
      title="Take Trapets"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <TextField
        label="Leave a comment"
        multiline
        maxRows="2"
        value={comment}
        onChange={onChangeComment}
        error={!!commentError}
        helperText={commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
});

export default TakeTrapetsButton;
