import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';
import { adminRoles as adminRolesValidation, email } from '../../utils/validationErrors';

import SelectArrayInput from '../input/SelectArrayInput';
import PageTitle from '../utils/PageTitle';
import MFA from './MFA';
import { useConstantContext } from '../utils/ConstantsContext';

const AdminTitle = () => {
  const admin = useRecordContext();
  return (<>Admin: {admin ? `${admin.firstName} ${admin.lastName}` : ''}</>);
};

const validateStock = [required()];

const AdminEdit = () => {
  const { choices } = useConstantContext();
  const { adminRoles } = choices;
  return (
    <Edit title={<AdminTitle />} mutationMode="pessimistic" redirect={false}>
      <SimpleForm>
        <PageTitle title="Admin | Edit" />
        <TextInput readOnly source="id" validate={validateStock} />
        <TextInput source="firstName" validate={validateStock} />
        <TextInput source="lastName" validate={validateStock} />
        <TextInput source="email" validate={[...validateStock, email]} type="email" />
        <TextInput source="password" type="password" />
        <SelectArrayInput label="Roles" source="roles" choices={adminRoles} validate={[adminRolesValidation]} />
        <MFA />
      </SimpleForm>
    </Edit>
  );
};

export default AdminEdit;
