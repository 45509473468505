import React from 'react';

import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  TextField,
  NumberField,
} from 'react-admin';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import AccountStatusChip from './AccountStatusChip';

import AltField from '../utils/AltField';
import PageTitle from '../utils/PageTitle';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import MoneyField from '../utils/MoneyField';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import HeadedDatagrid from '../utils/HeadedDatagrid';
import AutoRefresh from '../utils/AutoRefresh';
import AccountCategoryChip from '../utils/AccountCategoryChip';
import { useConstantContext } from '../utils/ConstantsContext';

const isUserBuilder = (holderTypes) => (record) => (
  record.accountHolders[0].holderType === holderTypes.USER
);

const AccountItem = ({ isListPage, bulkActionButtons }) => {
  const { constants } = useConstantContext();
  const { holderTypes } = constants;
  const isUser = isUserBuilder(holderTypes);

  return (
    <HeadedDatagrid
      head={isListPage && (
        <>
          <AutoRefresh />
          <PageTitle title="Account | List" />
        </>
      )}
      bulkActionButtons={bulkActionButtons}
    >
      <TextField source="id" />
      <AltField
        label="Identifier"
        source="accountHolders[0]._id"
        predicate={(record) => isUser(record)}
        primary={(
          <ReferenceField reference="user" source="id">
            <SocialSecurityNumberField source="socialSecurityNumber" />
          </ReferenceField>
        )}
        secondary={(
          <ReferenceField reference="organisation" source="id">
            <OrganisationNumberField source="organisationNumber" />
          </ReferenceField>
        )}
      />
      <AltField
        label="Name"
        source="accountHolders[0]._id"
        link={false}
        predicate={(record) => isUser(record)}
        primary={(
          <ReferenceField reference="user" source="id">
            <FunctionField render={(record) => (
              <Typography>{record.firstName} {record.lastName}</Typography>
            )}
            />
          </ReferenceField>
        )}
        secondary={(
          <ReferenceField reference="organisation" source="id">
            <TextField source="name" />
          </ReferenceField>
        )}
      />
      <TextField source="accountType" label="Account Type" />
      <AccountCategoryChip label="Category" />
      <MoneyField source="remainingCapitalAmount" />
      <DateField source="createdAt" />
      <NumberField label=" Remaining payments" source="estimatedRepaymentTime" />
      <FunctionField label="Status" render={(record) => <AccountStatusChip status={record.status} passedInvoicesPayed={record.passedInvoicesPayed} />} />
      <EditButton />
    </HeadedDatagrid>
  );
};

AccountItem.propTypes = {
  isListPage: PropTypes.bool,
  bulkActionButtons: PropTypes.node,
};

AccountItem.defaultProps = {
  isListPage: false,
  bulkActionButtons: null,
};

export default AccountItem;
