import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { numberFormat } from '../../../utils/formatters';

const rowStyle = {
  root: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
};

const StyledTableRow = withStyles(rowStyle)(TableRow);

const GraphCategorisationDetails = ({ negativeTransactions, positiveTransactions }) => (
  <Grid
    container
    direction="row"
    justifyContent="space-around"
  >
    <div>
      <Typography component="h2">Positive Transactions</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Category</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          positiveTransactions.map((transaction) => (
            <StyledTableRow key={transaction.amount}>
              <TableCell variant="body">
                {transaction.description}
              </TableCell>
              <TableCell variant="body">
                {numberFormat(transaction.amount)}
              </TableCell>
              <TableCell variant="body">
                {transaction.category}
              </TableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
    </div>
    <div>
      <Typography component="h2">Negative Transactions</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Category</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          negativeTransactions.map((transaction) => (
            <StyledTableRow key={transaction.amount}>
              <TableCell variant="body">
                {transaction.description}
              </TableCell>
              <TableCell variant="body">
                {numberFormat(transaction.amount)}
              </TableCell>
              <TableCell variant="body">
                {transaction.category}
              </TableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
    </div>
  </Grid>
);

GraphCategorisationDetails.propTypes = {
  positiveTransactions: PropTypes.array.isRequired,
  negativeTransactions: PropTypes.array.isRequired,
};

export default GraphCategorisationDetails;
