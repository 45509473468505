import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Confirm } from 'react-admin';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'auto',
    height: 'auto',
    maxHeight: '90%',
    minWidth: '35rem',
    minHeight: '15rem',
    padding: '2rem',
    boxShadow: '0px 5px 8px 0px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
  },
  button: {
    margin: '0.5rem',
  },
  mainWrapper: (props) => (props.right ? {
    margin: '0.5rem',
    float: 'right',
    ...props.buttonWrapperStyle,
  } : props.buttonWrapperStyle),
}));

const ModalButton = ({
  onClick,
  onOpen,
  onClose,
  disabled,
  title,
  children,
  right,
  buttonWrapperStyle,
  confirmationMessage,
}) => {
  const classes = useStyles({ right, buttonWrapperStyle });
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    setConfirmOpen(false);
    if (onClick()) setModalOpen(false);
  }, [onClick]);

  const handleOpenModal = useCallback((e) => {
    e.preventDefault();
    onOpen();
    setModalOpen(true);
  }, [onOpen]);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    onClose();
    setModalOpen(false);
    setConfirmOpen(false);
  }, [onClose]);

  const useConfirm = useCallback((e) => {
    e.preventDefault();
    setConfirmOpen(true);
  }, []);

  return (
    <div className={classes.mainWrapper}>
      <Button
        title="Manually step update"
        disabled={disabled}
        onClick={handleOpenModal}
        variant="contained"
        color="primary"
        size="small"
      >
        {title}
      </Button>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <section className={classes.modal}>
          <Typography align="center" variant="h6">{title}</Typography>
          {children}
          <Button
            variant="contained"
            onClick={confirmationMessage ? useConfirm : handleClick}
            disabled={disabled}
            color="primary"
            size="small"
            className={classes.button}
          >
            {title}
          </Button>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            size="small"
            className={classes.button}
          >
            Close
          </Button>
        </section>
      </Modal>
      { confirmationMessage && (
        <Confirm
          isOpen={isConfirmOpen}
          title="Confirm Action"
          content={confirmationMessage}
          onConfirm={handleClick}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

ModalButton.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonWrapperStyle: PropTypes.shape({}),
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  right: PropTypes.bool,
  disabled: PropTypes.bool,
  confirmationMessage: PropTypes.string,
};

ModalButton.defaultProps = {
  onClose: () => null,
  onOpen: () => null,
  buttonWrapperStyle: {},
  right: false,
  disabled: false,
  confirmationMessage: undefined,
};

export default ModalButton;
