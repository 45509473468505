import React from 'react';
import {
  ReferenceField,
  TextField,
  Datagrid,
  DateField,
  ShowButton,
  BooleanField,
} from 'react-admin';

const UcInfoFieldsPreview = () => (
  <Datagrid bulkActionButtons={null}>
    <TextField source="id" />
    <DateField source="createdAt" label="Date" />
    <BooleanField source="isComplete" />
    <TextField source="product" />
    <TextField source="score" />
    <ReferenceField label="Social security number" source="user" reference="user">
      <TextField source="socialSecurityNumber" />
    </ReferenceField>
    <TextField source="fullName" label="Full name" />
    <ShowButton />
  </Datagrid>
);

export default UcInfoFieldsPreview;
