import React from 'react';

import {
  TextInput,
} from 'react-admin';
import { useConstantContext } from '../utils/ConstantsContext';

import SelectInput from '../input/SelectInput';

const useAccountFilters = () => {
  const { choices } = useConstantContext();
  const { pledgees, accountStatuses, accountTypes } = choices;
  return [
    <TextInput alwaysOn label="Account ID" source="_id" />,
    <TextInput alwaysOn label="User ID" source="accountHolders._id" />,
    <TextInput alwaysOn label="OCR Number" source="ocrNumber" />,
    <TextInput label="Invoice Number" source="invoices.referenceNumber" />,
    <SelectInput
      alwaysOn
      label="Status"
      source="status"
      choices={accountStatuses}
    />,
    <SelectInput
      alwaysOn
      label="Type"
      source="accountType"
      choices={accountTypes}
    />,
    <SelectInput
      alwaysOn
      label="Pledged to"
      source="pledgedTo"
      choices={[
        { id: 'null', name: 'Not pledged' },
        ...pledgees,
      ]}
    />,
    <SelectInput
      alwaysOn
      label="Pledgeable to"
      source="custom_pledgeableTo"
      choices={pledgees}
    />,
    <TextInput label="Public ID" source="publicId" />,
    <TextInput label="Transaction ID" source="transactions._id" />,
  ];
};

export default useAccountFilters;
