import React, { useCallback, useMemo } from 'react';
import { usePermissions, useRecordContext } from 'react-admin';

import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import ModalButton from './ModalButton';

import { numberParser } from '../../utils/formatters';
import validators from '../../utils/validators';
import useInputStatus from '../hooks/useInputStatus';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined, isStrictlyPositiveNumber } = validators.generics;

const UpdateNominalRateButton = () => {
  const { permissions } = usePermissions();
  const record = useRecordContext();

  const { constants } = useConstantContext();
  const { userRoles: { CAPITAL_MANAGER } } = constants;

  const { id, currentTerms } = record;

  const { mutate: updateNominalRate } = useCustomRpc({
    httpMethod: 'POST',
    path: `account/${id}/nominal-rate`,
    successMessage: 'Nominal rate updated on account',
    errorMessage: 'Failed to update nominal rate',
    shouldRefresh: true,
  });

  const [
    nominalRate,
    setNominalRate,
    onChangeNominalRate,
    nominalRateError,
    setNominalRateError,
  ] = useInputStatus(currentTerms.nominalRate, numberParser);

  const [
    adminMessage,
    setAdminMessage,
    onChangeAdminMessage,
    adminMessageError,
    setAdminMessageError,
  ] = useInputStatus('');

  const onClick = useCallback(() => {
    setNominalRateError('');
    setAdminMessageError('');
    if (!isDefined(nominalRate)) {
      setNominalRateError('Please insert a nominal rate');
      return false;
    }
    if (!isStrictlyPositiveNumber(nominalRate)) {
      setNominalRateError('Please insert a valid nominal rate');
      return false;
    }

    if (!isDefined(adminMessage)) {
      setNominalRateError('Please add a reason message');
      return false;
    }

    const data = {
      nominalRate,
      adminMessage,
    };
    updateNominalRate(data);
    return true;
  }, [setNominalRateError, setAdminMessageError, nominalRate, adminMessage, updateNominalRate]);

  const onClose = useCallback(() => {
    setNominalRate(currentTerms.nominalRate);
    setAdminMessage('');
    setNominalRateError('');
    setAdminMessageError('');
  }, [setNominalRate, currentTerms.nominalRate, setAdminMessage, setNominalRateError,
    setAdminMessageError]);

  const shouldNotifyCustomer = useMemo(
    () => nominalRate > currentTerms.nominalRate,
    [nominalRate, currentTerms],
  );

  if (!permissions
  || !permissions.includes(CAPITAL_MANAGER)) return null;

  return (
    <ModalButton
      title="Update nominal rate"
      onClick={onClick}
      onClose={onClose}
    >
      <TextField
        type="number"
        label="New nominal rate"
        onChange={onChangeNominalRate}
        value={nominalRate}
        error={!!nominalRateError}
        helperText={nominalRateError}
        margin="normal"
        fullWidth
      />
      <TextField
        type="string"
        label="Add reason"
        onChange={onChangeAdminMessage}
        value={adminMessage}
        error={!!adminMessageError}
        helperText={adminMessageError}
        margin="normal"
        fullWidth
      />
      { shouldNotifyCustomer && (
      <Typography
        color="error"
      >
        Customer must be notified of the interest increase!
      </Typography>
      )}
    </ModalButton>
  );
};

export default UpdateNominalRateButton;
