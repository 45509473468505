// Stage config
module.exports = {
  ajaxTimeout: 10000,
  css: [
    'bootstrap',
    'bootcards-desktop',
    'font-awesome',
    'react-autosuggest',
    'style',
  ],
  sessionStore: {
    instance: 'connect-memcached',
    options: {
      hosts: ['127.0.0.1:11211'],
    },
  },
  sentryDsn: 'https://9507fb5c8e2843698c7a41638869dfc9@o111207.ingest.sentry.io/5951844',
  sentryRelease: process.env.SENTRY_RELEASE,
};
