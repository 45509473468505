import React, { useCallback, useMemo } from 'react';
import { useRecordContext, usePermissions } from 'react-admin';

import TextField from '@mui/material/TextField';

import useInputStatus from '../hooks/useInputStatus';

import ModalButton from '../utils/ModalButton';

import { isSameOrAfterFactory } from '../../utils/signup/compareApplicationSteps';

import validators from '../../utils/validators';
import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const {
  optional,
  required,
  isString,
} = validators.generics;
const {
  email: emailValidator,
  phone: phoneValidator,
} = validators;

const AddSignatory = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const { constants } = useConstantContext();
  const {
    userRoles,
    accountTypes,
    applicationSteps,
    orderedApplicationSteps,
  } = constants;
  const { ROLE_SUPER_ADMIN } = userRoles;

  const canAddSignatory = useMemo(
    () => permissions && permissions.includes(ROLE_SUPER_ADMIN),
    [permissions, ROLE_SUPER_ADMIN],
  );

  const {
    id,
    accountType,
    applicationStep,
  } = record;

  const [
    user,
    setUser,
    onChangeUser,
    errorUser,
    setErrorUser,
  ] = useInputStatus('');
  const [
    comment,
    setComment,
    onChangeComment,
    errorComment,
    setErrorComment,
  ] = useInputStatus('');
  const [
    email,
    setEmail,
    onChangeEmail,
    errorEmail,
    setErrorEmail,
  ] = useInputStatus('');
  const [
    phone,
    setPhone,
    onChangePhone,
    errorPhone,
    setErrorPhone,
  ] = useInputStatus('');

  const isAgreementSigned = useCallback(
    () => isSameOrAfterFactory(orderedApplicationSteps)(
      applicationStep,
      applicationSteps.SIGNED,
    ),
    [orderedApplicationSteps, applicationStep, applicationSteps.SIGNED],
  );

  const { mutate: addSignatory } = useCustomRpc({
    httpMethod: 'POST',
    path: `application/${id}/add-signatory`,
    successMessage: 'Signatory added',
    errorMessage: 'Error adding signatory',
    shouldRefresh: true,
  });

  const handleSubmit = useCallback(() => {
    setErrorComment('');
    setErrorEmail('');
    setErrorPhone('');
    setErrorUser('');

    if (!required(isString, comment)) {
      setErrorComment('A comment is required');
      return false;
    }

    if (!required(isString, user)) {
      setErrorUser('A user is required');
      return false;
    }

    if (!optional(emailValidator, email)) {
      setErrorEmail('Invalid email');
      return false;
    }

    if (!optional(phoneValidator, phone)) {
      setErrorPhone('Invalid phone number');
      return false;
    }

    addSignatory({
      comment,
      email,
      phone,
      user,
    });
    return true;
  }, [comment, user, email, phone, addSignatory,
    setErrorComment, setErrorEmail, setErrorPhone, setErrorUser]);

  const onClose = useCallback(() => {
    setComment('');
    setErrorComment('');
    setPhone('');
    setErrorPhone('');
    setEmail('');
    setErrorEmail('');
    setUser('');
    setErrorUser('');
  }, [setComment, setErrorComment, setPhone, setUser,
    setErrorPhone, setEmail, setErrorEmail, setErrorUser]);

  if (
    accountType !== accountTypes.PROJECT_FINANCING
    || isAgreementSigned()
    || !canAddSignatory
  ) return null;

  return (
    <ModalButton
      title="Add Signatory"
      onClick={handleSubmit}
      onClose={onClose}
    >
      <TextField
        fullWidth
        label="Signatory User SSN"
        value={user}
        onChange={onChangeUser}
        helperText={errorUser}
        error={!!errorUser}
      />
      <TextField
        fullWidth
        label="Email"
        value={email}
        onChange={onChangeEmail}
        helperText={errorEmail}
        error={!!errorEmail}
      />
      <TextField
        fullWidth
        label="Phone"
        value={phone}
        onChange={onChangePhone}
        type="phone"
        helperText={errorPhone}
        error={!!errorPhone}
      />
      <TextField
        fullWidth
        label="Comment"
        multiline
        maxRows="4"
        value={comment}
        onChange={onChangeComment}
        helperText={errorComment}
        error={!!errorComment}
      />
    </ModalButton>
  );
};

export default AddSignatory;
