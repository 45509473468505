import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Grid from '@mui/material/Grid';
import {
  DateField,
  Labeled,
  RecordContextProvider,
  useRecordContext,
} from 'react-admin';

import MoneyField from './MoneyField';

const AmountDateField = ({ source, label }) => {
  const record = useRecordContext();
  return (
    <RecordContextProvider value={get(record, source)}>
      <Grid
        container
        spacing={1}
        style={{ width: '60rem' }}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item xs={3}>
          <Labeled label={`${label} amount`}>
            <MoneyField source="amount" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled label={`${label} date`}>
            <DateField source="date" />
          </Labeled>
        </Grid>
      </Grid>
    </RecordContextProvider>
  );
};

AmountDateField.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
};

AmountDateField.defaultProps = {
  source: null,
  label: '',
};

export default AmountDateField;
