import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/fp/get';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';

import { ssnFormatter } from '../../utils/formatters';

const SocialSecurityNumberField = ({
  source,
}) => {
  const record = useRecordContext();
  return (
    <Typography noWrap>{ssnFormatter(get(source, record))}</Typography>
  );
};

SocialSecurityNumberField.propTypes = {
  source: PropTypes.string.isRequired,
};

SocialSecurityNumberField.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

export default SocialSecurityNumberField;
