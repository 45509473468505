const lessThan = (number1, number2) => number1 <= number2;
const strictlyLessThan = (number1, number2) => number1 < number2;
const moreThan = (number1, number2) => number1 >= number2;
const strictlyMoreThan = (number1, number2) => number1 > number2;

const stepComparisonFactory = (
  comparison,
) => (steps) => (
  step1,
  step2,
) => {
  const index1 = steps.findIndex((step) => step === step1);
  const index2 = steps.findIndex((step) => step === step2);

  return comparison(index1, index2);
};

export const isBeforeFactory = stepComparisonFactory(strictlyLessThan);
export const isSameOrBeforeFactory = stepComparisonFactory(lessThan);
export const isAfterFactory = stepComparisonFactory(strictlyMoreThan);
export const isSameOrAfterFactory = stepComparisonFactory(moreThan);
