import React, { useState, useCallback } from 'react';
import Card from '@mui/material/Card';
import { Title } from 'react-admin';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import PageTitle from '../utils/PageTitle';
import MongoChart from './MongoChart';
import config from '../../../config';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  largeGraph: {
    width: '50%',
    minHeight: '20rem',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  flexLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const [showCharts, setShowCharts] = useState(false);

  const onClick = useCallback(() => {
    setShowCharts(true);
  }, []);

  return (
    <Card>
      <Title title="Dashboard" />
      <PageTitle title="Dashboard" />
      <div className={classes.container}>
        {showCharts ? (
          <>
            <div className={classes.largeGraph}>
              <MongoChart
                chartId={config.chartIds.VOLUME_SIGNUP}
                width={475}
                height={236}
              />
            </div>
            <div className={classes.largeGraph}>
              <div className={classes.flexLine}>
                <MongoChart
                  chartId={config.chartIds.NEW_VOLUME_TODAY}
                  width={300}
                  height={120}
                />
              </div>
              <div className={classes.flexLine}>
                <MongoChart
                  chartId={config.chartIds.NEW_INSURANCE_TODAY}
                  width={300}
                  height={120}
                />
              </div>
            </div>
            <div className={classes.largeGraph}>
              <MongoChart
                chartId={config.chartIds.SIGNUP_TODAY}
                width={600}
                height={300}
              />
            </div>
            <div className={classes.largeGraph}>
              <div className={classes.flexLine}>
                <MongoChart
                  chartId={config.chartIds.MANUAL_COMMENTS_TOTAL_TODAY}
                  width={300}
                  height={150}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={classes.largeGraph}>
            <Button
              onClick={onClick}
              variant="contained"
              color="secondary"
              size="small"
            >
              Load charts
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Dashboard;
