import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import useCustomRpc from '../../hooks/useCustomRpc';
import onSuccessGetDocument from '../../utils/onSuccessGetDocument';

const DownloadEARDocumentButton = ({ accountId }) => {
  const record = useRecordContext();
  const earId = record._id;

  const { mutate: getEARDocument } = useCustomRpc({
    httpMethod: 'GET',
    path: `account/${accountId}/extra-amortisation-request/${earId}/download`,
    errorMessage: 'Failed to download document',
    onSuccess: onSuccessGetDocument,
  });

  const onClick = useCallback(() => {
    getEARDocument();
  }, [getEARDocument]);

  if (!accountId || !earId) return null;

  return (
    <Button
      title="Download Document"
      color="primary"
      size="small"
      variant="contained"
      onClick={onClick}
    >
      Download Document
    </Button>
  );
};

DownloadEARDocumentButton.propTypes = {
  accountId: PropTypes.string,
};

DownloadEARDocumentButton.defaultProps = {
  accountId: undefined,
};

export default DownloadEARDocumentButton;
