import React from 'react';
import {
  List,
  DateField,
  TextField,
  ReferenceField,
} from 'react-admin';
import ExtensionDecisionButton from './ExtensionDecisionButton';

import PageTitle from '../utils/PageTitle';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import MoneyField from '../utils/MoneyField';
import HeadedDatagrid from '../utils/HeadedDatagrid';
import AutoRefresh from '../utils/AutoRefresh';

const ExtensionList = () => (
  <List title="Extensions list">
    <HeadedDatagrid
      head={(
        <>
          <AutoRefresh />
          <PageTitle title="Extension | List" />
        </>
      )}
    >
      <TextField source="id" />
      <ReferenceField label="User" source="user" reference="user">
        <SocialSecurityNumberField source="socialSecurityNumber" />
      </ReferenceField>
      <DateField source="createdAt" />
      <MoneyField label="Remaining Capital" source="remainingCapitalAmount" />
      <MoneyField label="Extension Amount" source="pendingExtensionAmount" />
      <ExtensionDecisionButton accept />
      <ExtensionDecisionButton accept={false} />
    </HeadedDatagrid>
  </List>
);

export default ExtensionList;
