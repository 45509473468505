import React from 'react';
import Chip from '@mui/material/Chip';
import { useRecordContext } from 'react-admin';

const transactionColours = {
  interest: '#ff9800',
  internal: '#cccccc',
  payOut: '#00bcd4',
  payIn: '#a4c639',
  adjustment: '#ffeb3b',
  fee: '#ff0000',
};

const transactionLabels = {
  interest: 'Interest',
  internal: 'Internal',
  payOut: 'Payout',
  payIn: 'Payin',
  adjustment: 'Adjustment',
  fee: 'Fee',
};

const TransactionTypeField = () => {
  const transaction = useRecordContext();
  if (!transaction) return null;
  return (
    <Chip
      style={{ backgroundColor: transactionColours[transaction.type] }}
      label={transactionLabels[transaction.type]}
    />
  );
};

export default TransactionTypeField;
