import React, { useCallback } from 'react';

import {
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  ReferenceField,
} from 'react-admin';

import MoneyField from '../utils/MoneyField';
import PageTitle from '../utils/PageTitle';

import SendPaymentButton from '../utils/SendPaymentButton';

import useCustomRpc from '../../hooks/useCustomRpc';

const RejectedPayoutShow = () => {
  const { mutate: retryPayout } = useCustomRpc({
    httpMethod: 'POST',
    successMessage: 'Success. Payment will be added in the next payment file',
    errorMessage: 'Failed to retry paying out',
  });
  const handleRetry = useCallback((id, paymentDetails) => {
    retryPayout({
      ...paymentDetails,
      overridePath: `rejectedPayouts/${id}/retry-payout`,
    });
  }, [retryPayout]);
  return (
    <Show actions={null}>
      <SimpleShowLayout>
        <PageTitle title="Rejected Payouts | Show" />
        <SendPaymentButton dispatchAction={handleRetry} title="Retry payout" redirectToList />
        <TextField source="_id" />
        <ReferenceField label="Related account" source="account" reference="account">
          <TextField source="id" />
        </ReferenceField>
        <TextField label="Payout Reference" source="paymentDetails.payoutReference" />
        <MoneyField source="amount" />
        <TextField label="Bank Account Type" source="paymentDetails.bankAccountType" />
        <TextField label="Account number" source="paymentDetails.accountNumber" />
        <TextField label="Clearing Number" source="paymentDetails.clearingNumber" />
        <TextField label="Ocr Number" source="paymentDetails.ocrNumber" />
        <DateField label="Paid out at" source="paymentDetails.payedOutAt" />
        <DateField label="Rejected At" source="paymentDetails.rejectedAt" />
        <TextField label="Lender" source="paymentDetails.lender" />
      </SimpleShowLayout>
    </Show>
  );
};

export default RejectedPayoutShow;
