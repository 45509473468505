import React from 'react';

import {
  ArrayField,
  ShowView,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField,
  Datagrid,
  ReferenceField,
  useShowController,
  ShowContextProvider,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';

import ObjectFields from '../utils/ObjectFields';
import OrganisationNumberField from '../utils/OrganisationNumberField';

const CreditSafeCheckShow = () => {
  const controllerProps = useShowController();
  return (
    <ShowContextProvider value={controllerProps}>
      <ShowView>
        <SimpleShowLayout>
          <PageTitle title="Credit Safe Check | Show" />
          <ReferenceField label="User Id" source="user" reference="user">
            <TextField source="id" />
          </ReferenceField>
          <TextField source="data.scoring" label="Scoring" />
          <DateField source="createdAt" label="Created At" />
          <SocialSecurityNumberField source="data.socialSecurityNumber" label="Social Security Number" />
          <TextField source="data.firstName" label="First name" />
          <TextField source="data.lastName" label="Last name" />
          <TextField source="data.givenName" label="Given name" />
          <TextField source="data.previousName" label="Previous name" />
          <TextField source="data.address" label="Address" />
          <TextField source="data.registeredAddress" label="Registered Address" />
          <TextField source="data.coAddress" label="Care Of" />
          <TextField source="data.town" label="Town" />
          <TextField source="data.zipCode" label="Post code" />
          <DateField source="data.registrationDate" label="Recently register at" />
          <BooleanField source="data.businessOwner" label="Run Business" />
          <BooleanField source="data.hasTrustee" label="Has trustee" />
          <TextField source="data.realEstateInformation.totalAssessedValue" />
          <ArrayField source="data.realEstateInformation.realEstateDetail" label="Properties">
            <Datagrid bulkActionButtons={null}>
              <TextField source="description" />
              <TextField source="type.propertyType" />
              <TextField source="county" />
              <TextField source="municipality" />
              <TextField source="parish" />
              <TextField source="area" />
              <TextField source="ownedPart" />
            </Datagrid>
          </ArrayField>
          <ObjectFields source="data.incomeInformation" label="Income information" />
          <ArrayField source="data.paymentRemarks.publicPaymentRemarksDetails" label="Public payment remarks">
            <Datagrid bulkActionButtons={null}>
              <DateField source="date" />
              <TextField source="amount" />
              <TextField source="typeText" />
              <TextField source="term" />
              <TextField source="comment" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="data.paymentRemarks.privatePaymentRemarksDetails" label="Private payment remarks">
            <Datagrid bulkActionButtons={null}>
              <DateField source="date" />
              <TextField source="amount" />
              <TextField source="typeText" />
              <OrganisationNumberField source="organisationNumber" />
              <TextField source="name" />
              <BooleanField source="isForeign" />
              <TextField source="comment" />
            </Datagrid>
          </ArrayField>
          <ObjectFields source="data.debt" label="Debt information" />
          <ObjectFields source="data.bankruptcyInformation" label="Bankruptcy information" />
          <ObjectFields source="error" label="Error" />
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};

export default CreditSafeCheckShow;
