import React from 'react';
import {
  ArrayInput,
  EditView,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  BooleanInput,
  ReferenceInput,
  NumberInput,
  required,
  ImageField,
  UrlField,
  useEditController,
  EditContextProvider,
} from 'react-admin';
import { Typography } from '@mui/material';

import SelectInput from '../input/SelectInput';
import AutocompleteInput from '../input/AutocompleteInput';
import SelectArrayInput from '../input/SelectArrayInput';

import PageTitle from '../utils/PageTitle';
import UploadPartnerLogoButton from '../utils/UploadPartnerLogoButton';
import PhoneNumberInput from '../utils/PhoneNumberInput';
import { accountNumberFormat } from '../../utils/formatters';
import { utmSource, hexColor, email } from '../../utils/validationErrors';
import cleanNumberSearch from '../../utils/cleanNumberSearch';
import { useConstantContext } from '../utils/ConstantsContext';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const validateStock = [required()];

const getRecord = (props) => props.record || {};

const getBankAccountFilter = (props) => ({
  Organisation: getRecord(props).organisation,
});

const checkSuggestionOrganisation = (filter, choice) => choice.organisationNumber
  .includes(cleanNumberSearch(filter));
const filterToQueryOrganisation = (searchText) => ({
  organisationNumber: cleanNumberSearch(searchText),
});

const PartnerEdit = () => {
  const { choices } = useConstantContext();
  const {
    paymentReferenceTypes,
    integrationTypes,
    reasonsForLoan,
    partnerProducts,
    eventsToNotify,
    payoutSummaryMediums,
  } = choices;

  const controllerProps = useEditController({
    mutationMode: 'pessimistic',
    redirect: false,
  });

  return (
    <EditContextProvider value={controllerProps}>
      <EditView>
        <SimpleForm>
          <PageTitle title="Partner | Edit" />
          <BooleanInput source="isActive" helperText="If not active, the system will not allow the Partner to create applications" />
          <TextInput source="name" validate={validateStock} />
          <TextInput source="displayName" />
          <TextInput source="username" validate={validateStock} />
          <TextInput source="password" type="password" />
          <TextInput source="brandName" />
          <ArrayInput source="ipWhitelist" label="IP whitelist">
            <SimpleFormIterator>
              <TextInput label="CIDR subnet" source="" />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="utmSource" validate={[...validateStock, utmSource]} />
          <SelectInput
            source="defaultProduct"
            label="Default product"
            choices={partnerProducts}
          />
          <SelectInput
            source="integrationType"
            label="Integration Type"
            choices={integrationTypes}
          />
          <TextInput source="ucBrokerCode" />
          <BooleanInput source="isPayedThroughOrganisation" />
          <BooleanInput source="isPayedThroughSettlement" />
          <ReferenceInput
            label="Organisation"
            source="organisation"
            reference="organisation"
          >
            <AutocompleteInput
              optionText="name"
              matchSuggestion={checkSuggestionOrganisation}
              filterToQuery={filterToQueryOrganisation}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Bank account"
            source="bankAccountId"
            reference="bankAccount"
            filter={getBankAccountFilter(controllerProps)}
          >
            <SelectInput optionText={(record) => `${record.name || ''} ${accountNumberFormat(record.bankAccountType, record.accountNumber, record.clearingNumber)}`} />
          </ReferenceInput>
          <SelectInput
            source="paymentReferenceType"
            label="Payment reference type"
            choices={paymentReferenceTypes}
          />
          <TextInput source="paymentReferenceSettlement" />
          <SelectInput
            source="payoutSummaryMedium"
            label="Payout Summary Medium"
            choices={payoutSummaryMediums}
            helperText="Medium used to share with the organisation the payout summary related to this partner when using the portal checkout"
          />
          <NumberInput source="skipBankDetailsLimit" validate={validateStock} />
          <BooleanInput source="shouldNotifyNewApplications" />
          <BooleanInput
            label="Is Point of Sale"
            source="isPointOfSale"
            helperText="If on, the loan example will be hidden"
          />
          <BooleanInput
            source="shouldWaitForUseLoanPromise"
            helperText="If on, will not send the agreement to the customer. The partners will be responsible for it"
          />
          <BooleanInput
            source="isAllowedForAutomatedGrant"
            helperText="If on, a manual review on the application will not be required for applicants with a good profile"
          />
          <BooleanInput
            label="Should skip agreement protection with BankID"
            source="skipAgreementProtectionWithBankId"
            helperText="If on, users won't need to login with their BankID in order to see the account agreement"
          />
          <Typography variant="h5">Pricing</Typography>
          <NumberInput source="pricing.startupFee" label="Startup fee" />
          <NumberInput source="pricing.administrationFee" label="Administration fee" helperText="Will be charged monthly" />
          <ArrayInput source="pricing.scoreInterestMatrix.aboveThreshold" label="Score-Interest Matrix above threshold">
            <SimpleFormIterator inline>
              <TextInput source="maximumUCScore" label="Maximum UC Score" />
              <TextInput source="interest" label="Interest" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="pricing.scoreInterestMatrix.belowOrEqualThreshold" label="Score-Interest Matrix below or equal threshold">
            <SimpleFormIterator inline>
              <TextInput source="maximumUCScore" label="Maximum UC Score" />
              <TextInput source="interest" label="Interest" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="pricing.scoreAmountMatrix" label="Score-Amount Matrix">
            <SimpleFormIterator inline>
              <TextInput source="maximumUCScore" label="Maximum UC Score" />
              <TextInput source="amount" label="Amount" />
            </SimpleFormIterator>
          </ArrayInput>
          <Typography variant="h5">White Label</Typography>
          <UploadPartnerLogoButton />
          <SimpleShowLabeled>
            <UrlField source="whiteLabel.url" label="Signup Flow Minified Url" target="_blank" />
          </SimpleShowLabeled>
          <SimpleShowLabeled>
            <UrlField source="whiteLabel.unshortenedUrl" label="Signup Flow Non Minified Url" target="_blank" />
          </SimpleShowLabeled>
          <SimpleShowLabeled>
            <ImageField source="whiteLabel.logoKey" label="Logo" />
          </SimpleShowLabeled>
          <TextInput
            source="whiteLabel.primaryColour"
            label="Primary Colour"
            helperText="Hex color e.g. #ffffff"
            validate={[hexColor]}
          />
          <TextInput
            source="whiteLabel.secondaryColour"
            label="Secondary Colour"
            helperText="Hex color e.g. #ffffff"
            validate={[hexColor]}
          />
          <TextInput source="whiteLabel.callbackUrl" label="Callback Url" />
          <ArrayInput source="whiteLabel.sellingPoints" label="Selling points">
            <SimpleFormIterator>
              <TextInput label="Selling point" source="" />
            </SimpleFormIterator>
          </ArrayInput>
          <SelectInput
            source="whiteLabel.defaultLoanReason"
            label="Default reason for loan"
            choices={reasonsForLoan}
          />
          <TextInput source="whiteLabel.referenceNumberQuestion" label="Reference Number question" />
          <SelectArrayInput
            label="Notifiable Application Events"
            source="whiteLabel.eventsToNotify"
            choices={eventsToNotify}
            helperText="List of application events to notify the partner"
          />
          <TextInput
            source="whiteLabel.notificationEmail"
            label="Notification email"
            helperText="Email to which the partner will be notified of loan payouts etc."
            validate={[email]}
          />
          <TextInput
            source="whiteLabel.notificationSlack"
            label="Notification slack"
            helperText="Slack hook integration url to the channel where the partner will be notified of loan payouts etc."
          />
          <TextInput
            source="whiteLabel.notificationApiUrl"
            label="Notification API URL"
            helperText="API callback for the partner to be notified of loan payouts etc."
          />
          <TextInput
            source="whiteLabel.notificationApiToken"
            label="Notification API token"
            helperText="Authentication token for the partner API callback (Bearer format)"
          />
          <TextInput
            source="whiteLabel.contactEmail"
            label="Contact email"
            helperText="Contact email presented to the end customers on the thank you page at the end of the signup flow"
            validate={[email]}
          />
          <PhoneNumberInput
            source="whiteLabel.contactPhone"
            label="Contact phone"
            helperText="Contact phone presented to the end customer on the thank you page at the end of the signup flow"
          />
          <BooleanInput
            source="whiteLabel.splitSignupFlow"
            label="Split signup flow"
            helperText="If on, the white label solution flow will allows to send the personal and economic details of the applicant. The customer will receive a link through email and sms to complete the application."
          />
          <BooleanInput
            source="silentDenyNotifications"
            label="Silent deny notifications"
            helperText="If on, applicants will not receive a deny email. The Partner is responsible of notifying the customer about the denial"
          />
        </SimpleForm>
      </EditView>
    </EditContextProvider>
  );
};

export default PartnerEdit;
