import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';

const validateStock = [required()];

const CommentCreate = () => {
  const [urlSearchParams] = useSearchParams();
  const userId = urlSearchParams.get('user');
  const organisationId = urlSearchParams.get('organisation');
  const redirect = urlSearchParams.get('redirectPath');
  return (
    <Create redirect={redirect}>
      <SimpleForm
        defaultValues={{ organisation: organisationId, user: userId }}
      >
        <TextInput multiline source="message" validate={validateStock} />
      </SimpleForm>
    </Create>
  );
};

export default CommentCreate;
