// Production config
module.exports = {
  apiBase: 'https://api.moank.se/api',
  apiKey: '914c1f240d851d967f42eb124ee673e71a9131d3',
  chartIds: {
    PORTFOLIO_VOLUME: '76bb93dc-d4c5-423e-9dfb-c45f2793b748',
    TOTAL_VOLUME: 'a5530e6a-e475-42a9-873a-8c8651bc28eb',
    VOLUME_SIGNUP: '241d0a78-de98-428c-b6f8-3207915eefba',

    PAYMENT_FILE_LOANS: 'f73ea5ea-67b1-4d13-9d45-291251e7ac08',
    PAYMENT_FILE_UNIDENTIFIED: '73f394d7-149a-46ec-a70c-cf50206c24ff',
    VOLUME_PLEDGEABLE: 'e8fe66cf-df0b-43f1-8bdc-d754ebf6f7d8',

    NEW_VOLUME_TODAY: '693adb7b-76aa-4c1f-83b1-9b8e50eb1df9',
    NEW_VOLUME_MONTH: '8767a89c-41d1-4c98-9ad3-0bc854e0e4ce',
    NEW_INSURANCE_TODAY: 'fcf7fe9c-5097-44d5-a6ca-8352f6f3b2d3',
    NEW_INSURANCE_MONTH: '9b44b485-a2de-4c0a-a902-991e27de97e7',

    SIGNUP_TODAY: '058e8e20-60b4-463f-987f-e6257c968e8d',
    SIGNUP_HOUR: '6e22cd32-4aa8-4bb8-bc32-99e1855b3278',

    MANUAL_COMMENTS_TOTAL_TODAY: '46c051ec-401b-4b12-bda1-9d5434ea7b3b',
    MANUAL_COMMENTS_TOTAL_HOUR: '9889a900-2f14-4887-8268-9510a7c5295f',

    QUEUE_MANAGEMENT_TODAY: 'fec3adf0-708f-4c68-81a9-bd5c28a45e3f',
    QUEUE_MANAGEMENT_HOUR: 'f31362c3-7e2c-4f9d-90c2-08ad7c86ad20',
  },
  sentryEnvironment: 'production',
};
