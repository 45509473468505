import {
  fullAccountNumber as fullAccountNumberValidation,
  accountNumber as accountNumberValidation,
  clearingNumber as clearingNumberValidation,
} from '../../utils/validationErrors';

const validateBankDetailsFactory = (bankAccountTypes) => (bankAccount) => {
  let { accountNumber, bankAccountType, clearingNumber } = bankAccount;
  const errors = {};
  let path = errors;

  if (bankAccount.bankDetails) {
    ({ accountNumber, bankAccountType, clearingNumber } = bankAccount.bankDetails);
    errors.bankDetails = {};
    path = errors.bankDetails;
  }

  if (!clearingNumber && !accountNumber) return errors;

  if (!bankAccountType) {
    path.bankAccountType = 'Please select a bank account type';
    return errors;
  }

  if (bankAccountType === bankAccountTypes.BANK_GIRO
    || bankAccountType === bankAccountTypes.POST_GIRO) {
    const errorMessage = (accountNumber
      ? (fullAccountNumberValidation(accountNumber) || undefined)
      : 'Account number is needed for that account type'
    );
    if (errorMessage) path.accountNumber = errorMessage;
    if (clearingNumber) path.clearingNumber = 'Clearing number is not needed for that account type';
  } else if (bankAccountType === bankAccountTypes.BANK_ACCOUNT) {
    const errorMessageAccountNumber = (accountNumber
      ? (accountNumberValidation(accountNumber) || undefined)
      : 'Account number is needed for that account type'
    );
    if (errorMessageAccountNumber) path.accountNumber = errorMessageAccountNumber;
    const errorMessageClearingNumber = (clearingNumber
      ? (clearingNumberValidation(clearingNumber) || undefined)
      : 'Clearing number is needed for that account type'
    );
    if (errorMessageClearingNumber) path.clearingNumber = errorMessageClearingNumber;
  }

  return errors;
};

export default validateBankDetailsFactory;
