import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { useGetOne, useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ModalButton from './ModalButton';

import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const styles = {
  inputLine: {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    margin: '2rem',
  },
};

const ManuallySigned = () => {
  const record = useRecordContext();
  const [file, setFile] = useState(null);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [error, setError] = useState('');
  const [signatoriesCombination, setSignatoriesCombination] = useState('');

  const { constants } = useConstantContext();
  const { applicationSteps, decisionStatuses } = constants;

  const { data: organisation } = useGetOne(
    'organisation',
    { id: record.applicants[0]._id },
    { enabled: record.applicants[0].holderType === 'Organisation' },
  );

  const signatoriesCombinations = organisation?.signatoriesCombinations ?? [];

  const { mutate: sendDocumentSigned } = useCustomRpc({
    httpMethod: 'POST',
    path: `application/manual-sign/${record?.id}`,
    mimeType: 'formData',
    shouldRefresh: true,
  });

  const handleChange = useCallback((e) => {
    e.preventDefault();
    if (e.target.name === 'file') setFile(e.target.files[0]);
    if (e.target.name === 'date') setDate(e.target.value);
    if (e.target.name === 'signatoriesCombination') setSignatoriesCombination(e.target.value);
  }, []);

  const manuallySigned = useCallback(() => {
    if (!file) {
      setError('Add the agreement pdf');
      return false;
    }
    if (!date) {
      setError('Add the date of signature');
      return false;
    }

    const formDataFields = [
      {
        fieldName: 'date',
        value: date,
      },
      {
        fieldName: 'signatoriesCombination',
        value: signatoriesCombination,
      },
      {
        fieldName: 'file',
        value: file,
        name: `${date}`,
      },
    ];
    sendDocumentSigned(formDataFields);
    setFile(null);
    setDate(moment().format('YYYY-MM-DD'));
    setError('');
    setSignatoriesCombination('');
    return true;
  }, [file, date, signatoriesCombination, sendDocumentSigned]);

  const resetState = useCallback(() => {
    setFile(null);
    setDate(moment().format('YYYY-MM-DD'));
    setError('');
    setSignatoriesCombination('');
  }, []);

  const isSignable = useCallback(() => {
    const { applicationStep, currentDecisionStatus } = record;
    const validStep = applicationStep === applicationSteps.AGREEMENT_SENT;
    const validDecision = currentDecisionStatus === decisionStatuses.GRANT;
    return validStep && validDecision;
  }, [record, applicationSteps, decisionStatuses]);

  if (!isSignable()) return null;
  return (
    <ModalButton
      title="Manually Signed"
      onClick={manuallySigned}
      onClose={resetState}
    >
      {record.applicants[0].holderType === 'Organisation' && (
        <div style={styles.inputLine}>
          <Typography align="center">Select the signatories who signed the manual agreement</Typography>
          <Select
            id="signatoriesCombination"
            name="signatoriesCombination"
            label="Choose signatory combination"
            value={signatoriesCombination}
            onChange={handleChange}
          >
            {(signatoriesCombinations || []).map(({ _id, signatories }) => (
              <MenuItem key={_id} value={_id}>{signatories.map(({ user }) => `User ${user}`).join(', ')}</MenuItem>
            ))}
          </Select>
        </div>
      )}
      <Typography align="center">Upload the manually signed agreement:</Typography>
      <div style={styles.inputLine}>
        <input
          type="file"
          id="file"
          name="file"
          accept=".pdf"
          onChange={handleChange}
        />
        <TextField
          id="date"
          name="date"
          type="date"
          label="Signing date"
          value={date}
          onChange={handleChange}
        />
      </div>
      <Typography color="error" align="center">{error}</Typography>
    </ModalButton>
  );
};

export default ManuallySigned;
