import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  useRecordContext,
  Datagrid,
  useListContext,
} from 'react-admin';

import RefinanceButton from './RefinanceButton';

const RefinanceBulkButton = ({ holderId }) => {
  const { data, selectedIds } = useListContext();

  const selectedAccounts = selectedIds.map((id) => {
    const account = data?.find((acc) => acc.id === id);
    return ({
      id: account?.id,
      publicId: account?.publicId,
      todaySolvingAmount: account?.todaySolvingAmount,
      status: account?.status,
    });
  });

  return (
    <RefinanceButton
      holderId={holderId}
      selectedAccounts={selectedAccounts}
    />
  );
};

RefinanceBulkButton.propTypes = {
  holderId: PropTypes.string.isRequired,
};

const RefinanciableList = ({ children }) => {
  const record = useRecordContext();

  const holderId = record.id;

  return (
    <List
      resource="account"
      filterDefaultValues={{ 'accountHolders._id': holderId }}
      component="div"
      storeKey={false}
      exporter={false}
    >
      <Datagrid bulkActionButtons={<RefinanceBulkButton holderId={holderId} />}>
        {children}
      </Datagrid>
    </List>
  );
};

RefinanciableList.propTypes = {
  children: PropTypes.node.isRequired,
};

const HolderAccountList = ({ children }) => (
  <RefinanciableList>
    {children}
  </RefinanciableList>
);

HolderAccountList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HolderAccountList;
