import React, { useCallback } from 'react';
import { usePermissions, useRecordContext, useGetIdentity } from 'react-admin';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import useInputStatus from '../hooks/useInputStatus';
import ModalButton from './ModalButton';

import useCustomRpc from '../../hooks/useCustomRpc';
import { useConstantContext } from './ConstantsContext';

const ReviewDecision = () => {
  const [
    comment,
    setComment,
    onChangeComment,
    error,
    setError,
  ] = useInputStatus('');

  const record = useRecordContext();

  const {
    id,
    applicationStep,
    decisionData,
    kalpAdmins,
  } = record;

  const { data } = useGetIdentity();
  const { id: userId } = data ?? {};

  const { constants } = useConstantContext();
  const { applicationSteps, decisionStatuses, userRoles } = constants;

  const { permissions } = usePermissions();

  const { mutate: reviewDecision, isLoading } = useCustomRpc({
    httpMethod: 'POST',
    path: `application/${id}/decision-override`,
    errorMessage: 'Override decision failed',
    shouldRefresh: true,
  });

  const handleSubmit = useCallback(() => {
    if (!comment) {
      setError('You need to leave a reason about why you want to override the decision engine.');
    } else {
      setError('');
      reviewDecision({ id, message: comment });
    }
  }, [comment, setError, reviewDecision, id]);

  const handleClose = useCallback(() => {
    setComment('');
    setError('');
  }, [setComment, setError]);

  if (applicationStep !== applicationSteps.DECISION_TAKEN) return null;

  const hasPermission = (permissions && permissions.includes(userRoles.ROLE_SUPER_ADMIN))
    || decisionData.recommendation !== decisionStatuses.DENY;

  const canBePeer = !kalpAdmins.includes(userId);

  return (
    <ModalButton
      title="Review Decision"
      onClick={handleSubmit}
      onClose={handleClose}
      disabled={!(hasPermission && canBePeer) || isLoading}
    >
      {hasPermission && canBePeer
        ? (
          <>
            <Typography align="center">Justify the approval</Typography>
            <TextField
              id="reason"
              name="reason"
              label="Reason"
              multiline
              maxRows="4"
              value={comment}
              onChange={onChangeComment}
              error={!!error}
              helperText={error}
              margin="normal"
              fullWidth
            />
          </>
        ) : (
          <Typography color="error" align="center">
            {!canBePeer ? 'You cannot self review your decision' : 'You do not have enough permissions to review the decision. Super Admin permissions are necessary'}
          </Typography>
        )}
    </ModalButton>
  );
};

export default ReviewDecision;
