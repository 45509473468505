import { getJWTPayload } from './utils/jwt';
import config from '../config';
import getAccessToken from './utils/getAccessToken';
import store from './utils/store';

const authClient = {
  login: async ({
    username,
    password,
    userId,
    code,
    mfaSecretKey,
  }) => {
    if (username && password) {
      const request = new window.Request(`${config.apiBase}/user/authenticate`, {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
        headers: new window.Headers({
          'Content-Type': 'application/json',
          'X-API-Key': config.apiKey,
        }),
      });
      const response = await window.fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const json = await response.json();
      const { data: { mfaToken } } = json;
      store.setItem('mfaToken', mfaToken);
      throw new Error('MFA required');
    } else if (userId && code) {
      const request = new window.Request(`${config.apiBase}/user/authenticate`, {
        method: 'POST',
        body: JSON.stringify({ userId, code, mfaSecretKey }),
        headers: new window.Headers({
          'Content-Type': 'application/json',
          'X-API-Key': config.apiKey,
        }),
      });
      const response = await window.fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const json = await response.json();
      const { data: { token, refreshToken } } = json;
      store.setItem('token', token);
      store.setItem('refreshToken', refreshToken);
    } else throw new Error('Missing required login parameters');
  },
  logout: async () => {
    if (store.getItem('token')) {
      store.removeItem('token');
      store.removeItem('refreshToken');
      store.removeItem('mfaToken');
    }
  },
  checkError: async ({ status }) => {
    if (status === 401) {
      throw new Error('Invalid credentials. Please login again');
    }
  },
  checkAuth: async () => {
    const token = await getAccessToken();
    if (!token) throw new Error('Not logged in');
    const expireTimestamp = getJWTPayload(token).exp;
    const now = Date.now();
    if (expireTimestamp * 1000 < now) throw new Error('Session expired');
  },
  getPermissions: async () => {
    const token = await getAccessToken();
    if (!token) return [];
    const { roles } = getJWTPayload(token);
    if (!roles) throw new Error('Missing permissions in authentication token');
    return roles;
  },
  getIdentity: async () => {
    const token = await getAccessToken();
    if (!token) return {};
    const { user_id: userId } = getJWTPayload(token);
    if (!userId) throw new Error('Missing user ID in authentication token');
    return { id: userId };
  },
};

export default authClient;
