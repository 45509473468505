import React from 'react';

import {
  TextInput,
} from 'react-admin';
import { useConstantContext } from '../utils/ConstantsContext';

import SelectInput from '../input/SelectInput';

const useApplicationFilters = () => {
  const { choices } = useConstantContext();
  const { applicationSteps, decisionStatuses, accountTypes } = choices;
  return [
    <TextInput label="Application ID" source="_id" alwaysOn />,
    <TextInput label="Broker reference ID" source="brokerReferenceId" alwaysOn />,
    <TextInput label="Applicant phone" source="applicants.phone" alwaysOn />,
    <TextInput label="Applicant email" source="applicants.email" alwaysOn />,
    <TextInput label="User ID" source="applicants._id" alwaysOn />,
    <SelectInput
      label="Steps"
      source="applicationStep"
      choices={applicationSteps}
    />,
    <SelectInput
      label="Decision"
      source="currentDecisionStatus"
      choices={decisionStatuses}
    />,
    <SelectInput
      label="Account Type"
      source="accountType"
      choices={accountTypes}
    />,
    <TextInput label="UTM Sources" source="trackingData.utmSource" />,
    <TextInput label="UTM Medium" source="trackingData.utmMedium" />,
    <TextInput label="Public ID" source="publicId" />,
  ];
};

export default useApplicationFilters;
