import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
  ReferenceManyField,
  ShowButton,
  useRecordContext,
  Pagination,
} from 'react-admin';

import RevertTransaction from './RevertTransaction';
import ForceMovePayment from './ForceMovePayment';
import JustifyTransactionButton from './JustifyTransactionButton';
import TransactionTypeField from './TransactionTypeField';
import MoneyField from './MoneyField';
import AssignedAdmin from './AssignedAdmin';

import useGetAdminList from '../../utils/useGetAdminList';
import RevertPayinTransaction from './RevertPayinTransaction';
import { useConstantContext } from './ConstantsContext';

const useStyles = makeStyles({
  actionButtons: {
    disaply: 'flex',
    '&>*': {
      margin: '0.5rem',
    },
  },
});

const ActionButtons = ({ account }) => {
  const classes = useStyles();
  return (
    <div className={classes.actionButtons}>
      <RevertTransaction
        accountId={account.id}
      />
      <ForceMovePayment
        account={account}
      />
      <RevertPayinTransaction
        accountId={account.id}
      />
    </div>
  );
};

ActionButtons.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  label: PropTypes.string,
  account: PropTypes.shape({
    id: PropTypes.string,
    accountType: PropTypes.string,
  }),
};

ActionButtons.defaultProps = {
  label: 'Actions',
  account: {},
};

const TransactionExpand = ({ accountId, accountType }) => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { transactionTypes } = constants;
  const { data: adminList, error: adminListError, isLoading: adminListLoading } = useGetAdminList();
  if (record.type !== transactionTypes.USER_PAYMENT && !record.asPayin) return null;

  return (
    <ReferenceManyField
      reference="riskReport"
      target="additionalInfo.transactionId"
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={null}>
        <TextField source="rule" />
        <TextField source="status" />
        <DateField source="isClosedAt" />
        <AssignedAdmin
          loading={adminListLoading}
          error={adminListError}
          adminList={adminList}
          source="admin"
          label="Assigned to"
          resource="riskReport"
          minimal
        />
        <JustifyTransactionButton
          accountId={accountId}
          accountType={accountType}
          transactionId={record.id}
        />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>
  );
};

TransactionExpand.propTypes = {
  accountId: PropTypes.string,
  accountType: PropTypes.string,
};

TransactionExpand.defaultProps = {
  accountId: undefined,
  accountType: undefined,
};

const TransactionFields = () => {
  const account = useRecordContext();
  const { constants } = useConstantContext();
  const { accountTypes } = constants;

  return (
    <ArrayField source="transactions">
      <Datagrid
        expand={account.accountType === accountTypes.HELD_PAYMENTS
          ? (
            <TransactionExpand
              accountId={account.id}
              accountType={account.accountType}
            />
          )
          : null}
        bulkActionButtons={null}
      >
        <TextField source="_id" />
        <DateField source="date" />
        <MoneyField source="amount" />
        <TextField source="payinDetails.bankgiroNumber" label="Bankgiro number" />
        <TextField source="payinDetails.reference" label="OCR number" />
        <TextField source="payinDetails.informationText" label="Extra information text" />
        <TransactionTypeField source="type" />
        <TextField source="description" />
        <TextField label="Payer" source="payinDetails.payer" />
        <FunctionField
          label="PayoutStatus"
          render={(record) => {
            if (record.paymentDetails && record.paymentDetails.confirmedAt) {
              return (
                <>
                  <span>Confirmed At </span>
                  <DateField label="Confirmed At" source="paymentDetails.confirmedAt" />
                </>
              );
            }
            if (record.paymentDetails && record.paymentDetails.rejectedAt) {
              return (
                <>
                  <span>Rejected At </span>
                  <DateField label="Rejected At" source="paymentDetails.rejectedAt" />
                </>
              );
            }
            if (record.paymentDetails && record.paymentDetails.payedOutAt) {
              return (
                <>
                  <span>PayedOut At </span>
                  <DateField label="PayedOut At" source="paymentDetails.payedOutAt" />
                </>
              );
            }
            return null;
          }}
        />
        <ActionButtons account={account} />
      </Datagrid>
    </ArrayField>
  );
};

export default TransactionFields;
