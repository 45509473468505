import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ErrorIcon from '@mui/icons-material/Error';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import {
  ReferenceField,
  TextField,
  usePermissions,
  LinearProgress,
  useRecordContext,
  useGetIdentity,
} from 'react-admin';
import ColoredButton from './ColoredButton';

import useCustomRpc from '../../hooks/useCustomRpc';

const ASSIGNABLE_RESSOURCES = {
  REPORT: 'riskReport',
  APPLICATION: 'application',
  PENDING_APPLICATION: 'application/pendingDecision',
  PENDING_FACTORING_APPLICATION: 'application/pendingDecisionFactoring',
  PENDING_INSURANCE_APPLICATION: 'application/pendingDecisionInsurance',
};

const ACTIONS = {
  ASSIGN: 'assign',
  UNASSIGN: 'unassign',
};

const useAssign = (action, resource, id) => {
  const { mutate: assignReport } = useCustomRpc({
    httpMethod: 'PUT',
    path: `riskReport/assign/${id}`,
    successMessage: action === 'assign' ? 'Risk report assigned' : 'Risk report unassigned',
    errorMessage: 'Failure',
    shouldRefresh: true,
  });

  const { mutate: assignApplication } = useCustomRpc({
    httpMethod: 'PUT',
    path: `application/assign/${id}`,
    successMessage: action === 'assign' ? 'Application assigned' : 'Application unassigned',
    errorMessage: 'Failure',
    shouldRefresh: true,
  });

  const mapRessourceToAssign = {
    [ASSIGNABLE_RESSOURCES.REPORT]: assignReport,
    [ASSIGNABLE_RESSOURCES.APPLICATION]: assignApplication,
    [ASSIGNABLE_RESSOURCES.PENDING_APPLICATION]: assignApplication,
    [ASSIGNABLE_RESSOURCES.PENDING_FACTORING_APPLICATION]: assignApplication,
    [ASSIGNABLE_RESSOURCES.PENDING_INSURANCE_APPLICATION]: assignApplication,
  };

  const assignMutation = mapRessourceToAssign[resource];

  const handleAssign = useCallback((e) => {
    const adminId = e.target.closest('[admin_id]')?.getAttribute('admin_id');
    assignMutation({ action, adminId });
  }, [assignMutation, action]);

  return handleAssign;
};

const useStyles = makeStyles({
  dialogList: {
    minWidth: '10rem',
    padding: '0 2rem',
  },
});

const AssignDialog = ({
  open,
  onClose,
  handleAssign,
  adminList,
}) => {
  const classes = useStyles();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleListItemClick = useCallback((e) => {
    handleAssign(e);
    onClose();
  }, [handleAssign, onClose]);

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Assign to:</DialogTitle>
      <List className={classes.dialogList}>
        {adminList.map((admin) => (
          <ListItemButton
            onClick={handleListItemClick}
            key={admin.firstName}
            admin_id={admin.id}
          >
            <Typography component="span" variant="body2">{admin.firstName}</Typography>
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  );
};

AssignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleAssign: PropTypes.func.isRequired,
  adminList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
  })).isRequired,
};

const AssignedAdmin = ({
  error,
  loading,
  adminList,
  minimal,
  source,
  resource,
}) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const { data } = useGetIdentity();
  const { id: userId } = data ?? {};

  const handleAssign = useAssign(ACTIONS.ASSIGN, resource, record.id);
  const handleUnassign = useAssign(ACTIONS.UNASSIGN, resource, record.id);
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const isAssigned = !!record[source];
  const isCurrentAdmin = record[source] === userId;

  if (loading || !permissions) return <LinearProgress />;
  if (error) {
    return (
      <ErrorIcon
        color="error"
        fontSize="small"
        role="presentation"
      />
    );
  }

  if (permissions.includes('ROLE_SUPER_ADMIN')) {
    return (
      <>
        {!minimal && <span>Application assigned to:</span>}
        {isAssigned ? (
          <>
            <ReferenceField
              label="Assigned to"
              source="admin"
              reference="admins"
            >
              <TextField source="firstName" />
            </ReferenceField>
            <ColoredButton
              onClick={handleUnassign}
              background="DarkOrange"
              color="white"
            >
              Unassign
            </ColoredButton>
          </>
        ) : (
          <>
            <strong>Nobody</strong>
            <ColoredButton
              onClick={handleOpenDialog}
              background="ForestGreen"
              color="white"
            >
              Assign to...
            </ColoredButton>
            <AssignDialog
              adminList={adminList}
              open={openDialog}
              onClose={handleCloseDialog}
              handleAssign={handleAssign}
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      {!minimal && <span>Application assigned to:</span>}
      {isAssigned ? (
        <ReferenceField
          label="Assigned to"
          source="admin"
          reference="admins"
        >
          <TextField source="firstName" />
        </ReferenceField>
      ) : (
        <strong>Nobody</strong>
      )}
      {isAssigned && isCurrentAdmin ? (
        <ColoredButton
          onClick={handleUnassign}
          background="DarkOrange"
          color="white"
        >
          Unassign
        </ColoredButton>
      ) : (
        <ColoredButton
          onClick={handleAssign}
          background="ForestGreen"
          color="white"
        >
          Assign to me
        </ColoredButton>
      )}
    </>
  );
};

AssignedAdmin.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  resource: PropTypes.oneOf(Object.values(ASSIGNABLE_RESSOURCES)).isRequired,
  minimal: PropTypes.bool,
  source: PropTypes.string.isRequired,
  adminList: PropTypes.arrayOf(PropTypes.shape({})),
};

AssignedAdmin.defaultProps = {
  adminList: [],
  error: '',
  minimal: false,
};

export default AssignedAdmin;
