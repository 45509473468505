import React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import SelectInput from '../input/SelectInput';
import { email, organisationNumber } from '../../utils/validationErrors';
import { organisationNumberFormatter, organisationNumberParser } from '../../utils/formatters';
import { useConstantContext } from '../utils/ConstantsContext';

const validateFunc = [required()];

const OrganisationCreate = () => {
  const { choices } = useConstantContext();
  const { invoiceMedia } = choices;
  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="organisationNumber"
          label="Organisation Number"
          format={organisationNumberFormatter}
          parse={organisationNumberParser}
          validate={[...validateFunc, organisationNumber]}
        />
        <TextInput source="name" label="Name" validate={validateFunc} />
        <TextInput source="email" label="Email" validate={[...validateFunc, email]} />
        <SelectInput
          source="invoiceMedium"
          label="Invoice Medium"
          choices={invoiceMedia}
          validate={validateFunc}
        />
        <TextInput source="streetAddress" label="Street" />
        <TextInput source="postcode" label="Postal code" />
        <TextInput source="town" label="City" />
      </SimpleForm>
    </Create>
  );
};

export default OrganisationCreate;
