import React from 'react';
import PropTypes from 'prop-types';

import {
  ArrayField,
  Datagrid,
  ReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';

import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import DownloadBankIdSignature from '../utils/DownloadBankIdSignature';

const AccountHoldersFields = () => {
  const record = useRecordContext();

  if (record.accountHolders.length === 0) return null;
  return (
    <ArrayField source="accountHolders">
      {record.accountHolders[0].holderType === 'User'
        ? (
          <Datagrid bulkActionButtons={null}>
            <ReferenceField label="User id" source="_id" reference="user">
              <TextField source="id" />
            </ReferenceField>
            <ReferenceField label="SSN" source="_id" reference="user" link={false}>
              <SocialSecurityNumberField source="socialSecurityNumber" />
            </ReferenceField>
            <ReferenceField label="First Name" source="_id" reference="user" link={false}>
              <TextField source="firstName" />
            </ReferenceField>
            <ReferenceField label="Last Name" source="_id" reference="user" link={false}>
              <TextField source="lastName" />
            </ReferenceField>
            <DownloadBankIdSignature accountId={record.id} />
          </Datagrid>
        ) : (
          <Datagrid bulkActionButtons={null}>
            <ReferenceField label="Organisation id" source="_id" reference="organisation">
              <TextField source="id" />
            </ReferenceField>
            <ReferenceField label="Org. nummer" source="_id" reference="organisation" link={false}>
              <OrganisationNumberField source="organisationNumber" />
            </ReferenceField>
            <ReferenceField label="name" source="_id" reference="organisation" link={false}>
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        )}
    </ArrayField>
  );
};

AccountHoldersFields.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  label: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  addLabel: PropTypes.bool,
};

AccountHoldersFields.defaultProps = {
  label: 'Account Holders',
  addLabel: false,
};

export default AccountHoldersFields;
