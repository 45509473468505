export const ssnFormatter = (ssn) => {
  if (!ssn) return '';
  if (ssn.length > 8) return `${ssn.substr(0, 8)}-${ssn.substr(8)}`;
  return ssn;
};
export const ssnParser = (ssn) => (ssn ? ssn.replace(/[^0-9]/g, '').substr(0, 12) : '');

export const organisationNumberFormatter = (organisationNumber) => {
  if (!organisationNumber) return '';
  if (organisationNumber.length > 6) return `${organisationNumber.substr(0, 6)}-${organisationNumber.substr(6)}`;
  return organisationNumber;
};
export const organisationNumberParser = (organisationNumber) => (organisationNumber ? organisationNumber.replace(/[^0-9]/g, '').substr(0, 12) : '');

export const fromCammelCaseToWords = (str) => str
  .replace(/([A-Z])/g, ' $1')
  .replace(/^./, (world) => world.toUpperCase());

export const numberParser = (num) => {
  const parsedNumber = parseFloat(num);
  if (Number.isNaN(parsedNumber)) return '';
  return parsedNumber;
};

const toFixedFix = (n, prec) => {
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  const k = 10 ** prec;
  return Math.round(n * k) / k;
};

export const numberFormat = (number, decimals = 2, options = {}) => {
  const n = !Number.isFinite(+number) ? 0 : +number;
  const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = options.thousandSep || '\xa0';
  const dec = options.decPoint || ',';
  const s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array((prec - s[1].length) + 1).join('0');
  }
  const formattedAmount = s.join(dec);
  if (options.unit) return `${formattedAmount}\xa0${options.unit}`;
  return formattedAmount;
};

export const accountNumberFormat = (bankAccountType, accountNumber, clearingNumber = '') => {
  if (bankAccountType === 'BankGiro') {
    return `BG ${accountNumber.slice(0, -4)}-${accountNumber.slice(-4)}`;
  }
  if (bankAccountType === 'PostGiro') {
    return `PG ${accountNumber.slice(0, -5)} ${accountNumber.slice(-5, -3)} ${accountNumber.slice(-3, -1)}-${accountNumber.slice(-1)}`;
  }
  if (bankAccountType === 'BankAccount') {
    if (!clearingNumber) return `BA ${accountNumber.slice(0, 4)}-${accountNumber.slice(4)}`;
    return `BA ${clearingNumber.slice(0, 4)}-${accountNumber.padStart(12, '0')}`;
  }
  throw new Error('Unexpected bankAccountType. Only BankGiro and PostGiro are supported.');
};
