import React, { useState, useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ModalButton from '../utils/ModalButton';

import useInputStatus from '../hooks/useInputStatus';
import useCustomRpc from '../../hooks/useCustomRpc';

const VerifyBankAccount = () => {
  const record = useRecordContext();
  const bankAccountId = record.id;

  const { mutate: verifyBankAccount } = useCustomRpc({
    httpMethod: 'POST',
    path: `bankAccount/${bankAccountId}/verify`,
    mimeType: 'formData',
    shouldRefresh: true,
  });

  const [file, setFile] = useState(undefined);
  const [errorFile, setErrorFile] = useState('');

  const onChangeFile = useCallback((e) => {
    setErrorFile('');
    setFile(e.target.files[0]);
  }, [setErrorFile, setFile]);

  const [
    reason,
    setReason,
    onChangeReason,
    reasonError,
    setReasonError,
  ] = useInputStatus();

  const onClick = useCallback(() => {
    if (!file) {
      setErrorFile('Need the verified bank account statement');
      return false;
    }
    if (!reason) {
      setReasonError('Need to leave a reason');
      return false;
    }
    const formData = [{
      fieldName: 'reason',
      value: reason,
    }, {
      fieldName: 'file',
      value: file,
    }];
    verifyBankAccount(formData);
    return true;
  }, [file, setErrorFile, verifyBankAccount, reason, setReasonError]);

  const onClose = useCallback(() => {
    setFile(undefined);
    setErrorFile('');
    setReason('');
    setReasonError('');
  }, [setFile, setErrorFile, setReason, setReasonError]);

  if (!record) return null;
  if (record.isOwnershipVerified || record.isManuallyVerified) return null;

  return (
    <ModalButton
      title="Verify bank account"
      onClick={onClick}
      onClose={onClose}
    >
      <Typography align="center">Upload the verified bank account statement:</Typography>
      <div>
        <input
          type="file"
          name="file"
          accept=".pdf"
          onChange={onChangeFile}
        />
        {errorFile && <Typography align="center">{errorFile}</Typography>}
      </div>
      <TextField
        type="string"
        label="Reason"
        value={reason}
        helperText={reasonError}
        error={!!reasonError}
        onChange={onChangeReason}
        multiline
        maxRows="4"
        fullWidth
      />
    </ModalButton>
  );
};

export default VerifyBankAccount;
