import React from 'react';
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  DateField,
  EditView,
  FileField,
  FileInput,
  FormDataConsumer,
  FormTab,
  FunctionField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectField,
  ShowButton,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  UrlField,
  required,
  useEditController,
  EditContextProvider,
  Pagination,
  SingleFieldList,
  Datagrid,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import Kalp from './tabs/Kalp';
import KalpAside from './KalpAside';
import SelectInput from '../input/SelectInput';
import NullableBooleanInput from '../input/NullableBooleanInput';
import AutocompleteInput from '../input/AutocompleteInput';
import { TakeUcButton, UcInfoFieldsPreview } from '../uc';
import CreditSafeDatagrid from '../creditSafeChecks/CreditSafeDatagrid';

import OverridePricingTermsButton from './OverridePricingTermsButton';
import ApplicationConsole from './ApplicationConsole';
import ApplicationTitle from './ApplicationTitle';
import WaitingForCustomer from './WaitingForCustomer';
import OrganisationFields from './OrganisationFields';
import UserFields from './UserFields';
import DecisionField from './DecisionField';
import ResetQueuePosition from './ResetQueuePosition';

import MarkAMLRisk from '../riskReport/MarkAMLRisk';

import ActionBar from '../utils/ActionBar';
import AssignedAdmin from '../utils/AssignedAdmin';
import CategorisationTab from '../utils/CategorisationTab';
import CreateRelatedButton from '../utils/CreateRelatedButton';
import ManualDecision from '../utils/ManualDecision';
import MoneyField from '../utils/MoneyField';
import ObjectFields from '../utils/ObjectFields';
import PageTitle from '../utils/PageTitle';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import SendSignSmsButton from '../utils/SendSignSmsButton';
import SendCompleteSmsButton from '../utils/SendCompleteSmsButton';
import InsuranceTab from '../utils/InsuranceTab';
import LineSeparator from '../utils/LineSeparator';
import QuickPagination from '../utils/QuickPagination';

import useGetAdminList from '../../utils/useGetAdminList';
import { accountNumberFormat, numberFormat } from '../../utils/formatters';
import { isSameOrAfterFactory, isBeforeFactory, isAfterFactory } from '../../utils/signup/compareApplicationSteps';

import {
  isAnnuityInvoicingSelector,
  isInterestInvoicingSelector,
  isManualPayoutSelector,
  isInIndirectInvoiceSelector,
} from '../../utils/accountFeatures';

import getMainApplicant from '../../utils/getMainApplicant';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';

import { ocrNumber, email } from '../../utils/validationErrors';
import validators from '../../utils/validators';
import cleanNumberSearch from '../../utils/cleanNumberSearch';

import ApplicationItem from './ApplicationItem';
import AccountItem from '../accounts/AccountItem';
import AddRefinancingButton from './AddRefinancingButton';
import { useConstantContext } from '../utils/ConstantsContext';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const useStyles = makeStyles(() => ({
  payoutItem: {
    display: 'flex',
    width: '12rem',
    marginRight: '1em',
  },
  payoutItemReference: {
    display: 'flex',
    width: '25rem',
    marginRight: '1em',
  },
}));

const isPayoutDisabledBuilder = (applicationSteps, orderedApplicationSteps) => (appStep) => (
  isSameOrAfterFactory(orderedApplicationSteps)(appStep, applicationSteps.PAYED_OUT)
);

const isFieldsDisabledBuilder = (applicationSteps, orderedApplicationSteps) => (appStep) => (
  isSameOrAfterFactory(orderedApplicationSteps)(appStep, applicationSteps.DECISION_TAKEN)
);

const isOverrideTermsAllowedBuilder = (applicationSteps, orderedApplicationSteps) => (appStep) => (
  isSameOrAfterFactory(orderedApplicationSteps)(appStep, applicationSteps.SUBMITTED)
  && isBeforeFactory(orderedApplicationSteps)(appStep, applicationSteps.SIGNED)
);

const isApplicationProcessedBuilder = (applicationSteps, orderedApplicationSteps) => (appStep) => (
  isAfterFactory(orderedApplicationSteps)(appStep, applicationSteps.PROCESSED)
);

const checkSuggestionLender = (filter, choice) => choice.accountNumber
  .includes(cleanNumberSearch(filter));
const filterToQueryLender = (searchText) => ({ accountNumber: cleanNumberSearch(searchText) });

const checkSuggestionOrganisation = (filter, choice) => choice.organisationNumber
  .includes(cleanNumberSearch(filter));
const filterToQueryOrganisation = (searchText) => ({
  organisationNumber: cleanNumberSearch(searchText),
});

const displayPayoutAmount = (payout, loanTerms, refinance) => {
  if (!loanTerms || !loanTerms.amount) return 0;
  let leftAmount = loanTerms.amount;
  if (payout) {
    const sumPayout = payout
      .reduce((acc, p) => (p && parseFloat(p.amount) ? acc + p.amount : acc), 0);
    leftAmount -= sumPayout;
  }
  if (refinance) {
    const sumRefinance = refinance
      .reduce((acc, r) => (r && parseFloat(r.amount) ? acc + r.amount : acc), 0);
    leftAmount -= sumRefinance;
  }
  return numberFormat(leftAmount, 2, { unit: 'kr' });
};

const getRecord = (controllerProps) => controllerProps.record || {};

const lenderRender = (choice) => (choice ? `${choice.name} - ${accountNumberFormat(choice.bankAccountType, choice.accountNumber, choice.clearingNumber)}` : '');

const getApplicantIds = (controllerProps) => (getRecord(controllerProps).applicants || [])
  .map((applicant) => applicant._id);

const getBankAccountFilter = (controllerProps) => ({
  [getRecord(controllerProps).applicants[0].holderType]: getApplicantIds(controllerProps),
});

const ApplicationEdit = () => {
  const { constants, choices, accountFeatures } = useConstantContext();
  const {
    holderTypes,
    accountTypes,
    applicationSteps,
    orderedApplicationSteps,
  } = constants;
  const isAnnuityInvoicing = isAnnuityInvoicingSelector(accountFeatures);
  const isInterestInvoicing = isInterestInvoicingSelector(accountFeatures);
  const isIndirectInvoicing = isInIndirectInvoiceSelector(accountFeatures);
  const isManualPayout = isManualPayoutSelector(accountFeatures);
  const isPayoutDisabled = isPayoutDisabledBuilder(
    applicationSteps,
    orderedApplicationSteps,
  );
  const isFieldsDisabled = isFieldsDisabledBuilder(
    applicationSteps,
    orderedApplicationSteps,
  );
  const isOverrideTermsAllowed = isOverrideTermsAllowedBuilder(
    applicationSteps,
    orderedApplicationSteps,
  );
  const isApplicationProcessed = isApplicationProcessedBuilder(
    applicationSteps,
    orderedApplicationSteps,
  );

  const classes = useStyles();

  const controllerProps = useEditController({
    redirect: false,
  });

  const { data: adminList, error: adminListError, isLoading: adminListLoading } = useGetAdminList();

  return (
    <EditContextProvider value={controllerProps}>
      <EditView
        title={<ApplicationTitle />}
        aside={getMainApplicant(controllerProps).holderType === holderTypes.USER
          ? <KalpAside />
          : null}
      >
        <TabbedForm>
          <FormTab label="Application details">
            <PageTitle title="Application | Edit" />
            <MarkAMLRisk context="application" />
            <ActionBar>
              <AssignedAdmin
                loading={adminListLoading}
                error={adminListError}
                adminList={adminList}
                source="admin"
                label="Assigned to"
                resource="application"
              />
              <WaitingForCustomer source="isWaitingForCustomer" />
              <ResetQueuePosition />
            </ActionBar>
            <ApplicationConsole />
            <TextInput readOnly source="applicationStep" label="Application Step" />
            <SimpleShowLabeled>
              <BooleanField label="Want Loan Protection" source="wantLoanProtection" emptyText="?" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <TextField source="brokerReferenceId" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <TextField source="publicId" label="Public ID of the future account to be used in agreements" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <BooleanField source="rejectedByCustomer" label="Rejected by Customer" />
            </SimpleShowLabeled>
            <DecisionField
              as={SelectInput}
              source="accountType"
              readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
              label="Account Type"
              choices={choices.activeTypes}
            />
            {isIndirectInvoicing(getRecord(controllerProps).accountType)
              && (
                <ReferenceInput
                  label="Organisation to invoice"
                  source="invoicedOrganisation"
                  reference="organisation"
                >
                  <AutocompleteInput
                    matchSuggestion={checkSuggestionOrganisation}
                    optionText="name"
                    filterToQuery={filterToQueryOrganisation}
                  />
                </ReferenceInput>
              )}
            {getRecord(controllerProps).loanTerms && (
              <SimpleShowLabeled>
                <ObjectFields source="loanTerms" label="Loan Terms" />
              </SimpleShowLabeled>
            )}

            {getRecord(controllerProps).loanTerms
              && getRecord(controllerProps).accountType === accountTypes.LEASING
              && (
                <ArrayField source="loanTerms.leasingArticles" label="Leasing Articles">
                  <Datagrid bulkActionButtons={null}>
                    <TextField source="articleNumber" label="Article Number" />
                    <MoneyField source="payoutAmount" label="Payout Amount" />
                    <TextField source="vatPercent" label="Vat Percent" />
                    <MoneyField source="residualValue" label="Residual Value" />
                    <MoneyField source="insurancePremium" label="Insurance Premium" />
                    <MoneyField source="insuranceExcess" label="Insurance Excess" />
                    <MoneyField source="nominalRate" label="Rate" />
                    <TextField source="serialNumber" label="Serial Number" />
                  </Datagrid>
                </ArrayField>
              )}

            {getRecord(controllerProps).insuredDocument && getRecord(controllerProps).insuredDocument.documentModel === 'account' ? (
              <SimpleShowLabeled>
                <ReferenceField
                  reference="account"
                  source="insuredDocument.id"
                  label="Insured Account"
                >
                  <TextField source="publicId" />
                </ReferenceField>
              </SimpleShowLabeled>
            ) : null}
            {getRecord(controllerProps).insuredDocument && getRecord(controllerProps).insuredDocument.documentModel === 'application' ? (
              <SimpleShowLabeled>
                <ReferenceField
                  reference="application"
                  source="insuredDocument.id"
                  label="Insured Application"
                >
                  <TextField source="publicId" />
                </ReferenceField>
              </SimpleShowLabeled>
            ) : null}
            <SimpleShowLabeled>
              <ArrayField source="refinancing" label="Refinancing">
                <Datagrid bulkActionButtons={null}>
                  <MoneyField source="amount" label="Refinancing amount" />
                  <ReferenceField
                    reference="account"
                    source="account"
                    label="Refinanced Account"
                  >
                    <TextField source="publicId" />
                  </ReferenceField>
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
            {getRecord(controllerProps).applicationStep
              && !isApplicationProcessed(controllerProps.record.applicationStep)
              && <AddRefinancingButton />}
            {isOverrideTermsAllowed(getRecord(controllerProps).applicationStep)
              && <OverridePricingTermsButton />}
            {isManualPayout(getRecord(controllerProps).accountType) && (
              <ArrayInput source="payout" label="Loans to solve" style={{ width: '80%' }}>
                <SimpleFormIterator
                  inline
                  disableRemove={isPayoutDisabled(getRecord(controllerProps).applicationStep)}
                  disableAdd={isPayoutDisabled(getRecord(controllerProps).applicationStep)}
                >
                  <ReferenceInput
                    label="Lender"
                    source="lender"
                    reference="lender"
                  >
                    <AutocompleteInput
                      TextFieldProps={{ style: { width: '36rem' } }}
                      validate={required()}
                      options={{
                        readOnly: isPayoutDisabled(getRecord(controllerProps).applicationStep),
                        className: classes.payoutItemReference,
                      }}
                      optionText={lenderRender}
                      matchSuggestion={checkSuggestionLender}
                      filterToQuery={filterToQueryLender}
                    />
                  </ReferenceInput>
                  <NumberInput
                    source="amount"
                    label="Amount"
                    key="Amount"
                    className={classes.payoutItem}
                    readOnly={isPayoutDisabled(getRecord(controllerProps).applicationStep)}
                  />
                  <FormDataConsumer>
                    {({ scopedFormData, getSource }) => scopedFormData && (
                      <TextInput
                        source={getSource('ocrNumber')}
                        label="OCR Number"
                        key="OCR Number"
                        className={classes.payoutItem}
                        validate={[ocrNumber]}
                        readOnly={isPayoutDisabled(getRecord(controllerProps).applicationStep)}
                        helperText={validators.ocrNumber(scopedFormData.ocrNumber, { withLengthCheck: true, withCheckDigit: true }) ? 'Not matching standard format' : ''}
                      />
                    )}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            )}
            <FormDataConsumer>
              {({ formData }) => (isManualPayout(getRecord(controllerProps).accountType)
                && <Typography variant="subtitle1" paragraph component="p">To be paid to customer: {displayPayoutAmount(formData.payout, getRecord(controllerProps).loanTerms, getRecord(controllerProps).refinancing)}</Typography>
              )}
            </FormDataConsumer>
            <SimpleShowLabeled>
              <TextField source="trackingData.utmSource" label="UTM Source" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <ArrayField source="decisionData.descriptions" label="Decision Description">
                <Datagrid bulkActionButtons={null}>
                  <TextField source="description" />
                  <TextField source="label" />
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <ArrayField source="decisionData.pledgeInformation" label="Pledge Information" fullWidth>
                <Datagrid bulkActionButtons={null}>
                  <ReferenceField
                    reference="organisation"
                    source="pledgee"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                  <BooleanField source="pledgeable" />
                  <ArrayField source="descriptions" label="Pledge Descriptions">
                    <Datagrid bulkActionButtons={null}>
                      <TextField source="description" />
                      <BooleanField source="pledgeable" />
                    </Datagrid>
                  </ArrayField>
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <SelectField
                label="Action to take"
                source="applicationStep"
                choices={choices.applicationMainSteps}
              />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <SelectField
                source="currentDecisionStatus"
                choices={choices.decisionStatuses}
              />
            </SimpleShowLabeled>
            <ManualDecision />
            <SimpleShowLabeled>
              <DecisionField
                as={TextInput}
                source="amount"
                label="Requested amount"
                readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
              />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <DecisionField
                as={TextInput}
                source="amountToRefinance"
                label="Amount To Refinance"
                readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
              />
            </SimpleShowLabeled>
            {(
              isAnnuityInvoicing(getRecord(controllerProps).accountType)
              || isInterestInvoicing(getRecord(controllerProps).accountType)
            ) && (
            <SimpleShowLabeled>
              <DecisionField
                as={TextInput}
                source="repaymentTime"
                label="Requested Repayment time"
                readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
              />
            </SimpleShowLabeled>
            )}
            {getRecord(controllerProps).applicants && (
              <ReferenceInput
                label="Bank account"
                source="bankAccountId"
                reference="bankAccount"
                filter={getBankAccountFilter(controllerProps)}
              >
                <SelectInput optionText={(record) => `${record.name || ''} ${accountNumberFormat(record.bankAccountType, record.accountNumber, record.clearingNumber)}`} />
              </ReferenceInput>
            )}
            <SimpleShowLabeled>
              <ArrayField source="applicants" label="">
                <Datagrid bulkActionButtons={null}>
                  <TextField source="email" validate={[email]} />
                  <TextField source="phone" />
                  <ArrayField source="signupUrls" label="">
                    <Datagrid bulkActionButtons={null}>
                      <ReferenceField source="id" reference="user">
                        <TextField source="id" />
                      </ReferenceField>
                      <UrlField source="signupUrl" label="Signup Url" />
                      <SendCompleteSmsButton application={getRecord(controllerProps)} />
                      <SendSignSmsButton application={getRecord(controllerProps)} />
                    </Datagrid>
                  </ArrayField>
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
            <ArrayInput source="applicants" fullWidth>
              <SimpleFormIterator>
                <SelectInput
                  choices={choices.holderTypes}
                  defaultValue={
                    ((getRecord(controllerProps).applicants || [])[0] || {}).holderType || 'User'
                  }
                  label="Holder Type"
                  source="holderType"
                  readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
                />
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => (
                    (scopedFormData.holderType === holderTypes.USER) ? (
                      <UserFields
                        applicationId={getRecord(controllerProps).id}
                        scopedFormData={scopedFormData}
                        getSource={getSource}
                        readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
                      />
                    ) : (
                      <OrganisationFields
                        applicationId={getRecord(controllerProps).id}
                        scopedFormData={scopedFormData}
                        getSource={getSource}
                        readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
                      />
                    )
                  )}
                </FormDataConsumer>
                <FileInput source="uploadedDocuments" label="Upload documents" multiple>
                  <FileField source="src" title="title" />
                </FileInput>
              </SimpleFormIterator>
            </ArrayInput>
            <SimpleShowLabeled>
              <ArrayField source="guarantors">
                <Datagrid bulkActionButtons={null}>
                  <ReferenceField source="_id" reference="user">
                    <TextField source="id" />
                  </ReferenceField>
                  <ReferenceField source="_id" reference="user" baseBath="/user" link={false} label="SSN">
                    <SocialSecurityNumberField source="socialSecurityNumber" />
                  </ReferenceField>
                  <ReferenceField source="_id" reference="user" baseBath="/user" link={false} label="Name">
                    <FunctionField render={(record) => (record && record.firstName ? <>{record.firstName}&nbsp;{record.lastName}</> : '')} />
                  </ReferenceField>
                  <ReferenceField source="_id" reference="user" baseBath="/user" link={false} label="Email">
                    <TextField source="email" emptyText="-" />
                  </ReferenceField>
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <BooleanField source="isWaitingForGuarantor" label="Waiting for Guarantor" />
            </SimpleShowLabeled>
            <DecisionField
              as={NullableBooleanInput}
              source="termsApproved"
              label="Terms approved"
              readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
            />
            <DecisionField
              as={NullableBooleanInput}
              source="canBeProfiled"
              label="Accept profiling"
              readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
            />
            <DecisionField
              as={SelectInput}
              source="reasonForLoan"
              readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
              label="Reason for loan"
              choices={choices.reasonsForLoan}
            />
            <DecisionField
              as={NullableBooleanInput}
              source="isBorrowingForOwnUse"
              readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
              label="Borrowing for own use"
            />
            <SelectInput
              readOnly={isFieldsDisabled(getRecord(controllerProps).applicationStep)}
              label="Brand awareness reason"
              source="brandAwarenessReason"
              choices={choices.brandAwarenessReasons}
            />
          </FormTab>
          <FormTab label="History">
            <SimpleShowLabeled fullWidth>
              <ArrayField source="applicants" label="Applications">
                <SingleFieldList linkType={false}>
                  <Box width="100%">
                    <ReferenceManyField
                      source="_id"
                      reference="application"
                      target="applicants._id"
                      pagination={<QuickPagination />}
                      perPage={5}
                    >
                      <ApplicationItem />
                    </ReferenceManyField>
                  </Box>
                </SingleFieldList>
              </ArrayField>
            </SimpleShowLabeled>
            <SimpleShowLabeled fullWidth>
              <ArrayField source="applicants" label="Accounts">
                <SingleFieldList linkType={false}>
                  <Box width="100%">
                    <ReferenceManyField
                      source="_id"
                      reference="account"
                      target="accountHolders._id"
                      pagination={<QuickPagination />}
                      perPage={5}
                    >
                      <AccountItem />
                    </ReferenceManyField>
                  </Box>
                </SingleFieldList>
              </ArrayField>
            </SimpleShowLabeled>
          </FormTab>
          <FormTab label="Categorisation">
            <ArrayField source="applicants">
              <Datagrid bulkActionButtons={null} sx={{ width: '100%' }}>
                <FunctionField render={(record) => (<CategorisationTab userId={record._id} />)} />
              </Datagrid>
            </ArrayField>
          </FormTab>
          <FormTab label="Events">
            <ArrayField source="events" label="" fullWidth>
              <Datagrid bulkActionButtons={null}>
                <TextField source="name" />
                <DateField source="date" />
                <TextField source="description" />
                <ReferenceField source="admin" reference="admins">
                  <TextField source="firstName" />
                </ReferenceField>
              </Datagrid>
            </ArrayField>
          </FormTab>
          <FormTab label="Comments">
            {getMainApplicant(controllerProps).holderType === holderTypes.USER ? (
              <ReferenceField label="" source="mainApplicant" reference="user" link={false} fullWidth>
                <ReferenceManyField label="Comments" reference="comment" target="user" fullWidth pagination={<Pagination />}>
                  <Datagrid bulkActionButtons={null}>
                    <DateField source="createdAt" label="Date" showTime />
                    <TextField source="message" />
                    <ReferenceField source="author" reference="admins">
                      <TextField source="firstName" />
                    </ReferenceField>
                  </Datagrid>
                </ReferenceManyField>
              </ReferenceField>
            ) : (
              <ReferenceField label="" source="mainApplicant" reference="organisation" link={false} fullWidth>
                <ReferenceManyField
                  label="Comments"
                  reference="comment"
                  target="organisation"
                  fullWidth
                  pagination={<Pagination />}
                >
                  <Datagrid bulkActionButtons={null}>
                    <DateField source="createdAt" label="Date" showTime />
                    <TextField source="message" />
                    <ReferenceField source="author" reference="admins">
                      <TextField source="firstName" />
                    </ReferenceField>
                  </Datagrid>
                </ReferenceManyField>
              </ReferenceField>
            )}
            {getMainApplicant(controllerProps).holderType === holderTypes.USER ? (
              <CreateRelatedButton
                source="mainApplicant"
                target="user"
                relatedPath="/comment"
                redirectPath="application"
                redirectSource="id"
              >
                Add Comment
              </CreateRelatedButton>
            ) : (
              <CreateRelatedButton
                source="mainApplicant"
                target="organisation"
                relatedPath="/comment"
                redirectPath="application"
                redirectSource="id"
              >
                Add Comment
              </CreateRelatedButton>
            )}
          </FormTab>
          {getMainApplicant(controllerProps).holderType === holderTypes.USER && (
            <FormTab label="Kalp">
              <Kalp
                applicationId={controllerProps.record && controllerProps.record.id}
                kalpUrl={controllerProps.record && controllerProps.record.kalpUrl}
                isApplicationProcessed={
                  controllerProps.record
                  && isApplicationProcessed(controllerProps.record.applicationStep)
                }
                isArchived={controllerProps.record && controllerProps.record.isKalpArchived}
              />
            </FormTab>
          )}
          <FormTab label="Extra resources">
            <ArrayField source="applicants" label="Credit Safes" fullWidth>
              <SingleFieldList>
                <ReferenceManyField
                  source="_id"
                  reference="creditSafeChecks"
                  target="user"
                  fullWidth
                  pagination={<Pagination />}
                >
                  <CreditSafeDatagrid />
                </ReferenceManyField>
              </SingleFieldList>
            </ArrayField>
            <LineSeparator />
            <ArrayField source="applicants" label="Uc checks" fullWidth>
              <Datagrid bulkActionButtons={null}>
                <TakeUcButton />
                <ReferenceManyField
                  source="_id"
                  label="Uc checks"
                  reference="ucChecks"
                  target="user"
                  fullWidth
                  pagination={<Pagination />}
                >
                  <UcInfoFieldsPreview />
                </ReferenceManyField>
              </Datagrid>
            </ArrayField>
          </FormTab>
          <FormTab label="Risk reports">
            <ArrayField source="applicants" label="Risk reports" fullWidth>
              <SingleFieldList>
                <ReferenceManyField
                  source="_id"
                  reference="riskReport"
                  target="accountHolder._id"
                  fullWidth
                  pagination={<Pagination />}
                >
                  <Datagrid bulkActionButtons={null}>
                    <TextField source="id" />
                    <DateField source="createdAt" />
                    {getMainApplicant(controllerProps).holderType === holderTypes.USER ? (
                      <ReferenceField label="Holder Id" source="accountHolder._id" reference="user">
                        <SocialSecurityNumberField source="socialSecurityNumber" />
                      </ReferenceField>
                    ) : (
                      <ReferenceField label="Holder Id" source="accountHolder._id" reference="organisation">
                        <OrganisationNumberField source="organisationNumber" />
                      </ReferenceField>
                    )}
                    <TextField source="rule" />
                    <TextField source="status" />
                    <FunctionField label="Reason" render={(record) => <Tooltip title={record.reason} placement="top"><Info /></Tooltip>} />
                    <ShowButton />
                  </Datagrid>
                </ReferenceManyField>
              </SingleFieldList>
            </ArrayField>
          </FormTab>
          <InsuranceTab model="application" />
        </TabbedForm>
      </EditView>
    </EditContextProvider>
  );
};

export default ApplicationEdit;
