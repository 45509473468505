import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import TextField from '@mui/material/TextField';

import useInputStatus from '../hooks/useInputStatus';

import ModalButton from './ModalButton';

import { isBeforeFactory } from '../../utils/signup/compareApplicationSteps';
import validators from '../../utils/validators';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const MarkAccepted = () => {
  const record = useRecordContext();
  const {
    id,
    applicationStep,
    rejectedByCustomer,
  } = record;

  const { constants } = useConstantContext();
  const { applicationSteps, orderedApplicationSteps } = constants;

  const { mutate: markAccepted } = useCustomRpc({
    httpMethod: 'PUT',
    path: `application/markAccepted/${id}`,
    successMessage: 'Application updated',
    errorMessage: 'Failed to update application',
    shouldRefresh: true,
  });

  const isBefore = isBeforeFactory(orderedApplicationSteps);

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus();

  const onClick = useCallback(() => {
    if (!isDefined(comment)) {
      setCommentError('A comment is required');
      return false;
    }
    markAccepted({ message: comment });
    return true;
  }, [comment, markAccepted, setCommentError]);

  const onClose = useCallback(() => {
    setComment('');
    setCommentError('');
  }, [setComment, setCommentError]);

  const validStep = isBefore(applicationStep, applicationSteps.PAYED_OUT);
  const canBeAccepted = validStep && !!rejectedByCustomer;

  if (!canBeAccepted) return null;
  return (
    <ModalButton
      title="Accepted By Customer"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <TextField
        label="Leave a comment"
        multiline
        maxRows="2"
        value={comment}
        onChange={onChangeComment}
        error={!!commentError}
        helperText={commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

export default MarkAccepted;
