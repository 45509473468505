import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

import CheckField from './CheckField';

const SourceField = ({
  source,
  required,
  perApplicant,
}) => {
  const record = useRecordContext();
  if (!record || !record.id) return null;

  const { decisionData } = record;
  const sources = decisionData ? decisionData.sources : [];
  const recurrences = sources
    .reduce(
      (res, item) => (item === source ? res + 1 : res),
      0,
    );

  return perApplicant ? record.applicants.map(({ _id }, index) => (
    <CheckField key={_id} value={recurrences >= (index + 1)} required={required} />
  )) : <CheckField value={recurrences > 0} required={required} />;
};

SourceField.propTypes = {
  source: PropTypes.string.isRequired,
  required: PropTypes.bool,
  perApplicant: PropTypes.bool,
};

SourceField.defaultProps = {
  required: false,
  perApplicant: false,
};

export default SourceField;
