import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import useDownloadDocument from '../../hooks/useDownloadDocument';

const styles = {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'inherit',
    height: 'inherit',
  },
  downloadButton: {
    margin: '0.5rem',
  },
};

const DownloadBankIdSignature = ({ accountId }) => {
  const record = useRecordContext();

  const { mutate: getBankIdSignature } = useDownloadDocument({
    path: `download/${accountId}/bank-id-signature/${record._id}`,
  });

  const onClick = useCallback(() => getBankIdSignature(), [getBankIdSignature]);

  return (
    <div style={styles.buttonWrapper}>
      <Button
        style={styles.downloadButton}
        size="small"
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        Download BankId Signature
      </Button>
    </div>
  );
};

DownloadBankIdSignature.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default DownloadBankIdSignature;
