import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ColoredButton from './ColoredButton';

import { useConstantContext } from './ConstantsContext';
import useDownloadDocument from '../../hooks/useDownloadDocument';

const WetAgreementLink = ({
  id,
  accountType,
}) => {
  const { accountFeatures } = useConstantContext();

  const { mutate: getWetAgreementDocument } = useDownloadDocument({
    path: `download/application/${id}/wet-agreement`,
    errorMessage: 'Error when downloading agreement',
  });

  const onClick = useCallback(
    () => getWetAgreementDocument(),
    [getWetAgreementDocument],
  );

  if (!accountFeatures.hasWetAgreementTemplate.includes(accountType)) return null;

  return (
    <ColoredButton
      onClick={onClick}
      background="DodgerBlue"
      color="white"
    >
      Download wet agreement
    </ColoredButton>
  );
};

WetAgreementLink.propTypes = {
  id: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
};

export default WetAgreementLink;
