import React from 'react';
import PropTypes from 'prop-types';

import {
  Datagrid,
  DateField,
  FormTab,
  ReferenceManyField,
  TextField,
  EditButton,
} from 'react-admin';

import InsureButton from './InsureButton';
import SimpleShowLabeled from './SimpleShowLabeled';

const InsuranceTab = ({ model, ...props }) => (
  <FormTab label="Insurance" {...props}>
    <InsureButton model={model} />
    <SimpleShowLabeled>
      <ReferenceManyField source="id" label="Insurance Applications" reference="application" target="insuredDocument.id" fullWidth>
        <Datagrid bulkActionButtons={null}>
          <DateField source="createdAt" />
          <TextField source="accountType" />
          <TextField source="applicationStep" />
          <TextField label="Insured Amount" source="loanTerms.amount" />
          <TextField label="Subscription Amount" source="loanTerms.subscriptionAmount" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLabeled>
    <SimpleShowLabeled>
      <ReferenceManyField source="id" label="Insurances" reference="account" target="terms.accountId" fullWidth>
        <Datagrid bulkActionButtons={null}>
          <DateField source="createdAt" />
          <TextField source="status" />
          <TextField source="accountType" />
          <TextField source="publicId" label="Public Id" />
          <TextField label="Insured Amount" source="terms.amount" />
          <TextField label="Subscription Amount" source="terms.subscriptionAmount" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLabeled>
  </FormTab>
);

InsuranceTab.propTypes = {
  model: PropTypes.string.isRequired,
};

export default InsuranceTab;
