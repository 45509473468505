import React, { useState, useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';

import useCustomRpc from '../../hooks/useCustomRpc';

const styles = {
  modal: {
    width: '500px',
    height: '200px',
    padding: '32px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2) 0px 5px 8px 0px rgba(0, 0, 0, 0.14) 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  buttonModal: {
    fontSize: '0.8rem',
    padding: '0.3rem 0.7rem',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: '0 0.5rem',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '1rem',
  },
  comment: {
    height: '2rem',
  },
};

const AddComment = () => {
  const [comment, setComment] = useState('');
  const [open, setOpen] = useState(false);

  const record = useRecordContext();

  const { mutate: addCommentRequest, isLoading } = useCustomRpc({
    httpMethod: 'POST',
    path: `riskReport/${record.id}/comment`,
    successMessage: 'Comment added',
    errorMessage: 'Error adding comment',
    shouldRefresh: true,
  });

  const handleSubmit = useCallback(() => {
    if (comment) {
      addCommentRequest({ comment });
      setComment('');
      setOpen(false);
    }
  }, [comment, addCommentRequest]);

  const handleOpenModal = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    setOpen(false);
  }, []);

  const handleChange = useCallback((e) => {
    e.preventDefault();
    setComment(e.target.value);
  }, []);

  return (
    <>
      <Fab
        disabled={isLoading}
        onClick={handleOpenModal}
        color="primary"
        size="small"
      >
        <AddIcon />
      </Fab>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleCloseModal}
      >
        <div style={styles.modal}>
          <Typography variant="h6" align="center">Add the comment:</Typography>
          <div style={styles.buttonsContainer}>
            <Button
              variant="contained"
              color="secondary"
              style={styles.buttonModal}
              onClick={handleCloseModal}
              disabled={isLoading}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={styles.buttonModal}
              onClick={handleSubmit}
              disabled={isLoading || (comment === '')}
            >
              Submit
            </Button>
          </div>
          <TextField
            id="reason"
            label="Reason"
            multiline
            maxRows="4"
            fullWidth
            value={comment}
            onChange={handleChange}
            style={styles.comment}
            margin="normal"
            disabled={isLoading}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddComment;
