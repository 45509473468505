import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import { isBeforeFactory } from '../../utils/signup/compareApplicationSteps';
import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const SendEmailButton = () => {
  const record = useRecordContext();
  const { applicationStep } = record;
  const { constants } = useConstantContext();
  const { applicationSteps, orderedApplicationSteps, holderTypes } = constants;

  const isBefore = isBeforeFactory(orderedApplicationSteps);

  const { mutate: sendSignupEmail, isLoading } = useCustomRpc({
    httpMethod: 'GET',
    path: `application/sendSignupEmail/${record.id}`,
    successMessage: 'Email sent',
    errorMessage: 'Email sending failed',
  });

  const handleSubmit = useCallback(() => {
    sendSignupEmail();
  }, [sendSignupEmail]);

  const isOrganisation = record.applicants[0].holderType === holderTypes.ORGANISATION;

  if (isBefore(applicationStep, applicationSteps.INFO_COLLECTED)
    || applicationStep === applicationSteps.AGREEMENT_SENT) {
    const label = applicationStep === applicationSteps.AGREEMENT_SENT
      ? 'Send sign link email'
      : 'Send complete link email';

    return (
      <Button
        title={label}
        disabled={isLoading || isOrganisation}
        onClick={handleSubmit}
        variant="contained"
        color="secondary"
        size="small"
      >
        {label}
      </Button>
    );
  }

  return null;
};

export default SendEmailButton;
