import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import { useConstantContext } from './ConstantsContext';

const styles = {
  alert: {
    backgroundColor: '#ff0000',
    color: '#f2f2f2',
  },
};

const AMLChip = ({ amlRiskLevel }) => {
  const { constants } = useConstantContext();
  const { amlRiskLevels } = constants;

  if (amlRiskLevel > amlRiskLevels.LOW_HIGH) return <Chip style={styles.alert} label="Risk AML" />;

  return null;
};

AMLChip.propTypes = {
  amlRiskLevel: PropTypes.number,
};

AMLChip.defaultProps = {
  amlRiskLevel: undefined,
};

export default AMLChip;
