import jsonServerRestClient from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';
import addUploadFeature from './addUploadFeature';
import getAccessToken from './utils/getAccessToken';
import config from '../config';

const httpClient = async (url, options = {}) => {
  const headers = options.headers || new window.Headers({ Accept: 'application/json' });

  headers.set('X-API-Key', config.apiKey);
  const token = await getAccessToken();
  headers.set('X-Token', token);
  return fetchUtils.fetchJson(url, {
    ...options,
    headers,
  });
};

const modifiedJsonServerRestClient = (apiUrl, _httpClient) => ({
  ...jsonServerRestClient(apiUrl, _httpClient),
  create: async (resource, params) => {
    const { json } = await _httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    });

    return {
      data: {
        id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
        ...json,
      },
    };
  },
  customRpc: async ({
    httpMethod,
    path,
    data,
    mimeType,
  }) => {
    let body;
    if (data) {
      if (mimeType === 'json') body = JSON.stringify(data);
      else if (mimeType === 'formData') {
        const formData = new window.FormData();

        data.forEach(({ fieldName, value, name }) => {
          if (name) formData.append(fieldName, value, name);
          else formData.append(fieldName, value);
        });
        body = formData;
      } else {
        throw new Error('Invalid mimeType');
      }
    }
    const { json } = await _httpClient(`${apiUrl}/${path}`, {
      method: httpMethod,
      body,
    });
    return json;
  },
});

const dataProvider = addUploadFeature(modifiedJsonServerRestClient(config.apiBase, httpClient));

export default dataProvider;
