import React from 'react';

import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  ShowView,
  SimpleShowLayout,
  TextField,
  FunctionField,
  BooleanField,
  useShowController,
  ShowContextProvider,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';

const SparCheckShow = () => {
  const controllerProps = useShowController();
  return (
    <ShowContextProvider value={controllerProps}>
      <ShowView>
        <SimpleShowLayout>
          <PageTitle title="Spar Check | Show" />
          <ReferenceField label="User Id" source="user" reference="user">
            <TextField source="id" />
          </ReferenceField>
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <SocialSecurityNumberField source="socialSecurityNumber" />
          <BooleanField label="Is Information Protected" source="isInformationProtected" />
          <DateField source="protectionUpdatedAt" />
          <BooleanField label="Is Information Hidden" source="isInformationHidden" />
          <DateField source="informationHiddenUpdatedAt" />
          <ArrayField source="personalDetails" label="Personal Details">
            <Datagrid bulkActionButtons={null}>
              <DateField source="validFrom" />
              <DateField source="validUntil" />
              <BooleanField label="Is Information Protected" source="isInformationProtected" />
              <BooleanField label="Is Information Hidden" source="isInformationHidden" />
              <DateField source="dateOfBirth" />
              <TextField source="gender" />
              <SocialSecurityNumberField source="previousSocialSecurityNumber" />
              <SocialSecurityNumberField source="newSocialSecurityNumber" />
              <TextField source="unregistrationReason" />
              <DateField source="unregistrationDate" />
              <DateField source="deceasedAt" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="names" label="Names">
            <Datagrid bulkActionButtons={null}>
              <DateField source="validFrom" />
              <DateField source="validUntil" />
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="middleName" />
              <FunctionField label="First Names" render={(record) => record.firstNames.join(' ')} />
            </Datagrid>
          </ArrayField>
          <ArrayField source="registrationsData">
            <Datagrid bulkActionButtons={null}>
              <DateField source="validFrom" />
              <DateField source="validUntil" />
              <DateField source="registeredAt" />
              <TextField source="countyCode" />
              <TextField source="communeCode" />
              <TextField source="extraInformation" />
              <TextField source="districtCode" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="addresses.registeredAddresses" label="Registered Addresses">
            <Datagrid bulkActionButtons={null}>
              <DateField source="validFrom" />
              <DateField source="validUntil" />
              <TextField source="careOf" />
              <TextField source="streetAddress" />
              <TextField source="addressContinuation" />
              <TextField source="postcode" />
              <TextField source="town" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="addresses.specialAddresses" label="Special Addresses">
            <Datagrid bulkActionButtons={null}>
              <DateField source="validFrom" />
              <DateField source="validUntil" />
              <TextField source="careOf" />
              <TextField source="streetAddress" />
              <TextField source="addressContinuation" />
              <TextField source="postcode" />
              <TextField source="town" />
              <TextField source="addressLine1" />
              <TextField source="addressLine2" />
              <TextField source="addressLine3" />
              <TextField source="country" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="addresses.foreignAddresses" label="Foreign Addresses">
            <Datagrid bulkActionButtons={null}>
              <DateField source="validFrom" />
              <DateField source="validUntil" />
              <TextField source="addressLine1" />
              <TextField source="addressLine2" />
              <TextField source="addressLine3" />
              <TextField source="country" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="errorMessages" label="Error Messages">
            <Datagrid bulkActionButtons={null}>
              <TextField source="code" />
              <TextField source="description" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};

export default SparCheckShow;
