import React from 'react';
import {
  List,
  TextField,
  FunctionField,
  DateField,
  EditButton,
  ReferenceField,
} from 'react-admin';

import useTodoFilters from './useTodoFilters';

import PageTitle from '../utils/PageTitle';
import AltField from '../utils/AltField';
import HeadedDatagrid from '../utils/HeadedDatagrid';
import AutoRefresh from '../utils/AutoRefresh';

const SUMMARY_CHARS = 150;

const descriptionSummary = (record) => {
  if (!record) return '';
  if (record.description.length < SUMMARY_CHARS) return record.description;
  return `${record.description.substring(0, SUMMARY_CHARS)}...`;
};

const TodoList = () => {
  const todoFilters = useTodoFilters();
  return (
    <List
      filters={todoFilters}
      sort={{ field: 'dueDate', order: 'ASC' }}
      filterDefaultValues={{ status: 'pending' }}
    >
      <HeadedDatagrid
        head={(
          <>
            <AutoRefresh />
            <PageTitle title="Todo | List" />
          </>
        )}
      >
        <DateField source="dueDate" showTime />
        <TextField source="status" />
        <FunctionField label="Description" render={descriptionSummary} />
        <AltField
          label="Author"
          predicate={(record) => record.author}
          primary={(
            <ReferenceField source="author" reference="admins">
              <TextField source="firstName" />
            </ReferenceField>
          )}
          secondary={(
            <FunctionField render={() => 'System'} />
          )}
        />
        <EditButton />
      </HeadedDatagrid>
    </List>
  );
};

export default TodoList;
