import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

import getAccessToken from '../../utils/getAccessToken';

const sdk = new ChartsEmbedSDK({
  baseUrl: 'https://charts.mongodb.com/charts-moank-live-db-pndjk',
  showAttribution: false,
  autoRefresh: true,
});

const MongoChart = ({
  chartId,
  className,
  height,
  width,
  background,
}) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const chart = sdk.createChart({
      getUserToken: async () => {
        const token = await getAccessToken();
        return token || '';
      },
      chartId,
      autoRefresh: true,
      maxDataAge: 300,
      height,
      width,
      background,
    });
    chart.render(chartRef.current);
  }, [background, chartId, height, width]);

  return (
    <div ref={chartRef} className={className} />
  );
};

MongoChart.propTypes = {
  chartId: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  background: PropTypes.string,
};

MongoChart.defaultProps = {
  className: undefined,
  height: undefined,
  width: undefined,
  background: undefined,
};

export default MongoChart;
