import React, { useCallback, useMemo } from 'react';
import { usePermissions, useRecordContext } from 'react-admin';

import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import ModalButton from '../utils/ModalButton';

import useInputStatus from '../hooks/useInputStatus';
import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const cardActionStyle = {
  zIndex: 2,
  float: 'right',
};

const RiskReportButtons = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const { constants } = useConstantContext();
  const { ROLE_SUPER_ADMIN } = constants.userRoles;

  const {
    id,
    status,
    reportedAt,
    ignoreReporting,
  } = record;

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus('');

  const canOverrideStatus = useMemo(
    () => permissions && permissions.includes(ROLE_SUPER_ADMIN) && !reportedAt,
    [permissions, ROLE_SUPER_ADMIN, reportedAt],
  );

  const {
    mutate: updateStatusRequest,
    isLoading: isLoadingUpdateStatus,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `riskReport/${id}`,
    successMessage: 'Status updated',
    errorMessage: 'Error updating status',
    shouldRefresh: true,
  });

  const {
    mutate: markReportedRequest,
    isLoading: isLoadingMarkReported,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `riskReport/${id}/mark-reported`,
    successMessage: 'Marked Reported',
    errorMessage: 'Error marking reported',
    shouldRefresh: true,
  });

  const { mutate: ignoreReportingRequest } = useCustomRpc({
    httpMethod: 'PUT',
    path: `riskReport/${id}/ignore-reporting`,
    successMessage: 'Flagged Ignored',
    errorMessage: 'Error flagging report as ignored',
    shouldRefresh: true,
  });

  const updateStatusFactory = (mutation, selectedStatus) => () => {
    if (!comment) {
      setCommentError('A comment is required to execute this action');
      return false;
    }

    mutation({ comment, status: selectedStatus });
    setComment('');
    setCommentError('');
    return true;
  };

  const canUpdateStatus = !isLoadingUpdateStatus && status === 'pending';
  const canMarkReport = !isLoadingMarkReported && status === 'confirmed' && !reportedAt && !ignoreReporting;

  const confirmRequest = updateStatusFactory(updateStatusRequest, 'confirmed');
  const resolveRequest = updateStatusFactory(updateStatusRequest, 'resolved');
  const ignoreRequest = updateStatusFactory(updateStatusRequest, 'ignored');
  const markReported = useCallback(() => markReportedRequest(), [markReportedRequest]);
  const ignoreReport = updateStatusFactory(ignoreReportingRequest);

  const onClose = () => {
    setComment('');
    setCommentError('');
  };

  return (
    <CardActions style={cardActionStyle}>
      <ModalButton
        title="Resolved"
        onClick={resolveRequest}
        onClose={onClose}
        fullWidth
        disabled={status === 'resolved' || (!canUpdateStatus && !canOverrideStatus)}
      >
        <TextField
          label="Add a comment"
          onChange={onChangeComment}
          value={comment}
          error={!!commentError}
          helperText={commentError}
          margin="normal"
          fullWidth
        />
      </ModalButton>
      <ModalButton
        title="Confirmed"
        onClick={confirmRequest}
        onClose={onClose}
        fullWidth
        disabled={status === 'confirmed' || (!canUpdateStatus && !canOverrideStatus)}
      >
        <TextField
          label="Add a comment"
          onChange={onChangeComment}
          value={comment}
          error={!!commentError}
          helperText={commentError}
          margin="normal"
          fullWidth
        />
      </ModalButton>
      <ModalButton
        title="Ignore"
        onClick={ignoreRequest}
        onClose={onClose}
        fullWidth
        disabled={status === 'ignored' || (!canUpdateStatus && !canOverrideStatus)}
      >
        <TextField
          label="Add a comment"
          onChange={onChangeComment}
          value={comment}
          error={!!commentError}
          helperText={commentError}
          margin="normal"
          fullWidth
        />
      </ModalButton>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={markReported}
        disabled={!canMarkReport}
      >
        Mark Reported
      </Button>
      <ModalButton
        title="Ignore Reporting"
        onClick={ignoreReport}
        onClose={onClose}
        fullWidth
        disabled={!canMarkReport}
      >
        <TextField
          label="Add a comment"
          onChange={onChangeComment}
          value={comment}
          error={!!commentError}
          helperText={commentError}
          margin="normal"
          fullWidth
        />
      </ModalButton>
    </CardActions>
  );
};

export default RiskReportButtons;
