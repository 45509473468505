/*
To update this file, update the copy in the api/src/utils folder and run at the root of the project:
cp api/src/utils/validators.ts frontend/src/utils/validators.js && \
  cp api/src/utils/validators.ts backend/src/utils/validators.js && \
  cp api/src/utils/validators.ts portal/src/utils/validators.js
*/
import moment from 'moment';
import { toUnicode } from 'punycode';
import { parsePhoneNumberFromString } from 'libphonenumber-js/core';
import metadata from './metadata.custom.json';
import topDomains from './validTopDomains.json';

const luhnCheck = (value) => {
  const parity = value.length % 2;
  let checkDigit = 0;
  for (let index = value.length - 1; index >= 0; index -= 1) {
    let digit = parseInt(value[index], 10);
    if (index % 2 === parity) { digit *= 2; }
    if (digit > 9) { digit -= 9; }
    checkDigit += digit;
  }
  return checkDigit % 10 === 0;
};

const ocrNumber = (
  value,
  options,
) => {
  if (typeof value !== 'string') return false;
  if (value.length < 2 || value.length > 25) return false;

  if (options && options.withLengthCheck) {
    const lengthDigitCheck = value.length % 10;
    const secondLastDigit = parseInt(value.slice(-2, -1), 10);
    if (secondLastDigit !== lengthDigitCheck) return false;
  }

  if (options && options.withCheckDigit) return luhnCheck(value);
  return true;
};

const socialSecurityNumber = (value) => {
  if (typeof value !== 'string') return false;
  if (!(/^(20|19)[0-9]{10}$/.test(value))) return false;
  if (!moment(value.slice(0, 8), 'YYYYMMDD').isValid()) return false;
  return luhnCheck(value.slice(2));
};

const organisationNumber = (value) => {
  if (typeof value !== 'string') return false;
  if (!(/^[0-9]{10}$/.test(value))) return false;
  return luhnCheck(value);
};

const isDefined = (value) => value !== undefined && value !== null && value !== '';
const optional = (validation, value, ...rest) => (isDefined(value)
  ? validation(value, ...rest)
  : true
);
const required = (validation, value, ...rest) => (isDefined(value)
  ? validation(value, ...rest)
  : false
);
const nullable = (validation, value, ...rest) => (value === null
  ? true
  : required(validation, value, ...rest)
);
const isEmptyObject = (obj) => Object.entries(obj).length === 0 && obj.constructor === Object;
const isBoolean = (value) => typeof value === 'boolean';
const isString = (value) => typeof value === 'string';
const isNumber = (value) => !Number.isNaN(parseFloat(value)) && Number.isFinite(+value);
const isPositiveNumber = (value) => isNumber(value) && parseFloat(value) >= 0;
const isStrictlyPositiveNumber = (value) => isNumber(value) && parseFloat(value) > 0;
const isNonEmptyArray = (value) => Array.isArray(value) && value.length > 0;
const isOneOf = (value, possibilities) => possibilities.includes(value);
const isArrayOf = (elementValidator, ...rest) => (value) => Array.isArray(value)
  && value.every((element) => elementValidator(element, ...rest));
const isNonEmptyArrayOf = (elementValidator, ...rest) => (value) => isNonEmptyArray(value)
  && value.every((element) => elementValidator(element, ...rest));
const isDate = (value) => moment(value).isValid() && moment(value).isAfter('1000-01-01');
const isInObject = (value, object) => Object.prototype.hasOwnProperty.call(object, value);
const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
const onlyDistinctValues = (values) => isNonEmptyArray(values)
  && new Set(values).size === values.length;
const onlySameValue = (values) => isNonEmptyArray(values)
  && new Set(values).size === 1;
const isURL = (value) => {
  try {
    const url = new URL(value);
    return !!url;
  } catch (e) {
    return false;
  }
};
const isHexColor = (value) => isString(value) && /^#[0-9A-F]{6}$/i.test(value);

export default {
  digitCheck: (value) => luhnCheck(value),
  socialSecurityNumber,
  organisationNumber,
  email: (value) => {
    const isValid = isString(value) && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(toUnicode(value));
    if (isValid) {
      const address = value.split('@').pop();
      if (!address) return false;
      let domain = address.split('.').pop();
      if (!domain) return false;
      domain = domain.toUpperCase();
      return topDomains.includes(domain);
    }
    return false;
  },
  validateIdentities: (values) => onlyDistinctValues(values),
  holderTypes: (values) => onlySameValue(values),
  phone: (value) => {
    if (!value) return false;
    const parsed = parsePhoneNumberFromString(value, 'SE', metadata);
    return !!parsed && parsed.isValid() && parsed.country === 'SE';
  },
  employerPhone: (value) => {
    if (!value) return true;
    const parsed = parsePhoneNumberFromString(value, 'SE', metadata);
    return !!parsed && parsed.isValid() && parsed.country === 'SE';
  },
  homeType: (value) => isString(value),
  bank: (value) => isString(value),
  employerName: (value) => isString(value),
  bankAccountId: (value) => isDefined(value),
  clearingNumber: (value) => isString(value) && /^[0-9]{4,5}$/.test(value),
  accountNumber: (value) => isString(value) && /^[0-9]{5,12}$/.test(value),
  amount: (value) => isStrictlyPositiveNumber(value),
  repaymentTime: (value) => isStrictlyPositiveNumber(value),
  monthlyIncome: (value) => isPositiveNumber(value),
  monthlyHousing: (value) => isPositiveNumber(value),
  monthlyLoans: (value) => isPositiveNumber(value),
  mortgageAmount: (value) => isPositiveNumber(value),
  isBorrowingForOwnUse: (value) => isBoolean(value),
  termsApproved: (value) => value === true,
  canBeProfiled: (value) => value === true,
  isNotPEP: (value) => isBoolean(value),
  wantLoanProtection: (value) => isBoolean(value),
  knownAmount: (value) => isBoolean(value),
  connectEmployer: (value) => isBoolean(value),
  applicants: (value) => isNonEmptyArray(value),
  ocrNumber,
  // NOTE: Standard is 7-8 digits but some lenders have 6 digits instead
  fullAccountNumber: (value) => isDefined(value) && /^[0-9]{6,8}$/.test(value),
  appointmentDate: (value) => !!value,
  appointmentTime: (value) => !!value,
  utmSource: (value) => isDefined(value) && isString(value) && /^[a-z-_0-9]{3,}$/.test(value),
  brokerReferenceId: (value) => isDefined(value) && isString(value),
  // Generics
  generics: {
    isBoolean,
    isDate,
    isDefined,
    isEmptyObject,
    isInObject,
    isObject,
    isNonEmptyArray,
    isArrayOf,
    isNonEmptyArrayOf,
    isNumber,
    isOneOf,
    isPositiveNumber,
    isStrictlyPositiveNumber,
    isString,
    isURL,
    isHexColor,
    onlyDistinctValues,
    optional,
    required,
    nullable,
  },
};
