import React, { useCallback, useState } from 'react';
import { useRecordContext } from 'react-admin';
import moment from 'moment';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';

import useInputStatus from '../hooks/useInputStatus';

import validators from '../../utils/validators';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { generics: { isStrictlyPositiveNumber, isDefined } } = validators;

const ExtraAmortisationRequestButton = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { extraAmortisationRequestValidity } = constants;

  const { mutate: sendExtraAmortisationDocument } = useCustomRpc({
    httpMethod: 'POST',
    path: `account/${record.id}/extra-amortisation-request`,
    mimeType: 'formData',
    shouldRefresh: true,
  });

  const [
    extraAmortisationRequestedAmount,
    setExtraAmortisationRequestedAmount,
    onChangeExtraAmortisationRequestedAmount,
    extraAmortisationRequestedAmountError,
    setExtraAmortisationRequestedAmountError,
  ] = useInputStatus();
  const [
    extraAmortisationComment,
    setExtraAmortisationComment,
    onChangeExtraAmortisationComment,
    extraAmortisationCommentError,
    setExtraAmortisationCommentError,
  ] = useInputStatus();

  const [file, setFile] = useState(undefined);
  const [fileError, setFileError] = useState('');
  const onChangeFile = useCallback((e) => {
    setFileError('');
    if (e.target.files && e.target.files.length > 0) setFile(e.target.files[0]);
  }, [setFileError, setFile]);

  const onClick = useCallback(() => {
    if (!isStrictlyPositiveNumber(extraAmortisationRequestedAmount)) {
      setExtraAmortisationRequestedAmountError('Extra amortisation requested amount should be a strictly positive number');
      return false;
    }
    if (!isDefined(extraAmortisationComment)) {
      setExtraAmortisationCommentError('The comment for the extra amortisation is required');
      return false;
    }

    if (!file) {
      setFileError('A file is required to justify the extra amortisation');
      return false;
    }

    const formDataFields = [
      {
        fieldName: 'extraAmortisationRequestedAmount',
        value: extraAmortisationRequestedAmount,
      },
      {
        fieldName: 'extraAmortisationComment',
        value: extraAmortisationComment,
      },
      {
        fieldName: 'file',
        value: file,
      },
    ];
    sendExtraAmortisationDocument(formDataFields);
    return true;
  }, [extraAmortisationRequestedAmount, extraAmortisationComment, file,
    sendExtraAmortisationDocument, setExtraAmortisationRequestedAmountError,
    setExtraAmortisationCommentError]);

  const onClose = useCallback(() => {
    setExtraAmortisationRequestedAmount(0);
    setExtraAmortisationRequestedAmountError('');
    setExtraAmortisationComment('');
    setExtraAmortisationCommentError('');
    setFile(undefined);
    setFileError('');
  }, [setExtraAmortisationComment, setExtraAmortisationCommentError,
    setExtraAmortisationRequestedAmount, setExtraAmortisationRequestedAmountError]);

  const today = moment().format('YYYY-MM-DD');
  return (
    <ModalButton
      title="Create extra amortisation request"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <Typography style={{ marginBottom: '1rem' }} variant="body2">(It will override the previous request)</Typography>
      <Typography variant="body1">Request will be valid from: {today}</Typography>
      <Typography variant="body1">to: {moment(today).add(extraAmortisationRequestValidity, 'days').format('YYYY-MM-DD')}</Typography>
      <input
        style={{ marginTop: '2rem' }}
        type="file"
        id="file"
        name="file"
        accept=".pdf"
        onChange={onChangeFile}
      />
      <Typography color="error" align="center">{fileError}</Typography>
      <TextField
        type="number"
        label="Extra amortisation requested amount"
        onChange={onChangeExtraAmortisationRequestedAmount}
        value={extraAmortisationRequestedAmount}
        error={!!extraAmortisationRequestedAmountError}
        helperText={extraAmortisationRequestedAmountError}
        margin="normal"
        fullWidth
      />
      <TextField
        type="text"
        label="Extra amortisation comment"
        onChange={onChangeExtraAmortisationComment}
        value={extraAmortisationComment}
        error={!!extraAmortisationCommentError}
        helperText={`It will be used as official documentation of the EAR file for the AML process. ${extraAmortisationCommentError}`}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

export default ExtraAmortisationRequestButton;
