import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { numberFormat } from '../../../utils/formatters';

const styles = {
  root: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
};

const StyledTabledRow = withStyles(styles)(TableRow);

class CategorisationTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      extended: false,
    };

    this.detailsView = this.detailsView.bind(this);
  }

  detailsView(e) {
    e.preventDefault();
    const { extended } = this.state;
    this.setState({ extended: !extended });
  }

  render() {
    const { transactions } = this.props;
    const { extended } = this.state;
    return (
      <>
        <StyledTabledRow onClick={this.detailsView}>
          <TableCell width={styles.descriptionWidth} variant="body">
            {transactions[0]}
          </TableCell>
          <TableCell width={styles.amountWidth} variant="body">
            {numberFormat(transactions[1].sum)}
          </TableCell>
          <TableCell width={styles.numberWidth} variant="body">
            {transactions[1].noTrans}
          </TableCell>
        </StyledTabledRow>
        {extended && (
          <StyledTabledRow onClick={this.detailsView}>
            <TableCell colSpan="3">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Date</TableCell>
                    <TableCell variant="head">Amount</TableCell>
                    <TableCell variant="head">Account Number</TableCell>
                  </TableRow>
                  {}
                </TableHead>
              </Table>
              <TableBody>
                {transactions[1].det.map((transaction) => (
                  <TableRow key={`${transaction.date}${transaction.amount}`}>
                    <TableCell>{moment(transaction.date).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{numberFormat(transaction.amount)}</TableCell>
                    <TableCell>{transaction.accountNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableCell>
          </StyledTabledRow>
        )}
      </>
    );
  }
}

CategorisationTransactions.propTypes = {
  transactions: PropTypes.array,
};

CategorisationTransactions.defaultProps = {
  transactions: [],
};

export default CategorisationTransactions;
