import { useState, useCallback } from 'react';

function useInputStatus(init = '', parser = (i) => i) {
  const [value, setValue] = useState(parser(init));
  const [error, setError] = useState('');
  const onChangeValue = useCallback(
    async (e) => {
      setError('');
      setValue(parser(e.target.value));
    },
    [parser],
  );
  return [
    value,
    setValue,
    onChangeValue,
    error,
    setError,
  ];
}

export default useInputStatus;
