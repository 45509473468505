import React, { useCallback, useState } from 'react';
import { useRecordContext } from 'react-admin';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';

import { numberParser } from '../../utils/formatters';
import validators from '../../utils/validators';

import useInputStatus from '../hooks/useInputStatus';
import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined, isStrictlyPositiveNumber } = validators.generics;

const AddDrawdownButton = () => {
  const record = useRecordContext();
  const { accountFeatures } = useConstantContext();
  const { drawdown } = accountFeatures;

  const { id, accountType, terms } = record || {};

  const { mutate: sendDrawdownRequest } = useCustomRpc({
    httpMethod: 'POST',
    path: `account/${id}/drawdown`,
    mimeType: 'formData',
    shouldRefresh: true,
  });

  const [
    comment,
    setComment,
    onChangeComment,
    commentError,
    setCommentError,
  ] = useInputStatus();

  const [
    amount,
    setAmount,
    onChangeAmount,
    amountError,
    setAmountError,
  ] = useInputStatus(0, numberParser);

  const [file, setFile] = useState(undefined);
  const [fileError, setFileError] = useState('');
  const onChangeFile = useCallback((e) => {
    setFileError('');
    if (e.target.files && e.target.files.length > 0) setFile(e.target.files[0]);
  }, [setFileError, setFile]);

  const onClick = useCallback(
    () => {
      setAmountError('');
      setCommentError('');
      setFileError('');

      if (!file) {
        setFileError('A file is required to justify the extra amortisation');
        return false;
      }

      if (!isStrictlyPositiveNumber(amount)) {
        setAmountError('Drawdown amount is required and should be strictly positive');
        return false;
      }
      if (!isDefined(comment)) {
        setCommentError('A comment is required');
        return false;
      }

      const formDataFields = [
        {
          fieldName: 'amount',
          value: amount,
        },
        {
          fieldName: 'comment',
          value: comment,
        },
        {
          fieldName: 'file',
          value: file,
        },
      ];

      sendDrawdownRequest(formDataFields);
      return true;
    },
    [amount, comment, sendDrawdownRequest, setAmountError, setCommentError, file, setFileError],
  );

  const onClose = useCallback(() => {
    setAmount(0);
    setComment('');
    setAmountError('');
    setCommentError('');
    setFile(undefined);
    setFileError('');
  }, [setAmount, setAmountError, setComment, setCommentError,
    setFile, setFileError]);

  if (!drawdown.includes(accountType)) return null;
  if (terms.drawdown && terms.drawdown.termination && terms.drawdown.termination.date) return null;

  return (
    <ModalButton
      title="Add Drawdown"
      onClick={onClick}
      onClose={onClose}
      right
    >
      <input
        style={{ marginTop: '2rem' }}
        type="file"
        id="file"
        name="file"
        accept=".pdf"
        onChange={onChangeFile}
      />
      <Typography variant="caption" color="error" align="center">{fileError}</Typography>
      <TextField
        type="number"
        label="Select the amount"
        onChange={onChangeAmount}
        value={amount}
        margin="normal"
        error={!!amountError}
        helperText={amountError}
        fullWidth
      />
      <TextField
        label="Leave a comment"
        multiline
        maxRows="4"
        value={comment}
        onChange={onChangeComment}
        error={!!commentError}
        helperText={commentError}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

export default AddDrawdownButton;
