import React, { useCallback, useMemo, useEffect } from 'react';
import { useRecordContext, usePermissions } from 'react-admin';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ModalButton from '../utils/ModalButton';
import getAnnuityAmount from '../../utils/getAnnuityAmount';

import {
  isAnnuityInvoicingSelector,
  isInterestInvoicingSelector,
  isMtpInvoicingSelector,
} from '../../utils/accountFeatures';

import useInputStatus from '../hooks/useInputStatus';

import validators from '../../utils/validators';
import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { isDefined } = validators.generics;

const styles = {
  reasonInput: {
    width: '29rem',
    margin: '0.5rem',
  },
  input: {
    width: '12rem',
    margin: '0.5rem',
  },
  inputs: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
  },
};

const parseInput = (value) => (value ? parseFloat(value) : undefined);

const OverridePricingTermsButton = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const { constants, accountFeatures } = useConstantContext();
  const { userRoles: { ROLE_SUPER_ADMIN } } = constants;

  const canOverrideTermsOverDE = useMemo(
    () => permissions && permissions.includes(ROLE_SUPER_ADMIN),
    [ROLE_SUPER_ADMIN, permissions],
  );

  const {
    loanTerms,
    id,
    decisionData: {
      terms: {
        descriptions: {
          maxAmount,
          minNominalRate,
          maxMonths: maxRepaymentTime,
        },
      },
    },
    accountType,
  } = record;

  const isAnnuityInvoicing = isAnnuityInvoicingSelector(accountFeatures);
  const isMtpInvoicing = isMtpInvoicingSelector(accountFeatures);
  const isInterestInvoicing = isInterestInvoicingSelector(accountFeatures);

  const { mutate: sendTermsUpdate } = useCustomRpc({
    httpMethod: 'PUT',
    path: `application/updatePricing/${id}`,
    successMessage: 'Application pricing/terms updated',
    errorMessage: 'Application pricing/terms updating failed',
    shouldRefresh: true,
  });
  const [
    reason,
    setReason,
    onChangeReason,
    errorReason,
    setErrorReason,
  ] = useInputStatus('');
  const [
    nominalRate,
    setNominalRate,
    onChangeNominalRate,
    errorNominalRate,
    setErrorNominalRate,
  ] = useInputStatus(loanTerms.nominalRate || '', parseInput);
  const [
    amount,
    setAmount,
    onChangeAmount,
    errorAmount,
    setErrorAmount,
  ] = useInputStatus(loanTerms.amount || '', parseInput);
  const [
    creditLimit,
    setCreditLimit,
    onChangeCreditLimit,
    errorCreditLimit,
    setErrorCreditLimit,
  ] = useInputStatus(loanTerms.crediLimit || '', parseInput);
  const [
    repaymentTime,
    setRepaymentTime,
    onChangeRepaymentTime,
    errorRepaymentTime,
    setErrorRepaymentTime,
  ] = useInputStatus(loanTerms.repaymentTime || '', parseInput);
  const [
    startupFee,
    setStartupFee,
    onChangeStartupFee,
    errorStartupFee,
    setErrorStartupFee,
  ] = useInputStatus(loanTerms.startupFee || '', parseInput);

  useEffect(() => {
    setReason('');
    setNominalRate(loanTerms.nominalRate || '');
    setAmount(loanTerms.amount || '');
    setCreditLimit(loanTerms.creditLimit || '');
    setRepaymentTime(loanTerms.repaymentTime || '');
    setStartupFee(loanTerms.startupFee || '');
    setErrorReason('');
    setErrorNominalRate('');
    setErrorAmount('');
    setErrorCreditLimit('');
    setErrorRepaymentTime('');
    setErrorStartupFee('');
  }, [loanTerms.nominalRate, loanTerms.amount, loanTerms.creditLimit,
    loanTerms.repaymentTime, loanTerms.startupFee, setReason, setNominalRate,
    setAmount, setCreditLimit, setRepaymentTime, setStartupFee, setErrorReason,
    setErrorNominalRate, setErrorAmount, setErrorCreditLimit, setErrorRepaymentTime,
    setErrorStartupFee]);

  const handleSubmit = useCallback(() => {
    if (!reason) {
      setErrorReason('Please add the reason');
      return false;
    }

    if (!amount && isDefined(loanTerms.amount)) {
      setErrorAmount('Please add an amount');
      return false;
    }

    if (!creditLimit && isDefined(loanTerms.creditLimit)) {
      setErrorCreditLimit('Please add a creditLimit');
      return false;
    }

    if (!repaymentTime && isDefined(loanTerms.repaymentTime)) {
      setErrorRepaymentTime('Please add a repaymentTime');
      return false;
    }

    if (!isDefined(startupFee) && isDefined(loanTerms.startupFee)) {
      setErrorStartupFee('Please add a startupFee');
      return false;
    }

    if (!nominalRate && isDefined(loanTerms.nominalRate)) {
      setErrorNominalRate('Please add a nominalRate');
      return false;
    }

    if (!canOverrideTermsOverDE) {
      if (isDefined(amount) && isDefined(maxAmount) && amount > maxAmount) {
        setErrorAmount(`Amount cannot be over the decision engine description: ${maxAmount}`);
        return false;
      }
      if (isDefined(nominalRate) && isDefined(minNominalRate) && nominalRate < minNominalRate) {
        setErrorNominalRate(`Nominal rate cannot be over the decision engine description: ${minNominalRate}`);
        return false;
      }
      if (isDefined(repaymentTime) && isDefined(maxRepaymentTime)
      && repaymentTime > maxRepaymentTime) {
        setErrorRepaymentTime(`Repayment time cannot be over the decision engine description: ${maxRepaymentTime}`);
        return false;
      }
    }

    sendTermsUpdate({
      amount,
      creditLimit,
      repaymentTime,
      startupFee,
      nominalRate,
      message: reason,
    });
    return true;
  }, [reason, amount, loanTerms.amount, loanTerms.creditLimit, loanTerms.repaymentTime,
    loanTerms.startupFee, loanTerms.nominalRate, creditLimit, repaymentTime, startupFee,
    nominalRate, canOverrideTermsOverDE, sendTermsUpdate, setErrorReason, setErrorAmount,
    setErrorCreditLimit, setErrorRepaymentTime, setErrorStartupFee, setErrorNominalRate,
    maxAmount, minNominalRate, maxRepaymentTime]);

  const onClose = useCallback(() => {
    setReason('');
    setNominalRate(loanTerms.nominalRate || '');
    setAmount(loanTerms.amount || '');
    setCreditLimit(loanTerms.creditLimit || '');
    setRepaymentTime(loanTerms.repaymentTime || '');
    setStartupFee(loanTerms.startupFee || '');
    setErrorReason('');
    setErrorNominalRate('');
    setErrorAmount('');
    setErrorCreditLimit('');
    setErrorRepaymentTime('');
    setErrorStartupFee('');
  }, [setReason, setNominalRate, loanTerms.nominalRate, loanTerms.amount, loanTerms.creditLimit,
    loanTerms.repaymentTime, loanTerms.startupFee, setAmount, setCreditLimit, setRepaymentTime,
    setStartupFee, setErrorReason, setErrorNominalRate, setErrorAmount, setErrorCreditLimit,
    setErrorRepaymentTime, setErrorStartupFee]);

  return (
    <ModalButton
      title="Override Terms"
      onClick={handleSubmit}
      onClose={onClose}
    >
      <Typography align="center">Override current pricing/terms ?</Typography>
      {(errorReason
        || errorAmount
        || errorNominalRate
        || errorCreditLimit
        || errorRepaymentTime
        || errorStartupFee
      ) && (
        <Typography color="error" align="center">
          {[
            errorReason,
            errorAmount,
            errorNominalRate,
            errorCreditLimit,
            errorRepaymentTime,
            errorStartupFee,
          ].filter((error) => !!error).join(', ')}
        </Typography>
      )}
      <div style={styles.inputs}>
        <TextField
          label="Amount"
          value={amount}
          onChange={onChangeAmount}
          type="number"
          style={styles.input}
          helperText={loanTerms.amount ? `Previous is ${loanTerms.amount}. Limit is ${maxAmount ? `${maxAmount} max` : 'not set'}` : 'Field not already set'}
          error={!!errorAmount}
        />
        <TextField
          label="Nominal Rate"
          value={nominalRate}
          onChange={onChangeNominalRate}
          type="number"
          style={styles.input}
          helperText={loanTerms.nominalRate ? `Previous is ${loanTerms.nominalRate}. Limit is ${minNominalRate ? `${minNominalRate} min` : 'not set'}` : 'Field not already set'}
          error={!!errorNominalRate}
        />
        {(isInterestInvoicing(accountType) || isAnnuityInvoicing(accountType)) && (
          <>
            <TextField
              label="Repayment Time"
              value={repaymentTime}
              onChange={onChangeRepaymentTime}
              type="number"
              style={styles.input}
              helperText={loanTerms.repaymentTime ? `Previous is ${loanTerms.repaymentTime}. Limit is ${maxRepaymentTime ? `${maxRepaymentTime} max` : 'not set'}` : 'Field not already set'}
              error={!!errorRepaymentTime}
            />
            <TextField
              label="Startup Fee"
              value={startupFee}
              onChange={onChangeStartupFee}
              type="number"
              style={styles.input}
              helperText={loanTerms.startupFee ? `Previous is ${loanTerms.startupFee}` : 'Field not already set'}
              error={!!errorStartupFee}
            />
          </>
        )}
        {isMtpInvoicing(accountType) && (
          <TextField
            label="Credit limit"
            value={creditLimit}
            onChange={onChangeCreditLimit}
            type="number"
            style={styles.input}
            helperText={loanTerms.creditLimit ? `Previous is ${loanTerms.creditLimit}` : 'Field not already set'}
            error={!!errorCreditLimit}
          />
        )}
        {isAnnuityInvoicing(accountType) && (
          <TextField
            disabled
            key="annuity"
            name="annuity"
            label="annuity"
            value={getAnnuityAmount(
              amount,
              repaymentTime,
              nominalRate,
              startupFee,
            )}
            type="number"
            style={styles.input}
          />
        )}
        <TextField
          label="Reason"
          multiline
          maxRows="4"
          style={styles.reasonInput}
          value={reason}
          onChange={onChangeReason}
        />
      </div>
    </ModalButton>
  );
};

export default OverridePricingTermsButton;
