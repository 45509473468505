import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { ssnFormatter, ssnParser } from '../../utils/formatters';
import { socialSecurityNumber } from '../../utils/validationErrors';

const validateStock = [required(), socialSecurityNumber];

const BlackListCreate = () => (
  <Create title="Create Blacklisted Item">
    <SimpleForm>
      <TextInput source="socialSecurityNumber" validate={validateStock} format={ssnFormatter} parse={ssnParser} />
    </SimpleForm>
  </Create>
);

export default BlackListCreate;
