import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ColoredButton from './ColoredButton';

import useDownloadDocument from '../../hooks/useDownloadDocument';
import { useConstantContext } from './ConstantsContext';

const DigitalAgreementLink = ({
  id,
  accountType,
}) => {
  const { accountFeatures } = useConstantContext();

  const hasDigitalAgreementTemplate = accountFeatures.hasDigitalAgreementTemplate
    .includes(accountType);

  const { mutate: getDigitalAgreementDocument } = useDownloadDocument({
    path: `download/application/${id}/digital-agreement`,
    errorMessage: 'Failed to download digital agreement',
  });

  const onClick = useCallback(
    () => getDigitalAgreementDocument(),
    [getDigitalAgreementDocument],
  );

  if (!hasDigitalAgreementTemplate) return null;
  return (
    <ColoredButton
      onClick={onClick}
      background="DodgerBlue"
      color="white"
    >
      Download digital agreement
    </ColoredButton>
  );
};

DigitalAgreementLink.propTypes = {
  id: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
};

export default DigitalAgreementLink;
