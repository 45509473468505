import React from 'react';
import PropTypes from 'prop-types';

import {
  ArrayField,
  Datagrid,
  DateField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';

import MoneyField from './MoneyField';
import OpenInvoice from './OpenInvoice';
import SendInvoiceManuallyButton from './SendInvoiceManuallyButton';
import { useConstantContext } from './ConstantsContext';

const InvoiceExpand = ({ accountId }) => {
  const record = useRecordContext();
  const invoiceType = record.type;
  const { constants } = useConstantContext();
  const { invoiceTypes } = constants;
  if (invoiceTypes && invoiceType !== invoiceTypes.INVOICE) return null;
  return (
    <SimpleShowLayout>
      <ArrayField source="dunning" label="Dunning">
        <Datagrid bulkActionButtons={null}>
          <MoneyField source="fee.amount" label="Amount" />
          <DateField source="createdAt" label="Created&nbsp;at" />
          <DateField source="expiryDate" label="Expiry&nbsp;date" />
          <OpenInvoice label="Open" accountId={accountId} />
        </Datagrid>
      </ArrayField>
      <ArrayField label="Credit Notes" source="creditInvoices">
        <Datagrid bulkActionButtons={null}>
          <MoneyField source="amount" label="Amount" />
          <DateField source="createdAt" label="Created&nbsp;at" />
          <DateField source="expiryDate" label="Expiry&nbsp;date" />
          <OpenInvoice label="Open" accountId={accountId} />
        </Datagrid>
      </ArrayField>
      <ArrayField label="Payments" source="payments">
        <Datagrid bulkActionButtons={null}>
          <TextField label="Transaction Id" source="transactionId" />
          <MoneyField source="amount" label="Amount" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

InvoiceExpand.propTypes = {
  accountId: PropTypes.string,
};

InvoiceExpand.defaultProps = {
  accountId: undefined,
};

const InvoiceFields = () => {
  const account = useRecordContext();
  return (
    <ArrayField source="invoices">
      <Datagrid bulkActionButtons={null} expand={<InvoiceExpand accountId={account?.id} />}>
        <TextField source="type" />
        <DateField source="createdAt" label="Created&nbsp;at" />
        <DateField source="expiryDate" label="Expiry&nbsp;date" />
        <MoneyField label="Left&nbsp;To&nbsp;Pay" source="remainingAmountToPay" />
        <TextField label="Status" source="status" />
        <OpenInvoice label="Open" accountId={account?.id} />
        <SendInvoiceManuallyButton accountId={account?.id} />
      </Datagrid>
    </ArrayField>
  );
};

export default InvoiceFields;
