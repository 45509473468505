import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';

import ModalButton from './ModalButton';

import { useConstantContext } from './ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const CancelAccountButton = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { cancellationSolvingAmountBuffer: solvingAmountBuffer, accountStatuses } = constants;

  const {
    id: accountId,
    status,
    isEligibleForCancellation,
    todaySolvingAmount,
  } = record || {};

  const { mutate: cancelAccount } = useCustomRpc({
    httpMethod: 'PUT',
    path: `account/${accountId}/close`,
    successMessage: 'Account cancelled',
    errorMessage: 'Failed cancelling account',
    shouldRefresh: true,
  });

  const onClickClose = useCallback(() => {
    cancelAccount();
    return true;
  }, [cancelAccount]);

  const onClickDummy = useCallback(() => true, []);

  if (
    !accountId
    || status === accountStatuses.CANCELLED
    || status === accountStatuses.SOLD
  ) return null;

  if (todaySolvingAmount > solvingAmountBuffer && !isEligibleForCancellation) {
    return (
      <ModalButton
        title="Cancel Account"
        onClick={onClickDummy}
        right
      >
        <Typography variant="h6" color="error" align="center">Account not Cancellable</Typography>
        <Typography variant="body2" align="center">You can cancel only accounts with solving amount lower than <strong>{solvingAmountBuffer} kr</strong> or elegible for cancellation.</Typography>
      </ModalButton>
    );
  }

  if (todaySolvingAmount < solvingAmountBuffer && !isEligibleForCancellation) {
    return (
      <ModalButton
        title="Cancel Account"
        onClick={onClickClose}
        right
      >
        <Typography variant="body2" align="center">Are you sure to close this account?</Typography>
      </ModalButton>
    );
  }

  return (
    <ModalButton
      title="Cancel Account"
      onClick={onClickClose}
      right
    >
      <Typography variant="h6" color="error" align="center">WARNING</Typography>
      <Typography variant="body2" color="error" align="center">You can cancel this account but you will mark it as defaulted.</Typography>
    </ModalButton>
  );
};

export default CancelAccountButton;
