import React, { useState, useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import ModalButton from '../utils/ModalButton';

import useInputStatus from '../hooks/useInputStatus';

import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const styles = {
  container: {
    float: 'right',
    paddingTop: '0.5rem',
  },
  input: {
    margin: '1rem 0',
  },
};

const contexes = {
  APPLICATION: 'application',
  HOLDER: 'holder',
};

const MarkAMLRisk = ({ context }) => {
  const record = useRecordContext();
  const { id, applicants } = record;

  const { choices } = useConstantContext();
  const { amlRiskReasons } = choices;
  const { mutate: markAMLRisk } = useCustomRpc({
    httpMethod: 'POST',
    path: 'riskReport/',
    successMessage: 'AML risk report created',
    errorMessage: 'Error creating AML risk report',
    shouldRefresh: true,
  });

  const [
    comment,
    setComment,
    onChangeComment,
  ] = useInputStatus();

  const [
    reason,
    setReason,
    onChangeReason,
  ] = useInputStatus();

  const defaultHolder = context === contexes.APPLICATION
    ? applicants[0]._id
    : id;

  const [
    holder,
    setHolder,
    onChangeHolder,
  ] = useInputStatus(defaultHolder);

  const [error, setError] = useState('');

  const onSubmit = useCallback(
    () => {
      if (!comment) {
        setError('Please add a comment');
        return false;
      }
      if (!reason) {
        setError('Please add a reason');
        return false;
      }
      if (!holder) {
        setError('Please select an holder');
        return false;
      }
      markAMLRisk({ holder, reason, comment });
      setReason('');
      setComment('');
      setHolder('');
      setError('');
      return true;
    },
    [comment, reason, holder, markAMLRisk, setReason, setComment, setHolder],
  );

  const onClose = useCallback(
    () => {
      setReason('');
      setComment('');
      setHolder('');
      setError('');
    },
    [setComment, setHolder, setReason],
  );

  if (!record) return null;

  return (
    <div style={styles.container}>
      <ModalButton
        title="Mark AML Risk"
        onClick={onSubmit}
        onClose={onClose}
      >
        {error && <Typography align="center" color="error">{error}</Typography>}
        <FormControl fullWidth style={styles.input}>
          <InputLabel shrink htmlFor="reason">
            Reason
          </InputLabel>
          <Select
            value={reason}
            onChange={onChangeReason}
            inputProps={{ id: 'reason', name: 'reason' }}
            fullWidth
          >
            {amlRiskReasons.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {context === contexes.APPLICATION && applicants.length > 1 && (
          <FormControl fullWidth style={styles.input}>
            <InputLabel shrink htmlFor={contexes.HOLDER}>
              Applicant
            </InputLabel>
            <Select
              fullWidth
              inputProps={{ id: contexes.HOLDER, name: contexes.HOLDER }}
              onChange={onChangeHolder}
              value={holder}
            >
              {applicants.map(({ _id: holderId, email }) => (
                <MenuItem value={holderId} key={holderId}>{holderId} - {email}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          id="comment"
          name="comment"
          label="Comment"
          multiline
          maxRows="4"
          fullWidth
          value={comment}
          onChange={onChangeComment}
          margin="normal"
          style={styles.input}
        />
      </ModalButton>
    </div>
  );
};

MarkAMLRisk.propTypes = {
  context: PropTypes.oneOf(Object.values(contexes)),
};

MarkAMLRisk.defaultProps = {
  context: contexes.HOLDER,
};

export default MarkAMLRisk;
