import React from 'react';
import PropTypes from 'prop-types';

import {
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js';

import { TextInput, required as requiredValidator } from 'react-admin';

import { phone } from '../../utils/validationErrors';

const PhoneNumberInput = ({
  source,
  required,
  label,
  disabled,
  readOnly,
  helperText,
}) => (
  <TextInput
    source={source}
    format={(value) => (value ? formatIncompletePhoneNumber(value, 'SE') : '')}
    parse={parseIncompletePhoneNumber}
    validate={(required ? [phone, requiredValidator('The phone number is required')] : [phone])}
    label={label}
    disabled={disabled}
    readOnly={readOnly}
    helperText={helperText}
    type="phone"
  />
);

PhoneNumberInput.propTypes = {
  required: PropTypes.bool,
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

PhoneNumberInput.defaultProps = {
  required: false,
  label: null,
  helperText: undefined,
  disabled: false,
  readOnly: false,
};

export default PhoneNumberInput;
