import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecordContext, useRedirect, useResourceContext } from 'react-admin';

import TextField from '@mui/material/TextField';

import ModalButton from './ModalButton';
import SelectField from './SelectField';
import validateBankDetailsFactory from './validateBankDetails';

import useInputStatus from '../hooks/useInputStatus';

import validators from '../../utils/validators';
import { useConstantContext } from './ConstantsContext';

const SendPaymentButton = ({
  dispatchAction,
  redirectToList,
  title,
}) => {
  const { constants, choices } = useConstantContext();
  const record = useRecordContext();
  const resource = useResourceContext();
  const { bankAccountTypes: bankAccountTypesConstants } = constants;
  const { bankAccountTypes: bankAccountTypesChoices } = choices;
  const validateBankDetails = validateBankDetailsFactory(bankAccountTypesConstants);
  const { paymentDetails: originalPaymentDetails = {}, id } = record;

  let originalBankAccountType = '';
  let originalAccountNumber = '';
  let originalClearingNumberNumber = '';
  let originalOcrNumber = '';
  if (originalPaymentDetails) {
    originalBankAccountType = originalPaymentDetails.bankAccountType || '';
    originalAccountNumber = originalPaymentDetails.accountNumber || '';
    originalClearingNumberNumber = originalPaymentDetails.clearingNumber || '';
    originalOcrNumber = originalPaymentDetails.ocrNumber || '';
  }
  const [
    bankAccountType,
    setBankAccountType,
    onChangeBankAccountType,
    bankAccountTypeError,
    setBankAccountTypeError,
  ] = useInputStatus(originalBankAccountType);

  const [
    accountNumber,
    setAccountNumber,
    onChangeAccountNumber,
    accountNumberError,
    setAccountNumberError,
  ] = useInputStatus(originalAccountNumber);

  const [
    clearingNumber,
    setClearingNumber,
    onChangeClearingNumber,
    clearingNumberError,
    setClearingNumberError,
  ] = useInputStatus(originalClearingNumberNumber);

  const [ocrNumber, setOcrNumber] = useState(originalOcrNumber);
  const [ocrNumberError, setOcrNumberError] = useState(false);
  const [ocrNumberHelperText, setOcrNumberHelperText] = useState('');
  const onChangeOcrNumber = useCallback(
    (e) => {
      const ocr = e.target.value;
      setOcrNumberError(false);
      setOcrNumberHelperText('');

      if (!validators.ocrNumber(ocr, { withLengthCheck: false, withCheckDigit: false })) {
        setOcrNumberHelperText('This is not a valid ocr number');
        setOcrNumberError(true);
      } else if (!validators.generics.optional(
        validators.ocrNumber,
        ocr,
        { withLengthCheck: true, withCheckDigit: true },
      )) {
        setOcrNumberHelperText('Warning: Ocr number doesn\'t follow the standard validation');
      }

      setOcrNumber(ocr);
    },
    [setOcrNumber, setOcrNumberError],
  );

  const redirect = useRedirect();
  const onSubmitSendPayment = useCallback(() => {
    setBankAccountTypeError('');
    setAccountNumberError('');
    setClearingNumberError('');

    if (!accountNumber) {
      setAccountNumberError('Please enter an account number');
      return false;
    }

    const paymentDetails = {
      bankAccountType,
      accountNumber,
      clearingNumber,
      ocrNumber,
    };
    const {
      bankAccountType: bankAccountTypeValidation,
      accountNumber: accountNumberValidation,
      clearingNumber: clearingNumberValidation,
    } = validateBankDetails(paymentDetails);

    if (bankAccountTypeValidation) setBankAccountTypeError(bankAccountTypeValidation);
    if (accountNumberValidation) setAccountNumberError(accountNumberValidation);
    if (clearingNumberValidation) setClearingNumberError(clearingNumberValidation);

    if ((bankAccountType === bankAccountTypesConstants.BANK_GIRO
        || bankAccountType === bankAccountTypesConstants.POST_GIRO)
        && !ocrNumber
    ) {
      setOcrNumberError(true);
      setOcrNumberHelperText('Ocr number is required for this account type');
      return false;
    }

    if (bankAccountTypeValidation
        || accountNumberValidation
        || clearingNumberValidation
        || ocrNumberError
    ) return false;

    dispatchAction(id, paymentDetails);
    if (redirectToList) redirect('list', resource);
    return true;
  }, [setBankAccountTypeError, setAccountNumberError, setClearingNumberError, accountNumber,
    bankAccountType, clearingNumber, ocrNumber, validateBankDetails,
    bankAccountTypesConstants.BANK_GIRO, bankAccountTypesConstants.POST_GIRO, ocrNumberError,
    dispatchAction, id, redirectToList, redirect, resource]);

  const onClose = () => {
    setBankAccountType(originalBankAccountType);
    setBankAccountTypeError('');
    setAccountNumber(originalAccountNumber);
    setAccountNumberError('');
    setClearingNumber(originalClearingNumberNumber);
    setClearingNumberError('');
    setOcrNumber(originalOcrNumber);
    setOcrNumberError(false);
    setOcrNumberHelperText('');
  };

  return (
    <ModalButton
      title={title}
      onClick={onSubmitSendPayment}
      onClose={onClose}
      right
    >
      <SelectField
        label="Bank account type"
        onChange={onChangeBankAccountType}
        value={bankAccountType}
        error={bankAccountTypeError}
        choices={bankAccountTypesChoices}
      />
      <TextField
        label="Enter the bank account number"
        onChange={onChangeAccountNumber}
        value={accountNumber}
        error={!!accountNumberError}
        helperText={accountNumberError}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Enter the clearing number"
        onChange={onChangeClearingNumber}
        value={clearingNumber}
        error={!!clearingNumberError}
        helperText={clearingNumberError}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Enter the ocr number"
        onChange={onChangeOcrNumber}
        value={ocrNumber}
        error={ocrNumberError}
        helperText={ocrNumberHelperText}
        margin="normal"
        fullWidth
      />
    </ModalButton>
  );
};

SendPaymentButton.propTypes = {
  dispatchAction: PropTypes.func.isRequired,
  redirectToList: PropTypes.bool,
  title: PropTypes.string,
};

SendPaymentButton.defaultProps = {
  redirectToList: false,
  title: 'Send Payment',
};

export default SendPaymentButton;
