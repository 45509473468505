import React, { useCallback } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import ModalButton from '../utils/ModalButton';
import validators from '../../utils/validators';

import useInputStatus from '../hooks/useInputStatus';

import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';
import onSuccessGetDocument from '../../utils/onSuccessGetDocument';

const { isDefined } = validators.generics;

const DownloadPledgeeButton = () => {
  const { choices } = useConstantContext();
  const { pledgees } = choices;

  const { mutate: getPledgeeDocument } = useCustomRpc({
    httpMethod: 'GET',
    errorMessage: 'Could not retrieve file',
    onSuccess: onSuccessGetDocument,
  });

  const [
    pledgee,
    setPledgee,
    onChangePledgee,
    pledgeeError,
    setPledgeeError,
  ] = useInputStatus();

  const onClick = useCallback(
    () => {
      setPledgeeError('');

      if (!isDefined(pledgee)) {
        setPledgeeError('Please select a pledgee');
        return false;
      }

      getPledgeeDocument({ overridePath: `files/pledgee/${pledgee}` });
      return true;
    },
    [getPledgeeDocument, pledgee, setPledgeeError],
  );

  const onClose = useCallback(() => {
    setPledgee('');
    setPledgeeError('');
  }, [setPledgee, setPledgeeError]);

  return (
    <ModalButton
      title="Download Pledgee File"
      onClick={onClick}
      onClose={onClose}
    >
      <Select
        value={pledgee}
        onChange={onChangePledgee}
        inputProps={{ name: 'pledgee', id: 'pledgee' }}
        fullWidth
        error={!!pledgeeError}
      >
        {pledgees.map((item) => (
          <MenuItem
            value={item.id}
            key={item.id}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {pledgeeError && <FormHelperText style={{ width: '100%' }} error>{pledgeeError}</FormHelperText>}
    </ModalButton>
  );
};

export default DownloadPledgeeButton;
