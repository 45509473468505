import React from 'react';

import {
  List,
  TextField,
  DateField,
  ShowButton,
  ReferenceField,
  TextInput,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import HeadedDatagrid from '../utils/HeadedDatagrid';

const rejectedPayoutFilters = [
  <TextInput label="Transaction ID" source="_id" alwaysOn />,
  <TextInput label="Account ID" source="account" alwaysOn />,
  <TextInput label="Payout reference" source="paymentDetails.payoutReference" alwaysOn />,
];

const RejectedPayoutList = () => (
  <List filters={rejectedPayoutFilters}>
    <HeadedDatagrid
      head={(<PageTitle title="Rejected Payouts | List" />)}
    >
      <TextField label="Transaction id" source="id" />
      <ReferenceField label="Related account" source="account" reference="account">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="amount" />
      <DateField label="Paid out at" source="paymentDetails.payedOutAt" />
      <DateField label="Rejected At" source="paymentDetails.rejectedAt" />
      <TextField source="paymentDetails.payoutReference" label="Payout Reference" />
      <ShowButton />
    </HeadedDatagrid>
  </List>
);

export default RejectedPayoutList;
