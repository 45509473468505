import React, { useState, useCallback, useEffect } from 'react';

import {
  useLogin,
  useNotify,
  Notification,
} from 'react-admin';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import QRCode from '../utils/QRCode';

import { getJWTPayload } from '../../utils/jwt';

import store from '../../utils/store';

const extractMfaToken = (token) => {
  if (!token) return undefined;
  return getJWTPayload(token);
};

const LoginContent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [mfaToken, setMfaToken] = useState(extractMfaToken(store.getItem('mfaToken')));
  const login = useLogin();
  const notify = useNotify();

  const submit = useCallback((e) => {
    e.preventDefault();
    if (!email || !password) return;

    login({ username: email, password }).catch((err) => {
      if (err.message === 'MFA required') return;
      notify('Invalid email or password', { type: 'error' });
    });
  }, [login, notify, email, password]);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const handleMfaCodeChange = useCallback((e) => {
    setMfaCode(e.target.value);
  }, []);

  useEffect(() => {
    const unsubscribe = store.subscribe('mfaToken', (value) => {
      setMfaToken(extractMfaToken(value));
    });

    return unsubscribe;
  }, []);

  const submitMfaCode = useCallback((e) => {
    e.preventDefault();
    if (!mfaCode) {
      notify('Invalid code', { type: 'error' });
      return;
    }
    login({
      userId: mfaToken.user_id,
      code: mfaCode,
      mfaSecretKey: mfaToken.mfaSecretKey,
    }).then(() => {
      if (mfaToken.mfaSecretKey) {
        notify('Successfully setup MFA');
      }
    }).catch((err) => {
      notify(`Failed to login through MFA: ${err}`, { type: 'error' });
    });
  }, [login, notify, mfaCode, mfaToken]);

  if (mfaToken) {
    return (
      <>
        <Typography variant="body2" color="textSecondary" align="center">Enter an MFA code to complete sign-in.</Typography>
        {
          mfaToken.otpauth_url
          && <QRCode link={decodeURIComponent(mfaToken.otpauth_url)} />
        }
        <form onSubmit={submitMfaCode}>
          <FormControl variant="standard">
            <InputLabel htmlFor="mfaCode">Code</InputLabel>
            <Input
              id="mfaCode"
              name="mfaCode"
              type="mfaCode"
              onChange={handleMfaCodeChange}
            />
          </FormControl>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="stretch"
            marginTop={2}
            marginBottom={4}
          >
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              Log in
            </Button>
          </Box>
        </form>
      </>
    );
  }

  return (
    <>
      <Typography
        color="primary"
        align="center"
        variant="h6"
        component="h1"
        gutterBottom
      >
        Log into your account
      </Typography>
      <form onSubmit={submit}>
        <Box flexDirection="column" display="flex" paddingX={8} marginTop={2}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="stretch"
            marginBottom={2}
          >
            <FormControl variant="standard">
              <InputLabel htmlFor="email">Username</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
              />
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="stretch"
            marginBottom={2}
          >
            <FormControl variant="standard">
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="stretch"
            marginTop={2}
            marginBottom={4}
          >
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              Log in
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

const Login = () => (
  <>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      flexGrow="1"
      minHeight="100vh"
    >
      <Container
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
        }}
        maxWidth="xs"
      >
        <Box marginY={3}>
          <Typography variant="subtitle1" color="textPrimary" align="center">Welcome to Moank Admin</Typography>
        </Box>
        <Card>
          <CardContent>
            <LoginContent />
          </CardContent>
        </Card>
      </Container>
      <Box
        display="flex"
        justifyContent="center"
        paddingY={4}
      >
        <Typography variant="caption" color="textSecondary">Copyright &copy;{new Date().getFullYear()} Moank AB.</Typography>
      </Box>
    </Box>
    <Notification />
  </>
);

export default Login;
