import React, { useState, useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Title } from 'react-admin';
import { Button, CircularProgress, Typography } from '@mui/material';

import useCustomRpc from '../../hooks/useCustomRpc';

const styles = {
  formStyle: { padding: '0 1em 3em 1em' },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};

const BalanceReport = ({
  classes,
}) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const onChange = useCallback((e) => {
    setDate(moment(e.target.value).format('YYYY-MM-DD'));
  }, [setDate]);

  const { mutate: getBalanceReportDocument, isLoading } = useCustomRpc({
    httpMethod: 'POST',
    path: 'files/balance-report',
    successMessage: 'File generation has started',
    errorMessage: 'File generation failed',
  });

  const onSubmit = useCallback(() => {
    getBalanceReportDocument({ date });
  }, [date, getBalanceReportDocument]);

  return (
    <Card>
      <Title title="Balance Report" />
      <div className={classes.formStyle}>
        <div>
          <TextField
            id="date"
            label="Balance at"
            margin="normal"
            type="date"
            value={date}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div>
          <Button
            color="primary"
            variant="contained"
            disabled={isLoading}
            onClick={onSubmit}
          >
            Generate balance report
          </Button>
          <Typography>After the generation starts, the file will be available in
            s3 moank-temporary-bucket with path &quot;debt-summary/{date}.csv&quot; in a few minutes
          </Typography>
          {isLoading && <CircularProgress className={classes.buttonProgress} />}
        </div>
      </div>
    </Card>
  );
};

BalanceReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BalanceReport);
