import React from 'react';

import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  ShowView,
  SimpleShowLayout,
  TextField,
  FunctionField,
  useShowController,
  ShowContextProvider,
  NumberField,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import OrganisationNumberField from '../utils/OrganisationNumberField';
import FieldsGroup from '../utils/FieldsGroup';

const TrapetsCompanyCheckShow = () => {
  const controllerProps = useShowController();

  return (
    <ShowContextProvider value={controllerProps}>
      <ShowView>
        <SimpleShowLayout>
          <PageTitle title="Trapets Company Check | Show" />
          <ReferenceField label="Organisation Number" source="organisation" reference="organisation">
            <OrganisationNumberField source="organisationNumber" label="Organisation Number" />
          </ReferenceField>
          <DateField source="createdAt" />
          <FunctionField render={({ services }) => ((services || []).join(', ')).toUpperCase()} label="Services" />
          <ArrayField source="possibleMatches" label="">
            <Datagrid bulkActionButtons={null} hover={false}>
              <FieldsGroup name="Possible Match" fullWidth withBorder>
                <FieldsGroup column={3} name="Details" fullWidth withBorder>
                  <TextField source="listType" label="List&nbsp;type" />
                  <DateField source="createdAt" label="Created&nbsp;at" />
                  <DateField source="lastUpdate" label="Last&nbsp;update" />
                  <TextField source="name" />
                  <TextField source="externalId" label="External&nbsp;Id" />
                  <NumberField source="hitRating" label="Hit&nbsp;rating" />
                  <NumberField source="keyWordHitRating" label="Key&nbsp;Word&nbsp;Hit&nbsp;rating" />
                  <TextField source="operationalStatus" label="Operational&nbsp;status" />
                  <DateField source="foundationDate" label="Fundation&nbsp;date" />
                  <TextField source="corporateId" label="Corporate&nbsp;Id" />
                  <FunctionField render={({ addresses }) => ((addresses || []).join(', '))} label="Addresses" />
                  <TextField source="comment" />
                  <TextField source="sourcename" />
                  <FunctionField render={({ aliases }) => ((aliases || []).join(', '))} label="Aliases" />
                  <FunctionField render={({ externalUrls }) => ((externalUrls || []).join(', '))} label="External&nbsp;Urls" />
                </FieldsGroup>
                <FieldsGroup name="Industry&nbsp;codes" withBorder>
                  <ArrayField source="industryCodes">
                    <Datagrid bulkActionButtons={null}>
                      <TextField source="name" />
                      <TextField source="value" />
                      <TextField source="description" />
                    </Datagrid>
                  </ArrayField>
                </FieldsGroup>
                <FieldsGroup name="Countries" withBorder>
                  <ArrayField source="countries">
                    <Datagrid bulkActionButtons={null}>
                      <TextField source="countryCode" />
                      <TextField source="countryType" />
                    </Datagrid>
                  </ArrayField>
                </FieldsGroup>
                <FieldsGroup name="Beneficial&nbsp;Owners" withBorder fullWidth>
                  <ArrayField source="beneficialOwnersInfo.beneficialOwners">
                    <Datagrid bulkActionButtons={null}>
                      <TextField source="itemNumber" label="SSN" />
                      <TextField source="name" />
                      <TextField source="country" />
                      <TextField source="nationality" />
                      <TextField source="controlInterval" />
                      <TextField source="controlCodes" />
                    </Datagrid>
                  </ArrayField>
                </FieldsGroup>
              </FieldsGroup>
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};

export default TrapetsCompanyCheckShow;
