import React, { useState, useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

import ModalButton from '../utils/ModalButton';
import SelectField from '../utils/SelectField';

import useInputStatus from '../hooks/useInputStatus';

import validators from '../../utils/validators';
import { useConstantContext } from '../utils/ConstantsContext';
import useCustomRpc from '../../hooks/useCustomRpc';

const { required } = validators.generics;

const useStylesWrapper = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: '1rem',
    },
  },
  checkBoxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  checkBoxRow: {
    display: 'flex',
  },
  helperText: {
    width: '100%',
  },
  textInputWrapper: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
}));

const useStylesInlineInput = makeStyles(() => ({
  root: {
    '&:not(:last-child)': {
      marginRight: '1rem',
    },
  },
}));

const AddBankAccount = () => {
  const record = useRecordContext();
  const [file, setFile] = useState(undefined);
  const [errorFile, setErrorFile] = useState('');
  const [isValidForPayin, setIsValidForPayin] = useState(false);
  const [isValidForPayout, setIsValidForPayout] = useState(false);
  const [errorPayoutPayin, setErrorPayoutPayin] = useState('');

  const { choices } = useConstantContext();
  const { banks } = choices;

  const { mutate: sendVerifiedBankAccountStatement } = useCustomRpc({
    httpMethod: 'POST',
    path: `user/${record?.id}/bank-account`,
    mimeType: 'formData',
    shouldRefresh: true,
  });

  const onChangeFile = useCallback((e) => {
    setErrorFile('');
    setFile(e.target.files[0]);
  }, []);

  const onCheckPayin = useCallback((e) => {
    setErrorPayoutPayin('');
    setIsValidForPayin(e.target.checked);
  }, []);
  const onCheckPayout = useCallback((e) => {
    setErrorPayoutPayin('');
    setIsValidForPayout(e.target.checked);
  }, []);

  const [
    accountNumber,
    setAccountNumber,
    onChangeAccountNumber,
    errorAccountNumber,
    setErrorAccountNumber,
  ] = useInputStatus('');

  const [
    clearingNumber,
    setClearingNumber,
    onChangeClearingNumber,
    errorClearingNumber,
    setErrorClearingNumber,
  ] = useInputStatus('');

  const [
    bank,
    setBank,
    onChangeBank,
    errorBank,
    setErrorBank,
  ] = useInputStatus('');

  const [
    reason,
    setReason,
    onChangeReason,
    reasonError,
    setReasonError,
  ] = useInputStatus();

  const onClose = useCallback(() => {
    setFile(undefined);
    setClearingNumber('');
    setReason('');
    setAccountNumber('');
    setBank('');
    setIsValidForPayout(false);
    setIsValidForPayin(false);

    setErrorFile('');
    setErrorClearingNumber('');
    setErrorAccountNumber('');
    setErrorBank('');
    setErrorPayoutPayin('');
    setReasonError('');

    return true;
  }, [setAccountNumber, setBank, setClearingNumber, setErrorAccountNumber, setErrorBank,
    setErrorClearingNumber, setReason, setReasonError]);

  const onClick = useCallback(() => {
    if (
      errorFile
      || errorAccountNumber
      || errorClearingNumber
      || errorBank
      || errorPayoutPayin
      || reasonError
    ) return false;

    const validateAccountNumber = required(validators.accountNumber, accountNumber);
    const validateClearingNumber = required(validators.clearingNumber, clearingNumber);
    const validateBank = required(validators.bank, bank);
    const validateReason = required(validators.generics.isString, reason);
    const isValidPayinPayout = (
      (isValidForPayin && isValidForPayout)
      || isValidForPayin || isValidForPayout);
    if (
      !file
      || !validateAccountNumber
      || !validateClearingNumber
      || !validateBank
      || !isValidPayinPayout
      || !validateReason
    ) {
      if (!file) setErrorFile('Need the verified bank account statement');
      if (!validateAccountNumber) setErrorAccountNumber('Invalid account number, it should be between 5 and 12 digits without any other characters');
      if (!validateClearingNumber) setErrorClearingNumber('Invalid clearing number, it should be 4 or 5 digits without any other characters');
      if (!validateBank) setErrorBank('Invalid bank');
      if (!isValidPayinPayout) setErrorPayoutPayin('Account must be valid for either payin or payout');
      if (!validateReason) setReasonError('Need to leave a reason');

      return false;
    }

    const bankAccountDetails = {
      accountNumber,
      clearingNumber,
      bank,
      isValidForPayout,
      isValidForPayin,
      reason,
    };
    const formData = [
      ...Object.entries(bankAccountDetails).map(([key, value]) => ({
        fieldName: key,
        value,
      })),
      {
        fieldName: 'file',
        value: file,
      },
    ];
    sendVerifiedBankAccountStatement(formData);

    return true;
  }, [errorFile, errorAccountNumber, errorClearingNumber, errorBank, errorPayoutPayin,
    reasonError, accountNumber, clearingNumber, bank, reason, isValidForPayin, isValidForPayout,
    file, sendVerifiedBankAccountStatement, setErrorAccountNumber, setErrorClearingNumber,
    setErrorBank, setReasonError]);

  const wrapperClasses = useStylesWrapper();
  const inlineClasses = useStylesInlineInput();

  return (
    <ModalButton
      title="Add verified bank account"
      onClick={onClick}
      onClose={onClose}
      buttonWrapperStyle={{ margin: '0.5rem 0 2rem' }}
    >
      <Typography align="center">Upload the verified bank account statement:</Typography>
      <div style={{ margin: '2rem' }}>
        <div className={wrapperClasses.root}>
          <div>
            <input
              type="file"
              name="file"
              accept=".pdf"
              onChange={onChangeFile}
            />
            {errorFile && (
            <FormHelperText className={wrapperClasses.helperText} error>
              {errorFile}
            </FormHelperText>
            )}
          </div>
          <SelectField
            label="Bank"
            value={bank}
            choices={banks}
            onChange={onChangeBank}
            error={errorBank}
          />
          <div className={wrapperClasses.textInputWrapper}>
            <TextField
              type="number"
              label="Clearing number"
              value={clearingNumber}
              classes={inlineClasses}
              helperText={errorClearingNumber}
              error={!!errorClearingNumber}
              onChange={onChangeClearingNumber}
            />
            <TextField
              type="number"
              label="Account number"
              value={accountNumber}
              classes={inlineClasses}
              helperText={errorAccountNumber}
              error={!!errorAccountNumber}
              onChange={onChangeAccountNumber}
            />
          </div>
          <div className={wrapperClasses.checkBoxRow}>
            <div className={wrapperClasses.checkBoxWrapper}>
              <Typography>Is valid for Payin: </Typography>
              <Checkbox
                checked={isValidForPayin}
                onChange={onCheckPayin}
              />
            </div>

            <div className={wrapperClasses.checkBoxWrapper}>
              <Typography>Is valid for Payout: </Typography>
              <Checkbox
                checked={isValidForPayout}
                onChange={onCheckPayout}
              />
            </div>
          </div>
          {errorPayoutPayin && (
            <FormHelperText className={wrapperClasses.helperText} error>
              {errorPayoutPayin}
            </FormHelperText>
          )}
          <TextField
            type="string"
            label="Reason"
            value={reason}
            classes={inlineClasses}
            helperText={reasonError}
            error={!!reasonError}
            onChange={onChangeReason}
            multiline
            maxRows="4"
            fullWidth
          />
        </div>
      </div>
    </ModalButton>
  );
};

export default AddBankAccount;
