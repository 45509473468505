import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import AcceptIcon from '@mui/icons-material/Done';
import RejectIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';

import useCustomRpc from '../../hooks/useCustomRpc';

const styles = {
  modal: {
    width: '500px',
    height: '125px',
    padding: '32px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2) 0px 5px 8px 0px rgba(0, 0, 0, 0.14) 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  buttonModal: {
    fontSize: '0.8rem',
    padding: '0.3rem 0.7rem',
    minWidth: 'auto',
    minHeight: 'auto',
    margin: '0 0.5rem',
    float: 'right',
  },
  comment: {
    width: '20rem',
    height: '2rem',
    display: 'block',
  },
};

const AcceptRejectButton = ({
  accept,
}) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');

  const record = useRecordContext();

  const { mutate: updateExtension, isLoading } = useCustomRpc({
    httpMethod: 'PUT',
    path: `account/extensions/${record.id}`,
    successMessage: accept ? 'Extension accepted' : 'Extension rejected',
    errorMessage: accept ? 'Extension accepting failed' : 'Extension rejecting failed',
    shouldRefresh: true,
  });

  const handleSubmit = useCallback(() => {
    if (accept) updateExtension({ accepted: true, comment });
    else updateExtension({ accepted: false, comment });
  }, [accept, updateExtension, comment]);

  const handleOpenModal = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    setOpen(false);
  }, []);

  const handleChange = useCallback((e) => {
    e.preventDefault();
    setComment(e.target.value);
  }, []);
  const icon = accept ? <AcceptIcon color="primary" /> : <RejectIcon color="error" />;

  return (
    <>
      <Button disabled={isLoading} onClick={handleOpenModal}>{icon}</Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleCloseModal}
      >
        <div style={styles.modal}>
          <Typography variant="h6" align="center">What is the reason of your decision?</Typography>
          <TextField
            id="reason"
            label="Reason"
            multiline
            maxRows="4"
            value={comment}
            onChange={handleChange}
            style={styles.comment}
            margin="normal"
            disabled={isLoading}
          />
          <div>
            <Button
              variant="contained"
              color="secondary"
              style={styles.buttonModal}
              onClick={handleCloseModal}
              disabled={isLoading}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={styles.buttonModal}
              onClick={handleSubmit}
              disabled={isLoading || (comment === '')}
            >
              {accept ? 'Accept' : 'Reject' }
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

AcceptRejectButton.propTypes = {
  accept: PropTypes.bool.isRequired,
};

export default AcceptRejectButton;
