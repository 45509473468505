import React, { useState, useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Kalp from './tabs/Kalp';

const styles = {
  openedAside: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    paddingTop: 48,
    paddingLeft: 32,
    paddingRight: 32,
    width: 'calc(100% - 64px)',
    maxWidth: 800,
  },
  closedAside: {
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    paddingTop: 64,
    paddingRight: 16,
  },
  button: { fontSize: 32 },
};

const useStyle = makeStyles(styles);

const KalpAside = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyle();
  const record = useRecordContext();

  const toggleAside = useCallback((e) => {
    e.preventDefault();
    setOpen(!open);
  }, [open, setOpen]);

  if (!record) return null;
  if (open) {
    return (
      <div className={classes.openedAside}>
        <Kalp
          applicationId={record.id}
          kalpUrl={record.kalpUrl}
          action={(
            <IconButton onClick={toggleAside} size="large">
              <KeyboardArrowRight className={classes.button} />
            </IconButton>
          )}
        />
      </div>
    );
  }
  return (
    <div className={classes.closedAside}>
      <IconButton onClick={toggleAside} size="large">
        <KeyboardArrowLeft className={classes.button} />
      </IconButton>
    </div>
  );
};

export default KalpAside;
