import React from 'react';
import PropTypes from 'prop-types';

import {
  ReferenceInput,
  Labeled,
  ReferenceField,
  TextField,
  TextInput,
  required,
  DateField,
  ArrayField,
  FunctionField,
  Datagrid,
} from 'react-admin';

import {
  accountNumber,
  clearingNumber,
  email,
} from '../../utils/validationErrors';
import cleanNumberSearch from '../../utils/cleanNumberSearch';

import DocumentsField from '../utils/DocumentsField';
import { useConstantContext } from '../utils/ConstantsContext';

import AddSignatory from './AddSignatory';
import SelectInput from '../input/SelectInput';
import AutocompleteInput from '../input/AutocompleteInput';

const checkSuggestionOrganisation = (filter, choice) => choice.organisationNumber
  .includes(cleanNumberSearch(filter));
const filterToQueryOrganisation = (searchText) => ({
  organisationNumber: cleanNumberSearch(searchText),
});
const userRenderer = (choice) => (choice
  ? `${choice.socialSecurityNumber} - ${choice.firstName || ''} ${choice.lastName || ''}`
  : '');

const OrganisationFields = ({
  applicationId,
  scopedFormData,
  getSource,
  readOnly,
}) => {
  const { choices } = useConstantContext();
  const { banks } = choices;
  const { user: userId } = scopedFormData;

  return applicationId && userId ? (
    <>
      <div>
        <Labeled label="Organisation Id">
          <ReferenceField
            source={getSource('_id')}
            reference="organisation"
          >
            <TextField source="id" />
          </ReferenceField>
        </Labeled>
      </div>
      <div>
        <Labeled label="Name">
          <ReferenceField
            link={false}
            source={getSource('_id')}
            reference="organisation"
          >
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
      </div>
      <div>
        <TextInput
          source={getSource('email')}
          label="Email"
          type="email"
          validate={[
            required('The email is required'),
            email,
          ]}
          readOnly={readOnly}
        />
      </div>
      <div>
        <Labeled label="Signatories">
          <ArrayField source={getSource('signatories')}>
            <Datagrid bulkActionButtons={null}>
              <ReferenceField
                source="user"
                reference="user"
                label="Signatory User"
              >
                <FunctionField render={userRenderer} />
              </ReferenceField>
              <TextField source="email" label="Email" />
              <TextField source="phone" label="Phone" />
              <DateField label="Signed At" source="signedAt" emptyText="-" />
            </Datagrid>
          </ArrayField>
        </Labeled>
      </div>
      <div>
        <AddSignatory />
      </div>
      <div>
        <ReferenceField
          label="Documents"
          source={getSource('_id')}
          reference="organisation"
          link={false}
        >
          <DocumentsField source="id" model="organisation" />
        </ReferenceField>
      </div>
    </>
  ) : (
    <>
      <div>
        <ReferenceInput
          label="Organisation"
          source={getSource('_id')}
          reference="organisation"
        >
          <AutocompleteInput
            optionText="name"
            filterToQuery={filterToQueryOrganisation}
            matchSuggestion={checkSuggestionOrganisation}
            validate={required()}
            readOnly={readOnly}
          />
        </ReferenceInput>
      </div>
      <div>
        <TextInput
          source={getSource('email')}
          type="email"
          label="Email"
          validate={[required, email]}
        />
      </div>
      <div>
        <TextInput
          source={getSource('bankDetails.clearingNumber')}
          label="Clearing number"
          validate={[clearingNumber]}
        />
      </div>
      <div>
        <TextInput
          source={getSource('bankDetails.accountNumber')}
          label="Account number"
          validate={[accountNumber]}
        />
      </div>
      <div>
        <SelectInput
          source={getSource('bankDetails.bank')}
          label="Bank"
          choices={banks}
        />
      </div>
    </>
  );
};

OrganisationFields.propTypes = {
  getSource: PropTypes.func.isRequired,
  applicationId: PropTypes.string,
  scopedFormData: PropTypes.shape({
    _id: PropTypes.string,
    user: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
};

OrganisationFields.defaultProps = {
  applicationId: undefined,
  scopedFormData: {},
  readOnly: false,
};

export default OrganisationFields;
