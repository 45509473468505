import React from 'react';
import {
  List,
  EditButton,
  DateField,
  TextInput,
} from 'react-admin';

import PageTitle from '../utils/PageTitle';
import SocialSecurityNumberField from '../utils/SocialSecurityNumberField';
import HeadedDatagrid from '../utils/HeadedDatagrid';

const blacklistFilters = [
  <TextInput label="Social Security Number" source="socialSecurityNumber" alwaysOn />,
];

const BlackListList = () => (
  <List filters={blacklistFilters} title="Blacklisted Items">
    <HeadedDatagrid head={(<PageTitle title="BlackList | List" />)}>
      <SocialSecurityNumberField source="socialSecurityNumber" />
      <DateField source="createdAt" />
      <EditButton />
    </HeadedDatagrid>
  </List>
);

export default BlackListList;
