import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import ChoicesPropType from './choicePropType';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: (props) => props.position,
  },
}));

const SelectField = ({
  label,
  value,
  choices,
  onChange,
  error,
  position,
}) => {
  const classes = useStyles({ position });

  return (
    <TextField
      label={label}
      value={value}
      helperText={error}
      error={!!error}
      onChange={onChange}
      classes={classes}
      select
    >
      {choices.map((item) => (
        <MenuItem
          value={item.id}
          key={item.id}
        >
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

SelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  choices: ChoicesPropType.isRequired,
  error: PropTypes.string,
  position: PropTypes.string,
};

SelectField.defaultProps = {
  error: null,
  position: 'relative',
};

export default SelectField;
